import { useCallback, useEffect } from 'react'

export const useClickOutside = (ref, callback) => {
  const memoizedCallback = useCallback(
    (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback()
      }
    },
    [ref, callback]
  )

  useEffect(() => {
    document.addEventListener('click', memoizedCallback)
    return () => {
      document.removeEventListener('click', memoizedCallback)
    }
  }, [memoizedCallback])
}
