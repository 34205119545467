import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import Footer from '../components/footer/footer'
import Style from './styles/page-now-found.module.css'

class PageNotFount extends Component {
  render() {
    return (
      <Fragment>
        <Fragment>
          <h2 className={Style.h2}>
            <FormattedMessage id={'404! Page not found'} />
          </h2>
        </Fragment>
        <div className={Style.textContainer}>
          <h1 className={Style.mainTitle}>
            <FormattedMessage id={'We are Sorry'} />
          </h1>
          <p>
            <FormattedMessage
              id={'The page you are looking for does not exist.'}
            />
          </p>
          <p className={Style.p}>
            <FormattedMessage id={'Return to the '} />
          </p>
          <Link className={Style.a} to="/">
            {' '}
            <FormattedMessage id={'Main Page'} />{' '}
          </Link>
          <p className={Style.p}>
            <FormattedMessage id={'for access to all Artemest stories.'} />
          </p>
        </div>
        <Footer absolute={true} />
      </Fragment>
    )
  }
}

export default PageNotFount
