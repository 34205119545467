import { shape, string } from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import Style from './translator-table-card.module.css'

const TranslatorTableCard = ({ data }) => {
  const { artisanName, name, vendor, _id } = data

  return (
    <div className={Style.title}>
      <h4 className={Style.values}>
        <Link to={`/translate-collection/${_id}`}>{name}</Link>: {artisanName} (
        {vendor})
      </h4>
      <h4 className={Style.bio}>
        <Link to={`/company-bio/${_id}/${vendor}`}>
          <FormattedMessage id="bio" />
        </Link>
      </h4>
    </div>
  )
}

TranslatorTableCard.propTypes = {
  data: shape({
    artisanName: string,
    name: string,
    vendor: string,
    _id: string
  })
}
export default TranslatorTableCard
