import { PageNav } from 'components'
import { Button } from 'components/buttons'
import Radio from 'components/formParts/Radio'
import { InnerPage } from 'components/layout'
import { Modal } from 'components/modals'
import { func, shape, string } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getUserType } from 'utils/auth'
import { dtFormat } from 'utils/dateTime'
import { vendorNavItems } from 'utils/utils'

import { mapActions, mapState } from './config'
import st from './VendorSubscription.module.css'

const VendorSubscription = ({
  match: {
    params: { vendorId }
  },
  location: { pathname },
  GetVendor,
  getVendor,
  initialSubscriptionTh,
  vendor,
  intl,
  authReducer,
  braintreePlans,
  SetPremium
}) => {
  const [enablePlanModalIsOpen, setEnablePlanModalIsOpen] = useState(false)
  const [disablePlanModalIsOpen, setDisablePlanModalIsOpen] = useState(false)
  const [selectedPlanId, setSelectedPlanId] = useState()
  const handleDisableSubscription = () => {
    const fakeEndDate = new Date()
    fakeEndDate.setFullYear(fakeEndDate.getFullYear() + 1)
    SetPremium(
      vendorId,
      vendor.premium.endDate ?? dtFormat(fakeEndDate, 'DD.MM.YYYY'),
      false,
      vendor.premium.planId ?? 'None',
      () => {
        GetVendor({ vendorId })
        setDisablePlanModalIsOpen(false)
      }
    )
  }

  const handleEnableSubscription = () => {
    const fakeEndDate = new Date()
    fakeEndDate.setFullYear(fakeEndDate.getFullYear() + 1)
    SetPremium(
      vendorId,
      dtFormat(fakeEndDate, 'DD.MM.YYYY'),
      true,
      selectedPlanId,
      () => {
        GetVendor({ vendorId })
        setEnablePlanModalIsOpen(false)
      }
    )
  }

  useEffect(() => {
    if (vendorId) {
      GetVendor({ vendorId: vendorId })
      initialSubscriptionTh({ vendorId })
    } else if (vendor) {
      getVendor(null)
    }
  }, [vendorId])

  return (
    <InnerPage heading={vendor?.artisanName} vendor={vendor}>
      <PageNav
        className={st.pageNav}
        items={vendorNavItems({
          id: vendorId,
          intl,
          path: pathname,
          userType: getUserType(authReducer)
        })}
      />
      <div className={st.status}>
        <FormattedMessage id="Premium status" />:{' '}
        {vendor?.premium?.active ? (
          <span className={st.statusActive}>
            <FormattedMessage id="Active" />
          </span>
        ) : (
          <span className={st.statusInactive}>
            <FormattedMessage id="Inactive" />
          </span>
        )}
      </div>
      {vendor?.premium?.active ? (
        <>
          <div className={st.detail}>
            <FormattedMessage id="Current Plan" />:{' '}
            {braintreePlans?.find((plan) => plan.id === vendor.premium.planId)
              ?.name ?? vendor.premium.planId}
          </div>
          <div className={st.detail}>
            <FormattedMessage id="Manually enabled" />:{' '}
            {vendor.premium.manuallyEnabled ? 'YES' : 'NO'}
          </div>
          <Button
            className={st.button}
            onClick={() => setDisablePlanModalIsOpen(true)}
          >
            <FormattedMessage id="Disable plan" />
          </Button>
          <Modal
            isOpen={disablePlanModalIsOpen}
            toggle={() => setDisablePlanModalIsOpen(false)}
            title={intl.formatMessage({
              id: 'Disable premium plan'
            })}
            body={
              <FormattedMessage id="Are you sure you want to delete the premium subscription of this artisan?" />
            }
            footer={
              <Button className={st.button} onClick={handleDisableSubscription}>
                <FormattedMessage id="Confirm" />
              </Button>
            }
          />
        </>
      ) : (
        <>
          <Button
            className={st.button}
            onClick={() => setEnablePlanModalIsOpen(true)}
          >
            <FormattedMessage id="Enable plan" />
          </Button>
          <Modal
            isOpen={enablePlanModalIsOpen}
            toggle={() => setEnablePlanModalIsOpen(false)}
            title={intl.formatMessage({
              id: 'Enable premium plan'
            })}
            body={
              <>
                <div className={st.detail}>
                  <FormattedMessage id="Select a plan:" />
                </div>
                {braintreePlans.map((plan) => (
                  <Radio
                    key={plan.id}
                    id={`plan-${plan.id}`}
                    name="braintreePlan"
                    value={plan.id}
                    onChange={() => setSelectedPlanId(plan.id)}
                    checked={plan.id === selectedPlanId}
                  >
                    {plan.name}
                  </Radio>
                ))}
              </>
            }
            footer={
              <Button
                className={st.button}
                onClick={handleEnableSubscription}
                disabled={!selectedPlanId}
              >
                <FormattedMessage id="Confirm" />
              </Button>
            }
          />
        </>
      )}
    </InnerPage>
  )
}

VendorSubscription.propTypes = {
  GetVendor: func,
  getVendor: func,
  SetPremium: func,
  initialSubscriptionTh: func,
  braintreePlans: shape([{ id: string, name: string }]),
  vendor: shape({}),
  intl: shape({
    formatMessage: func
  }),
  match: shape({
    params: shape({
      vendorId: string
    })
  }),
  location: shape({
    pathname: string
  }),
  authReducer: shape({})
}

export default connect(mapState, mapActions)(injectIntl(VendorSubscription))
