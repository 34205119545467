import { get, take, values } from 'lodash'
import { HideLoading, ShowLoading } from 'redux/actions/loading-action'
import { vendorProductsGot } from 'redux/actions/vendor-action'
import { getCollectionByVendor } from 'redux/service/collection-service'
import { getProductsByCollectionId } from 'redux/service/product-service'
import { STATUSES } from 'utils/constants'
import { sortProducts } from 'utils/products'

/**
 * @param {Object} config
 * @param {String} config.vendorId
 */
export const vendorDashboardInitTh =
  (
    config = {},
    history // Accept history object as a parameter
  ) =>
  (dispatch) => {
    const { vendorId } = config

    dispatch(ShowLoading())
    getCollectionByVendor(vendorId, 1, 1000)
      .then((res) => {
        const { data } = res

        const collectionIds = get(data, 'docs', [])
          .filter(({ approved }) => approved)
          .map(({ _id }) => _id)
        const promises = collectionIds.map((collectionId) =>
          getProductsByCollectionId({
            collectionId,
            productStatus: STATUSES.APPROVED,
            sort: 'addDate'
          })
        )

        return Promise.all(promises)
      })
      .then((res) => {
        const mappedByProductId = res
          .filter(({ products }) => products)
          .reduce((acc, { products }) => {
            products.forEach((product) => {
              const { _id, saved } = product

              if (!saved) {
                acc[_id] = product
              }
            })

            return acc
          }, {})
        const unifiedProductsSorted = take(
          sortProducts({
            by: 'addDate',
            products: values(mappedByProductId)
          }),
          10
        )

        dispatch(vendorProductsGot(unifiedProductsSorted))
        dispatch(HideLoading())
      })
      .catch(() => {
        history.push('/')
      })
  }
