import { noop } from 'lodash'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardFooter, CardHeader, Col } from 'reactstrap'
import { STATUSES } from 'utils/constants'

import Style from './collection-card.module.css'

const ColectionCard = ({
  data: { _id, mainPhoto, photos, status },
  noLinkHeading,
  updateProduct
}) => {
  const image = mainPhoto ? mainPhoto : photos && photos[0] ? photos[0] : ''

  const handleApprove = () => {
    updateProduct(_id, { status: STATUSES.APPROVED })
  }
  const handleReject = () => {
    updateProduct(_id, { status: STATUSES.REJECTED })
  }

  return (
    <Col className={Style.mainCol} sm={6} md={3} lg={3}>
      <Card>
        <CardHeader className={Style.SKU}>
          {noLinkHeading ? (
            _id
          ) : (
            <Link to={`/product-detail/${_id}`}>{_id}</Link>
          )}
        </CardHeader>
        <CardBody className={Style.body}>
          <img alt="product" className="image" src={image} />
        </CardBody>
        <CardFooter className={Style.footer}>
          <button
            disabled={status === STATUSES.APPROVED}
            onClick={handleApprove}
            className={[
              Style.alignLeft,
              status === STATUSES.APPROVED ? Style.warning : Style.submit
            ].join(' ')}
          >
            <FormattedMessage
              id={status === STATUSES.APPROVED ? 'Approved' : 'Approve'}
            />
          </button>
          <button
            disabled={status === 'Rejected'}
            onClick={handleReject}
            className={[
              Style.alignRight,
              status === STATUSES.REJECTED
                ? Style.danger
                : status === 'Pending'
                ? Style.warning
                : Style.danger
            ].join(' ')}
          >
            <FormattedMessage
              id={status === STATUSES.REJECTED ? 'Disapproved' : 'Disapprove'}
            />
          </button>
        </CardFooter>
      </Card>
    </Col>
  )
}

ColectionCard.defaultProps = {
  data: {},
  noLinkHeading: false,
  updateProduct: noop
}
ColectionCard.propTypes = {
  data: shape({
    _id: string,
    mainPhoto: string,
    status: string,
    photos: arrayOf(string)
  }),
  noLinkHeading: bool,
  updateProduct: func
}

export default ColectionCard
