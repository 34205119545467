import classNames from 'classnames'
import { Button } from 'components/buttons'
import { Input, InputFile } from 'components/formParts'
import Radio from 'components/formParts/Radio'
import Spinner from 'components/Spinner/Spinner'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import { string } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  attachETDDocumentation,
  deleteETDDocumentation,
  getOrderDocumentation,
  getShippingRates,
  shipOrder
} from 'redux/service/order-service'
import { USER_TYPES } from 'utils/constants'
import { showNotify } from 'utils/toast'

import Modal from './../modals/Modal'
import { currencyFormatter, sanitizeCurrencyString } from './utils'
import st from './VendorOrderShipment.module.css'

const VendorOrderShipments = ({ userType, orderNumber, vendor }) => {
  const [documentationPending, setDocumentationPending] = useState(true)
  const [rates, setRates] = useState([])
  const [ratesErrors, setRatesErrors] = useState([])
  const [shipErrors, setShipErrors] = useState([])
  const [ratesModal, setRatesModal] = useState(false)
  const [selectedRate, setSelectedRate] = useState({})
  const [shipping, setShipping] = useState(false)
  const [step, setStep] = useState(1)
  const [dirty, setDirty] = useState(false)
  const intl = useIntl()
  const isLogistics = userType === USER_TYPES.LOGISTICS
  const isAdmin = userType === USER_TYPES.ADMIN
  const isVendor = userType === USER_TYPES.VENDOR
  const today = new Date()
  let nextBusinessDaySum = 1
  if (today.getDay() === 5) {
    nextBusinessDaySum = 3
  } else if (today.getDay() === 6) {
    nextBusinessDaySum = 2
  }
  const nextBusinessDay = new Date().setDate(
    today.getDate() + nextBusinessDaySum
  )
  const tomorrowString = new Date(nextBusinessDay).toISOString().split('T')[0]
  const [pickupDate, setPickupDate] = useState(tomorrowString)

  const {
    values,
    errors,
    setValues,
    handleSubmit,
    handleBlur,
    handleChange,
    isSubmitting,
    setFieldValue
  } = useFormik({
    initialValues: {
      etdDocumentation: '',
      addInsurance: false,
      sendNotification: true,
      invoiceTotal: undefined,
      fedexBookingConfirmationNumber: undefined
    },
    onSubmit: async (values, { setSubmitting }) => {
      setDirty(true)
      const { data, error } = await getShippingRates({
        orderNumber,
        vendorId: vendor,
        invoiceTotal: sanitizeCurrencyString(values.invoiceTotal),
        fedexBookingConfirmationNumber: values.fedexBookingConfirmationNumber,
        addInsurance: values.addInsurance
      })

      if (error) {
        showNotify({
          isError: true,
          message: `${error.message}${error.data ? `: ${error.data}` : ''}`
        })
      } else {
        setRates(data.Rates)
        setRatesErrors(data.RatesErrors)
      }

      setSubmitting(false)
    },
    validate: (values) => {
      const { etdDocumentation, invoiceTotal } = values
      const errors = {}

      if (!etdDocumentation) {
        errors.etdDocumentation = 'Required'
      }

      if (!invoiceTotal) {
        errors.invoiceTotal = 'Required'
      }

      return errors
    }
  })

  const {
    etdDocumentation,
    shippingLabel,
    trackingExternalLink,
    addInsurance,
    sendNotification,
    invoiceTotal,
    fedexBookingConfirmationNumber
  } = values

  useEffect(() => {
    setSelectedRate({})
    setTimeout(() => {
      setStep(1)
    }, 250)
  }, [ratesModal])

  useEffect(() => {
    if (!isEmpty(rates)) {
      setRatesModal(true)
    } else {
      if (dirty) {
        showNotify({
          isError: true,
          message: intl.formatMessage({ id: 'noRates' })
        })
      }
    }
  }, [rates])

  useEffect(async () => {
    const { data, error } = await getOrderDocumentation({
      orderNumber,
      vendorId: vendor
    })
    if (error) {
      showNotify({ isError: true, message: error.message })
    }
    if (data) {
      const { etdDocumentation, shippingLabel, trackingExternalLink } = data
      setValues({
        ...values,
        etdDocumentation,
        shippingLabel,
        // TODO(Emil): Remove dummy link after QA
        trackingExternalLink: trackingExternalLink || `https://artemest.com/`
      })
      setDocumentationPending(false)
    }
  }, [vendor, orderNumber])

  const onETDUpload = async (e) => {
    setDocumentationPending(true)
    const { files } = e.target
    if (files[0]) {
      const response = await attachETDDocumentation({
        orderNumber,
        vendorId: vendor,
        etdDocumentation: files[0]
      })
      const { error, etdDocumentation } = response
      if (error) {
        showNotify({ isError: true, message: error.message })
      }
      if (etdDocumentation) {
        setValues({ ...values, etdDocumentation })
        showNotify({ message: 'ETD Documentation uploaded successfully' })
      }
      if (response) {
        setDocumentationPending(false)
      }
    }
  }
  const onETDRemove = async () => {
    const { success, error } = await deleteETDDocumentation({
      orderNumber,
      vendorId: vendor
    })
    if (error && !success) {
      showNotify({ isError: true, message: error.message })
    }

    if (success) {
      showNotify({ message: 'Removed the ETD Documentation successfully' })
      setValues({ ...values, etdDocumentation: '' })
    }
  }

  const onShip = async () => {
    if (!isEmpty(selectedRate) && !isEmpty(pickupDate)) {
      setShipping(true)
      const pickupTimestamp = new Date(pickupDate).setUTCHours(8)
      const unixTimestamp = Math.floor(pickupTimestamp / 1000) // ShippyPro needs the unix timestamp in seconds, 10 digits

      const response = await shipOrder({
        ...selectedRate,
        orderNumber,
        vendorId: vendor,
        addInsurance,
        sendNotification,
        invoiceTotal: sanitizeCurrencyString(invoiceTotal),
        fedexBookingConfirmationNumber,
        pickupTime: unixTimestamp
      })
      const { error, data } = response
      if (error) {
        showNotify({ isError: true, message: error.data })
      }
      if (data) {
        setValues({
          ...values,
          shippingLabel: data.LabelURL,
          trackingExternalLink:
            // TODO(Emil): Remove artemest link after qa
            data.TrackingExternalLink || 'https://artemest.com/',
          etdDocumentation
        })
        setStep(2)
        setShipErrors(data.Errors)
      }
      if (response) {
        setShipping(false)
        setSelectedRate({})
        setPickupDate()
      }
    } else {
      showNotify({
        isError: true,
        message: 'Select shipping rate and pickup time first'
      })
    }
  }

  const invoiceTotalHandleOnChange = (event) => {
    const { value } = event.currentTarget

    setFieldValue('invoiceTotal', currencyFormatter(value))
  }

  return isVendor && !shippingLabel ? null : (
    <div className={st.container} data-cy="shippingSection">
      <form onSubmit={handleSubmit}>
        <section>
          {(isLogistics || isAdmin) && (
            // this should be a number input
            // the logic under the hood is too complex
            // and type number happens to be a native text input
            // without non-numeric values sanitation
            // That's why invoiceTotalHandleOnChange is implemented
            <Input
              required
              label={`${intl.formatMessage({
                id: 'Invoice Total'
              })} *`}
              className={st.textInput}
              name="invoiceTotal"
              onChange={invoiceTotalHandleOnChange}
              value={invoiceTotal}
              error={dirty ? errors.invoiceTotal : ''}
            />
          )}
          <div className={st.fileInputs}>
            {(isLogistics || isAdmin) && (
              <div>
                <p className={st.fileInputTitle}>
                  <FormattedMessage id="ETD Documentation" />
                  {etdDocumentation && (
                    <a
                      href={etdDocumentation}
                      rel="noopener noreferrer"
                      target="_"
                      style={{ marginLeft: '0.5em' }}
                    >
                      Link
                    </a>
                  )}
                </p>
                <InputFile
                  label={intl.formatMessage({
                    id: 'Upload ETD documentation'
                  })}
                  onChange={onETDUpload}
                  accept="application/pdf"
                  isLoading={documentationPending}
                  fileUrl={etdDocumentation}
                  onClose={onETDRemove}
                  error={dirty ? errors.etdDocumentation : ''}
                  dataCy="ShippingInputFile"
                  disableModify={!isLogistics}
                />
              </div>
            )}
            {shippingLabel && (
              <div>
                <p className={st.fileInputTitle}>
                  {shippingLabel.split(',').length === 1 && (
                    <a
                      href={shippingLabel.split(',')[0]}
                      rel="noopener noreferrer"
                      target="_"
                      className={st.fileInputsTitleA}
                    >
                      <FormattedMessage id="Shipping label" />
                    </a>
                  )}
                  {shippingLabel.split(',').length > 1 && (
                    <>
                      <FormattedMessage id="Shipping label" />
                      {shippingLabel.split(',').map((label, index) => (
                        <a
                          key={label}
                          href={label}
                          rel="noopener noreferrer"
                          target="_"
                          className={st.fileInputsTitleA}
                        >
                          <FormattedMessage id="Label" />{' '}
                          {shippingLabel.split(',').length > 1 && index + 1}
                        </a>
                      ))}
                    </>
                  )}
                </p>
              </div>
            )}
            {shippingLabel && trackingExternalLink && (
              <div>
                <p className={st.fileInputTitle}>
                  <a
                    href={trackingExternalLink}
                    rel="noopener noreferrer"
                    target="_"
                    className={st.fileInputsTitleA}
                  >
                    <FormattedMessage id="Tracking link" />
                  </a>
                </p>
              </div>
            )}
          </div>
          {(isLogistics || isAdmin) && (
            <>
              <Input
                label={`${intl.formatMessage({
                  id: 'Fedex Booking Confirmation Number'
                })}`}
                className={st.textInput}
                name="fedexBookingConfirmationNumber"
                value={fedexBookingConfirmationNumber}
                error={dirty ? errors.fedexBookingConfirmationNumber : ''}
                onChange={handleChange}
              />
              <div
                className={classNames(
                  'custom-control custom-checkbox',
                  st.checkbox
                )}
              >
                <input
                  checked={addInsurance}
                  className="custom-control-input"
                  id="addInsurance"
                  name="addInsurance"
                  type="checkbox"
                  value={addInsurance}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <label
                  className={classNames('custom-control-label', st.label)}
                  htmlFor="addInsurance"
                >
                  <FormattedMessage id="Add insurance" />
                </label>
              </div>
              <div
                className={classNames(
                  'custom-control custom-checkbox',
                  st.checkbox
                )}
              >
                <input
                  checked={sendNotification}
                  className="custom-control-input"
                  id="sendNotification"
                  name="sendNotification"
                  type="checkbox"
                  value={sendNotification}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <label
                  className={classNames('custom-control-label', st.label)}
                  htmlFor="sendNotification"
                >
                  <FormattedMessage id="Send notification" />
                </label>
              </div>
            </>
          )}
        </section>
        {isLogistics && (
          <section className={st.submitRow}>
            <Button
              black
              disabled={isSubmitting}
              onClick={() => setDirty(true)}
              type="submit"
            >
              {isSubmitting ? (
                <Spinner
                  color="--main-bg-light"
                  width={43}
                  length={3}
                  size={14}
                  show
                />
              ) : (
                <FormattedMessage id="Ship" />
              )}
            </Button>
          </section>
        )}
      </form>
      <Modal
        title={
          <FormattedMessage
            id={
              step === 1 ? 'Select rate and book pickup' : 'Shipment purchased'
            }
          />
        }
        isOpen={ratesModal}
        toggle={() => setRatesModal((curr) => !curr)}
        footer={
          step === 1 ? (
            <div>
              <Button
                onClick={!isEmpty(selectedRate) ? onShip : null}
                black
                disabled={
                  shipping || isEmpty(selectedRate) || isEmpty(pickupDate)
                }
              >
                {shipping ? (
                  <Spinner
                    color="--main-bg-light"
                    width={43}
                    length={3}
                    size={14}
                    show
                  />
                ) : (
                  <FormattedMessage id="Create label and book pickup" />
                )}
              </Button>
            </div>
          ) : (
            <div>
              <Button onClick={() => setRatesModal(false)}>
                <FormattedMessage id="Close" />
              </Button>
            </div>
          )
        }
        body={
          <div>
            {step === 1 ? (
              <div key="step1">
                {rates &&
                  rates.map((rate) => {
                    return (
                      <Radio
                        key={rate.carrier_id}
                        id={`rate-${rate.carrier}-${rate.rate_id}`}
                        name={rate.carrier_id}
                        value={false}
                        onChange={() => setSelectedRate(rate)}
                        checked={rate.rate_id === selectedRate.rate_id}
                      >
                        <div className={st.rate}>
                          <div>
                            <p className={st.rateName}>{rate.carrier}</p>
                            <p>
                              <FormattedMessage id="Delivery" />:{' '}
                              {rate.delivery_days}{' '}
                              <FormattedMessage id="days" />
                            </p>
                            <p className={st.rateDescription}>{rate.service}</p>
                          </div>
                          <p className={st.ratePrice}>
                            {rate.currency} {rate.rate?.toFixed(2)}
                          </p>
                        </div>
                      </Radio>
                    )
                  })}
                {ratesErrors &&
                  ratesErrors.map((rateError) => {
                    return (
                      <div className={st.rateError} key={rateError.carrier_id}>
                        <p>{rateError.error_message}</p>
                      </div>
                    )
                  })}
                <div style={{ marginTop: '30px' }}>
                  <Input
                    type="date"
                    onChange={(e) => {
                      setPickupDate(e.target.value)
                    }}
                    value={pickupDate}
                    label="Pickup date"
                    min={new Date().toISOString().split('T')[0]}
                  />
                </div>
              </div>
            ) : (
              <div key="step2">
                <h6>
                  <FormattedMessage id="Shipment tracking code" />:
                </h6>
                <p>
                  <a
                    href={trackingExternalLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {trackingExternalLink}
                  </a>
                </p>
                {shipErrors.length > 0 && (
                  <div className={st.shipErrors}>
                    <ul>
                      {shipErrors.map(
                        (error, index) =>
                          error.message && (
                            <li className={st.rateError} key={index}>
                              {error.message}
                              <br />
                              {error.message === 'Unable to book pickup' &&
                                error.details}
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        }
      />
    </div>
  )
}

VendorOrderShipments.propTypes = {
  userType: string,
  orderNumber: string,
  vendor: string
}

export default VendorOrderShipments
