import { cloneDeep, get, keyBy, values } from 'lodash'
import { STATUSES } from 'utils/constants'
import { parseParcelNumber } from 'utils/products'
import { switchObjProps } from 'utils/utils'

import {
  ADDED_PRODUCT,
  ADDED_PRODUCTS_FROM_COLLECTION,
  APPROVE_ALL,
  AUTHENTICATION_ERROR,
  COPY,
  DELETE_PRODUCTS,
  DISABLE_ADDED_PRODUCTS_FROM_COLLECTION,
  GET_PRODUCT,
  GET_PRODUCT_DATA,
  GET_PRODUCT_DATA_IDS,
  GET_PRODUCTS,
  GOT_PRODUCTS_FROM_COLLECTION,
  PRODUCT_ATTACHEMENT_PENDING_ADD,
  PRODUCT_ATTACHEMENT_PENDING_REMOVE,
  PRODUCT_ATTACHEMENT_REMOVE,
  PRODUCT_ATTACHEMENT_UPDATE,
  PRODUCT_AUDITED,
  PRODUCT_CARE_INSTRUCTIONS_PENDING,
  PRODUCT_CARE_INSTRUCTIONS_UPDATE,
  PRODUCT_DISCONTINUED,
  PRODUCT_MAIN_IMAGE_PENDING,
  PRODUCT_MAIN_IMAGE_UPLOADED,
  PRODUCT_ONBOARDING_PHOTO_GOT,
  PRODUCT_OTHER_IMAGE_PENDING,
  PRODUCT_OTHER_INFO_PENDING,
  PRODUCT_OTHER_INFO_UPDATE,
  PRODUCT_PHOTOS_SET,
  PRODUCT_PROPERTIES_GOT,
  PRODUCT_REACTIVATED,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCTS_BY_COLLECTION_GOT,
  PRODUCTS_REJECT_BY_ID_SUCCESS,
  SAVE_IN_DRAFT,
  SAVE_MAIN_IMAGE,
  SAVE_OTHER_IMAGES,
  SEND_TO_PROOF_READING,
  SHOW_COPY_MODAL,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_VALUES,
  UPDATE_PRODUCTS_ID_BY_COLLECTION
} from '../actions/action-types'

const defaultState = {
  added: false,
  careInstructionsPending: false,
  collectionProducts: [],
  colors: [],
  designers: [],
  GottenProducts: [],
  isOpen: false,
  mainImagePending: false,
  materials: [],
  otherInfoPending: false,
  parcelIndex: 0,
  product: null,
  productPhotosPending: {},
  attachementsPending: []
}

const ProductReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ADDED_PRODUCT:
      return {
        ...state,
        added: action.added,
        product: action.product
      }

    case AUTHENTICATION_ERROR:
      return {
        ...state,
        ...defaultState
      }

    case PRODUCT_AUDITED:
    case PRODUCT_DISCONTINUED:
    case PRODUCT_REACTIVATED: {
      const { product } = action.payload
      return { ...state, product }
    }

    case PRODUCT_CARE_INSTRUCTIONS_PENDING:
    case PRODUCT_MAIN_IMAGE_PENDING:
    case PRODUCT_OTHER_INFO_PENDING:
    case PRODUCT_PROPERTIES_GOT:
    case PRODUCTS_BY_COLLECTION_GOT:
    case SHOW_COPY_MODAL:
      return {
        ...state,
        ...action.payload
      }

    case PRODUCT_CARE_INSTRUCTIONS_UPDATE: {
      const { careInstructions } = action.payload

      return {
        ...state,
        product: { ...state.product, careInstructions }
      }
    }

    case PRODUCT_MAIN_IMAGE_UPLOADED: {
      const { mainPhoto, productId } = action.payload
      const { collectionProducts } = state
      const cpClone = cloneDeep(collectionProducts)
      const idx = collectionProducts.findIndex((cp) => cp._id === productId)

      if (idx > -1) {
        cpClone[idx].imageUrl = mainPhoto
      }

      return {
        ...state,
        collectionProducts: cpClone,
        product: {
          ...state.product,
          mainPhoto
        }
      }
    }

    case PRODUCT_ONBOARDING_PHOTO_GOT: {
      const { onboardingPhoto } = action.payload

      return {
        ...state,
        product: {
          ...state.product,
          onboardingPhoto
        }
      }
    }
    case PRODUCT_ATTACHEMENT_UPDATE: {
      const { attachementType, fileUrl } = action.payload
      return {
        ...state,
        product: { ...state.product, [attachementType]: fileUrl }
      }
    }
    case PRODUCT_ATTACHEMENT_REMOVE: {
      const attachementType = action.payload
      const productCopy = { ...state.product }
      delete productCopy[attachementType]
      return { ...state, product: productCopy }
    }
    case PRODUCT_ATTACHEMENT_PENDING_ADD: {
      const attachementType = action.payload

      return {
        ...state,
        attachementsPending: [...state.attachementsPending, attachementType]
      }
    }
    case PRODUCT_ATTACHEMENT_PENDING_REMOVE: {
      const attachementType = action.payload

      return {
        ...state,
        attachementsPending: state.attachementsPending.filter(
          (item) => item !== attachementType
        )
      }
    }
    case PRODUCT_OTHER_IMAGE_PENDING: {
      const { imagePath, pending } = action.payload

      return {
        ...state,
        productPhotosPending: switchObjProps({
          isOn: pending,
          obj: state.productPhotosPending,
          propName: imagePath
        })
      }
    }

    case PRODUCT_OTHER_INFO_UPDATE: {
      const { otherInfo } = action.payload

      return {
        ...state,
        product: { ...state.product, otherInfo }
      }
    }

    case PRODUCT_PHOTOS_SET: {
      const { photos } = action.payload

      return {
        ...state,
        product: { ...state.product, photos }
      }
    }

    case PRODUCT_UPDATE_SUCCESS: {
      const { product } = action.payload
      const newState = {
        ...state,
        product: {
          ...state.product,
          ...product
        }
      }
      const { collectionProducts } = state
      const cpIdx = collectionProducts.findIndex((cp) => cp._id === product._id)

      if (cpIdx > -1) {
        const newCp = [...state.collectionProducts]

        newCp[cpIdx].name = product.name
        newCp[cpIdx].saved = product.saved
        newState.collectionProducts = newCp
      }

      return newState
    }

    case UPDATE_PRODUCT_VALUES: {
      const { product } = action
      const gpIdx = state.GottenProducts.findIndex(
        (gp) => gp._id === product._id
      )
      const newGottenProducts = cloneDeep(state.GottenProducts)

      if (gpIdx > -1) {
        newGottenProducts[gpIdx].status = product.status
      }

      return {
        ...state,
        GottenProducts: newGottenProducts,
        product: action.product,
        update: action.update
      }
    }
    case GET_PRODUCTS:
      return {
        ...state,
        got: action.got,
        products: action.products
      }
    case GET_PRODUCT_DATA:
      return {
        ...state,
        gotProductData: action.gotProductData,
        productData: action.productData
      }
    case GET_PRODUCT_DATA_IDS:
      return {
        ...state,
        gotProductsDataIds: action.gotProductsDataIds,
        productsDataIds: action.productsDataIds
      }
    case ADDED_PRODUCTS_FROM_COLLECTION:
      return {
        ...state,
        addedFromCollection: action.addedFromCollection
      }
    case DISABLE_ADDED_PRODUCTS_FROM_COLLECTION:
      return {
        ...state,
        addedFromCollection: action.addedFromCollection
      }
    case GOT_PRODUCTS_FROM_COLLECTION:
      return {
        ...state,
        gottenImages: action.gottenImages,
        GottenProducts: action.GottenProducts
      }
    case DELETE_PRODUCTS: {
      const { deleted, id } = action.payload
      const { GottenProducts } = state

      return {
        ...state,
        GottenProducts: GottenProducts.filter((gp) => gp._id !== id),
        deleted
      }
    }
    case UPDATE_PRODUCT:
      return {
        ...state,
        updated: action.updated
      }
    case SAVE_IN_DRAFT:
      return {
        ...state,
        savedInDraft: action.savedInDraft
      }
    case SEND_TO_PROOF_READING:
      return {
        ...state,
        sentToProofReading: action.sentToProofReading
      }
    case SAVE_MAIN_IMAGE:
      return {
        ...state,
        mainImage: action.mainImage
      }
    case SAVE_OTHER_IMAGES:
      return {
        ...state,
        otherImages: action.otherImages
      }
    case UPDATE_PRODUCTS_ID_BY_COLLECTION:
      return {
        ...state,
        productsId: action.productsId
      }

    case GET_PRODUCT: {
      const { product } = action
      const parcels = (product.parcels ?? []).sort((p1, p2) => {
        return p1.number > p2.number ? -1 : 1
      })
      const parcelNumber = get(parcels, '[0].number', '')
      const parsed = parseParcelNumber(parcelNumber)

      return {
        ...state,
        parcelIndex: parsed.number + 1,
        product
      }
    }

    case COPY: {
      const collectionProductsById = keyBy(state.collectionProducts, '_id')

      action.copy.forEach(({ _id, name, saved, status }) => {
        const productObj = collectionProductsById[_id]

        if (productObj) {
          productObj.name = name
          productObj.saved = saved
          productObj.status = status
        }
      })

      return {
        ...state,
        collectionProducts: values(collectionProductsById),
        copy: action.copy,
        isOpen: false
      }
    }
    case PRODUCTS_REJECT_BY_ID_SUCCESS: {
      const { payload: rejected } = action
      const GottenProducts = cloneDeep(state.GottenProducts)

      rejected.forEach((r) => {
        const idx = GottenProducts.findIndex((gp) => gp._id === r._id)

        if (idx > -1) {
          GottenProducts[idx].status = r.status
        }
      })

      return {
        ...state,
        GottenProducts
      }
    }
    case APPROVE_ALL:
      return {
        ...state,
        gottenImages: false,
        GottenProducts: state.GottenProducts.map((gp) => ({
          ...cloneDeep(gp),
          status: STATUSES.APPROVED
        })),
        approvedAll: action.approvedAll
      }
    default:
      return state
  }
}

export default ProductReducer
