import cn from 'classnames'
import { Dropdown } from 'components'
import { get, noop } from 'lodash'
import {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string
} from 'prop-types'
import React from 'react'
import { AiOutlineDown } from 'react-icons/ai'
import { FormattedMessage } from 'react-intl'

import st from './LabelValueSelect.module.css'

const LabelValueSelect = ({
  className,
  field: { name: fieldName, value: fieldValue },
  form: { setFieldValue },
  hideDropdown,
  items,
  label,
  labelClassName,
  value,
  valueClassName,
  onChange
}) => {
  const handleItemClick = (item) => {
    const { content, value } = item

    if (typeof setFieldValue === 'function') {
      setFieldValue(fieldName, { content, value })
    }

    if (typeof onChange === 'function') {
      onChange({ content, value })
    }
  }

  const ddItems = items.map((ddItem) => ({
    ...ddItem,
    _id: ddItem.content,
    onClick: handleItemClick
  }))
  const valueToShow = value || get(fieldValue, 'content', '')

  return (
    <article className={cn(st.wrap, className)}>
      <h1 className={cn(st.label, labelClassName)}>{label}</h1>
      <div className={st.data}>
        <p className={cn(st.value, valueClassName)}>{valueToShow}</p>
        {!hideDropdown && (
          <Dropdown
            isUncontrolled
            menuRight
            classNameToggler={st.toggler}
            items={ddItems}
          >
            <FormattedMessage id="Change" />
            <AiOutlineDown className={st.icon} />
          </Dropdown>
        )}
      </div>
    </article>
  )
}

LabelValueSelect.defaultProps = {
  className: '',
  field: {},
  form: {},
  hideDropdown: false,
  items: [],
  label: '',
  labelClassName: '',
  value: '',
  valueClassName: '',
  onChange: noop
}
LabelValueSelect.propTypes = {
  className: string,
  field: shape({
    value: shape({}),
    onChange: func
  }),
  form: shape({
    setFieldValue: func
  }),
  hideDropdown: bool,
  items: arrayOf(
    shape({
      content: string,
      disabled: bool
    })
  ),
  label: string,
  labelClassName: string,
  value: oneOfType([number, string]),
  valueClassName: string,
  onChange: func
}

export default LabelValueSelect
