import { get, pick } from 'lodash'
import {
  LIST_LIMIT_DEFAULT,
  ORDER_FILTERS_DEFAULT,
  VENDOR_ORDER_ACTIONS
} from 'utils/constants'
import { notifyMappedError } from 'utils/errors'

import notify, { notifyPositions } from '../../utils/toast'
import { getVendorOrders, updateOrder } from '../service/order-service'
import {
  GET_ORDERS,
  ORDER_SHIPMENT_PENDING,
  ORDER_SHIPMENT_STATE,
  ORDERS_FETCHING,
  ORDERS_PAGE_SET
} from './action-types'
import { AuthError } from './auth-action'

const getOrders = ({ currentPage, limit, orders, pages, vendorId }) => ({
  currentPage,
  delete: false,
  limit,
  type: GET_ORDERS,
  orders,
  pages,
  vendorId
})

const ordersFetching = (isFetching = false) => ({
  payload: { ordersFetching: isFetching },
  type: ORDERS_FETCHING
})

/**
 * @param {Object} config
 * @param {Number} config.limit
 * @param {Number} config.page
 * @param {String} config.vendorId
 * @param {Object} filters
 * @param {String} filters.status
 */
const GetOrdersTh =
  (config = {}) =>
  (dispatch) => {
    const {
      page,
      limit = LIST_LIMIT_DEFAULT,
      vendorId = '',
      filters = ORDER_FILTERS_DEFAULT
    } = config

    dispatch(ordersFetching(true))
    getVendorOrders({ page, limit, vendorId, filters }).then(
      ({ data, error }) => {
        if (data) {
          dispatch(
            getOrders({
              ...pick(data, ['pages']),
              orders: get(data, 'orders') || [],
              limit,
              currentPage: +data.current_page || 1,
              vendorId
            })
          )
        }

        if (error) {
          const { status } = error

          if (status === 401) {
            dispatch(AuthError(status))
          }

          dispatch(getOrders([]))
          notifyMappedError(error)
        }

        dispatch(ordersFetching(false))
      }
    )
  }

/**
 * @param {Object} payload
 * @param {Boolean} payload.pending
 * @param {String} payload.pendingShipment
 */
const orderShipmentPending = (payload) => ({
  payload,
  type: ORDER_SHIPMENT_PENDING
})

/**
 * @param {Object} payload
 * @param {String} payload.oldState
 * @param {Number|String} payload.orderId
 * @param {Number|String} payload.shipment
 * @param {String} payload.state
 */
const orderShipmentState = (payload) => ({
  payload,
  type: ORDER_SHIPMENT_STATE
})

/**
 * @param {Object} payload
 * @param {String} payload.oldState
 * @param {Number|String} payload.orderId
 * @param {String} [config.orderNumber]
 * @param {Number|String} payload.shipment
 * @param {String} payload.state
 * @param {String} [config.stockLocationId]
 * @param {Number|String} [config.trackingNumber]
 * @param {Number|String} [config.variantId]
 */
const orderShipmentStateAsync = (payload) => (dispatch) => {
  const { oldState, orderId, shipment, state, ...restPayload } = payload

  if (state === VENDOR_ORDER_ACTIONS.COMPLETE.value) {
    return
  }

  dispatch(
    orderShipmentPending({
      pending: true,
      pendingShipment: shipment
    })
  )
  dispatch(
    orderShipmentState({
      orderId,
      shipment,
      state
    })
  )

  updateOrder({
    orderId,
    shipmentNumber: shipment,
    shipmentStatus: state,
    ...restPayload
  }).then(({ error }) => {
    if (error) {
      notify(
        'error',
        error.data || error.message,
        notifyPositions.bottom.center
      )
      dispatch(
        orderShipmentState({
          orderId,
          shipment,
          state: oldState
        })
      )
    }

    dispatch(
      orderShipmentPending({
        pending: false,
        pendingShipment: shipment
      })
    )
  })
}

const ordersPageSet = (currentPage = 1) => ({
  payload: { currentPage },
  type: ORDERS_PAGE_SET
})

export { GetOrdersTh, orderShipmentStateAsync, ordersPageSet }
