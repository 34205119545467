import { pick } from 'lodash'
import { BAD_IMAGE_DIMENSIONS, notifyMappedError } from 'utils/errors'

/**
 * @param {Object} config
 * @param {File} config.file
 * @param {String} config.type
 */
export const checkFileType = (config = {}) => {
  const { file, type = '' } = config

  return file.type.indexOf(type) > -1
}

/**
 * @param {Object} config
 * @param {Files} config.files
 * @param {[String]} config.types
 */
export const checkFilesTypes = (config = {}) => {
  const { files = [], types = [] } = config
  const badFile = Array.prototype.find.call(files, (file) => {
    return !types.find((type) => checkFileType({ file, type }))
  })

  return !badFile
}

/**
 * @param {FileList} files
 * @returns {Array} promises
 */
export const readFilesAsDataUrls = (files = []) => {
  const filesArr = [...files]

  return filesArr.map((file) => {
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file)

      reader.onerror = (e) => {
        reader.abort()
        reject(e)
      }

      reader.onload = (e) => {
        resolve(e)
      }
    })
  })
}

/**
 * @param {Object} config
 * @param {Files} config.files
 */
export const getFilePaths = (config) => {
  const { files = [] } = config
  const pResults = readFilesAsDataUrls(files).map((p) =>
    p.then(({ target: { result } }) => result)
  )

  return Promise.all(pResults)
}

/**
 * @param {Object} config
 * @param {Files} config.files
 * @param {String} config.propName
 */
export const getFormDataWithFiles = (config = {}) => {
  const { files = [], propName = 'file' } = config
  const formData = new FormData()

  Array.prototype.forEach.call(files, (file) => {
    formData.append(propName, file)
  })

  return formData
}

/**
 * @param {Object} config
 * @param {Array} config.imageFiles
 * @param {File} config.imageFiles[]
 */
export const getImagesDimensions = (config = {}) => {
  const { imageFiles = [] } = config

  const pResults = readFilesAsDataUrls(imageFiles).map(
    (promise) =>
      new Promise((res, rej) => {
        promise
          .then((fileData) => {
            const {
              target: { result }
            } = fileData
            const image = new Image()

            image.src = result
            image.onerror = () => {
              rej({ error: 'Bad image loading' })
            }
            image.onload = () => {
              res({
                dimensions: pick(image, ['height', 'width'])
              })
            }
          })
          .catch((error) => ({ error }))
      })
  )

  return Promise.all(pResults)
}

export const handleImageUploadError = ({ error }) => {
  if (error === BAD_IMAGE_DIMENSIONS) {
    notifyMappedError({
      message: 'The image resolution must be 1400x1400px'
    })
  }
}

export const handleMainImageUploadError = ({ error }) => {
  if (error === BAD_IMAGE_DIMENSIONS) {
    notifyMappedError({
      message: 'The minimum image resolution must be 1400x1400px'
    })
  }
}

/**
 * Function checks if the file might be a csv file.
 * @param {File} file
 * @returns {boolean}
 */
export const checkIfFileIsCsv = (file) => {
  const fileExtension = file.name.split('.')[1] || ''
  return fileExtension.includes('csv')
}
