import { HIDE_LOADING, SHOW_LOADING, UPDATE_PROGRESS } from './action-types'

export const ShowLoading = () => ({
  type: SHOW_LOADING,
  show: true
})

export const HideLoading = () => ({
  type: HIDE_LOADING,
  show: false
})

export const UpdateLoadingProgress = (progress) => ({
  type: UPDATE_PROGRESS,
  progress
})
