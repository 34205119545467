import cn from 'classnames'
import { BarChart, PieChart } from 'components/charts'
import StackedBarChart from 'components/charts/BarChart/StackedBarChart'
import { VendorAnalyticsLayout } from 'components/layout'
import { get } from 'lodash'
import { func, shape, string } from 'prop-types'
import React, { useEffect } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ResizeDetector from 'react-resize-detector'
import { Col, Row } from 'reactstrap'
import { GetVendor } from 'redux/actions/vendor-action'
import { COLORS } from 'utils/constants'
import { canSeeCubeData, getCubeJsApi } from 'utils/cube'

import {
  genderAgeQuery,
  genderAgeResMap,
  genderCubeQuery,
  genderResultsMap,
  genderResultsMapValidate,
  geoQuery,
  mapState,
  prepareGeoRes
} from './config'
import st from './VendorAnalyticsAudience.module.css'

const VendorAnalyticsAudience = ({
  analyticsToken,
  intl,
  location: { pathname },
  match,
  authReducer,
  GetVendor,
  vendorName
}) => {
  const cubeJsApi = getCubeJsApi(analyticsToken)
  const vendorId = get(match, 'params.id', '')

  useEffect(() => {
    GetVendor({ vendorId })
  }, [])

  return (
    <VendorAnalyticsLayout
      currentURL={pathname}
      timeFrame={365}
      vendorId={vendorId}
      authReducer={authReducer}
      vendorName={vendorName}
    >
      <p className={st.intro}>
        <FormattedMessage id="audienceIntro" />
      </p>
      {canSeeCubeData({
        canSee: Boolean(analyticsToken),
        userType: get(authReducer, 'userType'),
        toShow: (
          <Row className={cn('rowWide', st.layout)}>
            <Col md={6} xs={12}>
              <p className={st.hd}>
                <FormattedMessage id="Gender" />
              </p>
              <ResizeDetector
                handleWidth
                refreshMode="debounce"
                refreshRate={200}
              >
                {({ width }) => (
                  <PieChart
                    cubeJsApi={cubeJsApi}
                    height={Math.floor(width > 300 ? 350 : (width || 0) * 0.9)}
                    mapResults={genderResultsMap}
                    query={genderCubeQuery}
                    validateMappedResults={genderResultsMapValidate}
                    valueItem="%"
                  />
                )}
              </ResizeDetector>
            </Col>
            <Col md={6} xs={12}>
              <p className={st.hd}>
                <FormattedMessage id="Age" />
              </p>
              <BarChart
                barColor={COLORS.BAR}
                cubeJsApi={cubeJsApi}
                height={380}
                mapChartData={genderAgeResMap}
                query={genderAgeQuery}
                valueItem="%"
                yLabel={intl.formatMessage({
                  id: 'Age Percentage'
                })}
              />
            </Col>
            <Col xs={12}>
              <p className={st.hd}>
                <FormattedMessage id="Site Sales per Area" />
              </p>
              <ResizeDetector
                handleWidth
                refreshMode="debounce"
                refreshRate={200}
              >
                {({ width }) => (
                  <StackedBarChart
                    barColors={[COLORS.BAR, '#888']}
                    cubeJsApi={cubeJsApi}
                    height={width < 768 ? 200 : 300}
                    prepareChartData={(data) => {
                      return prepareGeoRes(data, intl)
                    }}
                    query={geoQuery}
                    valueUnit="%"
                  />
                )}
              </ResizeDetector>
            </Col>
          </Row>
        )
      })}
    </VendorAnalyticsLayout>
  )
}

VendorAnalyticsAudience.propTypes = {
  analyticsToken: string,
  intl: shape({
    formatMessage: func
  }),
  location: shape({
    pathname: string
  }),
  match: shape({}),
  authReducer: shape({}),
  GetVendor: func,
  vendorName: string
}

const mapActions = {
  GetVendor
}

export default connect(
  mapState,
  mapActions
)(injectIntl(VendorAnalyticsAudience))
