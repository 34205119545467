import { Cube } from 'components'
import { get } from 'lodash'
import { arrayOf, func, noop, number, shape, string } from 'prop-types'
import React, { useCallback } from 'react'
import { GrMapLocation } from 'react-icons/gr'
import {
  Bar,
  BarChart as ChartCmp,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { COLORS } from 'utils/constants'

import st from './BarChart.module.css'

const labelFormat = (label) => (
  <span className={st.labelFormatter}>
    <GrMapLocation className={st.labelFormatterIcon} />
    {label}
  </span>
)
const validateMappedResults = (results = []) => results.length > 0

const StackedBarChart = ({
  barColors,
  cubeJsApi,
  height,
  prepareChartData,
  query,
  valueUnit,
  xLabel
}) => {
  const prepareResults = useCallback(
    (resultSet) =>
      prepareChartData(get(resultSet, 'loadResponses.[0].data', [])),
    [prepareChartData]
  )

  return (
    <Cube
      isChart
      cubeJsApi={cubeJsApi}
      height={height}
      mapResults={prepareResults}
      query={query}
      validateMappedResults={validateMappedResults}
    >
      {({ data }) => {
        return (
          <ChartCmp layout="vertical" data={data}>
            <Legend
              formatter={(value) => {
                return <span style={{ color: '#666' }}>{value}</span>
              }}
            />
            <Tooltip
              labelFormatter={labelFormat}
              formatter={(value, name, { fill }) => {
                return [
                  <span key={name} className={st.toolTip}>
                    <span
                      className={st.toolTipColor}
                      style={{
                        backgroundColor: fill
                      }}
                    />
                    {name.toUpperCase()}: {value} {valueUnit}
                  </span>
                ]
              }}
              contentStyle={{
                background: '#000',
                fontSize: '0.875em'
              }}
              itemStyle={{ color: '#fff' }}
              labelStyle={{ color: '#fff' }}
            />
            <CartesianGrid horizontal={false} />
            <XAxis
              type="number"
              domain={[0, 100]}
              tickCount={11}
              unit={valueUnit}
              label={{
                value: xLabel,
                position: 'insideBottom'
              }}
              height={xLabel ? 50 : undefined}
            />
            <YAxis dataKey="x" type="category" width={120} />
            {data[0].y.map((bar, i) => (
              <Bar
                dataKey={`y[${i}].value`}
                fill={barColors[i]}
                stackId="1"
                key={bar.key}
                name={bar.key}
              />
            ))}
          </ChartCmp>
        )
      }}
    </Cube>
  )
}

StackedBarChart.defaultProps = {
  barColors: [COLORS.BAR],
  cubeJsApi: {},
  height: 200,
  prepareChartData: noop,
  query: {},
  valueUnit: '',
  xLabel: ''
}
StackedBarChart.propTypes = {
  barColors: arrayOf(string),
  cubeJsApi: shape({}),
  height: number,
  prepareChartData: func,
  query: shape({}),
  valueUnit: string,
  xLabel: string
}

export default StackedBarChart
