import cn from 'classnames'
import { PageNav, VendorActions } from 'components'
import { noop } from 'lodash'
import { any, arrayOf, bool, func, shape, string } from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Col, Row } from 'reactstrap'
import { USER_TYPES } from 'utils/constants'
import { vendorNavItems } from 'utils/utils'

import st from './PageNavVendorActions.module.css'

const PageNavVendorActions = ({
  className,
  collectionId,
  currentUrl,
  intl,
  userType,
  vendorId,
  onClickImages,
  lastUpdatedByAt,
  lastChanges,
  isProduct
}) => {
  const isUserAdmin = userType === USER_TYPES.ADMIN
  const isUserManager = userType === USER_TYPES.MANAGER
  const navItems = vendorNavItems({
    collectionId,
    id: vendorId,
    intl
  })

  return (
    <Row className={cn('rowWide', className)}>
      <Col sm={7} xs={12}>
        {isUserAdmin && <PageNav items={navItems} />}
      </Col>
      <Col className={st.actionsTop} sm={5} xs={12}>
        {(isUserAdmin || isUserManager) && (
          <VendorActions
            collection={collectionId}
            owner={userType}
            url={currentUrl}
            vendorId={vendorId}
            onClickImages={onClickImages}
            lastUpdatedByAt={lastUpdatedByAt}
            lastChanges={lastChanges}
            isProduct={isProduct}
          />
        )}
      </Col>
    </Row>
  )
}

PageNavVendorActions.defaultProps = {
  className: '',
  collectionId: '',
  currentUrl: '',
  userType: '',
  vendorId: '',
  onClickImages: noop,
  lastChanges: []
}
PageNavVendorActions.propTypes = {
  className: string,
  collectionId: string,
  currentUrl: string,
  userType: string,
  vendorId: string,
  onClickImages: func,
  intl: shape({}),
  lastUpdatedByAt: shape({
    [string]: shape({
      date: string,
      email: string
    })
  }),
  lastChanges: arrayOf(
    shape({
      userType: string,
      email: string,
      date: string,
      changes: arrayOf(
        shape({
          field: string,
          from: any,
          to: any
        })
      )
    })
  ),
  isProduct: bool
}

export default injectIntl(PageNavVendorActions)
