export const INIT = 'INIT'
export const ORDERS_LOADING = 'ORDERS_LOADING'
export const ORDERS_PRODUCT_PARCEL_ADD = 'ORDERS_PRODUCT_PARCEL_ADD'
export const ORDERS_PRODUCT_PARCEL_DELETE = 'ORDERS_PRODUCT_PARCEL_DELETE'
export const ORDERS_DATA_LOADING_ADD = 'ORDERS_DATA_LOADING_ADD'
export const ORDERS_DATA_LOADING_REMOVE = 'ORDERS_DATA_LOADING_REMOVE'
export const ORDERS_IMAGE_LOADING_ADD = 'ORDERS_IMAGE_LOADING_ADD'
export const ORDERS_IMAGE_LOADING_REMOVE = 'ORDERS_IMAGE_LOADING_REMOVE'
export const ORDERS_PRODUCT_PACKAGING_PENDING_ADD =
  'ORDERS_PRODUCT_PACKAGING_PENDING_ADD'
export const ORDERS_PRODUCT_PACKAGING_PENDING_REMOVE =
  'ORDERS_PRODUCT_PACKAGING_PENDING_REMOVE'
export const ORDERS_LAST_SUMITTED_VALUES_ADD = 'ORDERS_LAST_SUMITTED_VALUES_ADD'
export const INIT_PICKUP_FORM = 'INIT_PICKUP_FORM'
export const PICKUP_FORM_LOADING = 'PICKUP_FORM_LOADING'
export const ORDERS_IMAGE_PENDING_ADD = 'ORDERS_IMAGE_PENDING_ADD'
export const ORDERS_IMAGE_PENDING_REMOVE = 'ORDERS_IAMGE_PENDING_REMOVE'
export const ORDERS_PROFORMA_FILE_PENDING_ADD =
  'ORDERS_PROFORMA_FILE_PENDING_ADD'
export const ORDERS_PROFORMA_FILE_PENDING_REMOVE =
  'ORDERS_PROFORMA_FILE_PENDING_REMOVE'
export const ORDERS_PROFORMA_FILE_ADD = 'ORDER_PROFORMA_FILE_ADD'
export const ORDERS_PROFORMA_FILE_REMOVE = 'ORDER_PROFORMA_FILE_REMOVE'
export const ORDERS_PICKUP_SUBMITTING = 'ORDERS_PICKUP_SUBMITTING'
export const ORDERS_CERTIFICATION_FILE_PENDING_ADD =
  'ORDERS_CERTIFICATION_FILE_PENDING_ADD'
export const ORDERS_CERTIFICATION_FILE_PENDING_REMOVE =
  'ORDERS_CERTIFICATION_FILE_PENDING_REMOVE'
export const ORDERS_CERTIFICATION_FILE_ADD = 'ORDERS_CERTIFICATION_FILE_ADD'
export const ORDERS_CERTIFICATION_FILE_REMOVE =
  'ORDERS_CERTIFICATION_FILE_REMOVE'
