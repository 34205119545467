import { any } from 'prop-types'
import React from 'react'
import { Col } from 'reactstrap'

import Style from './bio-css.module.css'

const VideoGallery = (props) => {
  const { video } = props
  return video && video.length ? (
    <Col className={Style.mainDivVideo} md={12} lg={12} sm={12}>
      <video className={Style.video} controls>
        <source src={video} />
      </video>
    </Col>
  ) : null
}

VideoGallery.propTypes = {
  video: any
}
export default VideoGallery
