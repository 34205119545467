import { Button } from 'components/buttons'
import Modal from 'components/modals/Modal'
import { bool, func, string } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

import st from './PDFModal.module.css'

const PDFModal = ({ url, open, toggle }) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  useEffect(() => {
    setPageNumber(1)
  }, [open])
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }
  return (
    <Modal
      isOpen={open}
      size="lg"
      toggle={toggle}
      title={
        <p>
          <FormattedMessage id="Purchase order" />{' '}
          <a href={url} target="_blank" rel="noreferrer">
            Download
          </a>
        </p>
      }
      body={
        <div className={st.pdf}>
          <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
            <Page size="A4" pageNumber={pageNumber} />
          </Document>
        </div>
      }
      footer={
        <div className={st.flex}>
          <p className={st.pages}>
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </p>

          {numPages > 1 ? (
            <>
              <Button
                type="button"
                disabled={pageNumber <= 1}
                onClick={!(pageNumber <= 1) ? previousPage : null}
              >
                Previous
              </Button>
              <Button
                type="button"
                disabled={pageNumber >= numPages}
                onClick={!(pageNumber >= numPages) ? nextPage : null}
              >
                Next
              </Button>
            </>
          ) : null}
        </div>
      }
    />
  )
}

PDFModal.propTypes = {
  url: string,
  open: bool,
  toggle: func
}

export default PDFModal
