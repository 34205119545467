import cn from 'classnames'
import { get, noop, truncate, uniqueId } from 'lodash'
import React from 'react'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import { FormattedMessage } from 'react-intl'
import { Element } from 'react-scroll'
import { FormFeedback, Input as InputRS, Label } from 'reactstrap'
import { handleInputEvent, isInvalidField } from 'utils/form'
import { numberToString } from 'utils/number'
import { FORMIK_DEFAULT_PROPS, INPUT_PROP_TYPES } from 'utils/propTypes'

import st from './Input.module.css'

const numberButtons = ({
  clickDown = noop,
  clickUp = noop,
  cmp = null,
  disabled = false,
  showButtons = false
} = {}) =>
  showButtons ? (
    <div className={st.inputWrap}>
      {cmp}
      <div
        className={cn(st.numberChanges, {
          [st.numberChangesDisabled]: !!disabled
        })}
      >
        <AiFillCaretUp
          className={st.numberChangesBtn}
          onClick={disabled ? noop : clickUp}
        />
        <AiFillCaretDown
          className={st.numberChangesBtn}
          onClick={disabled ? noop : clickDown}
        />
      </div>
    </div>
  ) : (
    cmp
  )

const Input = ({
  className,
  decimalSign,
  error,
  field,
  form,
  horizontal,
  id,
  inputClassName,
  isScrollTo,
  label,
  labelAfter,
  labelClassName,
  name,
  nextNode,
  onBlur,
  onChange,
  options,
  reversed,
  separateNumberValue,
  step,
  type,
  value,
  valueLengthLimit,
  ...props
}) => {
  const { disabled } = props
  const fName = field.name ?? name
  const fValue = field.value ?? value
  const fValueTruncated =
    valueLengthLimit && typeof fValue === 'string'
      ? truncate(fValue, {
          length: valueLengthLimit,
          omission: ''
        })
      : fValue
  const errorMessage = get(form, `errors.${fName}`) || error
  const extraProps = {}
  const isInvalid = isInvalidField({
    error,
    fieldName: fName,
    form
  })
  const isTypeNumber = type === 'number'
  const uid = id || uniqueId()
  const valueLengthLimitCounter = `${fValueTruncated.length}/${valueLengthLimit}`

  let realType = isTypeNumber ? 'text' : type
  const realValue = isTypeNumber
    ? numberToString({
        decimalSign,
        separate: separateNumberValue,
        value: fValueTruncated
      })
    : fValueTruncated

  if (options instanceof Array) {
    const { placeholder, allowEmpty } = props
    const finalOptions = [
      {
        disabled: !allowEmpty,
        label: placeholder,
        value: ''
      },
      ...options
    ]

    extraProps.children = finalOptions.map(({ label, ...opnRest }) => (
      <option key={opnRest.value || label} {...opnRest}>
        {label}
      </option>
    ))
    extraProps.options = options
    realType = 'select'
  }

  const handleBlur = handleInputEvent({
    eventName: 'blur',
    field,
    propEventHandler: onBlur
  })

  const handleChange = handleInputEvent({
    decimalSign,
    eventName: 'change',
    field,
    propEventHandler: onChange,
    type
  })

  const handleChangeEmulate = ({ value } = {}) => {
    const e = {
      target: {
        name: fName,
        value: value.split('.').join(decimalSign)
      }
    }

    handleChange(e)
  }

  const handleClickArrowDown = () => {
    handleChangeEmulate({
      value: `${fValueTruncated - step}`
    })
  }

  const handleClickArrowUp = () => {
    handleChangeEmulate({
      value: `${fValueTruncated + step}`
    })
  }

  return (
    <div className={className}>
      <div
        className={cn({
          [st.horizontal]: horizontal
        })}
      >
        {isScrollTo && <Element name={fName} />}
        <div
          className={cn(st.input, {
            [st.reversed]: reversed,
            [st.withValueLengthLimit]: valueLengthLimit > 0
          })}
        >
          <div
            className={cn(st.inputField, {
              'custom-control custom-checkbox': realType === 'checkbox'
            })}
          >
            {realType !== 'checkbox' && label.length > 0 && (
              <div
                className={cn(st.label, {
                  [labelClassName]: labelClassName.length > 0
                })}
              >
                <Label for={uid}>{label}</Label>
                {labelAfter && (
                  <span className={st.labelAfter}>{labelAfter}</span>
                )}
              </div>
            )}
            {numberButtons({
              clickDown: handleClickArrowDown,
              clickUp: handleClickArrowUp,
              cmp: (
                <InputRS
                  {...props}
                  {...field}
                  {...extraProps}
                  className={cn(st.input, {
                    [inputClassName]: inputClassName.length > 0,
                    'custom-control-input': realType === 'checkbox'
                  })}
                  id={uid}
                  invalid={isInvalid}
                  name={fName}
                  type={realType}
                  value={realValue}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              ),
              disabled,
              showButtons: isTypeNumber
            })}
            {realType === 'checkbox' && label.length > 0 && (
              <div
                className={cn(st.label, {
                  [labelClassName]: labelClassName.length > 0
                })}
              >
                <Label className="custom-control-label" for={uid}>
                  {label}
                </Label>
                {labelAfter && (
                  <span className={st.labelAfter}>{labelAfter}</span>
                )}
              </div>
            )}
          </div>
          {valueLengthLimit > 0 && (
            <span className={st.valueLengthLimit}>
              {valueLengthLimitCounter}
            </span>
          )}
        </div>
        {nextNode && <div className={st.nextNode}>{nextNode}</div>}
      </div>
      {errorMessage && isInvalid && (
        <div className={st.feedbackWrap}>
          <FormFeedback className={st.feedback}>
            <FormattedMessage id={errorMessage} />
          </FormFeedback>
        </div>
      )}
    </div>
  )
}
Input.defaultProps = {
  ...FORMIK_DEFAULT_PROPS,
  className: '',
  decimalSign: ',',
  error: '',
  horizontal: false,
  id: '',
  inputClassName: '',
  isScrollTo: false,
  label: '',
  labelAfter: null,
  labelClassName: '',
  name: '',
  nextNode: null,
  onBlur: noop,
  onChange: noop,
  options: null,
  placeholder: '',
  reversed: false,
  separateNumberValue: false,
  step: 1,
  type: 'text',
  value: '',
  valueLengthLimit: 0
}
Input.propTypes = INPUT_PROP_TYPES

export default Input
