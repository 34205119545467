import Style from 'components/approver-dashboard-components/approver-dashboard-component.module.css'
import { get } from 'lodash'

import { STATUSES } from './constants'

export const SORT_BY = {
  ADD_DATE: 'addDate',
  APPROVAL_DATE: 'approvalDate'
}

/**
 * @param {Object} collection
 * @param {Array} collection.products
 * @param {String} collection.products[].status
 */
export const getApprovedProducts = (collection = {}) =>
  get(collection, 'products', []).filter(
    (product) => product.status === STATUSES.APPROVED
  )

/**
 * @param {Object} config
 * @param {Object} config.collectionReducer
 */
export const getCollection = (config = {}) => {
  const { collectionReducer = {} } = config || {}

  return get(collectionReducer, 'collections.[0]') || {}
}

/**
 * @param {Object} config
 * @param {Object} config.showActions
 */
export const headingsApproved = (config = {}) => {
  const { showActions } = config
  const headings = [
    { className: Style.col1, text: 'Products' },
    { text: 'Vendor' },
    { text: 'Collection' }
  ]

  if (showActions) {
    headings.push({ text: 'Actions' })
  }

  return headings
}

export const headingsToApprove = [
  { className: Style.col1, text: 'Products' },
  { text: 'Vendor' },
  { text: 'Area' },
  { text: 'Exclusive' }
]

/**
 * @param {Object} data
 * @param {Array} data.collections
 * @param {Object} data.collections[].vendor
 * @param {String} data.collections[].vendor.artisanName
 * @param {String} [data.collections[].vendor.name]
 * @param {String} data.name
 */
export const filterByVendorName = (data) => {
  const { collections = [], name = '' } = data

  return collections.filter((collection) => {
    const vendorName =
      get(collection, 'vendor.artisanName', '') ||
      get(collection, 'vendor.name', '')

    return vendorName.toLowerCase().indexOf(name.toLowerCase()) > -1
  })
}

/**
 * @param {Object} col1
 * @param {String|Date} col1.addDate
 * @param {Object} col2
 * @param {String|Date} col2.addDate
 */
export const sortCollectionsByAddDate = (col1, col2) => {
  const addDate1 = get(col1, 'addDate')
  const addDate2 = get(col2, 'addDate')

  return addDate1 > addDate2 ? -1 : 1
}
