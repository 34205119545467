import axios from 'axios/index'
import cookie from 'react-cookies'
import { mapError } from 'utils/errors'

import apiUrl from './apiUrl'

const changeLanguage = (lang, id) =>
  axios.put(
    `${apiUrl}/api/user/language/${id}`,
    { language: lang },
    { headers: { token: cookie.load('artemest') } }
  )

const read = (id) =>
  axios.get(`${apiUrl}/api/user/${id}`, {
    headers: { token: cookie.load('artemest') }
  })

const login = (user) =>
  axios.post(`${apiUrl}/api/auth/login`, user, {
    headers: {
      token: cookie.load('artemest'),
      'Content-Type': 'application/json'
    }
  })

const logout = () =>
  axios.post(
    `${apiUrl}/api/auth/logout`,
    {},
    {
      headers: { token: cookie.load('artemest') }
    }
  )

/**
 * @param {Object} config
 * @param {String} [config.newEmail]
 * @param {String} config.newPassword
 * @param {String} config.password
 */
const updateCredentials = async (config = {}) => {
  const { newEmail, ...restConfig } = config
  const apiPath = `${apiUrl}/api/auth/profile`
  const data = { ...restConfig }

  if (newEmail) {
    data.newEmail = newEmail
  }

  try {
    const { status } = await axios.put(apiPath, data, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 204) {
      return { success: true }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

const resetPassword = (token, data) =>
  axios.post(`${apiUrl}/api/auth/reset-password?token=${token}`, {
    newPassword: data.newPassword
  })

const forgotPassword = (email) =>
  axios.post(`${apiUrl}/api/auth/forgot-password?email=${email}`)

const activate = (id) =>
  axios.post(
    `${apiUrl}/api/auth/send-credentials/${id}`,
    {},
    {
      headers: { token: cookie.load('artemest') }
    }
  )

const removeUser = (id) =>
  axios.delete(`${apiUrl}/api/user/${id}`, {
    headers: { token: cookie.load('artemest') }
  })

export {
  activate,
  changeLanguage,
  forgotPassword,
  login,
  logout,
  read,
  removeUser,
  resetPassword,
  updateCredentials
}
