import { GetVendor, getVendor, SetPremium } from 'redux/actions/vendor-action'
import { initialSubscriptionTh } from 'redux/thunks/braintree'

export const mapActions = {
  GetVendor,
  getVendor,
  SetPremium,
  initialSubscriptionTh
}

export const mapState = (
  {
    authReducer,
    braintreeReducer: { plans: braintreePlans },
    vendorReducer: { vendor }
  },
  { intl }
) => {
  return {
    vendor,
    intl,
    authReducer,
    braintreePlans
  }
}
