import cn from 'classnames'
import { string } from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Media, Row } from 'reactstrap'
import { COLS_WIDTH } from 'utils/propTypes'

import { PROP_TYPES_PRODUCTS } from './config'
import st from './ProductHeadingList.module.css'

const ProductHeadingList = ({ className, cols, items }) => (
  <Row
    className={cn({
      [className]: className.length > 0
    })}
    tag="ul"
  >
    {items.map(({ _id, alt, hits, hitsIcon, imageUrl, name, path }) => (
      <Col {...cols} className={st.item} key={_id} tag="li">
        <Media className={st.data}>
          <Media left className={st.image}>
            <Link to={path}>
              <img alt={alt} src={imageUrl || 'https://fakeimg.pl/50'} />
            </Link>
          </Media>
          <Media body className={st.content}>
            <Link to={path}>{name}</Link>
            {typeof hits === 'number' && (
              <span className={st.hits}>
                <span className={st.hitsIcon}>{hitsIcon}</span>
                {hits}
              </span>
            )}
          </Media>
        </Media>
      </Col>
    ))}
  </Row>
)

ProductHeadingList.defaultProps = {
  className: '',
  cols: { md: 6, xs: 12 },
  items: []
}
ProductHeadingList.propTypes = {
  className: string,
  cols: COLS_WIDTH,
  items: PROP_TYPES_PRODUCTS
}

export default ProductHeadingList
