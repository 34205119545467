import cn from 'classnames'
import { Button, ButtonGroup } from 'components/buttons'
import { Input, InputFile, ProductPackaging } from 'components/formParts'
import FormImageList from 'components/formParts/FormImageList'
import PDFModal from 'components/PDFModal'
import Spinner from 'components/Spinner/Spinner'
import { FormikProvider, useFormik } from 'formik'
import { noop } from 'lodash'
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types'
import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Col, Row } from 'reactstrap'
import {
  ERRORS,
  ORDER_FILE_INPUT_TYPE,
  ORDER_IMAGE_TYPES,
  ORDER_IMAGES_LIMIT,
  ORDER_PROFORMA_STATUSES
} from 'utils/constants'
import { fileInputsCheck } from 'utils/orders'
import * as Yup from 'yup'

import st from './OrderProductForm.module.css'

const OrderProductForm = ({
  id,
  className,
  initialValues,
  productId,
  intl,
  isLoading,
  details,
  onImageAdd,
  onImageRemove,
  onImageError,
  imagesLoading,
  onSubmit,
  lastSubmittedValues,
  orderManagementId,
  imagesPending,
  deleteFiles,
  uploadFiles,
  proformaFile,
  proformaFilePending,
  canApprove,
  onProformaAction,
  allowCarbCertification,
  certificationFile,
  certificationFilePending
}) => {
  const schema = Yup.object().shape({
    shippingDate: Yup.string().required(ERRORS.REQUIRED),
    parcels: Yup.array().of(
      Yup.object().shape({
        weight: Yup.number()
          .min(0.1, intl.formatMessage({ id: ERRORS.ENTER_A_VALUE }))
          .required(intl.formatMessage({ id: ERRORS.ENTER_A_VALUE })),
        D: Yup.number()
          .min(0.1, intl.formatMessage({ id: ERRORS.ENTER_A_VALUE }))
          .required(intl.formatMessage({ id: ERRORS.ENTER_A_VALUE })),
        W: Yup.number()
          .min(0.1, intl.formatMessage({ id: ERRORS.ENTER_A_VALUE }))
          .required(intl.formatMessage({ id: ERRORS.ENTER_A_VALUE })),
        H: Yup.number()
          .min(0.1, intl.formatMessage({ id: ERRORS.ENTER_A_VALUE }))
          .required(intl.formatMessage({ id: ERRORS.ENTER_A_VALUE }))
      })
    )
  })
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      onSubmit({ ...values, proformaFile, certificationFile }, initialValues)
    },
    validationSchema: schema
  })
  const { values, handleChange, handleSubmit, setFieldValue, errors } = formik
  const [modal, setModal] = useState(false)
  const toggleModal = () => {
    setModal((curr) => !curr)
  }
  const imageInputs = [
    {
      label: intl.formatMessage({ id: 'Ready item pictures' }),
      type: ORDER_IMAGE_TYPES.READY
    },
    {
      label: intl.formatMessage({ id: 'Packaged item pictures' }),
      type: ORDER_IMAGE_TYPES.PACKED
    }
  ]
  const { handleFileUploadProforma, handleFileUploadCertification } =
    uploadFiles
  const { handleFileDeleteProforma, handleFileDeleteCertification } =
    deleteFiles
  const fileInputs = (values, setFieldValue) => {
    return [
      // Show certification field only if the product has MATERIAL: Wood and
      // ships to one of the countries: [US, CA]
      ...(allowCarbCertification
        ? [
            {
              fileUrl: certificationFile,
              pending: certificationFilePending,
              titleClassName: st.fieldTitle,
              containerClassName: st.carbCert,
              type: ORDER_FILE_INPUT_TYPE.CERTIFICATION,
              title: (
                <p className={st.fileInputsTitle}>
                  {intl.formatMessage({ id: 'Certification' })}{' '}
                  {certificationFile ? (
                    <a
                      className={st.fileInputsTitleA}
                      href={certificationFile}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Link
                    </a>
                  ) : null}
                </p>
              ),
              onChange: async (e) => {
                const { files } = e.target
                const allFilesGood = fileInputsCheck(files)

                if (allFilesGood) {
                  const { url, error } = await handleFileUploadCertification({
                    files,
                    productId
                  })

                  if (!error) {
                    onSubmit(
                      { ...values, certificationFile: url },
                      initialValues
                    )
                  }
                }
              },
              onClose: async () => {
                const { url } = await handleFileDeleteCertification({
                  productId
                })
                onSubmit({ ...values, certificationFile: url }, initialValues)
              }
            }
          ]
        : []),
      {
        fileUrl: proformaFile,
        pending: proformaFilePending,
        titleClassName: st.fieldTitle,
        containerClassName: st.proformaFile,
        type: ORDER_FILE_INPUT_TYPE.PROFORMA,
        label: `${intl.formatMessage({ id: 'Status' })}: ${intl.formatMessage({
          id: values.proformaStatus
        })}`,

        title: (
          <p className={st.fileInputsTitle}>
            {intl.formatMessage({ id: 'Purchase order' })}{' '}
            {proformaFile ? (
              <a className={st.fileInputsTitleA} onClick={toggleModal}>
                Open
              </a>
            ) : null}
          </p>
        ),
        onChange: async (e) => {
          const { files } = e.target
          const allFilesGood = fileInputsCheck(files)
          if (allFilesGood) {
            const { url, error } = await handleFileUploadProforma({
              files,
              productId
            })
            if (!error) {
              onSubmit({ ...values, proformaFile: url }, initialValues)
            }
          }
        },
        onClose: async () => {
          const { url } = await handleFileDeleteProforma({ productId })
          onSubmit(
            {
              ...values,
              proformaFile: url
            },
            initialValues
          )
        }
      }
    ].map(({ pending, containerClassName, type, ...content }, id) => {
      const { proformaStatus } = values
      const approved =
        proformaStatus === ORDER_PROFORMA_STATUSES.APPROVED &&
        type === ORDER_FILE_INPUT_TYPE.PROFORMA
      const showButtons =
        canApprove &&
        !proformaFilePending &&
        proformaFile &&
        orderManagementId &&
        type === ORDER_FILE_INPUT_TYPE.PROFORMA

      return {
        content: (
          <div className={containerClassName}>
            <InputFile
              disableModify={approved}
              isLoading={pending}
              className={st.fileInput}
              {...content}
            />
            {showButtons && !approved && (
              <ButtonGroup size="sm">
                <>
                  <Button
                    color="success"
                    onClick={() => {
                      onProformaAction({
                        productId,
                        orderManagementId,
                        type: ORDER_PROFORMA_STATUSES.APPROVED,
                        values,
                        setFieldValue
                      })
                    }}
                    className={st.proformaButton}
                  >
                    <FormattedMessage id="Approve" />
                  </Button>
                  <Button
                    color="warning"
                    onClick={() => {
                      onProformaAction({
                        productId,
                        orderManagementId,
                        type: ORDER_PROFORMA_STATUSES.REJECTED,
                        values,
                        setFieldValue
                      })
                    }}
                    className={st.proformaButton}
                  >
                    <FormattedMessage id="Reject" />
                  </Button>
                </>
              </ButtonGroup>
            )}
          </div>
        ),
        id
      }
    })
  }

  const onImageDelete =
    (type) =>
    async ({ id, path }) => {
      const { name, newValues } = await onImageRemove(
        type,
        values,
        orderManagementId,
        productId,
        lastSubmittedValues
      )({ id, path })

      setFieldValue(name, newValues)

      if (name) {
        onSubmit(
          {
            ...{ ...values, [name]: newValues },
            proformaFile,
            certificationFile
          },
          initialValues
        )
      }
    }

  const onImageChange = (type) => async (e) => {
    const { name, newValues } = await onImageAdd(type, values)(e)

    setFieldValue(name, newValues)
    if (name) {
      onSubmit(
        {
          ...{ ...values, [name]: newValues },
          proformaFile,
          certificationFile
        },
        initialValues
      )
    }
  }

  return (
    <FormikProvider value={formik} className={cn(st.form, className)}>
      <Row className={st.orderShippingDetailsRow}>
        {details()}
        <Col className={st.colRight} lg={6} xs={12}>
          <Row className="rowWide">
            <Col className={st.marginBottom} md={5} xs={12}>
              <Input
                label={`${intl.formatMessage({
                  id: 'Artisan confirmed shipping date'
                })}:`}
                labelClassName={st.fieldTitle}
                className={st.field}
                name="shippingDate"
                type="date"
                onChange={handleChange}
                value={values.shippingDate}
                error={errors.shippingDate}
              />
              <Input
                label={`${intl.formatMessage({ id: 'Comments' })}:`}
                labelClassName={st.fieldTitle}
                name="comments"
                type="textarea"
                onChange={handleChange}
                value={values.comments}
              />
            </Col>
            <Col className={st.marginBottom} md={7} xs={12}>
              <Row className={cn('rowWide', st.marginBottom)}>
                {fileInputs(values, setFieldValue).map((fi) => {
                  return <div key={fi.id}>{fi.content}</div>
                })}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={st.imagesRow}>
        {imageInputs.map((imageInput) => {
          const disableModify =
            values[`images${imageInput.type}`].length >= ORDER_IMAGES_LIMIT
          return (
            <FormImageList
              key={imageInput.type}
              onChange={onImageChange(imageInput.type)}
              imageInput={imageInput}
              onImageError={onImageError}
              imagesLoading={imagesLoading}
              disableModify={disableModify}
              onRemove={onImageDelete(imageInput.type)}
              imageListItems={values[`images${imageInput.type}`]}
              imagesPending={imagesPending}
            />
          )
        })}
      </Row>
      <div className={st.packages}>
        <ProductPackaging
          productId={id}
          parcels={values.parcels}
          lightInputs
          noCost
        />
      </div>
      <Row>
        <Col>
          <div className={st.buttons}>
            <Button
              disabled={isLoading}
              black
              type="submit"
              onClick={handleSubmit}
            >
              {isLoading ? (
                <Spinner
                  color="--main-bg-light"
                  width={43}
                  length={3}
                  size={14}
                  show
                />
              ) : (
                <FormattedMessage id="Save" />
              )}
            </Button>
          </div>
        </Col>
      </Row>

      {proformaFile && (
        <PDFModal open={modal} toggle={toggleModal} url={proformaFile} />
      )}
    </FormikProvider>
  )
}

OrderProductForm.defaultProps = {
  className: '',
  initialValues: {
    comments: '',
    shippingDate: '',
    parcels: []
  },
  inputFileCntClassName: '',
  onFileChange: noop,
  onFileClose: noop,
  onSubmit: noop,
  isLoading: false,
  details: noop,
  onImageAdd: noop,
  onImageError: noop,
  imagesLoading: [],
  onImageRemove: noop,
  lastSubmittedValues: {},
  orderManagementId: '',
  imagesPending: {},
  productId: '',
  onProformaAction: noop,
  canApprove: false
}
OrderProductForm.propTypes = {
  id: string,
  className: string,
  initialValues: shape({
    comments: string,
    productId: string.isRequired,
    shippingDate: string,
    parcels: arrayOf(
      shape({
        amountCurrency: string,
        deep: number,
        height: number,
        name: string,
        shipmentDataLoaded: bool,
        weight: number,
        width: number
      })
    )
  }),
  inputFileCntClassName: string,
  onFileChange: func,
  onFileClose: func,
  onSubmit: func,
  isLoading: bool,
  intl: object,
  details: func,
  onImageAdd: func,
  onImageError: func,
  onImageRemove: func,
  imagesLoading: arrayOf(string),
  lastSubmittedValues: shape({}),
  orderManagementId: string,
  imagesPending: shape({}),
  productId: string,
  deleteFiles: shape({}),
  uploadFiles: shape({}),
  proformaFile: string,
  proformaFilePending: bool,
  onProformaAction: func,
  canApprove: bool,
  allowCarbCertification: bool,
  certificationFile: string,
  certificationFilePending: bool
}

export default injectIntl(OrderProductForm)
