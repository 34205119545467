import {
  AUTHENTICATION_ERROR,
  HIDE_LOADING,
  SHOW_LOADING,
  UPDATE_PROGRESS
} from '../actions/action-types'

const initialState = {
  progress: 0,
  show: false
}

const LoadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATION_ERROR:
      return {
        ...state,
        ...initialState
      }

    case SHOW_LOADING:
      return {
        ...state,
        show: true,
        progress: initialState.progress
      }

    case HIDE_LOADING:
      return {
        ...state,
        show: false,
        progress: initialState.progress
      }

    case UPDATE_PROGRESS:
      return {
        ...state,
        progress: action.progress
      }

    default:
      return state
  }
}

export default LoadingReducer
