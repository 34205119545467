import { get, noop } from 'lodash'
import {
  braintreeClientTokenSet,
  braintreeDeviceDataSet,
  braintreeIsNoticePeriod,
  braintreePlanPendingId,
  braintreePlansGet,
  braintreeSubscriptionsCanceledIds,
  braintreeSubscriptionsGet
} from 'redux/actions/braintree-action'
import { HideLoading, ShowLoading } from 'redux/actions/loading-action'
import { vendorPremiumAnalyticsEnable } from 'redux/actions/vendor-action'
import {
  brainTreeSetup,
  buySubscription,
  deleteSubscriptions,
  getSubscriptionPlans,
  getSubscriptionsForVendor,
  getTokenByVendorId,
  updateCardDetails
} from 'redux/service/payment-service'
import { BRAINTREE_ERRORS, notifyMappedError } from 'utils/errors'
import { mapSubscriptions, onlyActiveAndPending } from 'utils/payment'
import { showNotify } from 'utils/toast'

/**
 * @param {Object} config
 * @param {Function} config.dispatch
 * @param {String} config.environment
 * @param {String} config.vendorId
 */
const _commonInit = (config = {}) => {
  const { dispatch, environment, vendorId } = config
  let token = ''

  return getTokenByVendorId(vendorId)
    .then(({ clientToken }) => {
      if (clientToken) {
        token = clientToken
        dispatch(braintreeClientTokenSet(clientToken))

        return brainTreeSetup({
          clientToken,
          environment
        })
      }

      return {}
    })
    .then(({ deviceData }) => {
      dispatch(braintreeDeviceDataSet(deviceData))
      return { clientToken: token }
    })
}

/**
 * @param {Object} config
 * @param {Object} config.instance - Braintree instance
 * @param {Number|String} config.planId
 * @param {Number|String} config.vendorId
 * @param {Function} config.onSuccess
 */
export const buySubscriptionTh =
  (config = {}) =>
  (dispatch) => {
    const { instance, planId, vendorId, amount, onSuccess = noop } = config
    const isPaymentMethod = instance.isPaymentMethodRequestable()

    if (!isPaymentMethod) {
      showNotify({
        isError: true,
        message:
          'Please, select a correct payment method first or fill it with correct data'
      })

      return
    }

    dispatch(ShowLoading())

    getSubscriptionsForVendor(vendorId)
      .then(({ subscriptions }) => {
        if (subscriptions) {
          const subscriptionsIds = onlyActiveAndPending(subscriptions).map(
            ({ id, subscriptionId }) => id || subscriptionId
          )

          if (subscriptionsIds.length > 0) {
            deleteSubscriptions(subscriptionsIds)
          }
        }

        return buySubscription({ instance, planId, vendorId, amount })
      })
      .then((res) => {
        const { data, error } = res

        if (error) {
          const { GATEWAY_REJECTED_DUPLICATE, NONCE_MORE_THAN_ONCE } =
            BRAINTREE_ERRORS
          const { message } = error

          if (message === GATEWAY_REJECTED_DUPLICATE) {
            error.message =
              'We are processing your transaction. Please try again in 30 seconds.'
          }
          if (message === NONCE_MORE_THAN_ONCE) {
            error.message =
              'You have already used this payment method. Please choose another one.'
          }

          notifyMappedError(error)
        }

        if (data) {
          const isNoticePeriod = get(data, 'premium.isNoticePeriod', false)
          const pending = get(data, 'premium.pending', false)
          const planId = get(data, 'premium.planId', '')

          if (isNoticePeriod) {
            dispatch(braintreeIsNoticePeriod(planId))
          }

          if (pending) {
            dispatch(braintreePlanPendingId(planId))
          }

          if (typeof onSuccess === 'function') {
            onSuccess()
          }
        }

        dispatch(HideLoading())
      })
  }

/**
 * @param {Object} config
 * @param {Array} config.ids
 * @param {Number|String} config.ids[]
 * @param {Function} onSuccess
 */
export const deleteSubscriptionsTh =
  (config = {}) =>
  (dispatch) => {
    const { ids = [], onSuccess = noop } = config

    dispatch(ShowLoading())
    deleteSubscriptions(ids).then((res) => {
      const { canceled, error } = res

      if (canceled) {
        const ids = canceled.map(({ id }) => id)

        dispatch(braintreeSubscriptionsCanceledIds(ids))
        dispatch(vendorPremiumAnalyticsEnable())

        if (typeof onSuccess === 'function') {
          onSuccess()
        }
      }

      if (error) {
        notifyMappedError(error)
      }

      dispatch(HideLoading())
    })
  }

/**
 * @param {Object} config
 * @param {String} config.environment
 * @param {String} config.vendorId
 */
export const initialTh =
  (config = {}) =>
  (dispatch) => {
    dispatch(ShowLoading())
    _commonInit({ ...config, dispatch }).finally(() => {
      dispatch(HideLoading())
    })
  }

/**
 * @param {Object} config
 * @param {String} config.environment
 * @param {String} config.vendorId
 */
export const initialSubscriptionTh =
  (
    config = {},
    history // Accept history object as a parameter
  ) =>
  (dispatch) => {
    const { vendorId = '' } = config

    dispatch(ShowLoading())
    _commonInit({ ...config, dispatch }).then(({ clientToken }) => {
      getSubscriptionsForVendor(vendorId).then(({ error, subscriptions }) => {
        if (error) {
          history.push('/') // Navigate to homepage on error
          notifyMappedError(error)
        }

        if (subscriptions) {
          const mapped = onlyActiveAndPending(mapSubscriptions(subscriptions))
          dispatch(braintreeSubscriptionsGet(mapped))
        }
      })

      getSubscriptionPlans(clientToken).then(({ error, plans }) => {
        if (error) {
          notifyMappedError(error)
        }

        if (plans) {
          dispatch(braintreePlansGet(plans))
        }

        dispatch(HideLoading())
      })
    })
  }

export const updatePaymentDetails =
  (config = {}) =>
  () => {
    const { instance, amount, onSuccess, showLoading } = config
    const isPaymentMethod = instance.isPaymentMethodRequestable()

    if (!isPaymentMethod) {
      showNotify({
        isError: true,
        message:
          'Please, select a correct payment method first or fill it with correct data'
      })

      return
    }

    updateCardDetails({ instance, amount, showLoading }).then((res) => {
      const { data, error } = res
      if (error) {
        notifyMappedError(error)
        return
      }
      if (data) {
        if (typeof onSuccess === 'function') {
          onSuccess(data)
        }
      }
    })
  }
