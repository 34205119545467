import { bool, func, shape } from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label
} from 'reactstrap'
import { CURRENT_YEAR, EXTERNAL_URLS } from 'utils/constants'

import { ForgotPassword as forgotPassword } from '../redux/actions/users-action'
import logo from '../static/logos/artemestLong.svg'
import Style from './styles/login.module.css'

class ForgotPassword extends Component {
  state = {
    email: '',
    validate: {
      emailState: ''
    }
  }

  validateEmail(email) {
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const { validate } = this.state
    if (emailRex.test(email)) {
      validate.emailState = 'has-success'
    } else {
      validate.emailState = 'has-danger'
    }
    this.setState({ validate })
  }

  submitForm = (e) => {
    e.preventDefault()
    const email = this.state.email
    if (email.length && this.state.validate.emailState === 'has-success') {
      this.props.ForgotPassword(email)
    }
  }

  render() {
    const { intl, forgotPasswordReducer } = this.props

    return (
      <div className={Style.body}>
        <div
          className={['row', Style.mainRow].join(' ')}
          style={{ width: '100%' }}
        >
          <Col
            md={{ size: 4, offset: 4 }}
            sm={{ size: 6, offset: 3 }}
            xs={{ size: 8, offset: 2 }}
            className={Style.mainDiv}
          >
            <img alt="logo" className={Style.logo} src={logo} width="50%" />
            <h2 className={Style.title}>
              <FormattedMessage id="forgotPassword" />
            </h2>
            {forgotPasswordReducer && forgotPasswordReducer.completed ? (
              <p>
                <FormattedMessage
                  id="forgotPassword_success"
                  values={{ email: this.state.email }}
                />
              </p>
            ) : (
              <>
                <p>
                  <FormattedMessage id="forgotPassword_intro" />
                </p>
                <Form onSubmit={this.submitForm}>
                  <Col>
                    <FormGroup>
                      <Label for="exampleEmail" className={Style.txtColor}>
                        <FormattedMessage id={'Email'} />
                      </Label>
                      <Input
                        type="email"
                        name="email"
                        id="exampleEmail"
                        placeholder={intl.formatMessage({ id: 'email' })}
                        value={this.state.email}
                        valid={this.state.validate.emailState === 'has-success'}
                        invalid={
                          this.state.validate.emailState === 'has-danger'
                        }
                        onChange={(event) => {
                          this.validateEmail(event.target.value)
                          this.setState({
                            email: event.target.value,
                            show: false
                          })
                        }}
                        required
                      />
                      <FormFeedback>
                        <FormattedMessage id={'error label'} />
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <div style={{ width: '100%' }}>
                    <div className={Style.loginButtonDiv}>
                      <Button
                        className={Style.loginButton}
                        color="primary"
                        type="submit"
                      >
                        <FormattedMessage id={'send'} />
                      </Button>
                    </div>
                  </div>
                </Form>
              </>
            )}
            <div className={Style.loginButtonDiv}>
              <p>
                <FormattedMessage
                  id="forgotPassword_info"
                  values={{
                    email: (
                      <a href="mailto:catalogo@artemest.com">
                        catalogo@artemest.com
                      </a>
                    )
                  }}
                />
              </p>
            </div>
          </Col>
        </div>
        <div className={Style.copyRight}>
          <p className={Style.copyRightP}>
            {' '}
            &copy; {CURRENT_YEAR} ARTEMEST.{' '}
            <FormattedMessage id={'CopyRights'} tagName={'span'} />
          </p>
          <p className={Style.privacyPolicy}>
            <a
              href={EXTERNAL_URLS.PRIVACY_POLICY}
              rel="noreferrer"
              target="_blank"
            >
              <FormattedMessage id="Privacy Policy" />
            </a>
          </p>
        </div>
      </div>
    )
  }
}

ForgotPassword.propTypes = {
  forgotPasswordReducer: shape({
    completed: bool
  }),
  ForgotPassword: func,
  intl: shape({
    formatMessage: func
  })
}

const mapStateToProps = (state) => ({
  forgotPasswordReducer: state.forgotPasswordReducer,
  userReducer: state.userReducer
})

const mapDispatchToProps = (dispatch) => ({
  ForgotPassword: (email) => dispatch(forgotPassword(email))
})

export default withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword))
)
