import { QueryRenderer } from '@cubejs-client/react'
import { Status } from 'components'
import { get, noop } from 'lodash'
import { bool, func, number, oneOfType, shape, string } from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { ResponsiveContainer } from 'recharts'

const StatusMessage = ({ message }) => (
  <Status fontSize="1.5em">{message}</Status>
)
StatusMessage.propTypes = {
  message: string
}

const Cube = ({
  children,
  cubeJsApi,
  height,
  intl: { formatMessage },
  isChart,
  mapResults,
  query,
  validateMappedResults,
  width
}) => (
  <QueryRenderer
    cubejsApi={cubeJsApi}
    query={query}
    render={({ error, loadingState: { isLoading }, resultSet }) => {
      if (error) {
        // parser
        const errorMessage = get(error, 'message', '')
          .replace('<span data-ansi-line="true"><span>', '')
          .replaceAll('</span>', '')
          .replaceAll('&apos;', "'")
          .replaceAll('&quot;', '"')
        return <StatusMessage message={errorMessage} />
      }

      if (isLoading) {
        return (
          <StatusMessage message={`${formatMessage({ id: 'Loading' })}...`} />
        )
      }

      const noData = `${formatMessage({ id: 'No data' })}`

      if (!resultSet) {
        return <StatusMessage message={noData} />
      }

      const chartData = mapResults(resultSet)
      const validResults = validateMappedResults(chartData)

      if (!validResults) {
        return <StatusMessage message={noData} />
      }

      const toRender = children({ data: chartData })

      return isChart ? (
        <ResponsiveContainer height={height} width={width}>
          {toRender}
        </ResponsiveContainer>
      ) : (
        toRender
      )
    }}
    resetResultSetOnChange={false}
  />
)

Cube.defaultProps = {
  children: noop,
  cubeJsApi: {},
  height: 0,
  isChart: false,
  mapResults: noop,
  query: {},
  validateMappedResults: noop,
  width: '100%'
}
Cube.propTypes = {
  children: func,
  cubeJsApi: shape({}),
  height: number,
  isChart: bool,
  mapResults: func,
  query: shape({}),
  validateMappedResults: func,
  width: oneOfType([number, string]),
  intl: shape({
    formatMessage: func
  })
}

export default injectIntl(Cube)
