import classNames from 'classnames'
import { string } from 'prop-types'
import React from 'react'
import { DropdownToggle as DropdownToggleRS } from 'reactstrap'

import st from './DropdownToggle.module.css'

const DropdownToggle = ({ black, ...props }) => {
  return (
    <DropdownToggleRS
      className={classNames(st.dropdownToggle, {
        [st.black]: black
      })}
      {...props}
    />
  )
}

DropdownToggle.propTypes = {
  black: string
}
export default DropdownToggle
