import cn from 'classnames'
import { PageNavVendorActions, Pagination, Spinner } from 'components'
import { SearchFormLittle } from 'components/forms'
import { InnerPage } from 'components/layout'
import Loading from 'components/loading/loading'
import TranslatorTableCard from 'components/translator-table-card/translator-table-card'
import { get } from 'lodash'
import { array, func, shape, string } from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import {
  collectionTranslatesPageAc,
  GetCollectionById,
  GetCollectionProductTranslates
} from 'redux/actions/collection-action'
import { getUserType } from 'utils/auth'
import { PAGINATE } from 'utils/constants'

import Style from './styles/translator.module.css'

class TranslatorDashboard extends PureComponent {
  state = {
    vendor: null
  }

  componentDidMount() {
    const { GetCollectionById } = this.props
    const collectionId = this.getCollectionId() || null

    if (collectionId) {
      GetCollectionById({ id: collectionId })
    }

    this.initTranslates()
  }

  componentDidUpdate(prevProps) {
    const {
      collectionReducer: { translatesList: translatesListPrev }
    } = prevProps
    const page = this.page()
    const pagePrev = +get(translatesListPrev, 'page', 1)

    if (pagePrev !== page) {
      this.initTranslates({ page, vendor: this.state.vendor })
    }
  }

  getCollectionId = () => get(this.props, 'match.params.collection', '')

  getTranslatorId = () => get(this.props, 'match.params.translator', '')

  getVendorId = () => get(this.props, 'collectionReducer.collection.vendor', '')

  handleClickVAImages = () => {
    const {
      collectionReducer: { collection },
      history
    } = this.props

    const translatorId = this.getTranslatorId()
    const vendorId = this.getVendorId()

    history.push({
      pathname: `/image-assets/${translatorId}/${vendorId}`,
      state: { collection }
    })
  }

  initTranslates = (config = {}) => {
    const { page = 1, vendor = null } = config
    const { getCollectionProductTranslates } = this.props
    const collectionId = this.getCollectionId() || null

    getCollectionProductTranslates({
      collection: collectionId,
      hasProducts: true,
      page,
      readyToTranslate: true,
      vendor,
      isApproved: false
    })
  }

  notFound = ({ key } = {}) => (
    <div className={Style.h2} key={key}>
      <FormattedMessage id="translations not found" />
    </div>
  )

  page = () => {
    const {
      collectionReducer: { translatesList }
    } = this.props

    return +get(translatesList, 'page', 1)
  }

  pages = () => {
    const {
      collectionReducer: { translatesList }
    } = this.props

    return +get(translatesList, 'pages', 0)
  }

  setPage = (page) => {
    const { collectionTranslatesPageAc } = this.props

    collectionTranslatesPageAc(page)
  }

  renderCard = () => {
    const { collectionReducer } = this.props
    const docsToTranslate = get(collectionReducer, 'translatesList.docs', [])

    return docsToTranslate.map((element) => (
      <div className={cn('col-md-6', Style.card)} key={element._id}>
        <TranslatorTableCard data={element} />
      </div>
    ))
  }

  filterTranslationsByArtisan = (artisanData) => {
    const { value: vendor } = artisanData
    this.setState({ vendor })
    this.initTranslates({ vendor })
  }

  render() {
    const {
      authReducer,
      collectionReducer: { gotTranslates, pending },
      intl,
      match: { url }
    } = this.props
    const collectionId = this.getCollectionId()
    const userType = getUserType(authReducer)
    const vendorId = this.getVendorId()

    return gotTranslates ? (
      <InnerPage
        heading={intl.formatMessage({
          id: 'openTranslations'
        })}
        headingSlot={
          <SearchFormLittle
            placeholder={intl.formatMessage({
              id: 'Search for artisans'
            })}
            onSubmit={this.filterTranslationsByArtisan}
          />
        }
      >
        <PageNavVendorActions
          collectionId={collectionId}
          currentUrl={url}
          userType={userType}
          vendorId={vendorId}
          onClickImages={this.handleClickVAImages}
        />
        <Spinner show={pending}>
          <div className="row rowWide">{this.renderCard()}</div>
          <Pagination
            alignCenter
            className={Style.paginator}
            current={this.page()}
            pages={this.pages()}
            visiblePages={PAGINATE.VISIBLE_PAGES}
            onChange={this.setPage}
          />
        </Spinner>
      </InnerPage>
    ) : (
      <Loading />
    )
  }
}
TranslatorDashboard.propTypes = {
  GetCollectionById: func,
  collectionReducer: shape({
    translatesList: array,
    collection: string
  }),
  history: shape({
    push: func
  }),
  getCollectionProductTranslates: func,
  collectionTranslatesPageAc: func,
  authReducer: shape({}),
  intl: shape({
    formatMessage: func
  }),
  match: shape({
    url: string
  })
}
const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  collectionReducer: state.collectionReducer,
  translationReducer: state.translationReducer
})
const mapDispatchToProps = {
  collectionTranslatesPageAc,
  GetCollectionById,
  getCollectionProductTranslates: GetCollectionProductTranslates
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(TranslatorDashboard)
)
