import cn from 'classnames'
import { SearchFormDropdown, UpdateCredentialsForm } from 'components/forms'
import icons from 'glyphicons'
import { debounce, get, uniqueId } from 'lodash'
import includes from 'lodash/includes'
import { func, shape, string } from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {
  Collapse,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem
} from 'reactstrap'
import { Auth } from 'redux/actions/auth-action'
import { LogOut } from 'redux/actions/users-action'
import { GetVendor } from 'redux/actions/vendor-action'
import { getProducts } from 'redux/service/product-service'
import { updateCredentialsTh } from 'redux/thunks/users'
import avatar from 'static/images/avatar.svg'
import logo from 'static/logos/artemest-round.svg'
import { getUserEmail, isApprover, isTranslator, isVendor } from 'utils/auth'
import { getApprovedProducts } from 'utils/collections'
import { USER_TYPES } from 'utils/constants'
import { dataOfButtons } from 'utils/header-button-data'
import { filterByVendorId, mapSearchedProducts } from 'utils/products'
import { clearPathFromQuery } from 'utils/utils'

import ChangeLanguage from '../change-language/change-language'
import Loading from '../loading/loading'
import Style from './header.module.css'

class Header extends React.Component {
  _stateInit = {
    isOpen: false,
    isModalOpen: false,
    isDropdownOpen: false,
    findingProducts: false,
    findingProductsId: '',
    foundProducts: []
  }
  state = { ...this._stateInit }

  stateReset = () => {
    this.setState({ ...this._stateInit })
  }

  componentDidMount() {
    const userId = this.getUserId()
    const userType = this.getUserType()

    if (userType === 'vendor') {
      this.props.GetVendor({
        ifWithLoading: false,
        vendorId: userId
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { authReducer: authReducerPrev } = prevProps
    const { authReducer } = this.props
    const emailPrev = getUserEmail(authReducerPrev)
    const email = getUserEmail(authReducer)
    const userId = this.getUserId()
    const userType = get(authReducer, 'userType', '')
    const userTypePrev = get(authReducerPrev, 'userType', '')

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ isDropdownOpen: false })
    }
    if (emailPrev && email && emailPrev !== email) {
      this.logOut()
    }

    if (userTypePrev !== userType) {
      if (!userType) {
        this.stateReset()
      }

      if (userType === 'vendor') {
        this.props.GetVendor({
          ifWithLoading: false,
          vendorId: userId
        })
      }
    }
  }

  getUserId = () => get(this.props, 'authReducer.user._id', '')
  getUserType = () => get(this.props, 'authReducer.userType', '')
  getVendorCompanyId = () => {
    const {
      vendorReducer: { vendor }
    } = this.props

    return get(vendor, 'company', '')
  }
  getVendorId = () => {
    const userId = this.getUserId()
    const userType = this.getUserType()

    return userType === USER_TYPES.VENDOR ? userId : ''
  }

  toggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }))
  toggleDropdown = () =>
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen })
  toggleModal = () =>
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }))

  handleUpdateCredentials = (values = {}) => {
    const { email, newPassword, oldPassword } = values
    const { authReducer, updateCredentialsTh } = this.props
    const currentUserEmail = getUserEmail(authReducer)
    const toSend = {
      password: oldPassword,
      onSuccess: () => {
        this.setState({ isModalOpen: false }, this.logOut)
      }
    }

    if (email && currentUserEmail !== email) {
      toSend.newEmail = email
    }
    if (newPassword) {
      toSend.newPassword = newPassword
    }

    updateCredentialsTh(toSend)
  }

  logOut = () => {
    this.props.LogOut()
  }

  renderNavbar = () => {
    const {
      authReducer,
      location: { pathname }
    } = this.props
    const userType = get(authReducer, 'userType', '')

    return userType
      ? dataOfButtons({
          vendorCompanyId: this.getVendorCompanyId(),
          vendorId: this.getVendorId()
        })
          .filter((data) => includes(data.rights, userType))
          .map(({ button, key, link }) => {
            const linkPath = clearPathFromQuery(link)

            return (
              <NavItem key={`${key}_${button}`} className={Style.link}>
                <Link
                  className={cn(Style['link-a'], {
                    [Style['link-active']]: linkPath === pathname
                  })}
                  to={link}
                >
                  <FormattedMessage id={button} tagName="span" />
                </Link>
              </NavItem>
            )
          })
      : null
  }

  _searchedValue = ''

  handleSearchProducts = (values) => {
    const { value } = values

    this._searchedValue = value

    if (!value || value.length < 3) {
      this.setState({
        findingProducts: false,
        findingProductsId: '',
        foundProducts: []
      })

      return
    }

    const { authReducer, history } = this.props
    const findingProductsId = uniqueId()
    const isUserApprover = isApprover(authReducer)
    const isUserTranslator = isTranslator(authReducer)
    const isUserVendor = isVendor(authReducer)
    const params = { search: value }

    this.setState({
      findingProducts: true,
      findingProductsId
    })

    getProducts(params).then(
      ((callId) => (res) => {
        const { findingProductsId } = this.state

        if (findingProductsId !== callId) {
          return
        }

        let { products } = res

        if (!isUserApprover) {
          products = getApprovedProducts(res)
        }

        if (isUserVendor) {
          products = filterByVendorId({
            products,
            vendorId: this.getUserId()
          })
        }

        if (products) {
          this.setState({
            findingProducts: false,
            foundProducts: this._searchedValue
              ? mapSearchedProducts(products).map((fp) => ({
                  ...fp,
                  disabled: isUserTranslator,
                  onClick: () => {
                    history.push(`/product-detail/${fp._id}`)
                  }
                }))
              : []
          })
        }
      })(findingProductsId)
    )
  }

  render() {
    const { authReducer, className, intl, location } = this.props
    const { findingProducts, foundProducts, isModalOpen, isOpen } = this.state
    const initialValues = {
      email: getUserEmail(authReducer)
    }
    const isVisible =
      location.pathname !== '/' &&
      location.pathname !== '/forgot-password' &&
      location.pathname !== '/reset-password'
    const vendorId = this.getVendorId()

    return isVisible ? (
      <div className={cn('innerPage', Style.header)}>
        <Navbar light expand="md">
          <Link className={Style.logoLink} to="/">
            <img className={Style.smallLogo} alt="logo" src={logo} />
          </Link>
          <NavbarToggler onClick={this.toggle} />
          <Collapse navbar className={Style.navbar} isOpen={isOpen}>
            <Nav navbar>{this.renderNavbar()}</Nav>
            <Nav navbar>
              <NavItem className={Style.search}>
                {!isTranslator(authReducer) && (
                  <SearchFormDropdown
                    items={foundProducts}
                    noItemsText={intl.formatMessage({
                      id: 'No items. Try typing more characters'
                    })}
                    pending={findingProducts}
                    onSubmit={debounce(this.handleSearchProducts, 700)}
                  />
                )}
              </NavItem>
              <Dropdown
                isOpen={this.state.isDropdownOpen}
                toggle={this.toggleDropdown}
                nav
                inNavbar
              >
                <DropdownToggle nav caret>
                  <button className={Style.dropbtn}>
                    <img className={Style.avatar} alt="avatar" src={avatar} />
                  </button>
                </DropdownToggle>
                <DropdownMenu className={Style.dropdownMenu} right>
                  <ChangeLanguage className={Style['navbar-lang']} />
                  {vendorId && (
                    <Link className={Style.p} to={`/vendor-plans/${vendorId}`}>
                      Vendor Program
                      {icons.arrowR}
                    </Link>
                  )}

                  <p className={Style.p} onClick={this.toggleModal}>
                    <FormattedMessage id="updatePassword" />
                    {icons.arrowR}
                  </p>
                  <p className={Style.p} onClick={this.logOut}>
                    <FormattedMessage id="logout" />
                    {icons.arrowR}
                  </p>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
          <Modal
            isOpen={isModalOpen}
            toggle={this.toggleModal}
            className={className}
          >
            <ModalHeader toggle={this.toggleModal}>
              <FormattedMessage id="Update password" />
            </ModalHeader>
            <ModalBody>
              <UpdateCredentialsForm
                initialValues={initialValues}
                onCancel={this.toggleModal}
                onSubmit={this.handleUpdateCredentials}
              />
            </ModalBody>
          </Modal>
        </Navbar>
        <Loading />
      </div>
    ) : null
  }
}

Header.propTypes = {
  GetVendor: func,
  authReducer: shape({
    vendor: shape({})
  }),
  vendorReducer: shape({
    vendor: shape({})
  }),
  updateCredentialsTh: func,
  LogOut: func,
  location: shape({
    pathname: string
  }),
  history: shape({
    push: func
  }),
  className: string,
  intl: shape({
    formatMessage: func
  })
}
const mapActions = {
  Auth,
  GetVendor,
  LogOut,
  updateCredentialsTh
}
const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  userReducer: state.userReducer,
  vendorReducer: state.vendorReducer
})

export default withRouter(
  injectIntl(connect(mapStateToProps, mapActions)(Header))
)
