import dayjs from 'dayjs'

const EXPECTED_YEAR_LENGTH = 4

export const formatYearToYYYY = (dateString) => {
  const dateElements = dateString.split('-')
  const yearString = dateElements[0]
  const yearStringLength = yearString.length

  const formattedYearString =
    yearStringLength === EXPECTED_YEAR_LENGTH
      ? yearString
      : yearString.padStart(EXPECTED_YEAR_LENGTH, '0')

  dateElements[0] = formattedYearString

  return dateElements.join('-')
}

export const dtFormat = (dtString = '', format = 'YYYY-MM-DD') => {
  return dtString ? formatYearToYYYY(dayjs(dtString).format(format)) : ''
}
