import cn from 'classnames'
import { PageNavVendorActions, Spinner } from 'components'
import { Button } from 'components/buttons'
import ExportPreviewComponent from 'components/export-preview/export-preview-component'
import { InnerPage } from 'components/layout'
import FileDownload from 'js-file-download'
import { get } from 'lodash'
import { func, shape, string } from 'prop-types'
import qs from 'query-string'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row
} from 'reactstrap'
import { downloadCSV, readCollection } from 'redux/service/collection-service'
import {
  blockUnblock,
  getProductsByCollection
} from 'redux/service/product-service'
import { STATUSES } from 'utils/constants'
import { notifyMappedError } from 'utils/errors'
import { createArray } from 'utils/utils'

import Style from './styles/export-preview.module.css'

class ExportPreview extends Component {
  state = {
    collection: {},
    count: 0,
    limit: 26,
    name: '',
    page: 1,
    pending: false,
    products: [],
    vendorId: ''
  }

  componentDidMount() {
    const collectionId = this.getCollectionId()
    const { limit, page } = this.getPathSearch()

    this.getProducts(collectionId, page, limit)
    readCollection(collectionId).then(({ data, error }) => {
      if (error) {
        return
      }

      const collection = get(data, '[0]', {})

      this.setState({
        collection,
        limit,
        name: get(collection, 'name', ''),
        page,
        vendorId: get(collection, 'vendor', '')
      })
    })
  }

  getCollectionId = () => get(this.props, 'match.params.collection', '')

  getPathSearch = () => {
    const search = get(this.props, 'location.search', '')
    const { limit, page } = qs.parse(search)

    return {
      limit: +limit,
      page: +page
    }
  }

  getProducts = (collection, page, limit) => {
    getProductsByCollection({
      collectionId: collection,
      limit,
      page,
      status: STATUSES.APPROVED
    }).then(({ data, error }) => {
      if (data) {
        const { docs, limit, page, pages } = data

        this.setState({
          limit,
          page,
          pages,
          products: docs
        })
        window.scrollTo(0, 0)
      }

      if (error) {
        notifyMappedError(error)
      }
    })
  }

  handleClickVAImages = () => {
    const { history, userType } = this.props
    const { collection, vendorId } = this.state

    history.push({
      pathname: `/image-assets/${userType}/${vendorId}`,
      state: { collection }
    })
  }

  handleDownloadCSVClick = () => {
    const { products = [] } = this.state
    const collectionId = this.getCollectionId()

    const pBlockCalls = products
      .filter(({ blocked }) => !blocked)
      .map(({ _id }) =>
        blockUnblock({
          blocked: true,
          productId: _id
        })
      )

    this.setPagePending(true)
    Promise.all(pBlockCalls)
      .then(() => downloadCSV(collectionId))
      .then((res) => {
        FileDownload(res.data, `${collectionId}s_products.csv`)
        this.setPagePending()
      })
  }

  handlePaginationButtonClick = (idx) => () => {
    const { limit } = this.state
    const collectionId = this.getCollectionId()

    this.getProducts(collectionId, idx, limit)
  }

  paginationButtons = (pageCount) => {
    const { limit, page } = this.state

    return createArray(page, pageCount).map((index) => {
      const collectionId = this.getCollectionId()
      const path = `/export-preview/${collectionId}?page=${index}&limit=${limit}`

      return (
        <PaginationItem key={index}>
          <PaginationLink
            className={cn(Style.paginationLinks, {
              [Style.currentPage]: index === page
            })}
          >
            <Link onClick={this.handlePaginationButtonClick(index)} to={path}>
              {index}
            </Link>
          </PaginationLink>
        </PaginationItem>
      )
    })
  }

  setPagePending = (pending = false) => {
    this.setState({ pending })
  }

  renderPagination = (countObj) => {
    const { limit, page, pages } = this.state
    const collectionId = this.getCollectionId()
    const href1 = `/export-preview/${collectionId}?page=${
      page - 1
    }&limit=${limit}`
    const href2 = `/export-preview/${collectionId}?page=${
      page + 1
    }&limit=${limit}`

    return pages > 1 ? (
      <Pagination
        className={Style.pagination}
        aria-label="Page navigation example"
      >
        <PaginationItem disabled={page === 1}>
          <PaginationLink
            previous
            className={Style.paginationLinks}
            href={href1}
          />
        </PaginationItem>
        {this.paginationButtons(countObj)}
        <PaginationItem disabled={page === countObj}>
          <PaginationLink next className={Style.paginationLinks} href={href2} />
        </PaginationItem>
      </Pagination>
    ) : null
  }

  renderProduct = (elements) => {
    const { lang } = this.props

    return elements.map((element) => (
      <div key={element._id}>
        <ExportPreviewComponent element={element} lang={lang} />
        <hr />
      </div>
    ))
  }

  render() {
    const {
      location,
      match: { url },
      userType
    } = this.props
    const { name, pages, pending, products, vendorId } = this.state
    const collectionId = this.getCollectionId()
    const currentUrl = `${url}${location.search}`

    return products ? (
      <InnerPage heading={name}>
        <Spinner show={pending}>
          <Row className={Style.firstRow}>
            <Col className="flex-end" xs={12}>
              <Button
                className="btn btn-secondary"
                onClick={this.handleDownloadCSVClick}
              >
                <FormattedMessage id="Download CSV" />
              </Button>
            </Col>
          </Row>
          <PageNavVendorActions
            collectionId={collectionId}
            currentUrl={currentUrl}
            userType={userType}
            vendorId={vendorId}
            onClickImages={this.handleClickVAImages}
          />
          {this.renderProduct(products)}
          <Row className={Style.paginationDiv}>
            {this.renderPagination(pages)}
          </Row>
        </Spinner>
      </InnerPage>
    ) : null
  }
}

ExportPreview.propTypes = {
  history: shape({
    push: func
  }),
  userType: string,
  lang: string,
  location: shape({ search: string }),
  match: shape({ url: string })
}
const mapStateToProps = ({
  authReducer,
  languageReducer: { lang },
  productReducer
}) => {
  const { userType } = authReducer

  return {
    lang,
    productReducer,
    userType
  }
}

export default connect(mapStateToProps)(ExportPreview)
