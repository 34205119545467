import { Button } from 'components/buttons'
import { FieldsList } from 'components/formParts'
import { Form, Formik } from 'formik'
import { noop, pick } from 'lodash'
import { func, shape, string } from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { formFields, initialValuesDefault, validate } from './config'
import st from './UpdateCredentialsForm.module.css'

const UpdateCredentialsForm = ({ intl, initialValues, onCancel, ...props }) => (
  <Formik
    {...props}
    initialValues={{
      ...initialValuesDefault,
      ...initialValues
    }}
    validate={validate({
      ...pick(intl, ['formatMessage'])
    })}
  >
    {() => (
      <Form>
        <FieldsList className={st.fieldsList} fields={formFields(intl)} />
        <div className={st.buttons}>
          <Button danger onClick={onCancel}>
            <FormattedMessage id="Cancel" />
          </Button>
          <Button type="submit">
            <FormattedMessage id="Update" />
          </Button>
        </div>
      </Form>
    )}
  </Formik>
)

UpdateCredentialsForm.defaultProps = {
  initialValues: initialValuesDefault,
  onCancel: noop,
  onSubmit: noop
}
UpdateCredentialsForm.propTypes = {
  initialValues: shape({
    email: string,
    confirmNewPassword: string,
    newPassword: string,
    oldPassword: string
  }),
  onCancel: func,
  onSubmit: func,
  intl: shape({})
}

export default injectIntl(UpdateCredentialsForm)
