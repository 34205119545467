import cn from 'classnames'
import { Tooltip } from 'components'
import { Button } from 'components/buttons'
import { FieldsList, Input } from 'components/formParts'
import { Field, FieldArray } from 'formik'
import { arrayOf, bool, func, number, shape, string } from 'prop-types'
import React from 'react'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { MdDelete } from 'react-icons/md'
import { FormattedMessage, injectIntl } from 'react-intl'
import { parcelNumber as parcelNumberFunc } from 'utils/products'

import { dimensionsFields } from './config'
import st from './ProductPackaging.module.css'

const fieldsListCols = { sm: 4, xs: 12 }

const ProductPackaging = ({
  parcels,
  lightInputs,
  intl,
  decimalSign,
  productId,
  noCost
}) => {
  const weightLabel = intl.formatMessage({ id: 'Weight' })

  const renamedParcels = (parcels ?? []).map((parcel) => {
    return {
      ...parcel,
      parcelName:
        parcel.number ||
        parcelNumberFunc({
          intl,
          number: parcel.parcelIndex
        })
    }
  })

  const getNextParcelNumber = () => {
    const sortedParcels = renamedParcels.sort((a, b) =>
      a.parcelName.localeCompare(b.parcelName)
    )
    if (sortedParcels.length) {
      const lastParcelName = sortedParcels[sortedParcels.length - 1].parcelName
      return parseInt(lastParcelName.split('#')[1]) + 1
    } else {
      return 1
    }
  }

  return (
    <div className={st.wrapper}>
      <h2 className="mod1">
        <FormattedMessage id="Packaging info" />
        <div className={st.tooltip}>
          <Tooltip
            id="packagingInfo"
            tooltip={intl.formatMessage({
              id: 'decimalsTooltip'
            })}
          >
            <AiFillQuestionCircle className={st.popup} />
          </Tooltip>
        </div>
      </h2>
      <FieldArray name="parcels">
        {(arrayHelpers) => (
          <>
            {renamedParcels.map((parcel, index) => (
              <>
                <div className={st.row} key={parcel.number}>
                  <div>
                    <p className={st.parcelName}>{parcel.parcelName}</p>
                    <Field
                      separateNumberValue
                      className={cn(
                        st.parcelInput,
                        lightInputs && st.parcelInputLight
                      )}
                      component={Input}
                      decimalSign={decimalSign}
                      label={weightLabel}
                      labelClassName={st.title}
                      name={`parcels.${index}.weight`}
                      placeholder={weightLabel}
                      type="number"
                    />
                    <FieldsList
                      colsWidth={fieldsListCols}
                      decimalSign={decimalSign}
                      fields={dimensionsFields({
                        intl,
                        fieldClassName: lightInputs && st.parcelInputLight,
                        parentFieldName: 'parcels',
                        index
                      })}
                      gutterWidth={14}
                      title={intl.formatMessage({ id: 'Dimensions' })}
                      titleClassName={st.title}
                    />

                    <Button
                      className={st.button}
                      type="button"
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      <MdDelete />
                    </Button>
                  </div>
                  <div className={st.amountCurrency}>
                    {!noCost && parcel.shipmentDataLoaded && (
                      <>
                        {intl.formatMessage({
                          id: 'Shipment data loaded'
                        })}
                        {parcel.amountCurrency && (
                          <>: {parcel.amountCurrency}</>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            ))}
            <Button
              lightBrown
              thin
              className={st.button}
              onClick={() =>
                arrayHelpers.push({
                  weight: 0,
                  D: 0,
                  W: 0,
                  H: 0,
                  parcelIndex: getNextParcelNumber(),
                  productId: productId
                })
              }
            >
              <FormattedMessage id="Add New Parcel" /> +
            </Button>
          </>
        )}
      </FieldArray>
    </div>
  )
}

ProductPackaging.defaultProps = {
  decimalSign: '.',
  parcels: [
    {
      parcelIndex: 1,
      weight: 0,
      D: 0,
      H: 0,
      W: 0
    }
  ],
  productId: ''
}
ProductPackaging.propTypes = {
  decimalSign: string,
  parcels: arrayOf(
    shape({
      number: string,
      parcelIndex: number,
      D: number,
      H: number,
      W: number,
      weight: number,
      amountCurrency: string,
      shipmentDataLoaded: bool,
      productId: string
    })
  ),
  lightInputs: bool,
  productId: string,
  intl: shape({
    formatMessage: func
  }),
  noCost: bool
}

export default injectIntl(ProductPackaging)
