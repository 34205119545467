import cn from 'classnames'
import { cloneDeep, get, upperCase } from 'lodash'
import { bool, func, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { FaPlus, FaTrashAlt } from 'react-icons/fa'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'
import {
  ShowEditModal,
  UpdateProductData
} from 'redux/actions/product-types-action'
import { ERRORS } from 'utils/constants'
import { showNotify } from 'utils/toast'

import Style from '../add-product-data-types/add-product-data-types.module.css'

const INPUT_INIT = {
  en: '',
  errors: { en: '', it: '' },
  it: '',
  type: ''
}
const OPTIONS = ['boolean', 'number', 'text']

class UpdateAdditionalFields extends Component {
  state = {
    id: '',
    inputs: [],
    input: cloneDeep(INPUT_INIT),
    modal: this.props.open
  }

  componentDidMount() {
    this.setState({ modal: this.props.open })
  }

  componentDidUpdate(prevProps) {
    const {
      productDataReducer: {
        isOpenEditModal: isOpenEditModalPrev,
        updatedProductData: updatedProductDataPrev
      }
    } = prevProps
    const {
      productDataReducer: {
        editId,
        editItems,
        isOpenEditModal,
        updatedProductData
        // productsData
      }
    } = this.props

    if (updatedProductData && !updatedProductDataPrev) {
      this.clearModalInputs()
    }

    if (isOpenEditModal && !isOpenEditModalPrev) {
      // const productData = productsData.find(
      //   (productData) => productData._id === editId
      // )
      this.setState({ id: editId })
      this.prepareInputs(editItems)
      // this.setState({
      //   mandatoryPackaging: get(productData, 'mandatoryPackaging', false)
      // })
    }
  }

  change = (i, name, type) => {
    let { inputs } = this.state

    if (type) {
      inputs[i].type = type
    } else {
      inputs[i].name = name
    }

    this.setState({ inputs })
  }

  initStateInput = () => {
    this.setState({ input: cloneDeep(INPUT_INIT) })
  }

  isBtnPlusDisabled = () => {
    const {
      input: { en, it }
    } = this.state

    return !en || !it
  }

  handleInputsChange = (i, lang) => (e) => {
    const { value } = e.target
    const { inputs } = this.state
    const newInputs = cloneDeep(inputs)
    const validatedInput = this.validateInput({
      input: newInputs[i],
      lang,
      value
    })

    validatedInput[lang] = value
    newInputs[i] = validatedInput

    this.setState({ inputs: newInputs })
  }

  renderInputs = (intl) => {
    const { inputs } = this.state

    return inputs.map((input, i) => {
      const { en, errors, it } = input
      const valueInputs = [
        {
          invalid: Boolean(errors.en),
          key: 'en',
          value: en || ''
        },
        {
          invalid: Boolean(errors.it),
          key: 'it',
          value: it || ''
        }
      ]

      return input ? (
        <Row
          className={cn('list-group-item', Style.rowAddField)}
          key={i}
          tag="li"
        >
          <Col xs={5}>
            {valueInputs.map((data) => {
              const placeholder = `${upperCase(data.key)}:`

              return (
                <Input
                  {...data}
                  key={data.value}
                  className={Style.vendorInfoKey}
                  placeholder={placeholder}
                  onChange={this.handleInputsChange(i, data.key)}
                />
              )
            })}
          </Col>
          <Col xs={5}>{this.selectElement(intl, input.type, i)}</Col>
          <Col xs={2}>
            <Button
              className={cn('btn btn-primary', Style.btnDeleteField)}
              onClick={this.removeInput.bind(this, input.id)}
            >
              <FaTrashAlt color="var(--main-red-color)" size={16} />
            </Button>
          </Col>
        </Row>
      ) : null
    })
  }

  removeInput = (id) => {
    const inputs = this.state.inputs
      .filter((el) => el.id !== id)
      .map((el, i) => {
        el.id = i
        return el
      })
    this.setState({ inputs })
  }

  closeModal = () => {
    this.props.ShowModal(false)
    this.initStateInput()
  }

  changeHandler = (e, objKey) => {
    const { input: currentInput } = this.state

    currentInput[objKey] = e.target.value
    this.setState({ input: currentInput })
  }

  fillInputs = () => {
    const { input, inputs } = this.state
    const newInputs = inputs.map((el, i) => ({
      ...el,
      id: i
    }))

    if (!input.type) {
      input.type = OPTIONS[0]
    }

    this.setState({
      inputs: [...newInputs, { id: newInputs.length, ...input }],
      input: cloneDeep(INPUT_INIT)
    })
  }

  prepareInputs = (editInputs) => {
    const inputs = editInputs.map((el, i) => ({
      ...el,
      errors: { en: '', it: '' },
      id: i
    }))

    this.setState({ inputs })
  }

  clearModalInputs = () =>
    this.setState({
      id: '',
      inputs: []
    })

  haveInputsErrors = () => {
    const { inputs } = this.state
    const langs = ['en', 'it']
    let hasErrors = false

    const newInputs = inputs.map((input) => {
      let newInput = input

      langs.forEach((lang) => {
        newInput = this.validateInput({
          input: newInput,
          lang,
          value: input[lang]
        })

        if (!input[lang]) {
          hasErrors = true
        }
      })

      return newInput
    })

    this.setState({ inputs: newInputs })

    return hasErrors
  }

  prepareDataForAddRequest = () => {
    const { id, inputs } = this.state
    const filteredData = inputs
      .filter(({ en, it, name }) => (en && it) || name)
      .map(({ en, it, type }) => ({
        en: en || '',
        it: it || '',
        type: type || 'text'
      }))
    const hasErrors = this.haveInputsErrors()

    if (hasErrors) {
      showNotify({
        isError: true,
        message: 'Please fill all required fields.'
      })
    } else {
      this.props.Update(id, filteredData)
      this.initStateInput()
    }
  }

  productDataName = () => {
    const {
      lang,
      productDataReducer: { editId, productsData }
    } = this.props
    const productData = (productsData || []).find((pd) => pd._id === editId)
    const productDataName = get(productData, 'name') || {}

    return productDataName[lang] || productDataName.en || ''
  }

  selectElement = (intl, selected, i) => {
    const { input } = this.state
    const value = selected ? selected : input.type
    const onChange = (e) => {
      if (selected) {
        this.change(i, '', e.target.value)
      } else {
        this.changeHandler(e, 'type')
      }
    }

    return (
      <select
        required
        name="name"
        className={cn('form-control', Style.inputElement)}
        placeholder={intl.formatMessage({ id: 'Product Name' })}
        value={value || OPTIONS[0]}
        onChange={onChange}
      >
        {OPTIONS.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    )
  }

  validateInput = ({ input = {}, lang = '', value = '' } = {}) => {
    const newInput = cloneDeep(input)

    newInput.errors = newInput.errors || {}

    if (lang) {
      newInput.errors[lang] = value ? '' : ERRORS.REQUIRED
    }

    return newInput
  }

  render() {
    const {
      className,
      intl,
      productDataReducer: { isOpenEditModal }
    } = this.props
    const {
      id,
      input: { en, it }
    } = this.state
    const hdFields = [
      {
        language: 'EN',
        value: en,
        onChange: (e) => {
          this.changeHandler(e, 'en')
        }
      },
      {
        language: 'IT',
        value: it,
        onChange: (e) => {
          this.changeHandler(e, 'it')
        }
      }
    ]

    return (
      <Modal
        className={className}
        isOpen={isOpenEditModal}
        toggle={this.closeModal}
      >
        <ModalHeader toggle={this.closeModal}>
          <FormattedMessage id={'update product additional fields'} />
        </ModalHeader>
        <ModalBody>
          <Row className={Style.myRow}>
            <Col xs={12}>
              <div className={Style.idInputDiv}>
                <label htmlFor={'id'} className={Style.label}>
                  <FormattedMessage id={'Product data name'} />
                </label>
                <input
                  disabled
                  className={cn('form-control', Style.idInput)}
                  id="id"
                  name="name"
                  placeholder={intl.formatMessage({
                    id: 'Product data name'
                  })}
                  type="text"
                  value={this.productDataName()}
                />
              </div>
            </Col>
            <Col xs={12}>
              <Row className={Style.rowAdd}>
                <Col xs={5}>
                  {hdFields.map(({ language, ...data }) => (
                    <input
                      {...data}
                      required
                      className={cn('form-control', Style.inputElement)}
                      key={language}
                      placeholder={
                        intl.formatMessage({
                          id: 'Field Name'
                        }) + ` ${language}`
                      }
                      type="text"
                    />
                  ))}
                </Col>
                <Col xs={5}>{this.selectElement(intl)}</Col>
                <Col xs={2}>
                  <Button
                    className="btn btn-primary"
                    disabled={this.isBtnPlusDisabled()}
                    onClick={this.fillInputs}
                  >
                    <FaPlus color="var(--main-green-color)" size={16} />
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={12} tag="ul">
              {this.renderInputs(intl)}
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <FormInput
                name="mandatoryPackaging"
                checked={mandatoryPackaging}
                type="checkbox"
                onChange={this.handleMandatoryPackagingChange}
                label={intl.formatMessage({
                  id: 'Mandatory Packaging'
                })}
                horizontal
                reversed
                className={Style.mandatoryPackagingField}
              />
            </Col>
          </Row> */}
          <ModalFooter>
            <Button
              className="btn btn-primary"
              onClick={this.prepareDataForAddRequest}
              disabled={!id}
            >
              <FormattedMessage id="Update" />
            </Button>
            <Button
              color={'danger'}
              onClick={() => {
                this.clearModalInputs()
                this.closeModal()
              }}
            >
              <FormattedMessage id={'clear'} />
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    )
  }
}
UpdateAdditionalFields.propTypes = {
  open: bool,
  productDataReducer: shape({
    isOpenEditModal: bool
  }),
  ShowModal: func,
  Update: func,
  lang: string,
  className: string,
  intl: shape({
    formatMessage: func
  })
}
const mapStateToProps = (state) => ({
  lang: state.languageReducer.lang,
  productDataReducer: state.productDataReducer
})

const mapDispatchToProps = (dispatch) => ({
  Update: (id, element) => dispatch(UpdateProductData(id, element)),
  ShowModal: (status) => dispatch(ShowEditModal(status))
})

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(UpdateAdditionalFields)
)
