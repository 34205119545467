// import cn from 'classnames' - TODO: Uncomment later (AR-1890)
import {
  // toLower, - TODO: Uncomment later (AR-1890)
  uniqueId
} from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

// import { STATUSES } from 'utils/constants' - TODO: Uncomment later (AR-1890)
import st from './OrdersTable.module.css'

export const headings = [
  { text: 'Order No.' },
  { text: 'Product' },
  { text: 'Date' },
  { text: 'Ship by' }
  /* TODO: Uncomment later (AR-1890)
  { text: 'Status' },
  { text: 'Value' }
  */
]

export const mapRows = (rows = []) =>
  rows.map((row) => {
    const {
      date,
      orderNumber,
      orderPath = '',
      products,
      shipBy
      /* TODO: Uncomment later (AR-1890)
    status,
    value
    */
    } = row
    /* TODO: Uncomment later (AR-1890)
  const { SHIPPED_SM, WORKING_SM } = STATUSES
  let statusClassName = ''

  switch (toLower(status)) {
    case SHIPPED_SM:
      statusClassName = st.pending
      break

    case WORKING_SM:
      statusClassName = st.working
      break

    default:
      statusClassName = ''
  }
  */
    return {
      cells: [
        {
          className: st.orderNumber,
          content: <Link to={orderPath}>{orderNumber}</Link>
        },
        { content: products },
        { content: date },
        { content: shipBy }
        /* TODO: Uncomment later (AR-1890)
      {
        className: cn(st.status, statusClassName),
        content: status
      },
      {
        content: (
          <span className={st.price}>
            {value}
          </span>
        )
      }
      */
      ],
      _id: uniqueId(orderNumber)
    }
  })
