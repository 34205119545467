import { noop, range } from 'lodash'
import { arrayOf, func, shape, string } from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Col, CustomInput, Row } from 'reactstrap'

import Style from './merch.module.css'

const MerchElements = ({
  elements,
  intl: { formatMessage },
  selectedProductIds,
  changeSelected,
  changeShippingIn
}) => {
  return (
    <Row>
      {elements.map(({ _id, mainPhoto, shippingIn }) => (
        <Col key={_id} md={6}>
          <Row className={Style.mainRow}>
            <Col md={6}>
              <div className={Style.imageWrap}>
                <CustomInput
                  checked={Boolean(selectedProductIds[_id])}
                  className={Style.checkbox}
                  type="checkbox"
                  id={_id}
                  onChange={changeSelected.bind(this, _id)}
                />
                <img className={Style.image} src={mainPhoto} alt="product" />
              </div>
            </Col>
            <Col className={Style.data} md={6}>
              <Row>
                <h4>
                  <Link to={`/product-detail/${_id}`}>{_id}</Link>
                </h4>
              </Row>
              <Row>
                <select
                  className="custom-select"
                  placeholder={formatMessage({
                    id: 'Set New Lead Time'
                  })}
                  onChange={(e) => changeShippingIn(_id, e.target.value)}
                  value={shippingIn}
                >
                  {!shippingIn && <option></option>}
                  {range(1, 19).map((num) => (
                    <option key={num} value={num}>
                      {`${formatMessage({
                        id: 'Ships in'
                      })} ${formatMessage(
                        {
                          id: 'Weeks'
                        },
                        { amount: num }
                      )}`}
                    </option>
                  ))}
                </select>
              </Row>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  )
}

MerchElements.defaultProps = {
  elements: [],
  selectedProductIds: {},
  changeAcceptDiscount: noop,
  changeSelected: noop,
  changeShippingIn: noop
}
MerchElements.propTypes = {
  elements: arrayOf(
    shape({
      _id: string.isRequired
    })
  ),
  selectedProductIds: shape({}),
  changeAcceptDiscount: func,
  changeSelected: func,
  changeShippingIn: func,
  intl: shape({
    formatMessage: func
  })
}

export default injectIntl(MerchElements)
