import { get, max, range } from 'lodash'
import { arrayOf, shape } from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import { Card, CardBody, Table } from 'reactstrap'

import st from './translator-table-card.module.css'

const td = ({ cell, key }) => (
  <td key={key}>
    {cell && (
      <>
        <img
          alt=""
          className={st.img}
          src={cell.mainPhoto || cell.onboardingPhoto}
        />
        <Link to={`/translate-product/${cell._id}`}>{cell._id}</Link>
      </>
    )}
  </td>
)

const CardElement = ({ approved, done, draft, todo, waiting }) => {
  const approvedCol = [...approved, ...done]
  const maxLen = max([
    approvedCol.length,
    draft.length,
    todo.length,
    waiting.length
  ])
  const renderTable = () =>
    range(maxLen).map((el, rowNum) => (
      <tr key={`${rowNum}_${el}`}>
        {[todo, draft, waiting, approvedCol].map((data, key) =>
          td({
            cell: data[rowNum],
            key: get(data[rowNum], '_id') || `${key}`
          })
        )}
      </tr>
    ))
  const tableHds = ['To Do', 'In Draft', 'Waiting For', 'Approved']

  return (
    <Card className={st.card}>
      <CardBody>
        <Table striped>
          <thead>
            <tr>
              {tableHds.map((tableHd) => (
                <th key={tableHd}>
                  <FormattedMessage id={tableHd} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTable()}</tbody>
        </Table>
      </CardBody>
    </Card>
  )
}

CardElement.defaultProps = {
  approved: [],
  done: [],
  draft: [],
  todo: [],
  waiting: []
}
CardElement.propTypes = {
  approved: arrayOf(shape({})),
  done: arrayOf(shape({})),
  draft: arrayOf(shape({})),
  todo: arrayOf(shape({})),
  waiting: arrayOf(shape({}))
}

export default withRouter(CardElement)
