import { Input } from 'components/formParts'
import { InnerPage } from 'components/layout'
import { OtherImagesList } from 'components/lists'
import { get } from 'lodash'
import { func, shape, string } from 'prop-types'
import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import CircleLoader from 'react-spinners/ClipLoader'
import { Col, FormGroup, Row } from 'reactstrap'
import { getCollectionProductTranslates } from 'redux/service/collection-service'
import { getProductById } from 'redux/service/product-service'
import { canApproveTranslations, canUpdateTranslations } from 'utils/auth'
import { STATUSES } from 'utils/constants'
import { formatDimensions, getPhotoSrc, typeNameByLang } from 'utils/products'

import { SaveProductInDraft } from '../redux/actions/product-action'
import {
  HandleTranslationAccept,
  SetTranslationReady,
  UpdateTranslation
} from '../redux/actions/translation-action'
import Style from './styles/translator.module.css'

class TranslatorProduct extends Component {
  state = {
    product: null,
    translations: [],
    translatedText: '',
    draftText: '',
    draftName: '',
    yourNameTranslated: '',
    color: '',
    material: '',
    language: 'en',
    loading: false
  }

  getCollectionId = () => {
    const { product } = this.state

    return get(product, 'vendorCollection._id', '')
  }

  updateState(product = {}) {
    const { translation = {} } = product || {}
    const {
      color = '',
      description = '',
      material = '',
      name = ''
    } = translation || {}

    this.setState({
      draftName: name,
      draftText: description,
      translatedText: description,
      color,
      material,
      yourNameTranslated: name
    })
  }

  changeLanguage = (language) => this.setState({ language })

  updateTranslations = () => {
    const collectionId = this.getCollectionId()

    if (collectionId) {
      getCollectionProductTranslates({
        collection: collectionId,
        readyToTranslate: true
      }).then(({ docs }) => {
        const products = get(docs, '[0].products', [])

        this.setState({ translations: products })
      })
    }
  }

  componentDidMount() {
    this.getTranslationById()
  }

  getProductId = () => get(this.props, 'match.params.product', '')

  getTranslationById = () => {
    const productId = this.getProductId()

    if (productId) {
      getProductById({ productId }).then(({ data: product = {} }) => {
        const {
          mainColor = '',
          material = '',
          translation = {}
        } = product || {}
        const {
          description: translationDescription = '',
          en = {},
          name: translationName = ''
        } = translation || {}

        this.updateState(product)
        this.setState(
          {
            product,
            material,
            color: mainColor,
            name: en ? en.name : translationName,
            description: en ? en.description : translationDescription
          },
          () => {
            const collectionId = this.getCollectionId()

            if (collectionId) {
              getCollectionProductTranslates({
                collection: collectionId,
                readyToTranslate: true
              }).then(({ docs }) => {
                const products = get(docs, '[0].products', [])

                this.setState({ translations: products })
              })
            }
          }
        )
      })
    }
  }

  getTranslationId = () => {
    const { product } = this.state

    return get(product, 'translation._id', '')
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      location: { pathname: pathnamePrev }
    } = prevProps
    const {
      authReducer,
      history: { push },
      location: { pathname }
    } = this.props
    const { product } = this.state
    const collectionId = get(product, 'vendorCollection._id', '')
    const productStatus = get(product, 'status', '')
    const productStatusPrev = get(prevState, 'product.status')
    const userType = get(authReducer, 'userType', '')

    if (pathnamePrev !== pathname) {
      this.getTranslationById()
    }

    if (
      productStatusPrev !== productStatus &&
      productStatus === STATUSES.REJECTED
    ) {
      push(`/translator-dashboard/${userType}/${collectionId}`)
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleTranslationUpdate = () => {
    const { draftName, draftText } = this.state
    const translationId = this.getTranslationId()

    return this.props.updateTranslation(translationId, {
      name: draftName,
      description: draftText
    })
  }

  changeColor = (e) => this.setState({ color: e.target.value })

  changeMaterial = (e) => this.setState({ material: e.target.value })

  getTranslationStatus = () => {
    const { translations } = this.state
    const productId = this.getProductId()
    const selected = translations.find((t) => t._id === productId)

    return get(selected, 'translation.status', '')
  }

  renderVendorBio = (product) => (
    <Fragment>
      <div className={Style.productIdProdTranslations}>{product._id}</div>
      <div className={Style.vendorBioForProdTranslations}>vendor Bio</div>
      <div>{'test'}</div>
    </Fragment>
  )

  saveToDraft = () => {
    this.handleTranslationUpdate().then(() => this.updateTranslations())
  }

  saveAsReady = () => {
    this.props
      .setTranslationReady(this.state.product.translation._id, {
        name: this.state.draftName,
        description: this.state.draftText
      })
      .then(() => this.updateTranslations())
  }

  handleAccept = (id, isAccepted) => {
    const { handleTranslationAccept } = this.props

    this.handleTranslationUpdate()
      .then(() => handleTranslationAccept(id, isAccepted))
      .then(() => this.updateTranslations())
  }

  render() {
    const { authReducer, intl } = this.props
    const {
      language,
      loading,
      product = {}
      /* TODO: Uncomment later (AR-1996)
      translations
      */
    } = this.state
    const mainPhotoSrc = getPhotoSrc(product)
    const otherPhotos = get(product, 'photos', []).map((src) => ({
      alt: src,
      src
    }))
    /* TODO: Uncomment later (AR-1996)
    const otherProducts = translations
      .filter(({ readyToTranslate }) => readyToTranslate)
    */
    const productsAD = get(product, 'additionalData', [])
    const productCareInstructions = get(product, 'careInstructions', '')
    const productCategory =
      typeNameByLang({
        lang: language,
        product
      }) || intl.formatMessage({ id: 'Not defined' })
    const productDesigner =
      get(product, 'designer', '') || intl.formatMessage({ id: 'Not defined' })
    const productDimensions = formatDimensions({ product })
    const productOtherInfo = get(product, 'otherInfo', '')
    /* TODO: Uncomment later (AR-1996)
    const translationsData = groupByTranslationStatus(
      otherProducts
    )
    */
    const translationStatus = this.getTranslationStatus()
    const disableButtons = {
      accept: translationStatus === STATUSES.APPROVED_SM,
      proofReading: translationStatus === STATUSES.WAITING_SM,
      reject: translationStatus === STATUSES.TODO_SM,
      saveDraft: translationStatus === STATUSES.DRAFT_SM || !language
    }

    return product ? (
      <InnerPage>
        <div className={Style.openTranslations}>
          Collection: {get(product, 'vendorCollection.name', '')}, Product:{' '}
          {product.name || product._id}{' '}
        </div>
        <Row className="rowWide">
          <Col md={6}>
            {loading && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <CircleLoader size={150} />
              </div>
            )}
            <img
              className={Style.productImage}
              src={mainPhotoSrc}
              alt="product"
            />
            <OtherImagesList
              className={Style.otherImages}
              images={otherPhotos}
            />
          </Col>
          <Col md={6}>
            <div className={Style.paddingBottom}>
              <div className={Style.productIdProdTranslations}>
                {intl.formatMessage({
                  id: 'Product name'
                })}
                :{' '}
                {product.name ||
                  intl.formatMessage({
                    id: 'Name is not provided'
                  })}
              </div>
              <FormGroup>
                <Input
                  name="draftName"
                  placeholder={intl.formatMessage({
                    id: 'Translate product name'
                  })}
                  onChange={this.handleChange}
                  value={this.state.draftName}
                  valueLengthLimit={65}
                />
              </FormGroup>
              <div className={Style.productIdProdTranslations}>
                {intl.formatMessage({
                  id: 'Product description'
                })}
                :{' '}
                {product.description ||
                  intl.formatMessage({
                    id: 'Description is not provided'
                  })}
              </div>
              <FormGroup>
                <Input
                  type="textarea"
                  name="draftText"
                  placeholder={intl.formatMessage({
                    id: 'Translate product description'
                  })}
                  onChange={this.handleChange}
                  className={Style.textArea}
                  value={this.state.draftText}
                  valueLengthLimit={500}
                />
              </FormGroup>
              <div className={Style.materialAndColor}>
                <h4 className="font-weight-bold">
                  <FormattedMessage id="Material" /> :{' '}
                  {product.material ||
                    intl.formatMessage({
                      id: 'Material is not set'
                    })}
                </h4>
              </div>
              <div className={Style.materialAndColor}>
                <FormGroup>
                  <h4 className="font-weight-bold">
                    {' '}
                    <FormattedMessage id="Color" />:{' '}
                    {product.mainColor ||
                      intl.formatMessage({
                        id: 'Color is not set'
                      })}
                  </h4>
                </FormGroup>
              </div>
              <p>
                <FormattedMessage id="Category" />
                {': '}
                {productCategory}
              </p>
              <p>
                <FormattedMessage id="Designer" />
                {': '}
                {productDesigner}
              </p>
              {productDimensions && (
                <p>
                  <FormattedMessage id="Dimensions" />
                  {': '}
                  {productDimensions}
                </p>
              )}
              {productCareInstructions && (
                <p>
                  <FormattedMessage id="Care Instructions" />
                  {': '}
                  <Link target="_blank" to={productCareInstructions}>
                    {productCareInstructions}
                  </Link>
                </p>
              )}
              {productOtherInfo && (
                <p>
                  <FormattedMessage id="Other Info" />
                  {': '}
                  <Link target="_blank" to={productOtherInfo}>
                    {productOtherInfo}
                  </Link>
                </p>
              )}
              {productsAD.length > 0 && (
                <>
                  <p>
                    <FormattedMessage id="Custom properties" />
                    {':'}
                  </p>
                  <ul className={Style.customProps}>
                    {productsAD.map(({ _id, key, value }) => (
                      <li key={_id}>
                        {key}: {value === '' ? '""' : `${value}`}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <div className={Style.buttons}>
              {canUpdateTranslations(authReducer) && (
                <>
                  <button
                    className="btn btn-secondary"
                    disabled={disableButtons.saveDraft}
                    onClick={this.saveToDraft}
                  >
                    <FormattedMessage
                      id={`Save${disableButtons.saveDraft ? 'd' : ''} Draft`}
                    />
                  </button>
                  <button
                    className="btn btn-secondary"
                    disabled={disableButtons.proofReading}
                    onClick={this.saveAsReady}
                  >
                    <FormattedMessage
                      id={`Sen${
                        disableButtons.proofReading ? 't' : 'd'
                      } to proof Reading`}
                    />
                  </button>
                </>
              )}
              {canApproveTranslations(authReducer) && (
                <>
                  <button
                    className="btn btn-secondary"
                    disabled={disableButtons.accept}
                    onClick={() =>
                      this.handleAccept(product.translation._id, 'approve')
                    }
                  >
                    <FormattedMessage
                      id={`Accept${disableButtons.accept ? 'ed' : ''}`}
                    />
                  </button>
                  <button
                    className="btn btn-secondary"
                    disabled={disableButtons.reject}
                    onClick={() =>
                      this.handleAccept(product.translation._id, 'decline')
                    }
                  >
                    <FormattedMessage
                      id={`Reject${disableButtons.reject ? 'ed' : ''}`}
                    />
                  </button>
                </>
              )}
            </div>
          </Col>
        </Row>
      </InnerPage>
    ) : (
      <div className={Style.loadingWrapper}>
        <CircleLoader size={150} />
      </div>
    )
  }
}

TranslatorProduct.propTypes = {
  intl: shape({
    formatMessage: func
  }),
  location: shape({
    pathname: string
  }),
  authReducer: shape({}),
  updateTranslation: func,
  setTranslationReady: func,
  handleTranslationAccept: func,
  history: shape({
    push: func
  })
}
const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  productReducer: state.productReducer,
  isUpdated: state.translationReducer.updated
})

const mapDispatchToProps = (dispatch) => ({
  updateTranslation: (id, data) => dispatch(UpdateTranslation(id, data)),
  setTranslationReady: (id, data) => dispatch(SetTranslationReady(id, data)),
  saveInDraft: (id, data) => dispatch(SaveProductInDraft(id, data)),
  handleTranslationAccept: (id, translationState) =>
    dispatch(HandleTranslationAccept(id, translationState))
})

export default injectIntl(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(TranslatorProduct))
)
