import { func, object } from 'prop-types'
import React, { useEffect, useState } from 'react'

import inputStyle from '../../../../components/formParts/Input/Input.module.css'
import { artemestPickupLocations } from './config'
import st from './PickupForm.module.css'

const PickupLocationsSelect = ({
  pickupFormDataFromContact,
  values,
  setValues
}) => {
  const [pickupLocationSelected, setPickupLocationSelected] = useState('')
  const pickupLocations = [
    pickupFormDataFromContact,
    ...artemestPickupLocations
  ]
  const onPickupLocationChange = (e, values, setValues) => {
    setValues({
      ...values,
      ...pickupLocations.find(
        (pickupLocation) =>
          pickupLocation.locationName.trim() === e.target.value.trim()
      )
    })
  }

  useEffect(() => {
    const selected = pickupLocations.filter((pickupLocation) => {
      return [
        'locationName',
        'companyName',
        'address',
        'address2',
        'city',
        'state',
        'zip',
        'country',
        'phone'
      ].every((key) => {
        return values[key] === pickupLocation[key]
      })
    })
    setPickupLocationSelected(selected.length ? selected[0].locationName : '')
  }, [values])
  return (
    <div className={inputStyle.input}>
      <select
        className={st.pickupLocations}
        onChange={(e) => {
          onPickupLocationChange(e, values, setValues)
        }}
        value={pickupLocationSelected}
      >
        <option></option>
        {pickupLocations.map((pickupLocation) => (
          <option
            key={pickupLocation.locationName}
            value={pickupLocation.locationName}
          >
            {pickupLocation.locationName}
          </option>
        ))}
      </select>
    </div>
  )
}

PickupLocationsSelect.propTypes = {
  values: object,
  setValues: func,
  pickupFormDataFromContact: object
}

export default PickupLocationsSelect
