import { PageNav } from 'components'
import { Button } from 'components/buttons'
import { FieldsList, Input } from 'components/formParts'
import { InnerPage } from 'components/layout'
import { Field, FieldArray, Form, Formik } from 'formik'
import { get, isEqual, keys, pick, values } from 'lodash'
import msg from 'messages'
import { func, shape, string } from 'prop-types'
import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { GetCollection } from 'redux/actions/collection-action'
import { HideLoading, ShowLoading } from 'redux/actions/loading-action'
import { edit, readContact } from 'redux/service/contact-service'
import { getVendorById } from 'redux/service/vendor-service'
import notify, { notifyPositions } from 'utils/toast'
import {
  areAllObjPropsEmpty,
  keepFullObjProps,
  vendorNavItems
} from 'utils/utils'

import Style from './styles/vendor-contact.module.css'

const mapActions = { GetCollection, HideLoading, ShowLoading }
const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  vendorReducer: state.vendorReducer,
  collections: get(state, 'collectionReducer.collections')
})

class VendorContact extends Component {
  state = {
    vendor: null,
    adminContact: null,
    companyData: null,
    companyId: null,
    contactId: null,
    contactForOrderManagement: null,
    contactForProductInquiries: null,
    name: '',
    pickupAddressAndTime: null
  }

  componentDidMount() {
    this.initData()
  }

  getId = () => get(this.props, 'match.params.vendor', '')
  getCollectionId = () => get(this.props, 'collections[0]._id')

  getUserType = () => get(this.props, 'authReducer.userType', '')

  initData = async () => {
    const { GetCollection, HideLoading, ShowLoading } = this.props
    const vendorId = this.getId()

    try {
      ShowLoading()
      GetCollection(vendorId, 1, 1)

      const { data: vendorData } = await getVendorById(vendorId)
      const contactId = get(vendorData, 'contact') || null
      const newState = {
        vendor: vendorData,
        companyId: get(vendorData, 'company'),
        name: get(vendorData, 'artisanName', '') || get(vendorData, 'name', '')
      }
      if (contactId) {
        const { data, status } = await readContact(contactId)
        const contact = status === 200 ? data : null

        newState.adminContact = get(contact, 'adminContact') || null
        newState.companyData = get(contact, 'company.companyData')
        newState.companyId = get(contact, 'company._id')
        newState.contactForOrderManagement =
          get(contact, 'contactForOrderManagement') || null
        newState.contactForProductInquiries =
          get(contact, 'contactForProductInquiries') || null
        newState.contactId = contactId
        newState.pickupAddressAndTime =
          get(contact, 'pickupAddressAndTime') || null
      }

      const areStatesDifferent = !isEqual(
        pick(this.state, [
          'adminContact',
          'companyData',
          'contactForOrderManagement',
          'contactForProductInquiries',
          'contactId',
          'name',
          'pickupAddressAndTime'
        ]),
        newState
      )

      if (areStatesDifferent) {
        this.setState(newState)
      }
    } catch (err) {
      notify(
        'error',
        'Error fetching vendor contact',
        notifyPositions.bottom.center
      )
    } finally {
      HideLoading()
    }
  }

  formFields = () => {
    const fields = {
      adminContactFields: [
        { id: 'fullname' },
        { id: 'phone', type: 'tel' },
        { id: 'email', type: 'email' }
      ],
      companyDataFields: [
        { id: 'companyName', required: true, label: 'legalEntity' },
        { id: 'address', required: true },
        { id: 'addressLine2', name: 'address2' },
        { id: 'city', required: true },
        { id: 'zip', required: true },
        { id: 'VATNumber', required: true },
        { id: 'fiscalCodeMatchesVat', type: 'checkbox' },
        {
          id: 'fiscalCode',
          required: true,
          hiddenBy: 'fiscalCodeMatchesVat',
          onHiddenCopyValueFrom: 'VATNumber'
        },
        { id: 'iban', required: true }
      ],
      contactOrderManagementFields: [
        { id: 'fullname', name: 'comFullname' },
        { id: 'phone', name: 'comPhone', type: 'tel' },
        { id: 'email', name: 'comEmail', type: 'email' },
        { id: 'secondaryEmails', name: 'comSecondaryEmails' }
      ],
      contactProductInquiriesFields: [
        { id: 'fullname', name: 'cpiFullname' },
        { id: 'phone', name: 'cpiPhone', type: 'tel' },
        { id: 'email', name: 'cpiEmail', type: 'email' }
      ],
      pickUpFields: [
        { id: 'locationName', required: true },
        { id: 'address', name: 'pickUpAddress', required: true },
        { id: 'addressLine2', name: 'pickUpAddress2' },
        { id: 'citta', required: true },
        { id: 'state', required: true },
        { id: 'zip', name: 'pickUpZip', required: true },
        {
          id: 'morningTime',
          style: { display: 'flex', flexWrap: 'wrap' },
          rangeInput: true,
          required: true,
          fields: [
            {
              id: 'morningMinTime',
              required: true,
              type: 'time',
              name: 'morningMinTime'
            },
            {
              id: 'morningMaxTime',
              required: true,
              type: 'time',
              name: 'morningMaxTime'
            }
          ]
        },
        {
          id: 'afternoonTime',
          style: { display: 'flex', flexWrap: 'wrap' },
          rangeInput: true,
          required: true,
          fields: [
            {
              id: 'afternoonMinTime',
              name: 'afternoonMinTime',
              required: true,
              type: 'time'
            },
            {
              id: 'afternoonMaxTime',
              name: 'afternoonMaxTime',
              required: true,
              type: 'time'
            }
          ]
        }
      ]
    }
    const fieldKeys = keys(fields)

    return fieldKeys.reduce((acc, fieldKey) => {
      acc[fieldKey] = this.formFieldsMap(fields[fieldKey])

      return acc
    }, {})
  }

  formFieldsMap = (formFields = []) => {
    const { intl } = this.props

    return formFields.map(({ id, name, label, required, ...rest }) => {
      const fieldTranslated = intl.formatMessage({ id: label ?? id })

      return {
        label: `${fieldTranslated} ${required ? '*' : ''}`,
        name: name || id,
        placeholder: fieldTranslated,
        ...rest
      }
    })
  }

  getACFromState = (field = '') => get(this.state, `adminContact.${field}`, '')

  getCDFromState = (field = '') => get(this.state, `companyData.${field}`, '')

  getCOMFromState = (field = '') =>
    get(this.state, `contactForOrderManagement.${field}`, '')

  getCPIFromState = (field = '') =>
    get(this.state, `contactForProductInquiries.${field}`, '')

  getPATFromState = (field = '') =>
    get(this.state, `pickupAddressAndTime.${field}`, '')

  initialValues = () => {
    const res = {}
    const formFieldValues = values(this.formFields())

    const arrFields = formFieldValues.reduce(
      (acc, ffValue) => [...acc, ...ffValue],
      []
    )

    arrFields.forEach((field) => {
      const { name } = field

      switch (name) {
        case 'address2':
          res[name] = this.getCDFromState('adressLine2')
          break

        case 'comEmail':
          res[name] = this.getCOMFromState('email')
          break
        case 'comSecondaryEmails':
          res[name] = this.getCOMFromState('secondaryEmails')
          break
        case 'comFullname':
          res[name] = this.getCOMFromState('fullname')
          break
        case 'comPhone':
          res[name] = this.getCOMFromState('phone')
          break

        case 'cpiEmail':
          res[name] = this.getCPIFromState('email')
          break
        case 'cpiFullname':
          res[name] = this.getCPIFromState('fullname')
          break
        case 'cpiPhone':
          res[name] = this.getCPIFromState('phone')
          break

        case 'pickUpAddress':
          res[name] = this.getPATFromState('address')
          break
        case 'pickUpAddress2':
          res[name] = this.getPATFromState('address2')
          break
        case 'pickUpZip':
          res[name] = this.getPATFromState('zip')
          break
        case 'state':
          res[name] = this.getPATFromState('state')
          break

        case 'email':
        case 'fullname':
        case 'phone':
          res[name] = this.getACFromState(name)
          break

        case 'address':
        case 'city':
        case 'companyName':
        case 'iban':
        case 'VATNumber':
        case 'zip':
        case 'fiscalCode':
          res[name] = this.getCDFromState(name)
          break

        case 'citta':
        case 'locationName':
          res[name] = this.getPATFromState(name)
          break

        case 'fiscalCodeMatchesVat':
          res[name] =
            this.getCDFromState('VATNumber') ===
            this.getCDFromState('fiscalCode')
          break

        default:
          res[name] = ''
      }

      if (name === 'morningTime' || name === 'afternoonTime') {
        field.fields.forEach((internalField) => {
          res[internalField.name] = this.getPATFromState(internalField.name)
        })
      }
    })
    return res
  }

  submitForm = async (values) => {
    const { HideLoading, ShowLoading } = this.props
    const { address2, pickUpAddress2 } = values
    const { companyId, contactId } = this.state
    const adminContact = keepFullObjProps(
      pick(values, ['email', 'fullname', 'phone'])
    )
    const companyData = {
      ...pick(values, [
        'address',
        'city',
        'companyName',
        'iban',
        'VATNumber',
        'fiscalCode',
        'zip'
      ])
    }
    const contactForOrderManagement = keepFullObjProps({
      email: values.comEmail,
      secondaryEmails: values.comSecondaryEmails,
      fullname: values.comFullname,
      phone: values.comPhone
    })
    const contactForProductInquiries = keepFullObjProps({
      email: values.cpiEmail,
      fullname: values.cpiFullname,
      phone: values.cpiPhone
    })
    const pickupAddressAndTime = {
      ...pick(values, [
        'citta',
        'locationName',
        'morningMinTime',
        'morningMaxTime',
        'afternoonMinTime',
        'afternoonMaxTime'
      ]),
      address: values.pickUpAddress,
      zip: values.pickUpZip,
      state: values.state
    }
    const initialValues = this.initialValues()
    const alertOnEditFields = ['companyName', 'VATNumber', 'fiscalCode', 'iban']

    const editedFields = alertOnEditFields.reduce((edited, key) => {
      const oldValue = initialValues[key]
      const newValue = values[key]

      if (newValue !== oldValue) {
        edited.push({
          fieldName: msg.it[key !== 'companyName' ? key : 'legalEntity'], // always italian for email purposes
          oldValue,
          newValue
        })
      }
      return edited
    }, [])

    if (address2) {
      companyData.adressLine2 = address2
    }

    if (pickUpAddress2) {
      pickupAddressAndTime.address2 = pickUpAddress2
    }

    try {
      ShowLoading()

      if (contactId) {
        const contactBody = {
          company: companyId,
          companyData,
          pickupAddressAndTime,
          vendor: this.getId()
        }
        const hasAdminContactValues = !areAllObjPropsEmpty(adminContact)
        const hasCfoManagementValues = !areAllObjPropsEmpty(
          contactForOrderManagement
        )
        const hasCfpInquiriesValues = !areAllObjPropsEmpty(
          contactForProductInquiries
        )

        if (hasAdminContactValues) {
          contactBody.adminContact = adminContact
        }
        if (hasCfoManagementValues) {
          contactBody.contactForOrderManagement = contactForOrderManagement
        }
        if (hasCfpInquiriesValues) {
          contactBody.contactForProductInquiries = contactForProductInquiries
        }

        if (editedFields.length) {
          contactBody.editedFields = editedFields
        }

        const { status } = await edit(contactId, contactBody)

        if (status === 200) {
          notify(
            'success',
            'Vendor contact updated successfully',
            notifyPositions.bottom.center
          )
          this.initData()
        }
      }
    } catch (err) {
      notify(
        'error',
        'Vendor contact update failed',
        notifyPositions.bottom.center
      )
    } finally {
      HideLoading()
    }
  }

  validate = (values) => {
    const { intl } = this.props
    const errors = {}
    const requiredFieldNames = [
      'address',
      'citta',
      'city',
      'companyName',
      'iban',
      'locationName',
      'morningMinTime',
      'morningMaxTime',
      'afternoonMinTime',
      'afternoonMaxTime',
      'pickUpAddress',
      'pickUpZip',
      'VATNumber',
      'fiscalCode',
      'zip',
      'state'
    ]

    const pickupTimes = [
      'morningMinTime',
      'morningMaxTime',
      'afternoonMinTime',
      'afternoonMaxTime'
    ]

    requiredFieldNames.forEach((rfn) => {
      if (!values[rfn]) {
        errors[rfn] = intl.formatMessage({
          id: 'required'
        })
      }
    })

    pickupTimes.forEach((pickupTime, index) => {
      if (index > 0) {
        const value = values[pickupTime]
        const prevValue = values[pickupTimes[index - 1]]

        if (!value) {
          errors[pickupTime] = intl.formatMessage({ id: 'required' })
        } else if (value < prevValue) {
          errors[pickupTime] = intl.formatMessage({ id: `${pickupTime}Error` })
        }
      }
    })

    if (values['pickUpAddress'].length > 35) {
      errors['pickUpAddress'] = intl.formatMessage(
        {
          id: 'maxLengthError'
        },
        { maxValue: '35' }
      )
    }

    if (values['pickUpAddress2'].length > 35) {
      errors['pickUpAddress2'] = intl.formatMessage(
        {
          id: 'maxLengthError'
        },
        { maxValue: '35' }
      )
    }

    return errors
  }

  render() {
    const {
      adminContactFields,
      companyDataFields,
      contactOrderManagementFields,
      contactProductInquiriesFields,
      pickUpFields
    } = this.formFields()
    const {
      intl,
      location: { pathname }
    } = this.props
    const name = this.state.vendor?.artisanName ?? 'Contact Info'
    const navItems = vendorNavItems({
      collectionId: this.getCollectionId(),
      id: this.getId(),
      intl,
      path: pathname,
      userType: this.getUserType()
    })

    return (
      <InnerPage heading={name} vendor={this.state.vendor}>
        <PageNav className={Style.pageNav} items={navItems} />
        <Formik
          enableReinitialize
          initialValues={this.initialValues()}
          onSubmit={this.submitForm}
          validate={this.validate}
        >
          {(props) => {
            const { values } = props
            return (
              <Form className={Style.main}>
                <Row className="rowWide">
                  <Col lg={6} md={6} sm={12}>
                    <div className={Style.titleRow}>
                      <FormattedMessage id="Company data" />
                    </div>
                    <FieldsList fields={companyDataFields} />
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <div className={Style.titleRow}>
                      <FormattedMessage id="Pickup address and time" />
                    </div>
                    <FieldsList fields={pickUpFields} />
                  </Col>
                </Row>
                <br />
                <hr />
                <Row className="rowWide">
                  <Col lg={4} md={4} sm={12}>
                    <div className={Style.titleRow}>
                      <FormattedMessage id="Admin Contact" />
                    </div>
                    <FieldsList fields={adminContactFields} />
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <div className={Style.titleRow}>
                      <FormattedMessage id="contact for product inquiries" />
                    </div>
                    <FieldsList fields={contactProductInquiriesFields} />
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <div className={Style.titleRow}>
                      <FormattedMessage id="contact for order management" />
                    </div>
                    <FieldsList
                      fields={contactOrderManagementFields.filter(
                        (f) =>
                          f.name !== 'comSecondaryEmails' &&
                          f.name !== 'comEmail'
                      )}
                    />
                    <Row className="rowWide align-items-center">
                      <Col>
                        <Field
                          component={Input}
                          name="comEmail"
                          placeholder="Email"
                          type="email"
                          required
                        />
                      </Col>
                      <Col lg={2} md={2} sm={2}>
                        <button
                          type="button"
                          style={{
                            padding: '5px',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            border: 'none',
                            background: 'none'
                          }}
                          onClick={() =>
                            props.setFieldValue('comSecondaryEmails', [
                              ...values.comSecondaryEmails,
                              ''
                            ])
                          }
                        >
                          +
                        </button>
                      </Col>
                    </Row>
                    <FieldArray name="comSecondaryEmails">
                      {(arrayHelpers) => (
                        <>
                          {values.comSecondaryEmails &&
                          values.comSecondaryEmails.length ? (
                            <div>
                              {values.comSecondaryEmails.map((email, index) => (
                                <Row
                                  className="rowWide align-items-center"
                                  style={{ marginTop: 5 }}
                                  key={index}
                                >
                                  <Col lg={9} md={9} sm={9}>
                                    <Field
                                      name={`comSecondaryEmails.${index}`}
                                      placeholder="Email"
                                      type="email"
                                      component={Input}
                                      required
                                    />
                                  </Col>
                                  <Col
                                    lg={3}
                                    md={3}
                                    sm={3}
                                    className="justify-content-end"
                                  >
                                    <button
                                      style={{
                                        padding: '5px',
                                        fontSize: '20px',
                                        fontWeight: 'bold',
                                        border: 'none',
                                        background: 'none'
                                      }}
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      –
                                    </button>
                                    <button
                                      style={{
                                        padding: '5px',
                                        fontSize: '20px',
                                        fontWeight: 'bold',
                                        border: 'none',
                                        background: 'none'
                                      }}
                                      type="button"
                                      onClick={() =>
                                        arrayHelpers.insert(index + 1, '')
                                      }
                                    >
                                      +
                                    </button>
                                  </Col>
                                </Row>
                              ))}
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </FieldArray>
                  </Col>
                </Row>
                <hr />
                <Button type="submit">
                  <FormattedMessage id="Save" />
                </Button>
                <br />
                <br />
              </Form>
            )
          }}
        </Formik>
      </InnerPage>
    )
  }
}

VendorContact.propTypes = {
  GetCollection: func,
  HideLoading: func,
  ShowLoading: func,
  intl: shape({
    formatMessage: func
  }),
  location: shape({
    pathname: string
  })
}
export default connect(mapStateToProps, mapActions)(injectIntl(VendorContact))
