import './App.css'

import {
  BraintreeSubscriptionPage,
  CollectionTranslations,
  MassUpdater,
  ProductDetail,
  VendorAnalytics,
  VendorAnalyticsAudience,
  VendorAnalyticsSales,
  VendorDashboard,
  VendorOrderDetail,
  VendorOrders,
  VendorSubscription
} from 'pages'
import forgotPassword from 'pages/forgot-password'
import { shape } from 'prop-types'
import { string } from 'prop-types'
import { func } from 'prop-types'
import { bool } from 'prop-types'
import React, { Component } from 'react'
import cookies from 'react-cookies'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter
} from 'react-router-dom'
import { LogOut } from 'redux/actions/users-action'
import { getUserType } from 'utils/auth'
import { COOKIES, USER_TYPES } from 'utils/constants'
import { isExpired } from 'utils/utils'

import Header from './components/header/header'
import Loading from './components/loading/loading'
import messages from './messages'
import Admin from './pages/admin'
import ApprovalDetail from './pages/approval-detail'
import ApproverDashboard from './pages/approver-dashboard'
import ApproverWorkflow from './pages/approver-workflow'
import CollectionsForManager from './pages/collections-for-manager'
import CompanyBio from './pages/company-bio'
import ExportPreview from './pages/export-preview'
import ImageAssets from './pages/image-assets'
import Login from './pages/login'
import Merchandising from './pages/merchandising'
import Onboarding from './pages/onboarding'
import OnboardingFlowUi3 from './pages/onboarding-flow-UI3'
import PageNotFound from './pages/page-not-fount'
import Pricing from './pages/pricing'
import ProductDataTypes from './pages/product-data-types'
import PromoteUi from './pages/promote-ui'
import ResetPassword from './pages/reset-password'
import TranslatorDashboard from './pages/translator-dashboard'
import TranslatorProduct from './pages/translator-product'
import Vendor from './pages/vendor'
import VendorBio from './pages/vendor-bio'
import VendorCollectionList from './pages/vendor-collection-list'
import VendorContact from './pages/vendor-contact'

class App extends Component {
  collectionTranslationsHd = (config = {}) => {
    const { collectionName, formatMessage } = config || {}
    const t1 = formatMessage({ id: 'Translations for' })

    return `${t1} ${collectionName}`
  }

  render() {
    const { authReducer, lang, LogOut } = this.props

    const PrivateRoute = ({
      allowedUserTypes = [],
      component: Component,
      forbiddenUserTypes = [],
      redirectPath = '/',
      ...rest
    }) => {
      const { authorized } = authReducer
      const cookieAuth = cookies.load(COOKIES.AUTH)
      const userType = getUserType(authReducer)
      const inAllowedUT = allowedUserTypes.indexOf(userType) > -1
      const inForbiddenUT = forbiddenUserTypes.indexOf(userType) > -1
      let allowRoute = authorized

      if (allowedUserTypes.length > 0) {
        allowRoute = authorized && inAllowedUT
      }
      if (forbiddenUserTypes.length > 0) {
        allowRoute = authorized && !inForbiddenUT
      }

      const isTokenExpired = isExpired(authReducer.user.exp || 0)

      return (
        <Route
          {...rest}
          render={(props) => {
            if (isTokenExpired) {
              cookies.remove(COOKIES.AUTH)
              return (
                <Redirect
                  to={{
                    pathname: cookieAuth ? redirectPath : '/',
                    state: { from: props.location }
                  }}
                />
              )
            }

            if (!cookieAuth && authorized) {
              LogOut()
            }

            return allowRoute && cookieAuth ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: cookieAuth ? redirectPath : '/',
                  state: { from: props.location }
                }}
              />
            )
          }}
        />
      )
    }

    const MainRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) => {
          const cookieAuth = cookies.load(COOKIES.AUTH)
          let url = '/'

          if (authReducer.user) {
            url = authReducer.user.url
          }
          return !authReducer.authorized || !cookieAuth ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: url,
                state: { from: props.location }
              }}
            />
          )
        }}
      />
    )
    return (
      <IntlProvider locale={lang} messages={messages[lang]}>
        <div className="App">
          <BrowserRouter>
            <Header />
            <Switch>
              <MainRoute exact path="/" component={Login} />
              <Route exact path="/forgot-password" component={forgotPassword} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/loading" component={() => <Loading show />} />
              <PrivateRoute path="/admin" component={Admin} />
              <PrivateRoute
                component={ApproverWorkflow}
                forbiddenUserTypes={[USER_TYPES.VENDOR]}
                path="/approval-dashboard/:id?"
              />
              <PrivateRoute
                allowedUserTypes={[USER_TYPES.ADMIN, USER_TYPES.LOGISTICS]}
                component={MassUpdater}
                path="/mass-updater"
              />
              <PrivateRoute
                component={Onboarding}
                forbiddenUserTypes={[USER_TYPES.APPROVER, USER_TYPES.VENDOR]}
                path="/onboarding/:id?"
              />
              <PrivateRoute
                exact
                component={Vendor}
                forbiddenUserTypes={[USER_TYPES.VENDOR]}
                path="/vendor/:id"
              />
              <PrivateRoute
                exact
                component={VendorAnalytics}
                path="/vendor/:id/analytics"
              />
              <PrivateRoute
                component={VendorAnalyticsAudience}
                path="/vendor/:id/analytics/audience"
              />
              <PrivateRoute
                component={VendorAnalyticsSales}
                path="/vendor/:id/analytics/sales"
              />
              <PrivateRoute
                component={ProductDataTypes}
                forbiddenUserTypes={[USER_TYPES.VENDOR]}
                path="/product-data-types"
              />
              <PrivateRoute
                exact
                component={TranslatorDashboard}
                forbiddenUserTypes={[USER_TYPES.BUYER, USER_TYPES.VENDOR]}
                path="/translator-dashboard/:translator"
              />
              <PrivateRoute
                component={CollectionTranslations}
                forbiddenUserTypes={[USER_TYPES.BUYER, USER_TYPES.VENDOR]}
                path="/translator-dashboard/:translator/:collectionId"
              />
              <PrivateRoute
                component={CompanyBio}
                forbiddenUserTypes={[USER_TYPES.BUYER, USER_TYPES.VENDOR]}
                path="/company-bio/:collection/:vendor"
              />
              <PrivateRoute
                component={(props) => (
                  <CollectionTranslations
                    {...props}
                    makeHeading={this.collectionTranslationsHd}
                  />
                )}
                path="/translate-collection/:collectionId"
              />
              <PrivateRoute
                component={TranslatorProduct}
                forbiddenUserTypes={[
                  USER_TYPES.APPROVER,
                  USER_TYPES.BUYER,
                  USER_TYPES.VENDOR
                ]}
                path="/translate-product/:product"
              />
              <PrivateRoute
                component={OnboardingFlowUi3}
                forbiddenUserTypes={[USER_TYPES.VENDOR]}
                path="/onboarding-ui3/:collection"
              />
              <PrivateRoute
                component={VendorBio}
                path="/vendor-bio/:vendorId"
              />
              <PrivateRoute path="/promote/:vendor" component={PromoteUi} />
              <PrivateRoute
                path="/vendor-contact/:vendor"
                component={VendorContact}
              />
              <PrivateRoute
                path="/vendor-subscription/:vendorId"
                component={VendorSubscription}
              />
              <PrivateRoute
                component={VendorCollectionList}
                forbiddenUserTypes={[USER_TYPES.BUYER, USER_TYPES.VENDOR]}
                path="/collection-list/:owner/:vendor/:collection"
              />
              <PrivateRoute
                component={ExportPreview}
                forbiddenUserTypes={[USER_TYPES.BUYER, USER_TYPES.VENDOR]}
                path="/export-preview/:collection"
              />
              <PrivateRoute
                exact
                component={ProductDetail}
                path="/product-detail/:product"
              />
              <PrivateRoute
                component={ImageAssets}
                forbiddenUserTypes={[
                  USER_TYPES.BUYER,
                  USER_TYPES.TRANSLATOR,
                  USER_TYPES.VENDOR
                ]}
                path="/image-assets/:owner/:vendor"
              />
              <PrivateRoute
                allowedUserTypes={[
                  USER_TYPES.ADMIN,
                  USER_TYPES.APPROVER,
                  USER_TYPES.LOGISTICS
                ]}
                path="/approval-detail/:collection"
                component={ApprovalDetail}
              />
              <PrivateRoute
                allowedUserTypes={[
                  USER_TYPES.ADMIN,
                  USER_TYPES.APPROVER,
                  USER_TYPES.LOGISTICS
                ]}
                component={ApproverDashboard}
                path="/approver-dashboard/:approver"
              />
              <PrivateRoute
                component={ApproverWorkflow}
                forbiddenUserTypes={[USER_TYPES.BUYER, USER_TYPES.VENDOR]}
                path="/approver-workflow/:approver"
              />
              <PrivateRoute
                exact
                path="/vendor-dashboard/:vendor"
                component={VendorDashboard}
              />
              <PrivateRoute
                exact
                path="/order-list/:vendor"
                component={VendorOrders}
              />
              <PrivateRoute
                path="/order-list/:vendor/:orderNumber"
                component={VendorOrderDetail}
              />
              <PrivateRoute
                component={Pricing}
                forbiddenUserTypes={[USER_TYPES.BUYER, USER_TYPES.VENDOR]}
                path="/pricing/:owner/:vendor/:collection"
              />
              <PrivateRoute
                component={Merchandising}
                path="/merchandising/:vendor"
              />
              <PrivateRoute
                path="/collections-for-manager/:owner/:vendor"
                component={CollectionsForManager}
              />
              <PrivateRoute
                component={BraintreeSubscriptionPage}
                path="/vendor-plans/:vendorId"
              />
              <Route component={PageNotFound} />
            </Switch>
          </BrowserRouter>
        </div>
      </IntlProvider>
    )
  }
}

const mapActions = { LogOut }
const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  lang: state.languageReducer.lang
})
App.propTypes = {
  authReducer: shape({
    authorized: bool,
    user: shape({
      url: string
    })
  }),
  lang: string,
  LogOut: func
}
export default withRouter(connect(mapStateToProps, mapActions)(App))
