import cn from 'classnames'
import { Input } from 'components/formParts'
import { Field, useFormikContext } from 'formik'
import { arrayOf, bool, element, number, shape, string } from 'prop-types'
import React, { Fragment, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { COLS_WIDTH, INPUT_PROP_TYPES } from 'utils/propTypes'
import { gutterStyles } from 'utils/utils'

import st from './FieldsList.module.css'

const FieldsList = ({
  alignBottom,
  className,
  colsWidth,
  fields,
  gutterWidth,
  rowNarrow,
  title,
  titleClassName,
  topMarginLess,
  labelAfter
}) => {
  const { values, setFieldValue } = useFormikContext()
  const { col: colGutterStyle, row: rowGutterStyle } = gutterStyles(gutterWidth)
  const rowStyle = { ...rowGutterStyle }

  if (rowNarrow) {
    rowStyle.marginLeft = 0
    rowStyle.marginRight = 0
  }

  useEffect(() => {
    fields
      .filter((f) => f.hiddenBy)
      .forEach((f) => {
        if (values[f.hiddenBy] === true && f.onHiddenCopyValueFrom) {
          setFieldValue(f.name, values[f.onHiddenCopyValueFrom])
        }
      })
  }, [values])

  return (
    <Row
      className={cn(st.list, {
        [className]: className.length > 0,
        [st.alignBottom]: alignBottom,
        [st.topMarginLess]: topMarginLess
      })}
      style={rowStyle}
    >
      {title.length > 0 && (
        <Col
          className={cn(st.title, {
            [titleClassName]: titleClassName.length > 0
          })}
          style={colGutterStyle}
          tag="label"
          xs={12}
        >
          {title}
          {labelAfter && <span className={st.labelAfter}>{labelAfter}</span>}
        </Col>
      )}
      {fields.map(
        ({ colsWidth: colsWidthField, style, hiddenBy, ...field }) => {
          const hidden = values[hiddenBy] === true
          const colStyle = {
            display: hidden ? 'none' : undefined,
            ...colGutterStyle,
            ...style
          }
          const resColWidth = {
            ...colsWidth,
            ...colsWidthField
          }

          return (
            <Col
              {...resColWidth}
              key={field.name}
              style={colStyle}
              className={field.rangeInput ? st.range : ''}
            >
              {field.rangeInput && field.fields ? (
                <>
                  <div style={{ width: '100%' }}>
                    <label>{field.label}</label>
                  </div>
                  {field.fields.map((internalField, index) => {
                    return (
                      <Fragment key={internalField.id}>
                        <Field
                          key={internalField.id}
                          style={internalField.style}
                          {...internalField}
                          className={cn(st.field, internalField.className)}
                          component={Input}
                          id={internalField.name}
                        />
                        {index < field.fields.length - 1 && <span>-</span>}
                      </Fragment>
                    )
                  })}
                </>
              ) : (
                <Field
                  {...field}
                  className={cn(st.field, field.className)}
                  component={Input}
                  id={field.name}
                />
              )}
            </Col>
          )
        }
      )}
    </Row>
  )
}

FieldsList.defaultProps = {
  alignBottom: false,
  className: '',
  colsWidth: { xs: 12 },
  fields: [],
  gutterWidth: 30,
  rowNarrow: false,
  title: '',
  titleClassName: '',
  topMarginLess: false,
  labelAfter: ''
}
FieldsList.propTypes = {
  alignBottom: bool,
  className: string,
  colsWidth: COLS_WIDTH,
  fields: arrayOf(
    shape({
      ...INPUT_PROP_TYPES,
      colsWidth: COLS_WIDTH,
      style: shape({})
    })
  ),
  gutterWidth: number,
  rowNarrow: bool,
  title: string,
  labelAfter: element,
  titleClassName: string,
  topMarginLess: bool
}

export default FieldsList
