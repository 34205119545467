import axios from 'axios/index'
import cookie from 'react-cookies'

import apiUrl from './apiUrl'

const save = (body) =>
  axios.post(`${apiUrl}/api/contact`, body, {
    headers: { token: cookie.load('artemest') }
  })

const edit = (id, body) =>
  axios.put(`${apiUrl}/api/contact/${id}`, body, {
    headers: { token: cookie.load('artemest') }
  })

const readContact = (id) =>
  axios.get(`${apiUrl}/api/contact/${id}`, {
    headers: { token: cookie.load('artemest') }
  })

export { edit, readContact, save }
