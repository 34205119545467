import { noop } from 'lodash'
import { bool, func, number, string } from 'prop-types'
import React from 'react'
import {
  Pagination as PaginationRS,
  PaginationItem,
  PaginationLink
} from 'reactstrap'

import { usePagination } from './usePagination'

const Pagination = ({
  alignCenter,
  ariaLabel,
  className,
  current,
  disabled,
  pages,
  onChange,
  siblings = 3
}) => {
  const paginationRange = usePagination({
    pages,
    siblingCount: siblings,
    currentPage: current
  })

  const handleItemClick = (e) => {
    const currentPage = parseFloat(e.target.innerText)

    if (currentPage !== current && !disabled) {
      onChange(currentPage)
    }
  }

  const handleNextClick = () => {
    if (current < pages && !disabled) {
      onChange(current + 1)
    }
  }

  const handlePrevClick = () => {
    if (current > 1 && !disabled) {
      onChange(current - 1)
    }
  }

  const styleWrap = () => {
    const style = {}

    if (alignCenter) {
      style.justifyContent = 'center'
    }

    return style
  }

  return pages > 1 ? (
    <PaginationRS
      aria-label={ariaLabel}
      className={className}
      style={styleWrap()}
    >
      <PaginationItem
        disabled={current === 1 || disabled}
        onClick={handlePrevClick}
      >
        <PaginationLink previous />
      </PaginationItem>
      {paginationRange.map((page, index) => {
        const isDots = typeof page === 'string'
        return (
          <PaginationItem
            active={page === current}
            disabled={page === current || disabled || isDots}
            key={page + index}
            onClick={!isDots ? handleItemClick : null}
          >
            <PaginationLink>{page}</PaginationLink>
          </PaginationItem>
        )
      })}
      <PaginationItem
        disabled={current === pages || disabled}
        onClick={handleNextClick}
      >
        <PaginationLink next />
      </PaginationItem>
    </PaginationRS>
  ) : null
}

Pagination.defaultProps = {
  alignCenter: false,
  ariaLabel: 'Paginator',
  className: '',
  current: 1,
  disabled: false,
  pages: 1,
  visiblePages: 0,
  onChange: noop
}
Pagination.propTypes = {
  alignCenter: bool,
  ariaLabel: string,
  className: string,
  current: number,
  disabled: bool,
  pages: number,
  visiblePages: number,
  onChange: func,
  siblings: number
}

export default Pagination
