import cn from 'classnames'
import { range } from 'lodash'
import { bool, node, number, string } from 'prop-types'
import React from 'react'
import { Spinner as SpinnerRS } from 'reactstrap'

import st from './Spinner.module.css'

const Spinner = ({
  children,
  color,
  dumbMinHeight,
  length,
  show,
  showDumb,
  size,
  width,
  spinnerBg,
  hideChildrenWhileLoading
}) => {
  const spinnerStyle = {
    color,
    height: `${size}px`,
    width: `${size}px`
  }
  const wrapStyle = {
    minHeight: size,
    minWidth: width
  }

  return (
    <div className={st.wrap} style={wrapStyle}>
      {show && (
        <div
          className={cn(st.bg, {
            [st.bgWithColor]: spinnerBg,
            [st.spinnerDown]: showDumb
          })}
        >
          {range(length).map((c, key) => (
            <SpinnerRS key={key} size="sm" style={spinnerStyle} type="grow" />
          ))}
        </div>
      )}
      {(!show || !hideChildrenWhileLoading) && children}
      {showDumb && <div style={{ minHeight: dumbMinHeight }} />}
    </div>
  )
}
Spinner.defaultProps = {
  children: null,
  color: 'var(--loading-sec-color)',
  dumbMinHeight: 20,
  length: 5,
  show: false,
  showDumb: false,
  size: 20,
  spinnerBg: false,
  hideChildrenWhileLoading: false
}
Spinner.propTypes = {
  children: node,
  color: string,
  dumbMinHeight: number,
  length: number,
  show: bool,
  showDumb: bool,
  size: number,
  width: number,
  spinnerBg: bool,
  hideChildrenWhileLoading: bool
}

export default Spinner
