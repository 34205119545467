import { bool } from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { CURRENT_YEAR } from 'utils/constants'

import Style from './footer.module.css'

class Footer extends React.Component {
  render() {
    return (
      <div>
        <div
          className={[
            Style.absoluteDiv,
            this.props.absolute ? 'd-show' : 'd-none'
          ].join(' ')}
        >
          <div className={Style.unShownDiv}></div>

          <div className={Style.mainDiv}>
            <div className={Style.allRights}>
              <p>
                © {CURRENT_YEAR} ARTEMEST.{' '}
                <FormattedMessage id={'CopyRights'} tagName={'span'} />
              </p>
            </div>
          </div>
        </div>
        <div className={!this.props.absolute ? 'd-show' : 'd-none'}>
          <div className={Style.mainDiv}>
            <div className={Style.allRights}>
              <p>
                {' '}
                &copy; {CURRENT_YEAR} ARTEMEST.{' '}
                <FormattedMessage id={'CopyRights'} tagName={'span'} />
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Footer.propTypes = {
  absolute: bool
}
export default withRouter(Footer)
