import React from 'react'

import { DEFAULT_PROPS, PROP_TYPES } from './config'
import st from './ProductCard.module.css'

const ProductCard = ({
  imageAlt,
  imageSrc,
  name,
  price,
  status,
  statusColor
}) => {
  const statusStyle = {
    color: statusColor
  }

  return (
    <div className={st.card}>
      <figure className={st.img}>
        <img alt={imageAlt} src={imageSrc} />
      </figure>
      <p className={st.status} style={statusStyle}>
        {status}
      </p>
      <p className={st.name}>{name}</p>
      <p className={st.price}>{price}</p>
    </div>
  )
}

ProductCard.defaultProps = DEFAULT_PROPS
ProductCard.propTypes = PROP_TYPES

export default ProductCard
