import './styles/sheared.css'

import { PageNav } from 'components'
import CollectionCards from 'components/collection-cards/collection-card'
import Footer from 'components/footer/footer'
import { InnerPage } from 'components/layout'
import { get } from 'lodash'
import { bool, func, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { FaThumbsDown, FaThumbsUp, FaTrashAlt } from 'react-icons/fa'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import {
  Badge,
  Button,
  Col,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalFooter,
  ModalHeader,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
  Table
} from 'reactstrap'
import {
  AddCollection,
  ApproveCollection,
  DeleteCollection,
  DisapproveCollection,
  GetCollection
} from 'redux/actions/collection-action'
import { ChangeAddStatus, GetVendor } from 'redux/actions/vendor-action'
import { getUserType, isAdmin, isApprover, isManager } from 'utils/auth'
import { vendorNavItems } from 'utils/utils'

import Style from './styles/vendor.module.css'

class Vendor extends Component {
  state = {
    input: '',
    files: [],
    popoverOpen: false,
    collectionList: false,
    footer: true,
    collection: ''
  }

  _popover = null

  componentDidMount() {
    const { changeAddStatus, GetCollection, GetVendor } = this.props
    const vendorId = this.getVendorId()

    changeAddStatus(false)
    GetCollection(vendorId)
    GetVendor({ vendorId })

    document.body.addEventListener('click', this.handlePopover)
  }

  componentDidUpdate(prevProps) {
    const collectionIdPath = 'collectionReducer.collection._id'
    const collectionIdPrev = get(prevProps, collectionIdPath, '')
    const collectionId = get(this.props, collectionIdPath, '')

    if (collectionIdPrev !== collectionId && collectionId) {
      this.routeOnboardingUi3(collectionId)
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handlePopover)
  }

  getCollectionId = () =>
    get(this.props, 'collectionReducer.collections[0]._id', '')
  getVendorId = () => get(this.props, 'match.params.id', '')
  getVendorName = () => {
    const {
      vendorReducer: { vendor }
    } = this.props
    const artisanName = get(vendor, 'artisanName', '')
    const name = get(vendor, 'name', '')

    return artisanName || name
  }

  handlePopover = (e) => {
    const { popoverOpen } = this.state
    const outsidePopover = this._popover
      ? !this._popover.contains(e.target)
      : false

    if (popoverOpen && outsidePopover) {
      this.togglePopover()
    }
  }

  routeOnboardingUi3 = (id) => {
    const { history } = this.props

    history.push(`/onboarding-ui3/${id}`)
  }

  setRefPopover = (el) => {
    this._popover = el
  }

  handleChange = (e) => {
    this.setState({ input: e.target.value })
  }

  handleModalDeleteClick = () => {
    const { canActOnCollections, DeleteCollection } = this.props

    if (!canActOnCollections) {
      return
    }

    const { collection } = this.state
    const collectionId =
      typeof collection === 'string'
        ? collection
        : get(collection, 'collection._id', '')

    DeleteCollection(collectionId)
    this.closeDeleteModal()
  }

  showDeleteModal = (id) => {
    if (id) {
      this.setState({
        collection: id || '',
        deleteModal: true
      })
    }
  }

  closeDeleteModal = () => {
    this.setState({
      collection: '',
      deleteModal: false
    })
  }

  togglePopover = () => {
    this.setState((prevState) => ({
      popoverOpen: !prevState.popoverOpen
    }))
  }

  getUserId = () => {
    const { authReducer } = this.props

    return authReducer.user._id
  }

  createNew = () => {
    const { canAddCollections } = this.props

    if (!canAddCollections) {
      return
    }

    const { input } = this.state
    const id = this.getVendorId()

    if (input && id) {
      this.props.AddCollection({
        name: input,
        vendor: id,
        createdBy: this.getUserId()
      })
    }
  }

  renderTable(vendor, collection, product) {
    if (!vendor) {
      return null
    }

    const { canActOnCollections, isApp } = this.props
    const vendorProduct = product ? product.name : ''
    let collections
    let vendorCollectionLength

    if (collection.got && collection.collections) {
      vendorCollectionLength = collection.collections.length
      collections = collection.collections
    }

    return (
      <Table>
        <tbody>
          <tr>
            <th>
              <FormattedMessage id={'Name'} />
            </th>
            <th>
              <FormattedMessage id={'Area'} />
            </th>
            <th>
              <FormattedMessage id={'Material'} />
            </th>
            <th>
              <FormattedMessage id={'Collections'} />
            </th>
            <th>
              <FormattedMessage id={'Products'} />
            </th>
          </tr>
          <tr>
            <td>{vendor.artisanName} </td>
            <td>{vendor.area}</td>
            <td>{vendor.material}</td>
            <td className={Style.collection} id="Popover1">
              {' '}
              collections{' '}
              <Badge pill color="primary">
                {vendorCollectionLength}
              </Badge>
              <Popover
                placement="bottom"
                className={Style.popover}
                isOpen={this.state.popoverOpen}
                target="Popover1"
                toggle={this.togglePopover}
              >
                <div ref={this.setRefPopover}>
                  <PopoverHeader className={Style.popHeader}>
                    {vendor.artisanName}&apos;s{' '}
                    <FormattedMessage id="Collections" />
                  </PopoverHeader>
                  <PopoverBody className={Style.popBody}>
                    <ListGroup>
                      {collections &&
                        collections.map((element) => (
                          <ListGroupItem key={element._id}>
                            <Badge
                              pill
                              className={Style.badge}
                              color="primary"
                              onClick={() => {
                                if (canActOnCollections || isApp) {
                                  this.routeOnboardingUi3(element._id)
                                }

                                this.togglePopover()
                              }}
                            >
                              {element.name}
                            </Badge>
                            <div className={Style.actions}>
                              {element.approved ? (
                                <FaThumbsDown
                                  className={Style.action}
                                  color="var(--main-pink-color)"
                                  onClick={() => {
                                    if (canActOnCollections) {
                                      this.props.DisapproveCollection(
                                        element._id
                                      )
                                    }

                                    this.togglePopover()
                                  }}
                                />
                              ) : (
                                <FaThumbsUp
                                  className={Style.action}
                                  color="var(--main-green-color)"
                                  onClick={() => {
                                    if (canActOnCollections) {
                                      this.props.ApproveCollection(element._id)
                                    }

                                    this.togglePopover()
                                  }}
                                />
                              )}
                              <FaTrashAlt
                                className={Style.action}
                                color="var(--main-red-color)"
                                onClick={() => {
                                  if (canActOnCollections) {
                                    this.showDeleteModal(element._id)
                                  }

                                  this.togglePopover()
                                }}
                              />
                            </div>
                          </ListGroupItem>
                        ))}
                    </ListGroup>
                  </PopoverBody>
                </div>
              </Popover>
            </td>
            <td>{vendorProduct}</td>
          </tr>
        </tbody>
      </Table>
    )
  }

  render() {
    const {
      canActOnCollections,
      canAddCollections,
      className,
      collectionReducer,
      history,
      intl,
      userType,
      vendorReducer
    } = this.props
    const { collectionList, deleteModal, footer, input } = this.state
    const navItems = vendorNavItems({
      collectionId: this.getCollectionId(),
      id: this.getVendorId(),
      intl,
      userType
    })
    const vendorName = this.getVendorName()

    return (
      <InnerPage
        heading={vendorName}
        vendor={get(this.props, 'vendorReducer.vendor')}
      >
        <PageNav className="rowWide" items={navItems} />
        {collectionList && <CollectionCards history={history} />}
        {this.renderTable(vendorReducer.vendor, collectionReducer, null)}
        {canAddCollections && (
          <Row className={Style.newCollection}>
            <Col md={7} sm={8} style={{ textAlign: 'left' }}>
              <FormGroup>
                <p className={Style.label}>
                  <FormattedMessage id="Create New Collection" />
                </p>
                <Input
                  className={Style.input}
                  id="newCollection"
                  placeholder={intl.formatMessage({
                    id: 'Collection Name'
                  })}
                  type="text"
                  value={input}
                  onChange={this.handleChange}
                />
                <Button
                  color="primary"
                  className={Style.submitButton}
                  onClick={this.createNew}
                  disabled={!input || !canAddCollections}
                >
                  <FormattedMessage id="Create" />
                </Button>
              </FormGroup>
            </Col>
          </Row>
        )}
        <Footer absolute={footer} />
        {canActOnCollections && (
          <Modal
            isOpen={deleteModal}
            toggle={this.closeDeleteModal}
            className={className}
          >
            <ModalHeader toggle={this.closeDeleteModal}>
              <FormattedMessage id="Delete Collection" />
            </ModalHeader>
            <ModalFooter>
              <Button color="danger" onClick={this.handleModalDeleteClick}>
                <FormattedMessage id="Delete" />
              </Button>{' '}
              <Button color="secondary" onClick={this.closeDeleteModal}>
                <FormattedMessage id="Cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </InnerPage>
    )
  }
}

Vendor.propTypes = {
  changeAddStatus: func,
  GetCollection: func,
  GetVendor: func,
  vendorReducer: shape({}),
  history: shape({
    push: func
  }),
  canActOnCollections: bool,
  DeleteCollection: func,
  canAddCollections: bool,
  AddCollection: func,
  isApp: bool,
  DisapproveCollection: func,
  ApproveCollection: func,
  className: string,
  collectionReducer: shape({}),
  intl: shape({
    formatMessage: func
  }),
  userType: string,
  authReducer: shape({
    user: shape({
      _id: string
    })
  })
}
const mapStateToProps = ({
  authReducer,
  collectionReducer,
  productDataReducer,
  vendorReducer
}) => {
  const isAdMa = isAdmin(authReducer) || isManager(authReducer)

  return {
    authReducer,
    canActOnCollections: isAdMa,
    canAddCollections: isAdMa,
    collectionReducer,
    isApp: isApprover(authReducer),
    productDataReducer,
    userType: getUserType(authReducer),
    vendorReducer
  }
}

const mapDispatchToProps = (dispatch) => ({
  GetVendor: (config) => dispatch(GetVendor(config)),
  AddCollection: (collection) => dispatch(AddCollection(collection)),
  GetCollection: (vendor) => dispatch(GetCollection(vendor)),
  DeleteCollection: (id) => dispatch(DeleteCollection(id)),
  ApproveCollection: (id) => dispatch(ApproveCollection(id)),
  DisapproveCollection: (id) => dispatch(DisapproveCollection(id)),
  changeAddStatus: (status) => dispatch(ChangeAddStatus(status))
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Vendor))
