import { arrayOf, bool, shape, string } from 'prop-types'
import React from 'react'
import { FaStar } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { isProductPending } from 'utils/products'

import Style from './vendor-collection-list-card.module.css'

const CardElement = ({
  cardData: {
    _id,
    approved,
    mainPhoto,
    onboardingPhoto,
    photos,
    ...restCardData
  }
}) => {
  const imageSrc = mainPhoto || onboardingPhoto || (photos ? photos[0] : '')

  return (
    <Card className={Style.card}>
      <CardHeader className={Style.cardHeader}>
        {isProductPending(restCardData) ? (
          _id
        ) : (
          <Link to={`/product-detail/${_id}`}>{_id}</Link>
        )}
      </CardHeader>
      <CardBody className={Style.cardBody}>
        {imageSrc && (
          <img alt="collection" className={Style.image} src={imageSrc} />
        )}
        {approved && (
          <div className={Style.starDiv}>
            <FaStar size={32} />
          </div>
        )}
      </CardBody>
    </Card>
  )
}
CardElement.defaultProps = {
  cardData: {}
}
CardElement.propTypes = {
  cardData: shape({
    _id: string,
    approved: bool,
    onboardingPhoto: string,
    photos: arrayOf(string),
    status: string
  })
}

export default CardElement
