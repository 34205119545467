import { get } from 'lodash'

/**
 * @param {Object} config
 * @param {Array} config.items
 * @param {Object} config.items[]
 * @param {Object} config.items[].name
 * @param {String} config.items[].name[lang]
 * @param {String} config.lang
 */
export const mapToOptions = (config = {}) => {
  const { items, lang } = config
  const getValue = (name) => get(name, lang || 'en') || get(name, 'en', '')

  return items
    .filter(({ name }) => Boolean(getValue(name)))
    .map(({ name, _id }) => {
      const value = getValue(name)

      return {
        label: value,
        value: _id
      }
    })
}
