import { Dropdown } from 'components'
import { noop, uniqueId } from 'lodash'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import { SearchForm } from '..'
import st from './SearchFormDropdown.module.css'

const SearchFormDropdown = ({
  intl,
  items,
  noItemsText,
  pending,
  onSubmit
}) => {
  const mappedItems = pending
    ? [
        {
          content: `${intl.formatMessage({ id: 'Searching' })}...`,
          _id: '0'
        }
      ]
    : items.map(({ image, name, sku, vendor, _id, onClick, ...props }) => {
        const contentText = [
          {
            label: 'SKU',
            value: sku
          },
          {
            label: intl.formatMessage({ id: 'Name' }),
            value: name
          },
          {
            label: 'Vendor',
            value: vendor
          }
        ]

        return {
          className: st.item,
          content: (
            <div className={st.content}>
              <img alt={name} className={st.image} src={image} />
              <ul className={st.contentText}>
                {contentText.map((ct) => (
                  <li key={uniqueId(ct.label)}>
                    {ct.label}: {ct.value}
                  </li>
                ))}
              </ul>
            </div>
          ),
          _id: uniqueId(_id),
          onClick,
          ...props
        }
      })

  return (
    <Dropdown
      isUncontrolled
      classNameMenu={st.menu}
      items={mappedItems}
      noItemsText={noItemsText}
    >
      <SearchForm enableReinitialize onInputChange={onSubmit} />
    </Dropdown>
  )
}
SearchFormDropdown.defaultProps = {
  items: [],
  noItemsText: '',
  pending: false,
  onSubmit: noop
}
SearchFormDropdown.propTypes = {
  items: arrayOf(
    shape({
      disabled: bool,
      image: string,
      name: string,
      sku: string.isRequired,
      vendor: string,
      _id: string,
      onClick: func
    })
  ),
  noItemsText: string,
  pending: bool,
  onSubmit: func,
  intl: shape({
    formatMessage: func
  })
}

export default injectIntl(SearchFormDropdown)
