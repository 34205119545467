import axios from 'axios'
import { mapError } from 'utils/errors'

import { cubeApiUrl } from './apiUrl'

/**
 * @param {Object} config
 * @param {String} config.analyticsToken
 * @param {Array} config.dimensions
 * @param {Array} config.measures
 * @param {Object} config.queryOrder
 * @param {Array} config.timeDimensions
 */
export const getCubeData = async (config = {}) => {
  const {
    analyticsToken = '',
    dimensions = [],
    measures = [],
    queryOrder = {},
    timeDimensions = []
  } = config

  try {
    const { data, status } = await axios.get(`${cubeApiUrl}/load`, {
      headers: {
        Authorization: analyticsToken
      },
      params: {
        query: {
          dimensions,
          measures,
          order: queryOrder,
          timeDimensions
        }
      }
    })

    if (status === 200) {
      return { data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}
