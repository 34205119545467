import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { PRODUCT_AUDIT_COLORS } from './constants'

export const useAuditColors = (
  auditedOn,
  flaggedAsIncompleteOn,
  flaggedAsDiscontinuedOn,
  productId
) => {
  const [productAuditColor, setProductColor] = useState('')

  useEffect(() => {
    const today = new Date()

    const auditedOnDate = dayjs(auditedOn)
    const flaggedAsIncompleteOnDate = dayjs(flaggedAsIncompleteOn)
    const todayDate = dayjs(today)

    const auditWhen = todayDate.diff(auditedOnDate, 'month', true)
    const incompleteDiffAudit = auditedOnDate.diff(
      flaggedAsIncompleteOnDate,
      'month',
      true
    )
    let color = productAuditColor

    if (flaggedAsDiscontinuedOn) {
      color = PRODUCT_AUDIT_COLORS.GRAY
    } else {
      if (auditWhen < 6) {
        color = PRODUCT_AUDIT_COLORS.GREEN
      } else if (auditWhen > 6 && auditWhen < 12) {
        color = PRODUCT_AUDIT_COLORS.ORANGE
      } else if (auditWhen > 12) {
        color = PRODUCT_AUDIT_COLORS.RED
      }

      if (
        (!isNaN(incompleteDiffAudit) && incompleteDiffAudit < 0) ||
        (flaggedAsIncompleteOn && !auditedOn)
      ) {
        color = PRODUCT_AUDIT_COLORS.RED
      }
    }

    setProductColor(color)
  }, [auditedOn, flaggedAsIncompleteOn, flaggedAsDiscontinuedOn, productId])

  if (!auditedOn && !flaggedAsIncompleteOn && !flaggedAsDiscontinuedOn) {
    return null
  }

  return productAuditColor
}
