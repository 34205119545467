import { FORGOT_PASSWORD } from 'redux/actions/action-types'

const defaultState = {
  completed: false
}

const ForgotPasswordReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD: {
      return {
        ...state,
        completed: action.completed
      }
    }

    default:
      return state
  }
}

export default ForgotPasswordReducer
