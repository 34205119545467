import axios from 'axios/index'
import cookie from 'react-cookies'
import { STATUSES } from 'utils/constants'
import { mapError } from 'utils/errors'

import apiUrl from './apiUrl'

/**
 * @param {Object} config
 * @param {Object} config.translationData
 * @param {String} config.translationId
 */
const addTranslations = async (config = {}) => {
  const { translationData = {}, translationId = '' } = config
  const apiPath = `${apiUrl}/api/translation/${translationId}`

  try {
    const { data, status } = await axios.patch(apiPath, translationData, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return { data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} params
 * @param {String} [params.source]
 * @param {String} [params.status]
 */
const getAllTranslations = async (params) => {
  const apiPath = `${apiUrl}/api/translation`

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') },
      params
    })

    if (status !== 200) {
      return { error: data }
    }

    return { translations: data }
  } catch (error) {
    return { error }
  }
}

/**
 * @param {Object} config
 * @param {Object} config.collectionId
 * @param {Number} config.page
 * @param {Number} config.perPage
 * @param {String} config.productStatus
 */
const getTranslationsByCollection = async (config = {}) => {
  const {
    collectionId = '',
    page = 1,
    perPage = 20,
    productStatus = STATUSES.APPROVED
  } = config || {}
  const apiPath = `${apiUrl}/api/translation/collection/${collectionId}`

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') },
      params: { page, perPage, productStatus }
    })

    if (status === 200) {
      return { data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

const getTranslationsByOnId = async (onId) => {
  const apiPath = `${apiUrl}/api/translation/${onId}`

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return { data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} config
 * @param {Object} config.translationData
 * @param {String} config.translationId
 */
const setTranslationReady = async (config = {}) => {
  const { translationData = {}, translationId = '' } = config
  const apiPath = `${apiUrl}/api/translation/${translationId}/ready`

  try {
    const { data, status } = await axios.patch(apiPath, translationData, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return { data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} config
 * @param {String} config.id
 * @param {Object} config.translation
 */
const updateTranslation = async (config = {}) => {
  const { id = '', translation = {} } = config
  const apiPath = `${apiUrl}/api/translation/${id}`

  try {
    const { data, status } = await axios.put(
      apiPath,
      {
        translation
      },
      {
        headers: { token: cookie.load('artemest') }
      }
    )

    if (status === 200) {
      return { data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} config
 * @param {Boolean} config.isAccept
 * @param {String} config.translationId
 */
const handleTranslationAccept = async (config = {}) => {
  const { isAccept = false, translationId = '' } = config
  const pathEnd = isAccept ? 'approve' : 'decline'
  const apiPath = `${apiUrl}/api/translation/${translationId}/${pathEnd}`

  try {
    const { data, status } = await axios.patch(
      apiPath,
      {},
      {
        headers: { token: cookie.load('artemest') }
      }
    )

    if (status === 200) {
      return { data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

export {
  addTranslations,
  getAllTranslations,
  getTranslationsByCollection,
  getTranslationsByOnId,
  handleTranslationAccept,
  setTranslationReady,
  updateTranslation
}
