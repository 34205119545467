/* eslint-disable no-unused-vars */
import {
  PageNavVendorActions,
  Pagination,
  Spinner,
  Status,
  TranslationProduct
} from 'components'
import { TranslateProductForm } from 'components/forms'
import { InnerPage } from 'components/layout'
import { get, noop } from 'lodash'
import { bool, func, shape, string } from 'prop-types'
import React, { useEffect, useReducer } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getUrlParams, stringifyParams } from 'utils/url'

import st from './CollectionTranslations.module.css'
import { mapState } from './config'
import {
  acceptRejectMw,
  getAllTranslationsMw,
  initMw,
  proofReadingMw,
  saveDraftMw
} from './mw'
import { initialState, reducer } from './reducer'

const CollectionTranslations = ({
  dispatch: dispatchRedux,
  hideAccept,
  hideProofReading,
  hideReject,
  hideSaveDraft,
  history,
  intl: { formatMessage },
  lang,
  location: { pathname },
  makeHeading,
  match: { params, url },
  userType
}) => {
  const collectionId = get(params, 'collectionId', '')
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    bio,
    collection,
    page,
    pages,
    perPage,
    productPendingIds,
    productsToTranslate,
    vendorName,
    vendorSKU,
    webSite
  } = state

  useEffect(() => {
    initMw({
      collectionId,
      dispatch,
      dispatchRedux,
      lang,
      perPage
    })
  }, [collectionId, lang, perPage])

  const collectionName = get(collection, 'name', '')
  const pageHeading =
    makeHeading({
      collectionName,
      formatMessage
    }) || formatMessage({ id: 'openTranslations' })
  const vendorId = vendorSKU || get(collection, 'vendor', '')

  const handleClickImages = () => {
    history.push({
      pathname: `/image-assets/${userType}/${vendorId}`,
      state: { collection }
    })
  }

  const handlePageChange = (page) => {
    const up = getUrlParams()

    up.page = page

    const newPath = `${pathname}?${stringifyParams(up)}`

    history.push(newPath)
    getAllTranslationsMw({
      collectionId,
      dispatch,
      dispatchRedux,
      lang,
      page,
      perPage,
      showPending: true
    })
  }

  return (
    <InnerPage className={st.page} heading={pageHeading}>
      <PageNavVendorActions
        className={st.pageMenu}
        collectionId={collectionId}
        currentUrl={url}
        userType={userType}
        vendorId={vendorId}
        onClickImages={handleClickImages}
      />
      <ul className={st.info}>
        <li>
          {vendorName}: {vendorId}
          <a
            className={st.infoValue}
            href={webSite}
            rel="noreferrer"
            target="_blank"
          >
            {webSite}
          </a>
        </li>
        <li>
          BIO:
          <span className={st.infoValue}>{bio}.</span>
        </li>
      </ul>
      {productsToTranslate.length > 0 ? (
        productsToTranslate.map(
          ({
            careInstructions,
            category,
            color,
            customProps,
            description,
            designer,
            dimensions,
            material,
            name,
            otherInfo,
            otherPhotos,
            photo,
            translation,
            _id
          }) => {
            const initialValues = {
              description: get(translation, 'description') || '',
              name: get(translation, 'name') || ''
            }
            const isProductPending = Boolean(productPendingIds[_id])
            const translationId = get(translation, '_id') || ''
            const translationStatus = get(translation, 'status', '')

            const handleAcceprReject = (config = {}) => {
              const { isAccept = false, translationData = {} } = config

              acceptRejectMw({
                dispatch,
                formatMessage,
                isAccept,
                productId: _id,
                translationData,
                translationId
              })
            }

            const handleProofReading = (values) => {
              proofReadingMw({
                dispatch,
                formatMessage,
                productId: _id,
                translationData: values,
                translationId
              })
            }

            const handleSaveDraft = (values) => {
              saveDraftMw({
                dispatch,
                formatMessage,
                productId: _id,
                translatedProductData: values,
                translationId
              })
            }

            return (
              <TranslationProduct
                alt=""
                className={st.product}
                key={_id}
                otherPhotos={otherPhotos}
                photo={photo}
              >
                <Spinner show={isProductPending}>
                  <TranslateProductForm
                    description={description}
                    disableSaveDraft={!lang}
                    hideAccept={hideAccept}
                    hideProofReading={hideProofReading}
                    hideReject={hideReject}
                    hideSaveDraft={hideSaveDraft}
                    initialValues={initialValues}
                    name={name}
                    translationStatus={translationStatus}
                    onAcceptReject={handleAcceprReject}
                    onProofReading={handleProofReading}
                    onSaveDraft={handleSaveDraft}
                  >
                    {material && (
                      <p>
                        {formatMessage({ id: 'Material' })}: {material}
                      </p>
                    )}
                    {color && (
                      <p>
                        {formatMessage({ id: 'Color' })}: {color}
                      </p>
                    )}
                    {category && (
                      <p>
                        {formatMessage({ id: 'Category' })}: {category}
                      </p>
                    )}
                    {designer && (
                      <p>
                        {formatMessage({ id: 'Designer' })}: {designer}
                      </p>
                    )}
                    {dimensions && (
                      <p>
                        {formatMessage({ id: 'Dimensions' })}: {dimensions}
                      </p>
                    )}
                    {careInstructions && (
                      <p>
                        {formatMessage({ id: 'Care Instructions' })}:{' '}
                        <Link target="_blank" to={careInstructions}>
                          {careInstructions}
                        </Link>
                      </p>
                    )}
                    {otherInfo && (
                      <p>
                        {formatMessage({ id: 'Other Info' })}:{' '}
                        <Link target="_blank" to={otherInfo}>
                          {otherInfo}
                        </Link>
                      </p>
                    )}
                    {customProps.length > 0 && (
                      <>
                        <p>{formatMessage({ id: 'Custom properties' })}:</p>
                        <ul className={st.customProps}>
                          {customProps.map((cp) => (
                            <li key={cp.name}>
                              {cp.name}: {`${cp.type}`}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </TranslateProductForm>
                </Spinner>
              </TranslationProduct>
            )
          }
        )
      ) : (
        <Status>{formatMessage({ id: 'translations not found' })}.</Status>
      )}
      {pages > 1 && (
        <Pagination
          alignCenter
          current={page}
          pages={pages}
          onChange={handlePageChange}
          siblings={4}
        />
      )}
    </InnerPage>
  )
}

CollectionTranslations.defaultProps = {
  makeHeading: noop
}
CollectionTranslations.propTypes = {
  makeHeading: func,
  dispatch: func,
  hideAccept: bool,
  hideProofReading: bool,
  hideReject: bool,
  hideSaveDraft: bool,
  history: shape({}),
  intl: shape({
    formatMessage: func
  }),
  lang: string,
  location: shape({
    pathname: string,
    url: string
  }),
  match: shape({
    params: string,
    url: string
  }),
  userType: string
}

export default injectIntl(connect(mapState)(CollectionTranslations))
