import { capitalize, get, noop } from 'lodash'
import { bool } from 'prop-types'
import { CERTIFICATIONS } from 'utils/products'
import { decimalStringToNumber } from 'utils/string'

export const certificationValues = {
  default: CERTIFICATIONS.reduce((acc, c) => {
    acc[`certification${c}`] = false

    return acc
  }, {}),
  types: CERTIFICATIONS.reduce((acc, c) => {
    acc[`certification${c}`] = bool

    return acc
  }, {})
}

/**
 * @param {Array} fields
 * @param {Object} fields[]
 */
export const checkboxFieldsList = (fields = []) => {
  return fields.map((field) => ({
    ...field,
    horizontal: true,
    reversed: true,
    type: 'checkbox'
  }))
}

/**
 * @param {Object} config
 * @param {String} config.decimalSign - ',' or '.'
 * @param {String} config.eventName - e. g.: 'blur', 'change'
 * @param {Object} config.field - passed into the field from Formik
 * @param {Function} config.propEventHandler - passed into the field directly from props
 * @param {String} config.type
 */
export const handleInputEvent = (config = {}) => {
  const {
    decimalSign = '.',
    eventName,
    field,
    propEventHandler,
    type = 'text'
  } = config
  const eventHandlerName = `on${capitalize(eventName)}`
  const fieldEventHandler = get(field, eventHandlerName) || noop

  return (e) => {
    const name = get(e, 'target.name', '')
    let value = get(e, 'target.value')

    if (type === 'number') {
      const parsedValue = decimalStringToNumber({
        decimalSign,
        value
      })

      value = parsedValue
    }

    const event = {
      ...e,
      target: {
        ...e.target,
        name,
        value
      }
    }

    if (type === 'checkbox' || type === 'radio') {
      const checked = Boolean(e.target.checked)

      event.target.checked = checked
      event.target.value = checked
    }

    if (typeof fieldEventHandler === 'function') {
      fieldEventHandler(event)
    }

    propEventHandler(event)
  }
}

/**
 * @param {Object} config
 * @param {String} config.error
 * @param {String} config.fieldName
 * @param {Object} config.form - passed from Formik
 * @param {Boolean} config.form.isValid
 * @param {Object} config.form.touched
 */
export const isInvalidField = (config = {}) => {
  const { error = '', fieldName = '', form = {} } = config
  const hasError = Boolean(get(form, `errors[${fieldName}]`))
  const isTouched = Boolean(get(form, `touched[${fieldName}]`))

  return (
    (typeof form.isValid === 'boolean' &&
      !form.isValid &&
      hasError &&
      isTouched) ||
    error.length > 0
  )
}

/**
 * @param {Object} config
 * @param {String} config.errorField
 * @param {Object} config.errors
 * @param {Function} config.formatMessage
 * @param {Object} config.passwordCheckObj
 * @param {Array} config.passwordCheckObj.errors
 * @param {String} config.passwordCheckObj.start
 */
export const passwordErrors = (config = {}) => {
  const {
    errorField = '',
    errors = {},
    formatMessage = noop,
    passwordCheckObj = {}
  } = config

  if (passwordCheckObj.errors.length) {
    const errorMsgStart = passwordCheckObj.start
      ? formatMessage({ id: passwordCheckObj.start }) + ' '
      : ''
    const errorMsg =
      errorMsgStart +
      passwordCheckObj.errors
        .map((error) => formatMessage({ id: error }))
        .join(', ')

    errors[errorField] = errorMsg
  }
}
