import React from 'react'
import { STATUSES } from 'utils/constants'

import st from './BraintreePlanTable.module.css'

/**
 * @param {Object} config
 * @param {Object} config.intl
 * @param {Boolean} config.isEndingOn
 * @param {Node} config.renewingCnt
 * @param {Object} config.values
 */
export const rows = (config = {}) => {
  const {
    intl = {},
    isEndingOn = false,
    renewingCnt = null,
    values = {}
  } = config
  const { currentPlan, renewingOn, status, validFrom, firstBillingDate } =
    values
  const row1Name = 'Subscription status'
  const row2Name = 'Valid from'
  const row3Name = `${isEndingOn ? 'End' : 'Renew'}ing on`
  const row4Name = 'Current Plan'
  const row5Name = 'First billing date'

  return [
    {
      cells: [
        { content: intl.formatMessage({ id: row1Name }) },
        { className: st.status, content: status }
      ],
      _id: row1Name
    },
    {
      cells: [
        { content: intl.formatMessage({ id: row4Name }) },
        { content: currentPlan }
      ],
      _id: row4Name
    },
    // Display Valid From and Renewing on only if the status is not "Pending"
    ...(status !== STATUSES.PENDING
      ? [
          {
            cells: [
              { content: intl.formatMessage({ id: row2Name }) },
              { content: validFrom || '-' }
            ],
            _id: row2Name
          },
          {
            cells: [
              { content: intl.formatMessage({ id: row3Name }) },
              {
                content: renewingOn ? (
                  <div className={st.renewing}>
                    {renewingCnt}
                    {renewingOn}
                  </div>
                ) : (
                  '-'
                )
              }
            ],
            _id: row3Name
          }
        ]
      : []),
    // Display First billing date only if the status is "Pending"
    ...(status === STATUSES.PENDING
      ? [
          {
            cells: [
              { content: intl.formatMessage({ id: row5Name }) },
              { content: firstBillingDate || '-' }
            ],
            _id: row5Name
          }
        ]
      : [])
  ]
}
