import cookie from 'react-cookies'
import { LANG_DEFAULT_CODE } from 'utils/constants'
import { getDecimalSignByLang } from 'utils/number'

import { CHANGE_LANGUAGE } from '../actions/action-types'

const langDefault = cookie.load('lang') || LANG_DEFAULT_CODE
const defaultState = {
  decimalSign: getDecimalSignByLang(langDefault),
  lang: langDefault
}
const LanguageReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}

export default LanguageReducer
