import { get } from 'lodash'
import React from 'react'
import { AiTwotoneEye } from 'react-icons/ai'
// import { GiBedLamp } from 'react-icons/gi' - TODO: Uncomment later (AR-2076)
import { RiFlag2Fill, RiWalletFill } from 'react-icons/ri'

export const TOP_PRODUCTS_NUMBER = 12

export const mapState = ({ authReducer, vendorReducer: { vendor } }) => ({
  analyticsToken: get(vendor, 'premium.analyticsToken', ''),
  vendorName: get(vendor, 'artisanName', '') || get(vendor, 'name', ''),
  totalProducts: get(vendor, 'productCounter') || 0,
  authReducer
})

/**
 * @param {Object} config
 * @param {Number} config.impressions
 * @param {Object} config.intl
 * @param {Function} config.intl.formatMessage
 * @param {Number} config.productViews
 * @param {Number} config.sellerScore
 * @param {Number} config.siteViews
 * @param {Number} config.totalProducts
 * @param {String} config.totalSales
 */
export const totalData = ({
  impressions = 0,
  intl = {},
  productViews = 0,
  sellerScore = 0,
  siteViews = 0
  // totalProducts = 0, - TODO: Uncomment later (AR-2076)
  // totalSales = '' - TODO: Uncomment later (AR-2118)
}) => {
  const items = [
    {
      content: impressions,
      icon: <RiWalletFill />,
      title: intl.formatMessage({ id: 'impressions' })
    },
    /* TODO: Uncomment later (AR-2118)
    {
      content: totalSales,
      icon: (
        <RiWalletFill />
      ),
      title: intl.formatMessage({ id: 'Total Sales' })
    },
    */
    /* TODO: Uncomment later (AR-2076)
    {
      content: totalProducts,
      icon: (
        <GiBedLamp />
      ),
      title: intl.formatMessage({ id: 'Total Products' })
    },
    */
    {
      content: productViews,
      icon: <AiTwotoneEye />,
      title: intl.formatMessage({ id: 'Product Views' })
    },
    {
      content: siteViews,
      icon: <RiFlag2Fill />,
      title: intl.formatMessage({ id: 'Site Views' })
    }
  ]

  if (sellerScore) {
    items.push({
      content: sellerScore,
      icon: <RiFlag2Fill />,
      title: intl.formatMessage({ id: 'Seller Score' })
    })
  }

  return items.map((item, _id) => ({
    ...item,
    _id
  }))
}
