import cn from 'classnames'
import { ProductSummary } from 'components'
import TitleIconList from 'components/lists/TitleIconList/TitleIconList'
import { get } from 'lodash'
import {
  array,
  arrayOf,
  bool,
  boolean,
  func,
  node,
  number,
  shape,
  string
} from 'prop-types'
import React from 'react'
import { FaPlug } from 'react-icons/fa'
import { FiBookOpen, FiVideo } from 'react-icons/fi'
import { GiCardboardBox, GiEarthAmerica, GiWoodPile } from 'react-icons/gi'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Col, Row } from 'reactstrap'
import { GUIDE_LINKS } from 'utils/constants'

import { fieldTitle } from './../forms/OrderProductForm/OrderProductForm.module.css'
import st from './OrderProduct.module.css'
const OrderProduct = ({
  intl,
  id,
  allowCarbCertification,
  children,
  className,
  customization,
  customizationFile,
  productURL,
  imagePath,
  name,
  quantity,
  classifications,
  shipAddress,
  woodenCrate,
  vendorCode
}) => {
  const mapCategoryToAdditionalFields = {
    lighting: (
      <p>
        <FaPlug />
        <FormattedMessage
          id="Electrification"
          values={{ countryCode: get(shipAddress, 'country.code') }}
          tagName={React.Fragment}
        />
      </p>
    )
  }

  const createDetails = () => {
    const productCategories = classifications
      .map((clas) => get(clas, 'taxon.name'))
      .join('/')
    return (
      <div className={st.details}>
        <p>
          <GiEarthAmerica /> {get(shipAddress, 'country.name')}
        </p>
        {Object.keys(mapCategoryToAdditionalFields).map((cat) => {
          return productCategories &&
            productCategories.toLowerCase().includes(cat)
            ? mapCategoryToAdditionalFields[cat]
            : null
        })}
        {allowCarbCertification && (
          <p>
            <GiWoodPile />
            <FormattedMessage id="CARB certification required" />
          </p>
        )}
        {woodenCrate && (
          <p>
            <GiCardboardBox />
            <FormattedMessage id="woodenCrateNeeded" />
          </p>
        )}
      </div>
    )
  }

  const OrderDetails = () => {
    return (
      <>
        <Col sm={3} xs={12}>
          <ProductSummary
            className={st.product}
            productURL={productURL}
            imageSrc={imagePath}
            mod={1}
            name={name}
            size={120}
            details={createDetails()}
            isOrder
            id={id}
            vendorCode={vendorCode}
          />
          {quantity > 0 && <p className={st.quantity}>Quantity: {quantity}</p>}
        </Col>
        <Col sm={3} xs={12} className={st.customization}>
          <label className={fieldTitle}>
            <FormattedMessage id="Customization" />:
          </label>
          <p className={st.text}>
            {customization || <FormattedMessage id="No customization" />}{' '}
          </p>
          {customizationFile && (
            <a
              className={st.text}
              href={customizationFile}
              rel="noopener noreferrer"
              target="_blank"
            >
              Customization file link
            </a>
          )}
        </Col>
      </>
    )
  }

  return (
    <Row className={cn(st.wrap, className)} tag="article">
      <Col xs={12}>
        <Row>
          <Col>
            <h2 className={st.header}>
              <FormattedMessage id="Product info" />:
            </h2>
          </Col>
        </Row>
        <Row>
          {React.cloneElement(children, {
            details: OrderDetails,
            allowCarbCertification,
            productId: id
          })}
        </Row>
      </Col>
      <Row>
        <Col>
          <TitleIconList
            className={st.fulfilmentLinks}
            items={[
              {
                icon: <FiBookOpen />,
                text: intl.formatMessage({ id: 'Fulfilment Instructions' }),
                path: GUIDE_LINKS.ordersManagement
              },
              {
                icon: <FiVideo />,
                text: intl.formatMessage({ id: 'Fulfilment Video Tutorial' }),
                path: GUIDE_LINKS.ordersVideoTutorial
              }
            ]}
          />
        </Col>
      </Row>
    </Row>
  )
}

OrderProduct.defaultProps = {
  id: '',
  children: null,
  className: '',
  customization: '',
  productURL: '',
  imagePath: '',
  name: '',
  price: '',
  quantity: 0,
  shipAddress: {},
  type: {},
  vendorCode: ''
}
OrderProduct.propTypes = {
  intl: shape({
    formatMessage: func
  }),
  id: string,
  children: node,
  className: string,
  customization: string,
  customizationFile: string,
  productURL: string,
  imagePath: string,
  name: string,
  price: string,
  quantity: number,
  type: shape({
    active: boolean,
    addDate: string,
    dataItems: array,
    name: shape({
      en: string,
      it: string
    }),
    _id: string
  }),
  shipAddress: shape({
    address1: string,
    address2: string,
    city: string,
    company: string,
    country: shape({
      id: number,
      code: string,
      name: string
    }),
    customer_VAT: string,
    first_name: string,
    id: number,
    last_name: string,
    phone: string,
    primary: boolean,
    state: shape({
      id: number,
      name: string,
      code: string
    }),
    state_name: string,
    zipcode: string
  }),
  classifications: arrayOf(
    shape({
      id: number,
      position: number,
      taxon: shape({
        id: number,
        name: string,
        parmalink: string,
        pretty_name: string,
        taxonomy_id: number,
        taxons: arrayOf(
          shape({
            id: number
          })
        )
      })
    })
  ),
  woodenCrate: bool,
  allowCarbCertification: bool,
  vendorCode: string
}

export default injectIntl(OrderProduct)
