import * as at from './types'

export const collectionGot = (collection = null) => ({
  payload: { collection },
  type: at.COLLECTION_GOT
})

export const productPendingId = (id = '') => ({
  payload: { id },
  type: at.PRODUCT_PENDING_ID
})

export const productPendingIdStop = (id = '') => ({
  payload: { id },
  type: at.PRODUCT_PENDING_ID_STOP
})

export const productTranslationGot = ({
  productId = '',
  translation = {}
} = {}) => ({
  payload: { productId, translation },
  type: at.PRODUCT_TRANSLATION_GOT
})

export const productsToTranslateGet = ({
  page,
  pages,
  productsToTranslate = []
}) => ({
  payload: {
    page,
    pages,
    productsToTranslate
  },
  type: at.PRODUCTS_TO_TRANSLATE_GET
})

export const vendorDataGot = (payload = {}) => {
  const { bio = '', vendorName = '', vendorSKU = '', webSite = '' } = payload

  return {
    payload: { bio, vendorName, vendorSKU, webSite },
    type: at.VENDOR_DATA_GOT
  }
}
