import { omit } from 'lodash'
import { arrayOf, number, shape, string } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Table } from 'reactstrap'
import { changeProductType } from 'redux/service/product-service'

import { readProductsId } from '../../redux/service/product-types-service'
import notify, { notifyPositions } from '../../utils/toast'
import Loading from '../loading/loading'
import Elements from './elements'
import st from './pricing-table.module.css'

const PricingTable = ({ data, fiscalRegimenPercent, lang }) => {
  const [productTypesArray, setProductTypes] = useState(null)
  const [pendingCategoryProductId, setPendingCategoryProductId] = useState({})

  useEffect(() => {
    readProductsId()
      .then(({ data }) => {
        const types = data
          .map(({ name, _id }) => ({
            id: _id,
            label: name[lang] || name.en || ''
          }))
          .filter(({ label }) => label)
          .sort((a, b) => (a.label < b.label ? -1 : 1))

        setProductTypes(types)
      })
      .catch(() => {
        notify(
          'error',
          `can not fetch data from server`,
          notifyPositions.bottom.center
        )
      })
  }, [])

  const handleCategoryChange = ({ productId, typeId } = {}) => {
    setPendingCategoryProductId({
      ...pendingCategoryProductId,
      [productId]: true
    })
    changeProductType(productId, typeId).then(() => {
      setPendingCategoryProductId(omit(pendingCategoryProductId, [productId]))
    })
  }

  return productTypesArray ? (
    <Table borderless>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="Category" />
          </th>
          <th>
            <FormattedMessage id="SKU" />
          </th>
          <th>
            <FormattedMessage id="Curr" />.
          </th>
          <th>
            <FormattedMessage id="Wholesale" />
          </th>
          <th>
            <FormattedMessage id="Retail" />
          </th>
          <th>
            <FormattedMessage id="Shipping" />
          </th>
          <th>
            <FormattedMessage id="Wooden Crate" />
          </th>
          <th>
            <FormattedMessage id="Margin" /> %
          </th>
          <th>
            <FormattedMessage id="Margin Tot" />
          </th>
          <th>
            <FormattedMessage id="Final" />
          </th>
          <th>
            <FormattedMessage id="Shipping" /> <FormattedMessage id="Final" />
          </th>
          <th>
            <FormattedMessage id="Actions" />
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map(({ isHeading, label, rowData, _id }) =>
          isHeading ? (
            <tr className={st.rowColName} key={_id}>
              <td colSpan={100}>{label}</td>
            </tr>
          ) : (
            <Elements
              element={rowData}
              fiscalRegimenPercent={fiscalRegimenPercent}
              key={rowData._id}
              pendingCategoryProductId={pendingCategoryProductId}
              productTypes={productTypesArray}
              onCategoryChange={handleCategoryChange}
            />
          )
        )}
      </tbody>
    </Table>
  ) : (
    <Loading />
  )
}

PricingTable.propTypes = {
  data: [],
  fiscalRegimenPercent: 0,
  lang: ''
}
PricingTable.propTypes = {
  data: arrayOf(shape({})),
  fiscalRegimenPercent: number,
  lang: string
}

export default PricingTable
