import cn from 'classnames'
import { OtherImagesList } from 'components/lists'
import { arrayOf, node, shape, string } from 'prop-types'
import React from 'react'
import { Col, Row } from 'reactstrap'

import st from './TranslationProduct.module.css'

const TranslationProduct = ({
  alt,
  children,
  className,
  otherPhotos,
  photo
}) => (
  <Row className={cn('rowWide', st.wrap, className)}>
    <Col md={6} xs={12}>
      <img alt={alt} className={st.img} src={photo} />
      <OtherImagesList className={st.otherImages} images={otherPhotos} />
    </Col>
    <Col md={6} xs={12}>
      {children}
    </Col>
  </Row>
)

TranslationProduct.defaultProps = {
  alt: '',
  children: null,
  className: '',
  otherPhotos: [],
  photo: ''
}
TranslationProduct.propTypes = {
  alt: string,
  children: node,
  className: string,
  otherPhotos: arrayOf(
    shape({
      alt: string,
      src: string
    })
  ),
  photo: string
}

export default TranslationProduct
