import cn from 'classnames'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { Nav, NavItem, NavLink } from 'reactstrap'

import st from './PageNav.module.css'

const PageNav = ({ className, items }) => (
  <Nav className={className}>
    {items.map(({ current, label, path, onClick }) => (
      <NavItem
        className={cn({
          [st.current]: current
        })}
        key={label}
      >
        {current ? (
          <span className={st.item}>{label}</span>
        ) : (
          <>
            {onClick ? (
              <NavLink className={st.item} onClick={onClick}>
                {label}
              </NavLink>
            ) : (
              <Link className={cn(st.item, 'nav-link')} to={path}>
                {label}
              </Link>
            )}
          </>
        )}
      </NavItem>
    ))}
  </Nav>
)
PageNav.defaultProps = {
  className: '',
  items: []
}
PageNav.propTypes = {
  className: string,
  items: arrayOf(
    shape({
      current: bool,
      label: string,
      path: string,
      onClick: func
    })
  )
}

export default PageNav
