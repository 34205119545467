import { cloneDeep, omit } from 'lodash'

import * as at from './types'

export const initialState = {
  bio: '',
  collection: null,
  page: 1,
  pages: 1,
  perPage: 20,
  productPendingIds: {},
  productsToTranslate: [],
  vendorName: '',
  vendorSKU: '',
  webSite: ''
}

export const reducer = (state, action) => {
  switch (action.type) {
    case at.COLLECTION_GOT:
    case at.PRODUCTS_TO_TRANSLATE_GET:
    case at.VENDOR_DATA_GOT:
      return {
        ...state,
        ...action.payload
      }

    case at.PRODUCT_PENDING_ID: {
      const { id } = action.payload

      if (state.productPendingIds[id] === true || !id) {
        return state
      }

      return {
        ...state,
        productPendingIds: {
          ...state.productPendingIds,
          [id]: true
        }
      }
    }

    case at.PRODUCT_PENDING_ID_STOP: {
      const { id } = action.payload
      const { productPendingIds } = state

      if (id && productPendingIds[id]) {
        return {
          ...state,
          productPendingIds: omit(productPendingIds, [id])
        }
      }

      return state
    }

    case at.PRODUCT_TRANSLATION_GOT: {
      const { productId, translation } = action.payload
      const { productsToTranslate } = state
      const productIndex = productsToTranslate.findIndex(
        (p) => p._id === productId
      )

      if (productIndex > -1) {
        const cloned = cloneDeep(productsToTranslate)
        const { translation: translationOld } = cloned[productIndex] || {}

        cloned[productIndex].translation = {
          ...translationOld,
          ...translation
        }

        return {
          ...state,
          productsToTranslate: cloned
        }
      }

      return state
    }

    default:
      throw new Error()
  }
}
