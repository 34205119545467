import st from 'components/forms/OrderProductForm/OrderProductForm.module.css'
import { ImagesList } from 'components/lists'
import { noop } from 'lodash'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import React from 'react'
import { Col } from 'reactstrap'
import { ACCEPTED_FILE_TYPES, ORDER_IMAGES_LIMIT } from 'utils/constants'

import InputFile from '../InputFile/InputFile'

export const FormImageList = ({
  imageInput = {},
  onChange = noop,
  onImageError = noop,
  imagesLoading = [],
  disableModify = false,
  imageListItems = [],
  onRemove = noop,
  imagesPending = {}
}) => {
  const imagesList = imageListItems.map((image, index) => {
    return {
      alt: `product photo ${imageInput.type}`,
      id: `${image}${index}`,
      path: image
    }
  })

  return (
    <Col key={imageInput.type} sm={6} xs={12}>
      <h3>{imageInput.label}</h3>
      <div className={st.imageInputWithList}>
        <InputFile
          multiple
          size={120}
          onChange={onChange}
          onError={onImageError}
          accept={ACCEPTED_FILE_TYPES.IMAGES}
          label={`Upload image ${imageInput.type}`}
          subLabel={`Max ${ORDER_IMAGES_LIMIT}`}
          isLoading={imagesLoading.includes(imageInput.type)}
          disableModify={disableModify}
        />

        <ImagesList
          items={imagesList}
          className={st.imageList}
          itemClassName={st.imageListItem}
          square
          onClose={onRemove}
          pendingIds={imagesPending}
        />
      </div>
    </Col>
  )
}

FormImageList.propTypes = {
  imageInput: shape({
    label: string,
    type: string
  }),
  onChange: func,
  onImageError: func,
  imagesLoading: arrayOf(string),
  disableModify: bool,
  imageListItems: arrayOf(string),
  onRemove: func,
  imagesPending: shape({})
}
export default FormImageList
