import { InputFile } from 'components/formParts'
import { get } from 'lodash'
import { array, bool, func, shape } from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { DisapproveCollection } from 'redux/actions/collection-action'
import { AddProductsFromCollection } from 'redux/actions/product-action'
import { isAdmin, isManager } from 'utils/auth'
import { ACCEPTED_FILE_TYPES } from 'utils/constants'
import { pluralWord } from 'utils/utils'

import { GotProductsDataIds } from '../../redux/actions/product-types-action'
import ProductDataTypesAutocomplete from '../product-dataType-select/product-data-types-autocomplete'
import Style from './css-UI3.module.css'

class UploadFilesUi3 extends Component {
  state = {
    products: [],
    type: ''
  }

  componentDidUpdate(prevProps) {
    const productsLength = get(
      this.props,
      'productReducer.GottenProducts.length',
      0
    )
    const productsLengthPrev = get(
      prevProps,
      'productReducer.GottenProducts.length',
      0
    )

    if (productsLengthPrev < productsLength) {
      this.setState({ products: [] })
    }
  }

  getCollectionId = () => {
    const {
      match: { params }
    } = this.props

    return params.collection
  }

  selectElement = (type) => {
    const { canAdd } = this.props

    if (canAdd) {
      this.setState({ type })
    }
  }

  changeProdInput = (e) => {
    const { canAdd } = this.props

    if (canAdd) {
      this.setState({ products: e.target.files })
    }
  }

  deleteProductFiles = () => {
    this.setState({ products: [] })
  }

  addProducts = () => {
    const { AddProducts, canAdd, DisapproveCollection } = this.props

    if (!canAdd) {
      return
    }

    const { products, type } = this.state
    const collectionId = this.getCollectionId()

    DisapproveCollection(collectionId)
    AddProducts(products, type._id || type.id, collectionId)
  }

  render() {
    const { canAdd, intl } = this.props
    const { products, type } = this.state
    const productsLength = get(products, 'length', 0)
    const wEnd = productsLength > 1 ? 's' : ''
    const prodMessageId = pluralWord('Product', wEnd)
    const fileLabel = productsLength
      ? `${intl.formatMessage({
          id: 'You can add'
        })} ${productsLength} ${intl.formatMessage({ id: prodMessageId })}`
      : intl.formatMessage({ id: 'Choose files' })
    const fileSubLabel = productsLength ? '' : '(1400X1400)'
    const uploadImageId = pluralWord('Upload Image', wEnd)

    return (
      <div className={Style.mainDiv}>
        <Row className={Style.title}>
          <Col md={12}>
            <FormattedMessage id="Add products" />
          </Col>
        </Row>
        <Row className="rowWide">
          <Col md={12}>
            <ProductDataTypesAutocomplete selectElement={this.selectElement} />
          </Col>
        </Row>
        <Row className="rowWide">
          <Col md={12}>
            <InputFile
              multiple
              accept={ACCEPTED_FILE_TYPES.IMAGES}
              allowDeleting={productsLength > 0}
              className={Style.fileInput}
              height={178}
              label={fileLabel}
              subLabel={fileSubLabel}
              width="100%"
              onChange={this.changeProdInput}
              onClose={this.deleteProductFiles}
            />
          </Col>
        </Row>
        <Row className="rowWide">
          <Col md={12}>
            <button
              className="btn btn-primary"
              onClick={this.addProducts}
              disabled={!canAdd || !productsLength || !type}
            >
              <FormattedMessage id={uploadImageId} />
            </button>
          </Col>
        </Row>
      </div>
    )
  }
}

UploadFilesUi3.propTypes = {
  match: shape({
    params: shape({
      collection: array
    })
  }),
  canAdd: bool,
  AddProducts: func,
  DisapproveCollection: func,
  intl: shape({
    formatMessage: func
  })
}

const mapStateToProps = ({
  authReducer,
  collectionReducer,
  productReducer
}) => ({
  canAdd: isAdmin(authReducer) || isManager(authReducer),
  collectionReducer,
  productReducer
})

const mapDispatchToProps = (dispatch) => ({
  DisapproveCollection: (id) => dispatch(DisapproveCollection(id)),
  AddProducts: (files, dataType, id) =>
    dispatch(AddProductsFromCollection(files, dataType, id)),
  GotProductsDataIds: () => dispatch(GotProductsDataIds())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(UploadFilesUi3))
