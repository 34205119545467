import { TitleIconContent } from 'components/lists'
import { noop } from 'lodash'
import { func, node, shape, string } from 'prop-types'
import React from 'react'
import { FaEuroSign, FaMapMarkerAlt, FaRegCalendar } from 'react-icons/fa'
import { injectIntl } from 'react-intl'
import { Col, Row } from 'reactstrap'

import st from './Part.module.css'

const Part = ({ children, className, country, intl, shipBy, netToVendor }) => {
  const isLoaded = shipBy && country && netToVendor
  const listItems = [
    {
      content: shipBy,
      icon: <FaRegCalendar />,
      title: intl.formatMessage({ id: 'Customer expected shipping date' })
    },
    {
      content: country,
      icon: <FaMapMarkerAlt />,
      title: intl.formatMessage({ id: 'Country' })
    },
    {
      content: netToVendor,
      icon: <FaEuroSign />,
      title: intl.formatMessage({ id: 'Net to vendor' })
    }
  ].map((listItem) => ({ ...listItem, _id: listItem.title }))

  return (
    <div className={className}>
      {isLoaded ? (
        <Row className="rowWide">
          <Col xs={12}>
            <TitleIconContent
              cols={{ xs: 12, sm: 3 }}
              items={listItems}
              mod={1}
            />
          </Col>
        </Row>
      ) : null}
      <div className={st.children}>{children}</div>
    </div>
  )
}

Part.defaultProps = {
  children: null,
  className: '',
  country: '',
  listPrice: '',
  netPrice: '',
  shipBy: '',
  statusInit: '',
  onCancel: noop,
  onDelete: noop,
  onSave: noop
}
Part.propTypes = {
  children: node,
  className: string,
  country: string,
  listPrice: string,
  netPrice: string,
  shipBy: string,
  statusInit: string,
  onCancel: func,
  onDelete: func,
  onSave: func,
  intl: shape({
    formatMessage: func
  }),
  netToVendor: string
}

export default injectIntl(Part)
