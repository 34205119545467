import cn from 'classnames'
import { bool, func, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { FaEdit, FaRegEye, FaTrashAlt } from 'react-icons/fa'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import {
  Button,
  ListGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from 'reactstrap'
import {
  DeleteProductData,
  GetProductsData,
  ShowEditModal
} from 'redux/actions/product-types-action'
import { getLanguage } from 'utils/language'

import Style from './list-product-data-types.module.css'
import UpdateAdditionalFields from './update-additional-fields'

class ListProductDataTypes extends Component {
  static defaultProps = {
    className: ''
  }

  static propTypes = {
    className: string
  }

  state = {
    infoModal: false,
    productData: [],
    currentProductDataId: '',
    deleteModal: false
  }

  componentDidMount() {
    this.props.Get()
  }

  componentDidUpdate(prevProps) {
    const {
      productDataReducer: {
        added: prevAdded,
        deleted: prevDeleted,
        updatedProductData: prevUpdatedProductData
      }
    } = prevProps
    const {
      productDataReducer: { added, deleted, updatedProductData }
    } = this.props

    if (
      (deleted && !prevDeleted) ||
      (added && !prevAdded) ||
      (updatedProductData && !prevUpdatedProductData)
    ) {
      this.props.Get()
    }

    if (deleted && !prevDeleted) {
      this.closeDeleteModal()
    }
  }

  renderInfoValues = () =>
    this.state.productData
      ? this.state.productData.map((data) => (
          <li
            className={['list-group-item', Style.vendorInfo].join(' ')}
            key={data.name}
          >
            <p className={Style.vendorInfoKey}>{data.name}</p> :
            <p className={Style.vendorInfoValue}> {data.type}</p>
          </li>
        ))
      : ''

  renderDataItemNames = (dataItems = []) => {
    const { language } = this.props

    return dataItems
      .map(({ name, ...dataItem }) => name || dataItem[language])
      .join(', ')
  }

  toggleInfoModal = (productData) =>
    this.setState((prevState) => ({
      infoModal: !prevState.infoModal,
      productData: productData.dataItems,
      currentProductDataId: productData._id
    }))

  closeDeleteModal = () => this.setState({ deleteModal: false })

  openDeleteModal = (id) =>
    this.setState({ deleteModal: true, currentProductDataId: id })

  openEditModal = (productData) =>
    this.props.ShowModal(true, productData.dataItems, productData._id)

  renderList = () => {
    const {
      language,
      productDataReducer: { gotProductsData, productsData }
    } = this.props

    return gotProductsData ? (
      productsData.map((element) => {
        const { _id, dataItems, name } = element
        const nameToShow =
          typeof name === 'string' ? name : name[language] || name.en || name.it

        return (
          <tr key={_id}>
            <th scope="row">{nameToShow}</th>
            <td>{this.renderDataItemNames(dataItems)}</td>
            <td>
              <i
                className={Style.actionButton}
                id={`see-${_id}`}
                onClick={this.toggleInfoModal.bind(this, element)}
              >
                <FaRegEye color="rgb(250, 190, 58)" />
              </i>
              <i
                className={Style.actionButton}
                id={`see-${_id}`}
                onClick={this.openEditModal.bind(this, element)}
              >
                <FaEdit color="#b79961" />
              </i>
              <i
                className={Style.actionButton}
                id={`delete-${_id}`}
                onClick={this.openDeleteModal.bind(this, _id)}
              >
                <FaTrashAlt color="#f63636" />
              </i>
            </td>
          </tr>
        )
      })
    ) : (
      <tr />
    )
  }

  render() {
    const state = this.state
    const props = this.props
    const { className } = this.props

    return (
      <div className={cn(Style.mainDiv, className)}>
        <Table hover className={Style.table}>
          <thead>
            <tr>
              <th className={Style.forIds}>#</th>
              <th>Items</th>
              <th className={Style.forIds}>Actions</th>
            </tr>
          </thead>
          <tbody>{this.renderList()}</tbody>
        </Table>
        <Modal
          isOpen={state.infoModal}
          toggle={this.toggleInfoModal}
          className={props.className}
        >
          <ModalHeader toggle={this.toggleInfoModal}>
            <p> {state.currentProductDataId} </p>
          </ModalHeader>
          <ModalBody>
            <ListGroup>{this.renderInfoValues()}</ListGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleInfoModal}>
              <FormattedMessage id={'Cancel'} />
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={state.deleteModal}
          toggle={this.closeDeleteModal}
          className={props.className}
        >
          <ModalHeader toggle={this.closeDeleteModal}>
            <FormattedMessage id={'Delete message'} />
            <p className={Style.inline}> {state.currentProductDataId}?</p>
          </ModalHeader>
          <ModalFooter>
            <Button
              color="danger"
              onClick={props.Delete.bind(this, state.currentProductDataId)}
            >
              <FormattedMessage id={'Delete'} />
            </Button>
            <Button color="primary" onClick={this.closeDeleteModal}>
              <FormattedMessage id={'Cancel'} />
            </Button>
          </ModalFooter>
        </Modal>
        <UpdateAdditionalFields />
      </div>
    )
  }
}
ListProductDataTypes.propTypes = {
  Get: func,
  productDataReducer: shape({
    added: bool,
    deleted: bool,
    updatedProductionData: shape({})
  }),
  language: string,
  ShowModal: func,
  Delete: func
}
const mapStateToProps = ({ languageReducer, productDataReducer }) => ({
  language: getLanguage(languageReducer),
  productDataReducer
})

const mapDispatchToProps = (dispatch) => ({
  Get: (data) => dispatch(GetProductsData(data)),
  Delete: (id) => dispatch(DeleteProductData(id)),
  ShowModal: (status, items, id) => dispatch(ShowEditModal(status, items, id))
})

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ListProductDataTypes)
)
