import axios from 'axios/index'
import cookie from 'react-cookies'
import { mapError } from 'utils/errors'

import apiUrl from './apiUrl'

const addPoductData = (productType) =>
  axios.post(`${apiUrl}/api/product-data`, productType, {
    headers: { token: cookie.load('artemest') }
  })

const readProductsId = () =>
  axios.get(`${apiUrl}/api/product-data/products-id`, {
    headers: { token: cookie.load('artemest') }
  })

const read = (id) =>
  axios.get(`${apiUrl}/api/product-data/${id}`, {
    headers: { token: cookie.load('artemest') }
  })

const readProductsData = async () => {
  const apiPath = `${apiUrl}/api/product-data/`

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return { data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

const removeProductsData = (id) =>
  axios.delete(`${apiUrl}/api/product-data/${id}`, {
    headers: { token: cookie.load('artemest') }
  })

/**
 * @param {Object} config
 * @param {Array} config.dataItems
 * @param {String} config.id
 */
const editProductData = async (config = {}) => {
  const { dataItems = [], id = '', mandatoryPackaging = false } = config
  const apiPath = `${apiUrl}/api/product-data/${id}`

  try {
    const { data, status } = await axios.put(
      apiPath,
      { dataItems, mandatoryPackaging },
      {
        headers: { token: cookie.load('artemest') }
      }
    )

    if (status === 200) {
      return { data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

export {
  addPoductData,
  editProductData,
  read,
  readProductsData,
  readProductsId,
  removeProductsData
}
