import { shape, string } from 'prop-types'
import queryString from 'query-string'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row
} from 'reactstrap'

import Approved from '../components/approver-dashboard-components/approved'
import ToApprove from '../components/approver-dashboard-components/to-approve'
import { createArray } from '../utils/utils'
import Style from './styles/approver-dashboard.module.css'

class ApproverDashboard extends Component {
  state = {
    pages: 7,
    currentPage: 1
  }

  componentDidMount() {
    const currentPage = parseInt(
      queryString.parse(this.props.location.search).page
    )
    if (currentPage) this.setState({ currentPage })
  }

  // TODO create routes, after this it will be remove
  toApprove = [
    {
      _id: 'GEO_000',
      products: 'products',
      vendor: 'vendor',
      area: 'arean',
      exclusive: 'exc'
    },
    {
      _id: 'GEO_001',
      products: 'products1',
      vendor: 'vendor1',
      area: 'area1',
      exclusive: 'exc 1'
    },
    {
      _id: 'GEO_002',
      products: 'products3',
      vendor: 'vendor3',
      area: 'area3',
      exclusive: 'exc 2'
    }
  ]

  // TODO create routes, after this it will be remove
  approvedAlready = [
    {
      key: 1,
      _id: '5d38512408ce8e62486b8aa0',
      products: 'products',
      vendor: 'SASSOZGE',
      collection: 'collection'
    },
    {
      key: 2,
      _id: '5d38512408ce8e62486b8aa0',
      products: 'products1',
      vendor: 'SASSOZGE',
      collection: 'collection1'
    },
    {
      key: 3,
      _id: '5d38512408ce8e62486b8aa0',
      products: 'products3',
      vendor: 'SASSOZGE',
      collection: 'collection3'
    }
  ]

  paginationButtons = () =>
    createArray(this.state.currentPage, this.state.pages).map((index) => (
      <PaginationItem key={index}>
        <PaginationLink
          className={[
            index === this.state.currentPage ? Style.currentPage : '',
            Style.paginationLinks
          ].join(' ')}
          href={`/approver-dashboard/:approver?page=${index}`}
        >
          {index}
        </PaginationLink>
      </PaginationItem>
    ))

  render() {
    return (
      <Fragment>
        <div className={Style.main}>
          <Row>
            <Col>
              <ToApprove elements={this.toApprove} />
            </Col>
            <Col>
              <Approved elements={this.approvedAlready} />
            </Col>
          </Row>
          <Row>
            <Pagination
              className={Style.pagination}
              aria-label="Page navigation example"
            >
              <PaginationItem disabled={this.state.currentPage === 1}>
                <PaginationLink
                  className={Style.paginationLinks}
                  previous
                  href={`/approver-dashboard/:approver?page=${
                    this.state.currentPage - 1
                  }`}
                />
              </PaginationItem>
              {this.paginationButtons()}
              <PaginationItem
                disabled={this.state.currentPage === this.state.pages}
              >
                <PaginationLink
                  className={Style.paginationLinks}
                  next
                  href={`/approver-dashboard/:approver?page=${
                    this.state.currentPage + 1
                  }`}
                />
              </PaginationItem>
            </Pagination>
          </Row>
        </div>
      </Fragment>
    )
  }
}

ApproverDashboard.propTypes = {
  location: shape({
    search: string
  })
}
// TODO create route
const mapStateToProps = () => ({})

export default connect(mapStateToProps)(ApproverDashboard)
