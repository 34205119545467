import { get, isEqual, omit, pick } from 'lodash'
import { readContact } from 'redux/service/contact-service'
import {
  getOrderByNumber,
  proformaStatusService,
  removePhoto,
  saveImages,
  updateOrderShippingData,
  updatePickupAddressAndTimeService,
  uploadFile
} from 'redux/service/order-service'
import { getVendorById } from 'redux/service/vendor-service'
import { ORDER_IMAGES_LIMIT } from 'utils/constants'
import { notifyMappedError } from 'utils/errors'
import { getChangedValues } from 'utils/orders'
import notify, { showNotify } from 'utils/toast'

import {
  initAc,
  initPickupForm,
  orderCertificationAdd,
  orderCertificationPendingAdd,
  orderCertificationPendingRemove,
  orderCertificationRemove,
  orderDataLoadingAdd,
  orderDataLoadingRemove,
  orderImageLoadingAdd,
  orderImageLoadingRemove,
  orderImagePendingAdd,
  orderImagePendingRemove,
  orderPickupSubmitting,
  orderProfomaPendingAdd,
  orderProfomaPendingRemove,
  orderProformaAdd,
  orderProformaDelete,
  pickupFormLoading,
  setLastSubmittedValues,
  setOrdersLoading
} from './actions'
import { mapOrderInit } from './utils'

/**
 * @param {Object} config
 * @param {String} config.dispatch
 * @param {String} config.dispatchRedux
 * @param {String} config.orderNumber
 */
export const init = (config = {}) => {
  const { dispatch, orderNumber, vendor } = config
  dispatch(pickupFormLoading(true))

  const promises = [getOrderByNumber({ orderNumber }), getVendorById(vendor)]
  Promise.all(promises)
    .then(async ([order, data, error]) => {
      if (error) {
        notifyMappedError(error)
      }
      const { data: vendorData } = data
      if (order && vendorData) {
        const mapped = mapOrderInit(order.order, vendor, vendorData, dispatch)
        dispatch(initAc(mapped))
      }

      if (vendorData) {
        const contactId = vendorData.contact
        const { data: contactData, status } = await readContact(contactId)
        const pickupForm =
          status === 200 ? contactData.pickupAddressAndTime : {}
        const contactForOrderManagement =
          contactData.contactForOrderManagement || {}
        contactForOrderManagement.shippingLabelRecipent =
          contactData.contactForOrderManagement &&
          contactData.contactForOrderManagement.email

        // Renaming object's key from "citta" to "city" and add address2 empty string fallback
        const renamedPickupForm = omit(
          {
            ...pickupForm,
            city: get(pickupForm, 'citta', ''),
            address2: get(pickupForm, 'address2', '')
          },
          ['citta']
        )
        dispatch(
          initPickupForm({
            pickupFormDataFromContact: {
              ...renamedPickupForm,
              ...{
                ...contactForOrderManagement,
                country: get(vendorData, 'country', null)
              }
            }
          })
        )
      }
    })
    .catch((err) => {
      notifyMappedError(err)
    })
    .finally(() => {
      dispatch(pickupFormLoading(false))
      dispatch(setOrdersLoading(false))
    })
}

/**
 * @param {Object} config
 * @param {Function} dispatch
 */
export const updateShipmentData = (
  config = {},
  lastSubmittedValues,
  dispatch
) => {
  const { productId } = config
  const payloadConfig = pick(
    config,
    'productId',
    'orderNumber',
    'shipmentNumber'
  )

  const changedValues = {
    ...getChangedValues(config, lastSubmittedValues || {}),
    ...payloadConfig
  }
  const noChanges = isEqual(payloadConfig, changedValues)
  if (noChanges) {
    notify('info', 'No changes')
  } else {
    dispatch(orderDataLoadingAdd(productId))
    updateOrderShippingData(changedValues).then(({ data, error }) => {
      if (error) {
        notifyMappedError(error)
      }
      if (data) {
        showNotify({ message: 'Data successfully updated' })
        dispatch(setLastSubmittedValues({ data: config, productId }))
      }
      dispatch(orderDataLoadingRemove(productId))
    })
  }
}

/**
 * Function is reponsible for handling image upload of packaged and ready items
 * to s3 and saving the urls to Formik's form values
 * @param {Object}    config
 * @param {FileList}  config.imageFiles
 * @param {String}    config.type
 * @param {Object}    config.values -- Formik form values
 * @param {Function}  config.onDone-- Promise callback
 * @param {Function}  dispatch
 */
export const addImages = (config = {}, dispatch, onDone) => {
  const { imageFiles, type, values } = config
  if (imageFiles.length === 0) {
    return
  }
  let imagesToAdd = -(values[`images${type}`].length - ORDER_IMAGES_LIMIT)
  const formData = new FormData()
  const rejectedImages = []
  imageFiles.forEach((image) => {
    if (imagesToAdd > 0) {
      formData.append('image', image)
      imagesToAdd -= 1
    } else {
      rejectedImages.push(image.name)
    }
  })
  dispatch(orderImageLoadingAdd(type))
  if (rejectedImages.length > 0) {
    showNotify({
      isError: true,
      message: `Max 10 images, cannot add ${
        rejectedImages.length
      } images: ${rejectedImages.join(', ')}`
    })
  }
  saveImages({ formData }).then(({ error, fileUrls }) => {
    if (error) {
      notifyMappedError({ message: error.message })
    }
    if (fileUrls && fileUrls.length > 0) {
      onDone({
        name: `images${type}`,
        newValues: [...values[`images${type}`], ...fileUrls]
      })
    }
    dispatch(orderImageLoadingRemove(type))
  })
}

export const getPickupData = async (config = {}) => {
  const { dispatch, vendorId } = config
  dispatch(pickupFormLoading(true))
  try {
    const { data } = await getVendorById(vendorId)
    if (data) {
      const contactId = data.contact
      const { data: contactData, status } = await readContact(contactId)
      const pickupForm = status === 200 ? contactData.pickupAddressAndTime : {}
      const contactForOrderManagement =
        contactData.contactForOrderManagement || {}
      contactForOrderManagement.shippingLabelRecipent =
        contactData.contactForOrderManagement.email

      // Renaming object's key from "citta" to "city" and add address2 empty string fallback
      const renamedPickupForm = omit(
        {
          ...pickupForm,
          city: get(pickupForm, 'citta', ''),
          address2: get(pickupForm, 'address2', '')
        },
        ['citta']
      )
      dispatch(
        initPickupForm({
          pickupFormDataFromContact: {
            ...renamedPickupForm,
            ...contactForOrderManagement
          }
        })
      )
    }
  } catch (err) {
    notifyMappedError(err)
  } finally {
    dispatch(pickupFormLoading(false))
  }
}

/**
 *
 * @param {Object} config
 * @param {Function} config.setFieldValue
 * @param {String} config.type
 * @param {Object} config.values
 * @param {String} config.path
 * @param {Function} dispatch
 */
export const removeImages = async (config = {}, dispatch, onDone) => {
  const {
    type,
    values,
    path,
    orderManagementId,
    id,
    productId,
    lastSubmittedValues
  } = config
  dispatch(orderImagePendingAdd(id))
  try {
    const { error, data } = await removePhoto({
      type,
      url: path,
      orderManagementId
    })
    if (error) throw error
    if (data) {
      const newLastSubmittedValues = {
        ...lastSubmittedValues,
        [`images${type}`]: lastSubmittedValues[`images${type}`].filter(
          (img) => img !== path
        )
      }
      dispatch(
        setLastSubmittedValues({ data: newLastSubmittedValues, productId })
      )
      onDone({
        name: `images${type}`,
        newValues: values[`images${type}`].filter((image) => image !== path)
      })
    }
  } catch (err) {
    showNotify({ isError: true, message: 'Unable to remove image' })
  } finally {
    dispatch(orderImagePendingRemove(id))
  }
}

/**
 *
 * @param {Object} config
 * @param {String} config.productId
 * @param {FileList} config.files
 * @param {Function} dispatch
 * @returns {Promise}
 */
export const uploadFilesProforma = async (config = {}, dispatch, onDone) => {
  const { productId } = config
  dispatch(orderProfomaPendingAdd(productId))
  try {
    const { error, data } = await uploadFile(config)
    if (error) throw error
    if (data) {
      dispatch(orderProformaAdd({ productId, url: data.fileUrl }))
      onDone({ url: data.fileUrl })
    }
  } catch (err) {
    showNotify({ isError: true, message: 'Unable to upload file' })
    onDone({ error: 'Unable to upload file' })
  } finally {
    dispatch(orderProfomaPendingRemove(productId))
  }
}

/**
 *
 * @param {Object} config
 * @param {String} config.productId
 * @param {FileList} config.files
 * @param {Function} dispatch
 * @returns {Promise}
 */
export const uploadFilesCertification = async (
  config = {},
  dispatch,
  onDone
) => {
  const { productId } = config
  dispatch(orderCertificationPendingAdd(productId))
  try {
    const { error, data } = await uploadFile(config)
    if (error) throw error
    if (data) {
      dispatch(orderCertificationAdd({ productId, url: data.fileUrl }))
      onDone({ url: data.fileUrl })
    }
  } catch (err) {
    showNotify({ isError: true, message: 'Unable to upload file' })
    onDone({ error: 'Unable to upload file' })
  } finally {
    dispatch(orderCertificationPendingRemove(productId))
  }
}
/**
 *
 * @param {Object} config
 * @param {String} config.productId
 * @param {Function} dispatch
 * @returns {Promise}
 */
export const removeFilesProforma = async (config = {}, dispatch, onDone) => {
  const { productId } = config
  dispatch(orderProformaDelete(productId))
  onDone({ url: '' })
}

/**
 *
 * @param {Object} config
 * @param {String} config.productId
 * @param {Function} dispatch
 * @returns {Promise}
 */
export const removeFilesCertification = async (
  config = {},
  dispatch,
  onDone
) => {
  const { productId } = config
  dispatch(orderCertificationRemove(productId))
  onDone({ url: '' })
}

/**
 * @param {String} productId
 * @param {String} orderManagementId
 * @param {Function} dispatch
 * @param {Function} setFieldValue
 * @returns {Promise}
 */
export const changeProformaStatus = async (
  type,
  productId,
  orderManagementId,
  dispatch,
  setFieldValue
) => {
  dispatch(orderProfomaPendingAdd(productId))
  try {
    const { error, data } = await proformaStatusService({
      orderManagementId,
      type
    })
    if (error) throw error
    if (data) {
      showNotify({ message: 'Status changed' })
      setFieldValue('proformaStatus', type)
    }
  } catch (error) {
    showNotify({ isError: true, message: 'Unable to change status' })
  } finally {
    dispatch(orderProfomaPendingRemove(productId))
  }
}

/**
 *
 * @param {Object} config
 * @param {String} config.orderNumber
 * @param {String} config.vendorId
 * @param {Object} config.formData -- Order's Pickup Location and Time form Data
 * @param {Function} dispatch
 */
export const updatePickupAddressAndTime = async (config, dispatch) => {
  dispatch(orderPickupSubmitting(true))
  try {
    const { error } = await updatePickupAddressAndTimeService(config)
    if (error) throw error
    showNotify({ message: 'Pickup address and time successfully updated' })
  } catch (error) {
    showNotify({ isError: true, message: 'Unable to update the form' })
  } finally {
    dispatch(orderPickupSubmitting(false))
  }
}
