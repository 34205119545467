import * as at from './action-types'

export const impressionsSetAc = (impressions = 0) => ({
  payload: { impressions },
  type: at.IMPRESSIONS_SET
})

export const siteViewsSetAc = (siteViews = 0) => ({
  payload: { siteViews },
  type: at.SITE_VIEWS_SET
})

export const topSavedProductsGotAc = (products = []) => ({
  payload: { topSavedProducts: products },
  type: at.TOP_SAVED_PRODUCTS_GOT
})

export const topSavedProductsPendingAc = (pending = false) => ({
  payload: { topSavedProductsPending: pending },
  type: at.TOP_SAVED_PRODUCTS_PENDING
})

export const topViewedProductsGotAc = (products = []) => ({
  payload: { topViewedProducts: products },
  type: at.TOP_VIEWED_PRODUCTS_GOT
})

export const topViewedProductsPendingAc = (pending = false) => ({
  payload: { topViewedProductsPending: pending },
  type: at.TOP_VIEWED_PRODUCTS_PENDING
})

export const totalProductDataPendingAc = (pending = false) => ({
  payload: { totalProductDataPending: pending },
  type: at.TOTAL_PRODUCT_DATA_PENDING
})

export const totalProductDataSetAc = ({ totalProductViews = 0 } = {}) => ({
  payload: { totalProductViews },
  type: at.TOTAL_PRODUCT_DATA_SET
})

export const totalSalesSetAc = (totalSales = '') => ({
  payload: { totalSales },
  type: at.TOTAL_SALES_SET
})
