import {
  canApproveTranslations,
  canUpdateTranslations,
  getUserType
} from 'utils/auth'

export const mapState = ({ authReducer, languageReducer: { lang } }) => {
  const cat = canApproveTranslations(authReducer)
  const cut = canUpdateTranslations(authReducer)

  return {
    hideAccept: !cat,
    hideProofReading: !cut,
    hideReject: !cat,
    hideSaveDraft: !cut,
    lang,
    userType: getUserType(authReducer)
  }
}
