import {
  BRAINTREE_CLIENT_TOKEN_SET,
  BRAINTREE_DEVICE_DATA_SET,
  BRAINTREE_IS_NOTICE_PERIOD,
  BRAINTREE_PLAN_PENDING_ID,
  BRAINTREE_PLANS_GET,
  BRAINTREE_SUBSCRIPTIONS_CANCELED_IDS,
  BRAINTREE_SUBSCRIPTIONS_GET
} from './action-types'

export const braintreeClientTokenSet = (clientToken = '') => ({
  payload: { clientToken },
  type: BRAINTREE_CLIENT_TOKEN_SET
})

export const braintreeDeviceDataSet = (deviceData = '') => ({
  payload: { deviceData },
  type: BRAINTREE_DEVICE_DATA_SET
})

export const braintreeIsNoticePeriod = (isNoticePeriod = '') => ({
  payload: { isNoticePeriod },
  type: BRAINTREE_IS_NOTICE_PERIOD
})

export const braintreePlansGet = (plans = []) => ({
  payload: { plans },
  type: BRAINTREE_PLANS_GET
})

export const braintreePlanPendingId = (planPendingId = '') => ({
  payload: { planPendingId },
  type: BRAINTREE_PLAN_PENDING_ID
})

export const braintreeSubscriptionsCanceledIds = (ids = []) => ({
  payload: { ids },
  type: BRAINTREE_SUBSCRIPTIONS_CANCELED_IDS
})

export const braintreeSubscriptionsGet = (subscriptions = []) => ({
  payload: { subscriptions },
  type: BRAINTREE_SUBSCRIPTIONS_GET
})
