/* eslint-disable no-unused-vars */
import { get, keys, noop, round, take, uniqueId, values } from 'lodash'
import React from 'react'
import { AiFillHeart, AiTwotoneEye } from 'react-icons/ai'
import { getCubeData } from 'redux/service/analytics-service'
import { CURRENCIES } from 'utils/constants'
import { notifyMappedError } from 'utils/errors'
import { formatPrice } from 'utils/utils'

import {
  impressionsSetAc,
  siteViewsSetAc,
  topSavedProductsGotAc,
  topSavedProductsPendingAc,
  topViewedProductsGotAc,
  topViewedProductsPendingAc,
  totalProductDataPendingAc,
  totalProductDataSetAc,
  totalSalesSetAc
} from './actions'
import { mapTopProducts, TOP_PRODUCTS_NUMBER } from './config'

/**
 * @param {Object} config
 * @param {String} config.analyticsToken
 * @param {Boolean} config.areViewed
 * @param {Array} config.dimensions
 * @param {Function} config.dispatch
 * @param {Array} config.measures
 * @param {Object} config.queryOrder
 * @param {Array} config.timeDimensions
 */
export const getTopProductsMw = ({
  analyticsToken = '',
  areViewed = false,
  dimensions = [],
  dispatch = noop,
  measures = [],
  queryOrder = {},
  timeDimensions = []
} = {}) => {
  if (!analyticsToken) {
    return
  }

  const gotAc = areViewed ? topViewedProductsGotAc : topSavedProductsGotAc
  const pendingAc = areViewed
    ? topViewedProductsPendingAc
    : topSavedProductsPendingAc

  dispatch(pendingAc(true))
  getCubeData({
    analyticsToken,
    dimensions,
    measures,
    queryOrder,
    timeDimensions
  })
    .then(async ({ data, error }) => {
      if (error) {
        notifyMappedError(error)

        return
      }

      const topProductItems = take(
        get(data, 'data', []),
        TOP_PRODUCTS_NUMBER
      ).map((tpi) => {
        const sku = tpi['Product.sku']

        return {
          sku,
          name: tpi['Product.product_name'] || sku,
          imageUrl: tpi['Product.image_link'],
          alt: '',
          path: `/product-detail/${sku}`,
          _id: uniqueId(sku),
          hits:
            (areViewed
              ? +tpi['Product.detail_views']
              : +tpi['Product.interactions']) ?? 0,
          hitsIcon: areViewed ? <AiTwotoneEye /> : <AiFillHeart />
        }
      })

      dispatch(gotAc(topProductItems))
    })
    .finally(() => {
      dispatch(pendingAc())
    })
}

/**
 * @param {Object} config
 * @param {String} config.analyticsToken
 * @param {Function} config.dispatch
 */
export const getTotalDataMw = ({
  analyticsToken = '',
  dispatch = noop
} = {}) => {
  if (!analyticsToken) {
    return
  }

  const queries = {
    impressions: {
      measures: ['Product.list_views'],
      timeDimensions: [
        {
          dateRange: 'Last 90 days',
          dimension: 'Product.date'
        }
      ]
    },
    productViews: {
      measures: ['Product.detail_views'],
      timeDimensions: [
        {
          dateRange: 'Last 90 days',
          dimension: 'Product.date'
        }
      ]
    },
    siteViews: {
      measures: ['ArtisanPerformance.site_views'],
      timeDimensions: [
        {
          dateRange: 'Last 90 days',
          dimension: 'ArtisanPerformance.date'
        }
      ]
    }
  }
  const queriesKeys = keys(queries)
  const promises = values(queries).map((query) =>
    getCubeData({
      analyticsToken,
      ...query
    })
  )

  dispatch(totalProductDataPendingAc(true))

  Promise.all(promises).then((results) => {
    results.forEach(({ data, error }, idx) => {
      if (error) {
        notifyMappedError(error)

        return
      }

      const dataObj = get(data, 'data.[0]') || {}
      const queryKey = queriesKeys[idx]

      if (queryKey === 'impressions') {
        const impressions = +dataObj['Product.list_views'] || 0

        dispatch(impressionsSetAc(impressions))
      }

      if (queryKey === 'productViews') {
        dispatch(
          totalProductDataSetAc({
            totalProductViews: dataObj['Product.detail_views'] || 0
          })
        )
      }

      if (queryKey === 'siteViews') {
        const siteViews = +dataObj['ArtisanPerformance.site_views'] || 0

        dispatch(siteViewsSetAc(siteViews))
      }

      if (queryKey === 'totalSales') {
        const totalSalesAmount = formatPrice({
          currency: CURRENCIES.EUR,
          price: round(dataObj['Sales.GMV'], 2)
        })

        dispatch(totalSalesSetAc(totalSalesAmount))
      }
    })

    dispatch(totalProductDataPendingAc())
  })
}
