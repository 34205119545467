import { noop } from 'lodash'
import { HideLoading, ShowLoading } from 'redux/actions/loading-action'
import { massUpdate } from 'redux/service/product-service'
import { notifyMappedError } from 'utils/errors'
import { getFormDataWithFiles } from 'utils/files'

/**
 * @param {Object} config
 * @param {Function} config.dispatchRedux
 * @param {File} config.file
 */
export const massUpdateMw = (config = {}) => {
  const { dispatchRedux = noop, file, onSuccess = noop } = config
  const formData = getFormDataWithFiles({
    files: [file],
    propName: 'csvUpdator'
  })

  dispatchRedux(ShowLoading())
  massUpdate({ formData }).then(({ data, error }) => {
    if (error) {
      notifyMappedError(error)

      return
    }

    onSuccess(data)

    dispatchRedux(HideLoading())
  })
}
