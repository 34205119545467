import { Select } from 'components/formParts'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import React from 'react'

import styles from './Filters.module.css'

const Filters = ({ filters, values, onChange, disabled }) => {
  return (
    <div className={styles.filters}>
      {filters.map((filter) => {
        const filterId = `filter-${filter.name}`
        return (
          <div key={filter.name} className={styles.filter}>
            <label htmlFor={filterId}>{filter.placeholder}</label>
            <Select
              options={filter.options}
              placeholder={filter.placeholder}
              name={filter.name}
              value={values[filter.name]}
              onChange={onChange}
              id={filterId}
              disabled={disabled}
            />
          </div>
        )
      })}
    </div>
  )
}
Filters.propTypes = {
  filters: arrayOf(
    shape({
      name: string,
      options: arrayOf(
        shape({
          label: string,
          value: string
        })
      ),
      placeholder: string
    })
  ),
  values: shape({
    status: string
  }),
  onChange: func,
  disabled: bool
}
export default Filters
