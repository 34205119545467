import { PasswordSetForm } from 'components/forms'
import { bool, func, shape } from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import { Col } from 'reactstrap'
import { CURRENT_YEAR, EXTERNAL_URLS } from 'utils/constants'

import { ResetPassword as resetPassword } from '../redux/actions/users-action'
import logo from '../static/logos/artemestLong.svg'
import Style from './styles/login.module.css'

class ResetPassword extends Component {
  submitForm = (values = {}) => {
    const { password } = values
    const { search } = window.location
    const queryString = search
      ? search.split('?token=')[1]
      : window.location.search.slice(1)

    this.props.ResetPassword(queryString, {
      newPassword: password
    })
  }

  render() {
    const { userReducer } = this.props

    if (userReducer.updated) {
      return <Redirect to="/" />
    }

    return (
      <div className={Style.body}>
        <div
          className={['row', Style.mainRow].join(' ')}
          style={{ width: '100%' }}
        >
          <Col
            md={{ size: 4, offset: 4 }}
            sm={{ size: 6, offset: 3 }}
            xs={{ size: 8, offset: 2 }}
            className={Style.mainDiv}
          >
            <img alt="logo" className={Style.logo} src={logo} width="50%" />
            <h2 className={Style.title}>
              <FormattedMessage id="setPassword" />
            </h2>
            <PasswordSetForm
              className={Style.resetPasswordForm}
              onSubmit={this.submitForm}
            />
          </Col>
        </div>
        <div className={Style.copyRight}>
          <p className={Style.copyRightP}>
            {' '}
            &copy; {CURRENT_YEAR} ARTEMEST.{' '}
            <FormattedMessage id={'CopyRights'} tagName={'span'} />
          </p>
          <p className={Style.privacyPolicy}>
            <a
              href={EXTERNAL_URLS.PRIVACY_POLICY}
              rel="noreferrer"
              target="_blank"
            >
              <FormattedMessage id="Privacy Policy" />
            </a>
          </p>
        </div>
      </div>
    )
  }
}

ResetPassword.propTypes = {
  ResetPassword: func,
  userReducer: shape({
    updated: bool
  })
}
const mapStateToProps = (state) => ({
  userReducer: state.userReducer
})

const mapDispatchToProps = (dispatch) => ({
  ResetPassword: (token, data) => dispatch(resetPassword(token, data))
})

export default withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(ResetPassword))
)
