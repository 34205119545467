import { PageNav, Pagination, VendorActions } from 'components'
import { Button } from 'components/buttons'
import { InnerPage } from 'components/layout'
import { get, uniqueId } from 'lodash'
import { func, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { GetCollectionById } from 'redux/actions/collection-action'
import { HideLoading, ShowLoading } from 'redux/actions/loading-action'
import { GetVendor } from 'redux/actions/vendor-action'
import { isAdmin, isManager } from 'utils/auth'
import { getApprovedProducts } from 'utils/collections'
import { sortProducts } from 'utils/products'
import { vendorNavItems } from 'utils/utils'
import { vendorIdFromCollectionReducer } from 'utils/vendors'

import Loading from '../components/loading/loading'
import CollectionProducts from '../components/vendor-collection-list-card/collection-products'
import VendorCollectionListCard from '../components/vendor-collection-list-card/vendor-collection-list-card'
import {
  getCollectionByVendor,
  readCollection
} from '../redux/service/collection-service'
import { activate, removeUser } from '../redux/service/user-service'
import notify, { notifyPositions } from '../utils/toast'
import Style from './styles/vendor-collection-list.module.css'

class VendorCollectionList extends Component {
  state = {
    lastCollection: null,
    lastCollectionProducts: null,
    vendorName: '',
    otherCollections: null,
    page: 1,
    pages: 1,
    total: null
  }

  componentDidMount() {
    const { getCollectionById } = this.props

    getCollectionById({
      id: this.getCollectionId(),
      ifGetVendor: true,
      withLoading: true
    })
    this.fetchOtherProducts({ page: this.state.page })
  }

  fetchOtherProducts = ({ page }) => {
    const {
      GetVendor,
      HideLoadingAc,
      match: { params },
      ShowLoadingAc
    } = this.props
    const collectionId = this.getCollectionId()
    const vendorId = params.vendor

    GetVendor(vendorId)
    getCollectionByVendor(vendorId, page)
      .then(async (res) => {
        const data = res.data.docs.filter((col) => col._id !== collectionId)
        const collections = data
        this.setState({
          page: res.data.page,
          pages: res.data.pages,
          total: res.data.total
        })
        if (collections) {
          ShowLoadingAc()
          window.scrollTo(0, 0)

          const otherCollections = collections.filter(
            (col) => col._id !== collectionId
          )
          const otherCollectionsWithProducts = await Promise.all(
            otherCollections.map((collection) => {
              return readCollection(collection._id).then(({ data }) => {
                const collection = get(data, '[0]')

                return {
                  ...collection,
                  products: getApprovedProducts(collection)
                }
              })
            })
          )

          this.setState({
            otherCollectionsWithProducts
          })
          HideLoadingAc()
        }
      })
      .catch(() => {
        notify(
          'error',
          'can not get data from server',
          notifyPositions.bottom.center
        )
      })
  }
  handlePaginationNavigation = (currentPage = 1) => {
    this.fetchOtherProducts({ page: currentPage })
  }
  activateCreditials = () => {
    const vendorId = this.getVendorId()

    if (vendorId) {
      activate(vendorId)
        .then(() => {
          notify(
            'success',
            'activated successfully',
            notifyPositions.bottom.center
          )
        })
        .catch((onerror) => {
          const { message, response } = onerror

          notify(
            'error',
            get(response, 'data', '') || message,
            notifyPositions.bottom.center
          )
        })
    }
  }

  getCollectionId = () => get(this.props, 'match.params.collection', '')

  getOwner = () => get(this.props, 'match.params.owner', '')

  getUserType = () => get(this.props, 'authReducer.userType', '')

  getVendorId = () => {
    const { collectionReducer } = this.props

    return vendorIdFromCollectionReducer(collectionReducer)
  }

  getVendorIdFromPath = () => get(this.props, 'match.params.vendor', '')

  removeUser = () => {
    const vendorId = this.getVendorId()

    if (vendorId) {
      removeUser(vendorId)
        .then(() => {
          notify(
            'success',
            'deactivated/removed user successfully',
            notifyPositions.bottom.center
          )
        })
        .catch((onerror) => {
          notify('error', onerror.message, notifyPositions.bottom.center)
        })
    }
  }

  routeToImageAssets = (owner, vendor, collection) => {
    this.props.history.push({
      pathname: `/image-assets/${owner}/${vendor}`,
      state: { collection }
    })
  }

  render() {
    const {
      authReducer,
      collectionReducer,
      intl,
      location: { pathname },
      vendorReducer: { vendor }
    } = this.props
    const { otherCollectionsWithProducts, page, pages } = this.state
    const collectionObj = get(collectionReducer, 'collection')
    const collectionProductsToShow = sortProducts({
      products: getApprovedProducts(collectionObj),
      by: 'addDate'
    })

    const isUserAdmin = isAdmin(authReducer)
    const isUserManager = isManager(authReducer)

    const owner = this.getOwner()
    const vendorIdFromPath = this.getVendorIdFromPath()
    const vendorName =
      get(vendor, 'artisanName', '') ||
      intl.formatMessage({ id: "Vendor's Collection List" })
    const navItems = vendorNavItems({
      collectionId: this.getCollectionId(),
      id: vendorIdFromPath,
      intl,
      path: pathname,
      userType: this.getUserType()
    })

    return !collectionObj ? (
      <Loading />
    ) : (
      <InnerPage
        className={Style.main}
        heading={vendorName}
        vendor={get(this.props, 'vendorReducer.vendor')}
      >
        <PageNav className={Style.pageNav} items={navItems} />
        <Row className="rowWide">
          <Col className={Style.hd} md={6} sm={12}>
            <h3>
              <FormattedMessage id="Last Collection:" />{' '}
              <b>{collectionObj.name}</b>
            </h3>
          </Col>
          <Col className={Style.vendorActions} md={6} sm={12}>
            {(isUserAdmin || isUserManager) && (
              <VendorActions
                collection={this.getCollectionId()}
                owner={owner}
                vendorId={this.getVendorIdFromPath()}
                onClickImages={this.routeToImageAssets.bind(
                  this,
                  owner,
                  vendorIdFromPath,
                  collectionObj
                )}
              />
            )}
          </Col>
        </Row>
        {collectionProductsToShow.length > 0 && (
          <Row className={Style.borderBottom}>
            <VendorCollectionListCard cardsData={collectionProductsToShow} />
          </Row>
        )}
        {otherCollectionsWithProducts &&
          otherCollectionsWithProducts.length > 0 &&
          otherCollectionsWithProducts.map((collection) => {
            const key = uniqueId(get(collection, '_id', ''))
            const products = sortProducts({
              products: get(collection, 'products') || [],
              by: 'addDate'
            })

            return (
              <CollectionProducts
                className={Style.productList}
                data={{ collection, products }}
                key={key}
              />
            )
          })}
        {pages > 1 && (
          <Row>
            <Col>
              <Pagination
                alignCenter
                pages={pages}
                current={page}
                onChange={this.handlePaginationNavigation}
              />
            </Col>
          </Row>
        )}
        <div className={Style.buttons}>
          <h3>
            <FormattedMessage id="Vendor Management" />
          </h3>
          <Button className={Style.button} onClick={this.activateCreditials}>
            <FormattedMessage id="Activate Credentials" />
          </Button>
          <Button className={Style.button} onClick={this.removeUser}>
            <FormattedMessage id="Disable Credentials and Tools" />
          </Button>
        </div>
      </InnerPage>
    )
  }
}

VendorCollectionList.propTypes = {
  getCollectionByVendor: func,
  getCollectionById: func,
  GetVendor: func,
  HideLoadingAc: func,
  match: shape({
    params: string
  }),
  ShowLoadingAc: func,
  collectionReducer: shape({}),
  history: shape({
    push: func
  }),
  authReducer: shape({}),
  intl: shape({
    formatMessage: func
  }),
  location: shape({
    pathname: string
  }),
  vendorReducer: shape({
    vendor: string
  })
}
const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  collectionReducer: state.collectionReducer,
  vendorReducer: state.vendorReducer
})

const mapDispatchToProps = (dispatch) => ({
  getCollectionById: (data) => dispatch(GetCollectionById(data)),
  GetVendor: (vendorId) =>
    dispatch(
      GetVendor({
        ifWithLoading: false,
        vendorId
      })
    ),
  HideLoadingAc: (data) => dispatch(HideLoading(data)),
  ShowLoadingAc: (data) => dispatch(ShowLoading(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(VendorCollectionList))
