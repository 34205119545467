import cn from 'classnames'
import { node, number, oneOfType, string } from 'prop-types'
import React from 'react'

import st from './Status.module.css'

const Status = ({ children, className, color, fontSize, textAlign }) => {
  const style = {
    fontSize,
    textAlign
  }

  if (color.length > 0) {
    style.color = color
  }

  return (
    <div
      className={cn(st.wrap, {
        [className]: className.length > 0
      })}
      style={style}
    >
      {children}
    </div>
  )
}

Status.defaultProps = {
  children: null,
  className: '',
  color: '',
  fontSize: '4em',
  textAlign: 'center'
}
Status.propTypes = {
  children: node,
  className: string,
  color: string,
  fontSize: oneOfType([number, string]),
  textAlign: string
}

export default Status
