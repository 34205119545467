import cn from 'classnames'
import { PlanStatus, Spinner } from 'components'
import { Input } from 'components/formParts'
import { FloatList } from 'components/lists'
import gIcons from 'glyphicons'
import { noop } from 'lodash'
import { arrayOf, bool, func, number, shape, string } from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { parseNumber } from 'utils/utils'

import st from './BraintreePlansList.module.css'

const sortingRule = [
  'Premium_Plus_yearly',
  'Premium_Plus_monthly',
  'Premium_yearly',
  'Premium_monthly',
  'base_yearly',
  'base_monthly'
]

const BraintreePlansList = ({
  chosenPlanId,
  className,
  intl,
  items,
  onItemClick
}) => {
  const handleRadioCheck =
    ({ id, price }) =>
    (e) => {
      const { checked } = e.nativeEvent.target
      onItemClick({
        checked,
        id,
        price
      })
    }

  const itemsList = sortingRule

    // sort items by sortingRule
    .map((id) => items.find((item) => item.id.includes(id)))

    // remove undefined items
    .filter((item) => item)

    .map(({ ...item }) => {
      if (!item) return null
      const {
        id,
        currency,
        description,
        disabled,
        isNoticePeriod,
        isSelected,
        name,
        pending,
        price
      } = item
      const buttonDisabled = disabled || isSelected || pending

      return {
        content: (
          <Spinner show={pending}>
            <article className={st.wrap}>
              <h1>{name}</h1>
              <p>{description}</p>
              <p>
                <span className={st.price}>
                  {intl.formatMessage({ id: 'Price VAT' })}:{' '}
                  {parseNumber(price, 0)} {currency}
                </span>
                {pending && (
                  <span className={st.planStatusLbl}>
                    <span className={st.planStatusLblMsg}>
                      <FormattedMessage id="Pending" />
                    </span>
                  </span>
                )}
              </p>
              <div
                className={cn(st.button, {
                  [st.buttonPending]: pending
                })}
              >
                {isSelected ? (
                  <>
                    {isNoticePeriod ? (
                      <PlanStatus
                        message={intl.formatMessage({
                          id: 'On Notice Period'
                        })}
                      />
                    ) : (
                      <span className={st.gIconOk}>{gIcons.checkHeavy}</span>
                    )}
                  </>
                ) : (
                  <>
                    {pending ? (
                      <span className={st.planStatus}>
                        <FormattedMessage id="plan activating" />
                        {'.'}
                      </span>
                    ) : (
                      <Input
                        checked={chosenPlanId === id}
                        disabled={buttonDisabled}
                        name="braintreePlan"
                        type="radio"
                        value={id}
                        onChange={handleRadioCheck({ id, price })}
                      />
                    )}
                  </>
                )}
              </div>
            </article>
          </Spinner>
        ),
        id
      }
    })

  return <FloatList className={className} items={itemsList} />
}

BraintreePlansList.defaultProps = {
  chosenPlanId: '',
  className: '',
  items: [],
  onItemClick: noop
}
BraintreePlansList.propTypes = {
  chosenPlanId: string,
  className: string,
  items: arrayOf(
    shape({
      currency: string,
      description: string,
      disabled: bool,
      id: string,
      isSelected: bool,
      name: string,
      price: number
    })
  ),
  onItemClick: func,
  intl: shape({
    formatMessage: func
  })
}

export default injectIntl(BraintreePlansList)
