import { isNaN, isNumber } from 'lodash'
import {
  arrayOf,
  bool,
  element,
  func,
  node,
  number,
  oneOfType,
  shape,
  string
} from 'prop-types'

export const COLS_WIDTH = shape({
  lg: number,
  md: number,
  sm: number,
  xl: number,
  xs: number
})

export const FORMIK_DEFAULT_PROPS = {
  field: {},
  form: {}
}

export const FORMIK_FIELD_TYPE = shape({
  name: string,
  value: oneOfType([bool, number, string]),
  onBlur: func,
  onChange: func
})

export const FORMIK_FORM_TYPE = shape({
  isValid: bool
})

export const SELECT_OPTIONS_TYPE = arrayOf(
  shape({
    disabled: bool,
    label: string.isRequired,
    value: oneOfType([number, string]).isRequired
  })
)

export const INPUT_PROP_TYPES = {
  className: string,
  decimalSign: string,
  error: string,
  field: FORMIK_FIELD_TYPE,
  form: FORMIK_FORM_TYPE,
  horizontal: bool,
  id: string,
  inputClassName: string,
  isScrollTo: bool,
  label: oneOfType([element, string]),
  labelAfter: element,
  labelClassName: string,
  name: string.isRequired,
  nextNode: node,
  options: SELECT_OPTIONS_TYPE,
  placeholder: string,
  reversed: bool,
  separateNumberValue: bool,
  step: number,
  type: string,
  value: oneOfType([bool, number, string]),
  valueLengthLimit: number,
  onBlur: func,
  onChange: func
}

export const isNumeric = (value) => {
  const parsed = parseFloat(value)

  return isNumber(parsed) && !isNaN(parsed)
}
