import cn from 'classnames'
import { array, shape, string } from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { isProductPending } from 'utils/products'

const CollectionProducts = (props) => {
  const {
    className,
    data: { collection, products }
  } = props

  const renderLast = (productsArray) =>
    productsArray.map(({ _id, ...restProduct }) => (
      <Col md={3} sm={4} key={_id}>
        {isProductPending(restProduct) ? (
          _id
        ) : (
          <Link to={`/product-detail/${_id}`}>{_id}</Link>
        )}
      </Col>
    ))

  return (
    collection && (
      <>
        <h3>{collection.name}</h3>
        {products && products.length && (
          <Row className={cn('rowWide', className)}>{renderLast(products)}</Row>
        )}
      </>
    )
  )
}

CollectionProducts.propTypes = {
  className: string,
  data: shape({
    collection: array,
    products: array
  })
}
export default CollectionProducts
