import { FloatList } from 'components/lists'
import { arrayOf, shape, string } from 'prop-types'
import React from 'react'

import st from './OtherImagesList.module.css'

const OtherImagesList = ({ className, images }) =>
  images.length > 0 ? (
    <FloatList
      className={className}
      items={images.map(({ alt, src }) => ({
        content: (
          <span className={st.image}>
            <img alt={alt} src={src} />
          </span>
        ),
        id: src
      }))}
    />
  ) : null

OtherImagesList.defaultProps = {
  className: '',
  images: []
}
OtherImagesList.propTypes = {
  className: string,
  images: arrayOf(
    shape({
      alt: string,
      src: string
    })
  )
}

export default OtherImagesList
