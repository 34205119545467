import { Input } from 'components/formParts'
import { Field, Form, Formik } from 'formik'
import { debounce, noop } from 'lodash'
import { bool, func, shape } from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import st from './SearchForm.module.css'

const SearchForm = ({
  intl,
  onInputBlur,
  onInputChange,
  onInputFocus,
  onSubmit,
  ...props
}) => {
  const debouncedInputChange = debounce(onInputChange, 100)

  const handleKeyPress = (e) => {
    const { value } = e.target

    debouncedInputChange({ value })
  }

  return (
    <Formik
      {...props}
      initialValues={{
        searchTerm: ''
      }}
      onSubmit={onSubmit}
    >
      <Form className={st.SearchForm}>
        <div className={st.SearchForm__Field}>
          <Field
            autoComplete="off"
            component={Input}
            name="searchTerm"
            placeholder={intl.formatMessage({
              id: 'Search for products'
            })}
            onBlur={onInputBlur}
            onChange={handleKeyPress}
            onFocus={onInputFocus}
          />
        </div>
        <button className={st.SearchForm__Button} type="submit" />
      </Form>
    </Formik>
  )
}
SearchForm.defaultProps = {
  enableReinitialize: false,
  onInputChange: noop,
  onInputFocus: noop,
  onSubmit: noop
}
SearchForm.propTypes = {
  enableReinitialize: bool,
  onInputChange: func,
  onInputFocus: func,
  onSubmit: func,
  intl: shape({
    formatMessage: func
  }),
  onInputBlur: func
}

export default injectIntl(SearchForm)
