import { get } from 'lodash'
import { PRODUCT_DATA_TYPE } from 'utils/errors'

import notify, { notifyPositions } from '../../utils/toast'
import {
  addPoductData,
  editProductData,
  read,
  readProductsData,
  readProductsId,
  removeProductsData
} from '../service/product-types-service'
import {
  ADDED_PRODUCT_TYPE,
  DELETE_PRODUCT_DATA,
  GET_PRODUCT_DATA,
  GET_PRODUCT_DATA_IDS,
  GET_PRODUCTS_DATA,
  PRODUCT_DATA_ITEM_UPDATE,
  // UPDATE_PRODUCT_DATA, - TODO: Uncomment later
  SELECT_PROD_ADDITIONAL_TYPE_ID,
  SHOW_EDIT_MODAL,
  SHOW_MODAL
} from './action-types'
import { AuthError } from './auth-action'
import { HideLoading, ShowLoading } from './loading-action'

const add = (added, product) => ({
  type: ADDED_PRODUCT_TYPE,
  added,
  product
})

const gotProductData = (gotProductData, productData) => ({
  type: GET_PRODUCT_DATA,
  gotProductData,
  productData
})

const gotProductsData = (gotProductsData, productsData) => ({
  type: GET_PRODUCTS_DATA,
  gotProductsData,
  productsData
})

const gotProductDataIds = (gotProductsDataIds, productsDataIds) => ({
  type: GET_PRODUCT_DATA_IDS,
  gotProductsDataIds,
  productsDataIds
})

const deleteProductsData = (deleted) => ({
  type: DELETE_PRODUCT_DATA,
  deleted
})

const productDataItemUpdate = (dataItem = {}) => ({
  payload: { dataItem },
  type: PRODUCT_DATA_ITEM_UPDATE
})
/* TODO: Uncomment when need to update this boolean prop
const updateProductData = (updatedProductData) => ({
  type: UPDATE_PRODUCT_DATA,
  updatedProductData,
})
*/
const selectElement = (id) => ({
  type: SELECT_PROD_ADDITIONAL_TYPE_ID,
  id
})

const showModal = (isOpen) => ({
  type: SHOW_MODAL,
  isOpen
})

const showModalEditModal = (isOpenEditModal, items, id) => ({
  type: SHOW_EDIT_MODAL,
  isOpenEditModal,
  editItems: items,
  editId: id
})

const Add =
  (productType = {}) =>
  (dispatch) => {
    const { onSuccess, ...restArs } = productType

    return addPoductData(restArs)
      .then(({ data, status }) => {
        if (status === 200) {
          dispatch(add(true, data))
          notify('success', 'Added successfully', notifyPositions.bottom.center)

          if (typeof onSuccess === 'function') {
            onSuccess(data)
          }
        }
      })
      .catch((err) => {
        let message = 'can not add product data'

        if (err.response && err.response.status) {
          const { data, status } = err.response

          if (status === 401) {
            dispatch(AuthError(err.response.status))
          }

          if (status === 400) {
            message =
              'can not add product additional fields, product additional fields type already exists'
          }

          if (
            status === 500 &&
            data.indexOf(PRODUCT_DATA_TYPE.DUPLICATE_KEY) > -1
          ) {
            message = 'Data type you want to add already exists'
          }
        }

        notify('error', message, notifyPositions.bottom.center)
      })
  }

const GotProductsDataIds = () => (dispatch) =>
  readProductsId()
    .then((res) => {
      dispatch(gotProductDataIds(true, res.data))
    })
    .catch((err) => {
      if (err.response && err.response.status && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      notify('error', "base error can't got ids", notifyPositions.bottom.center)
    })

const GotProductData = (id) => (dispatch) =>
  read(id)
    .then((res) => {
      dispatch(gotProductData(true, res.data))
    })
    .catch((err) => {
      if (err.response && err.response.status && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      notify('error', 'can not got product data', notifyPositions.bottom.center)
    })

const GetProductsData = () => (dispatch) =>
  readProductsData().then(({ data, error }) => {
    if (data) {
      dispatch(gotProductsData(true, data))
    }

    if (error) {
      const { response } = error

      if (response && response.status && response.status === 401) {
        dispatch(AuthError(response.status))
      }

      notify(
        'error',
        'can not got products data',
        notifyPositions.bottom.center
      )
    }
  })

const DeleteProductData = (id) => (dispatch) =>
  removeProductsData(id)
    .then((res) => {
      dispatch(deleteProductsData(true, res.data))
      notify('success', 'deleted successfully', notifyPositions.bottom.center)
    })
    .catch((err) => {
      if (err.response && err.response.status && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      notify(
        'error',
        err.response.data ?? "hasn't been deleted",
        notifyPositions.bottom.center
      )
    })

const UpdateProductData = (id, element) => (dispatch) => {
  dispatch(ShowLoading())
  editProductData({
    dataItems: element,
    id
  }).then(({ data, error }) => {
    if (data) {
      dispatch(productDataItemUpdate(data.data))
      notify('success', 'updated successfully', notifyPositions.bottom.center)
    }

    if (error) {
      const status = get(error, 'response.status')

      if (status === 401) {
        dispatch(AuthError(status))
      }

      notify('error', "hasn't been updated", notifyPositions.bottom.center)
    }

    dispatch(HideLoading())
  })
}

const SelectElement = (id) => (dispatch) => dispatch(selectElement(id))

const ShowModal = (status) => (dispatch) => dispatch(showModal(status))

const ShowEditModal = (status, items, id) => (dispatch) =>
  dispatch(showModalEditModal(status, items, id))

export {
  Add,
  DeleteProductData,
  GetProductsData,
  GotProductData,
  GotProductsDataIds,
  SelectElement,
  ShowEditModal,
  ShowModal,
  UpdateProductData
}
