import axios from 'axios/index'
import { pick } from 'lodash'
import cookie from 'react-cookies'
import { ORDER_BY, STATUSES } from 'utils/constants'
import { mapError } from 'utils/errors'
import { getFormDataWithFiles } from 'utils/files'

import apiUrl from './apiUrl'

const add = (product) =>
  axios.post(`${apiUrl}/api/product`, product, {
    headers: { token: cookie.load('artemest') }
  })

const addProductsFromCollection = (bodyFormData, dataTypesId, callback) => {
  return axios({
    url: `${apiUrl}/api/product/image/${dataTypesId}`,
    method: 'post',
    data: bodyFormData,
    headers: { token: cookie.load('artemest') },
    onUploadProgress: function (progressEvent) {
      if (!callback) return
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      )
      callback(percentCompleted)
    }
  })
}

/**
 * @param {Object} config
 * @param {String} config.imageUrl
 * @param {String} config.productId
 */
const deleteProductImage = async (config = {}) => {
  const { imageUrl, productId } = config
  const apiPath = `${apiUrl}/api/product/${productId}/image`

  try {
    const { data, status } = await axios.delete(apiPath, {
      data: { imageUrl },
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return pick(data, ['photos'])
    }

    throw new Error(`Bad deleting image: ${imageUrl}`)
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} config
 * @param {String} config.productId
 * @param {String} config.resourceType - PRODUCT_RESOURCES
 */
const deleteResource = async (config = {}) => {
  const { productId = '', resourceType = '' } = config
  const apiPath = `${apiUrl}/api/product/${productId}/resource`

  try {
    const { status } = await axios.delete(apiPath, {
      headers: { token: cookie.load('artemest') },
      params: { type: resourceType }
    })

    if (status === 200) {
      return { success: true }
    }

    throw new Error('Bad resource deleting')
  } catch (error) {
    return { error: mapError(error) }
  }
}

const get = () =>
  axios.get(`${apiUrl}/api/product`, {
    headers: { token: cookie.load('artemest') }
  })

/**
 * @param {Object} params
 * @param {String} params.search
 */
const getProducts = async (params) => {
  const apiPath = `${apiUrl}/api/product/search/quick`

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') },
      params
    })

    if (status === 200) {
      return { products: data }
    }
  } catch (error) {
    return { error: error.message }
  }
}

const got = () =>
  axios.get(`${apiUrl}/api/product`, {
    headers: { token: cookie.load('artemest') }
  })

/**
 * @param {Object} config
 * @param {String} config.productId
 */
const getProductById = async (config = {}) => {
  const { productId = '' } = config
  const apiPath = `${apiUrl}/api/product/${productId}`

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return { data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

const read = (id) =>
  axios.get(`${apiUrl}/api/product/${id}`, {
    headers: { token: cookie.load('artemest') }
  })

const remove = (id) =>
  axios.delete(`${apiUrl}/api/product/${id}`, {
    headers: { token: cookie.load('artemest') }
  })

/**
 * @param {String} property - one of PRODUCT_PROPERTIES: 'color', 'designer', 'material'
 */
const readProperty = async (property = '') => {
  const apiPath = `${apiUrl}/api/order/properties/${property}`

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return { data }
    }

    throw new Error(`Bad fetching property: "${property}".`)
  } catch (error) {
    return { error: mapError(error) }
  }
}

const getImagesByCollection = (collection, params = { sort: 'addDate' }) =>
  axios.get(`${apiUrl}/api/product/images/${collection}`, {
    params,
    headers: { token: cookie.load('artemest') }
  })

/**
 * @param {Object} config
 * @param {String} config.collectionId
 * @param {Number} config.limit
 * @param {Number} config.page
 * @param {Number} config.status
 */
const getProductsByCollection = async (config = {}) => {
  const { collectionId = '', limit, page, status = '' } = config
  const apiPath = `${apiUrl}/api/product/product-data/${collectionId}`

  try {
    const { data, status: statusRes } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') },
      params: { limit, page, status }
    })

    if (statusRes === 200) {
      return { data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

const changeProductType = (id, type) =>
  axios.put(
    `${apiUrl}/api/product/change-type/${id}/${type}`,
    {},
    {
      headers: { token: cookie.load('artemest') }
    }
  )

/**
 * @param {Object} data
 * @param {FormData} data.formData
 * @param {String} data.productId
 */
const saveMainImage = async ({ formData, productId } = {}) => {
  const apiPath = `${apiUrl}/api/product/${productId}/main-image`

  try {
    const { data, status } = await axios.post(apiPath, formData, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return { fileUrl: data.mainPhoto }
    }

    throw new Error(data)
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} data
 * @param {FormData} data.formData
 * @param {String} data.productId
 * @param {String} data.type
 */
const saveAttachementService = async ({ formData, productId, type } = {}) => {
  const apiPath = `${apiUrl}/api/product/${productId}/attachment/${type}`
  try {
    const { data, status } = await axios.post(apiPath, formData, {
      headers: { token: cookie.load('artemest') }
    })
    if (status !== 200) {
      throw new Error(data)
    }
    return { fileUrl: data }
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} data
 * @param {String} data.productId
 * @param {String} data.type
 */
const removeAttachmentService = async ({ productId, type } = {}) => {
  const apiPath = `${apiUrl}/api/product/${productId}/attachment/${type}`
  try {
    const { data, status } = await axios.delete(apiPath, {
      headers: { token: cookie.load('artemest') }
    })
    if (status !== 200) {
      throw new Error(data)
    }
    return { success: true }
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} data
 * @param {FormData} data.formData
 * @param {String} data.productId
 */
const saveImages = async ({ formData, productId } = {}) => {
  const apiPath = `${apiUrl}/api/product/${productId}/multi-images`

  try {
    const { data, status } = await axios.post(apiPath, formData, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return { fileUrls: data.photos || [] }
    }

    throw new Error(data)
  } catch (error) {
    return { error }
  }
}

/**
 * @param {Object} config
 * @param {File} config.file
 * @param {String} config.productId
 */
const saveOnboardingPhoto = async (config = {}) => {
  const { file = null, productId = '' } = config
  const apiPath = `${apiUrl}/api/product/${productId}/onboarding-photo`
  const formData = getFormDataWithFiles({ files: [file] })

  try {
    const { data, status } = await axios.post(apiPath, formData, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return pick(data, ['onboardingPhoto'])
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Array} products
 * @param {number} value
 */
const batchShippingTimeEdit = async (products, value) => {
  const apiPath = `${apiUrl}/api/product/shipping-in`
  try {
    const { data, status } = await axios.patch(
      apiPath,
      { value, products },
      { headers: { token: cookie.load('artemest') } }
    )

    if (status !== 200) {
      throw new Error('Bad shipping time update')
    }
    return { data }
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {String} id
 * @param {Object} product
 */
const edit = async (id, product) => {
  const apiPath = `${apiUrl}/api/product/${id}`

  try {
    const { data, status } = await axios.put(apiPath, product, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return { product: data }
    }

    throw new Error('Bad product update')
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {[Number|String]} ids - product IDs
 */
const rejectByIds = async (ids = []) => {
  try {
    const reqs = ids.map((id) =>
      edit(id, {
        status: STATUSES.REJECTED
      })
    )
    const res = await Promise.all(reqs)

    return {
      data: res.map((r) => pick(r.product, ['_id', 'status'])),
      success: true
    }
  } catch (error) {
    return { error }
  }
}

const approveAllByCollection = (collection) =>
  axios.put(
    `${apiUrl}/api/product/approve-all/${collection}`,
    {},
    {
      headers: { token: cookie.load('artemest') }
    }
  )

/**
 * @param {Object} config
 * @param {Boolean} config.blocked
 * @param {String} config.productId
 */
const blockUnblock = async (config = {}) => {
  const { blocked = false, productId = '' } = config
  const apiPath = `${apiUrl}/api/product/block-unblock/${productId}`

  try {
    const { data, status } = await axios.put(
      apiPath,
      { blocked },
      {
        headers: { token: cookie.load('artemest') }
      }
    )

    if (status === 200) {
      return { product: data }
    }

    throw new Error('Bad product block/unblock')
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {String} productId
 */
const audit = async (productId) => {
  const apiPath = `${apiUrl}/api/product/audit/${productId}`

  try {
    const { data, status } = await axios.put(
      apiPath,
      {},
      { headers: { token: cookie.load('artemest') } }
    )

    if (status === 200) {
      return { product: data }
    }
    throw new Error('Unable to audit product')
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {String} productId
 */
const flagIncomplete = async (productId) => {
  const apiPath = `${apiUrl}/api/product/audit/invalidate/${productId}`
  try {
    const { data, status } = await axios.put(
      apiPath,
      {},
      { headers: { token: cookie.load('artemest') } }
    )

    if (status === 200) {
      return { product: data }
    }
    throw new Error('Unable to flag as incomplete')
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {String} productId
 */
const flagDiscontinued = async (productId) => {
  const apiPath = `${apiUrl}/api/product/discontinue/${productId}`
  try {
    const { data, status } = await axios.put(
      apiPath,
      {},
      { headers: { token: cookie.load('artemest') } }
    )

    if (status === 200) {
      return { product: data }
    }
    throw new Error('Unable to flag as discontinued')
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {String} productId
 */
const reactivate = async (productId) => {
  const apiPath = `${apiUrl}/api/product/reactivate/${productId}`
  try {
    const { data, status } = await axios.put(
      apiPath,
      {},
      { headers: { token: cookie.load('artemest') } }
    )

    if (status === 200) {
      return { product: data }
    }
    throw new Error('Unable to reactivate product')
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} config
 * @param {Object} config.formData
 * @param {File} config.formData.csvUpdator
 */
const massUpdate = async (config = {}) => {
  const { formData } = config
  const apiPath = `${apiUrl}/api/product/updator`

  try {
    const { data, status } = await axios.post(apiPath, formData, {
      headers: { token: cookie.load('artemest') }
    })

    if (status !== 200) {
      throw new Error('Bad mass update')
    }

    return { data }
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} config
 * @param {String} config.collectionId
 */
const readProductsByCollectionId = async (config = {}) => {
  const { collectionId = '' } = config
  const apiPath = `${apiUrl}/api/product/collection/${collectionId}`

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return { data }
    }

    const errorMessage = `Bad fetching products by the collection ${collectionId}`

    throw new Error(errorMessage)
  } catch (error) {
    return { error: mapError(error) }
  }
}

const copy = (fromId, toId) =>
  Promise.all(
    toId.map((item) =>
      axios.post(
        `${apiUrl}/api/product/copy/${fromId}/${item}`,
        {},
        {
          headers: { token: cookie.load('artemest') }
        }
      )
    )
  )

const saveInDraft = (id, data) =>
  axios.put(
    `${apiUrl}/api/product/save-in-draft/${id}/${data}`,
    {},
    {
      headers: { token: cookie.load('artemest') }
    }
  )

const sendToProofReading = (id, data) =>
  axios.put(
    `${apiUrl}/api/product/send-to-proof-reading/${id}/${data}`,
    {},
    {
      headers: { token: cookie.load('artemest') }
    }
  )

/**
 * @param {Object} config
 * @param {String} config.collectionId
 * @param {String} config.order
 * @param {String} config.productStatus
 * @param {String} config.sort
 */
const getProductsByCollectionId = async (config = {}) => {
  const {
    collectionId = '',
    order = ORDER_BY.DESC,
    productStatus = '',
    sort = 'name'
  } = config
  const apiPath = `${apiUrl}/api/product/products/${collectionId}`

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') },
      params: {
        order,
        status: productStatus,
        sort
      }
    })

    if (status === 200) {
      return { products: data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

export {
  add,
  addProductsFromCollection,
  approveAllByCollection,
  audit,
  batchShippingTimeEdit,
  blockUnblock,
  changeProductType,
  copy,
  deleteProductImage,
  deleteResource,
  edit,
  flagDiscontinued,
  flagIncomplete,
  get,
  getImagesByCollection,
  getProductById,
  getProducts,
  getProductsByCollection,
  getProductsByCollectionId,
  got,
  massUpdate,
  reactivate,
  read,
  readProductsByCollectionId,
  readProperty,
  rejectByIds,
  remove,
  removeAttachmentService,
  saveAttachementService,
  saveImages,
  saveInDraft,
  saveMainImage,
  saveOnboardingPhoto,
  sendToProofReading
}
