import { Pagination, Spinner, Status } from 'components'
import { Table } from 'components/tables'
import { get, noop } from 'lodash'
import { shape, string } from 'prop-types'
import React, { Component } from 'react'
import { IoDiamondOutline, IoStarOutline } from 'react-icons/io5'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { read } from 'redux/service/collection-service'
import { isApprover } from 'utils/auth'
import {
  headingsToApprove,
  SORT_BY,
  sortCollectionsByAddDate
} from 'utils/collections'
import { ORDER_BY } from 'utils/constants'

import Style from './approver-dashboard-component.module.css'

const mapState = ({ authReducer }) => ({
  authReducer
})

class ToApprove extends Component {
  static defaultProps = {
    filteredVendorName: '',
    history: {}
  }
  static propTypes = {
    filteredVendorName: string,
    history: shape({})
  }

  state = {
    docs: [],
    page: 1,
    pages: 1,
    pending: true
  }

  componentDidMount() {
    this.fetchCollections()
  }

  componentDidUpdate(prevProps, prevState) {
    const { filteredVendorName: filteredVendorNamePrev } = prevProps
    const { page: pagePrev } = prevState
    const { filteredVendorName } = this.props
    const { page } = this.state

    if (filteredVendorNamePrev !== filteredVendorName || pagePrev !== page) {
      this.fetchCollections({
        resetPage: filteredVendorNamePrev !== filteredVendorName
      })
    }
  }

  fetchCollections = (config = {}) => {
    const { resetPage } = config
    const { filteredVendorName } = this.props
    const { page } = this.state
    const readArg = {
      allReviewed: false,
      approved: false,
      limit: 10,
      order: ORDER_BY.DESC,
      page: resetPage ? 1 : page,
      sort: SORT_BY.ADD_DATE
    }

    if (filteredVendorName) {
      readArg.artisan = filteredVendorName
    }

    this.setState({ pending: true })

    read(readArg).then((data) => {
      const docs = get(data, 'docs') || []
      const newData = {
        ...data,
        docs: docs.sort(sortCollectionsByAddDate),
        pending: false
      }

      this.setState(newData)
    })
  }

  handlePaginate = (page) => {
    this.setState({ page })
  }

  handleRowClick = (collectionId) => () => {
    const { history } = this.props

    history.push(`/approval-detail/${collectionId}`)
  }

  handleVendorClick = (e) => {
    e.stopPropagation()
  }

  tableRows = () => {
    const { authReducer } = this.props
    const { docs } = this.state
    const isRowClickable = isApprover(authReducer)

    return docs
      ? docs.map((collection) => {
          const { productsCount, vendor = {}, _id } = collection
          const { area, artisanName, contractType, name, SKUprefix, vetted } =
            vendor

          const isPremium = get(vendor, 'premium.active', false)

          return {
            cells: [
              {
                className: Style.col1,
                content: productsCount
              },
              {
                content: (
                  <Link
                    to={`/vendor/${vendor._id}`}
                    onClick={this.handleVendorClick}
                  >
                    <div className={Style.artisanName}>
                      {artisanName || name || SKUprefix}
                      <span>{isPremium && <IoStarOutline />}</span>
                      <span>{vetted && <IoDiamondOutline />}</span>
                    </div>
                  </Link>
                )
              },
              { content: area },
              { content: contractType }
            ],
            className: isRowClickable ? Style.tr : '',
            onClick: isRowClickable ? this.handleRowClick(_id) : noop,
            _id
          }
        })
      : []
  }

  render() {
    const { page, pages, pending } = this.state
    const rows = this.tableRows()
    const rowsEmpty = rows.length === 0
    return (
      <>
        <div className={Style.tableTitle}>
          <FormattedMessage id="Collection to approve" />
        </div>
        <Spinner show={pending} showDumb={rowsEmpty}>
          {pending || rows.length > 0 ? (
            <>
              <Table striped headings={headingsToApprove} rows={rows} />
              <Pagination
                alignCenter
                className={Style.paginator}
                current={page}
                disabled={pending}
                pages={pages}
                visiblePages={3}
                onChange={this.handlePaginate}
              />
            </>
          ) : (
            <Status
              className={Style.notFound}
              fontSize="1.5em"
              textAlign="left"
            >
              <FormattedMessage id="No collections found for the current page" />
            </Status>
          )}
        </Spinner>
      </>
    )
  }
}

ToApprove.propTypes = {
  authReducer: shape({})
}
export default connect(mapState)(ToApprove)
