import { get, noop, omit, pick } from 'lodash'
import { HideLoading, ShowLoading } from 'redux/actions/loading-action'
import {
  getAllTranslations,
  getCompanyBioByVendorId,
  readCollection
} from 'redux/service/collection-service'
import {
  addTranslations,
  handleTranslationAccept,
  setTranslationReady,
  updateTranslation
} from 'redux/service/translation-service'
import { getVendorById } from 'redux/service/vendor-service'
import { ORDER_BY, STATUSES } from 'utils/constants'
import { notifyMappedError } from 'utils/errors'
import {
  additionalDataMapped,
  formatDimensions,
  getPhotoSrc,
  typeNameByLang
} from 'utils/products'
import { showNotify } from 'utils/toast'
import { getUrlParams } from 'utils/url'

import * as ac from './actions'

const { page: urlPage } = getUrlParams()
const mapTranslation = (translationObj = {}) =>
  pick(translationObj, ['description', 'name', 'status', '_id'])

/**
 * @param {Object} config
 * @param {Function} config.dispatch
 * @param {Function} config.formatMessage
 * @param {Boolean} config.isAccept
 * @param {String} config.productId
 * @param {String} config.translationId
 */
export const acceptRejectMw = (config = {}) => {
  const {
    dispatch = noop,
    formatMessage = noop,
    isAccept = false,
    productId = '',
    translationData = {},
    translationId = ''
  } = config

  dispatch(ac.productPendingId(productId))

  updateTranslation({
    id: translationId,
    translation: translationData
  })
    .then(() =>
      handleTranslationAccept({
        isAccept,
        translationId
      })
    )
    .then(({ data, error }) => {
      if (data) {
        const mapped = mapTranslation(data)
        const notifyMessage = formatMessage({
          id: `Translation ${isAccept ? 'accept' : 'reject'}ed`
        })

        dispatch(
          ac.productTranslationGot({
            productId,
            translation: mapped
          })
        )
        showNotify({ message: `${notifyMessage}.` })
      }

      if (error) {
        notifyMappedError(error)
      }

      dispatch(ac.productPendingIdStop(productId))
    })
}

/**
 * @param {Object} config
 * @param {String} config.collectionId
 * @param {Function} config.dispatch
 * @param {Function} config.dispatchRedux
 * @param {String} config.lang
 * @param {Number} config.page
 * @param {Number} config.perPage
 * @param {String} config.productStatus
 * @param {Boolean} config.showPending
 */
export const getAllTranslationsMw = (config = {}) => {
  const {
    collectionId = '',
    dispatch = noop,
    dispatchRedux = noop,
    lang = '',
    page = +urlPage || 1,
    perPage = 20,
    showPending = false
  } = config

  if (showPending) {
    dispatchRedux(ShowLoading())
  }

  getAllTranslations({
    collectionId,
    page,
    perPage,
    productStatus: STATUSES.APPROVED,
    ready: true,
    sortBy: '_id',
    sortOrder: ORDER_BY.DESC
  }).then(({ data = {} }) => {
    const { currentPage, docs = [], pages } = data
    const mapped = docs.map((dataObj = {}) => {
      const { mainColor, name, photos, translation, _id } = dataObj

      return {
        ...pick(dataObj, [
          'careInstructions',
          'description',
          'designer',
          'material',
          'otherInfo'
        ]),
        category: typeNameByLang({
          lang,
          product: dataObj
        }),
        color: mainColor,
        customProps: additionalDataMapped({
          lang,
          product: dataObj
        }),
        dimensions: formatDimensions({ product: dataObj }),
        name: `${_id}${name ? ': ' + name : ''}`,
        otherPhotos:
          photos && photos.length
            ? photos.map((src) => ({ alt: '', src }))
            : [],
        photo: getPhotoSrc(dataObj),
        translation: mapTranslation(translation),
        _id
      }
    })

    dispatch(
      ac.productsToTranslateGet({
        page: currentPage,
        pages,
        productsToTranslate: mapped
      })
    )

    if (showPending) {
      dispatchRedux(HideLoading())
    }
  })
}

/**
 * @param {Object} config
 * @param {String} config.collectionId
 * @param {Function} config.dispatch
 * @param {Function} config.dispatchRedux
 * @param {String} config.lang
 * @param {Number} config.perPage
 */
export const initMw = (config = {}) => {
  const {
    collectionId,
    dispatch = noop,
    dispatchRedux = noop,
    lang,
    perPage
  } = config

  dispatchRedux(ShowLoading())

  getAllTranslationsMw({
    collectionId,
    dispatch,
    lang,
    perPage,
    showPending: true
  })

  readCollection(collectionId)
    .then(({ data }) => {
      if (data) {
        const collection = omit(get(data, '[0]', null), ['products'])
        const vendorId = get(collection, 'vendor', '')

        dispatch(ac.collectionGot(collection))

        return Promise.all([
          getCompanyBioByVendorId({ vendorId }),
          getVendorById(vendorId)
        ])
      }
    })
    .then((res = []) => {
      const [companyBio = {}, vendorData = {}] = res || []
      const { data: companyBioObj } = companyBio || {}
      const { data: vendorObj } = vendorData || {}

      if (vendorObj) {
        dispatch(
          ac.vendorDataGot({
            bio: get(companyBioObj, 'company.translation.description', ''),
            vendorName: get(vendorObj, 'artisanName', ''),
            vendorSKU: get(vendorObj, '_id', ''),
            webSite: get(vendorObj, 'website', '')
          })
        )
      }

      dispatchRedux(HideLoading())
    })
}

/**
 * @param {Object} config
 * @param {Function} config.dispatch
 * @param {Function} config.formatMessage
 * @param {String} config.productId
 * @param {Object} config.translationData
 * @param {String} config.translationId
 */
export const proofReadingMw = (config = {}) => {
  const {
    dispatch = noop,
    formatMessage = noop,
    productId = '',
    translationData = {},
    translationId = ''
  } = config

  dispatch(ac.productPendingId(productId))

  setTranslationReady({
    translationData,
    translationId
  }).then(({ data, error }) => {
    if (data) {
      const mapped = mapTranslation(data)
      const notifyMessage = formatMessage({
        id: 'Translation sent to proofreading'
      })

      dispatch(
        ac.productTranslationGot({
          productId,
          translation: mapped
        })
      )
      showNotify({ message: `${notifyMessage}.` })
    }

    if (error) {
      notifyMappedError(error)
    }

    dispatch(ac.productPendingIdStop(productId))
  })
}

/**
 * @param {Object} config
 * @param {Function} config.dispatch
 * @param {Function} config.formatMessage
 * @param {String} config.productId
 * @param {Object} config.translatedProductData
 * @param {String} config.translationId
 */
export const saveDraftMw = (config = {}) => {
  const {
    dispatch = noop,
    formatMessage = noop,
    productId = '',
    translatedProductData = {},
    translationId = ''
  } = config

  dispatch(ac.productPendingId(productId))

  addTranslations({
    translationData: translatedProductData,
    translationId
  }).then(({ data, error }) => {
    if (data) {
      const mapped = mapTranslation(data)
      const notifyMessage = formatMessage({
        id: 'Translation saved to draft'
      })

      dispatch(
        ac.productTranslationGot({
          productId,
          translation: mapped
        })
      )
      showNotify({ message: `${notifyMessage}.` })
    }

    if (error) {
      notifyMappedError(error)
    }

    dispatch(ac.productPendingIdStop(productId))
  })
}
