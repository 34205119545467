export const dimensionsFields = ({
  decimalSign = '.',
  intl = {},
  fieldClassName,
  parentFieldName = '',
  index = null
}) =>
  [
    {
      name: 'W',
      placeholder: intl.formatMessage({ id: 'Width' }),
      type: 'number'
    },
    {
      name: 'D',
      placeholder: intl.formatMessage({ id: 'Depth' }),
      type: 'number'
    },
    {
      name: 'H',
      placeholder: intl.formatMessage({ id: 'Height' }),
      type: 'number'
    }
  ].map((item) => ({
    ...item,
    name:
      index >= 0 && parentFieldName
        ? `${parentFieldName}.${index}.${item.name}` //for formik fieldarray
        : item.name,
    decimalSign,
    separateNumberValue: true,
    className: fieldClassName
  }))
