import cn from 'classnames'
import { noop } from 'lodash'
import { arrayOf, bool, func, node, shape, string } from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import {
  Dropdown as DropdownRS,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap'

import st from './Dropdown.module.css'

const Dropdown = ({
  children,
  className,
  classNameMenu,
  classNameToggler,
  isOpen,
  isUncontrolled,
  items,
  menuRight,
  noItemsText,
  toggleTag,
  onBlur,
  ...props
}) => {
  const [opened, setOpened] = useState(false)
  const wrapRef = useRef(null)
  const finalOpened = isUncontrolled ? opened : isOpen

  const handleItemClick = (item) => () => {
    const { onClick } = item

    if (typeof onClick === 'function') {
      onClick(item)
    }

    setOpened(false)
  }

  const itemsJSX =
    items.length > 0 ? (
      items.map((item) => {
        const { className, content, tag = 'div', _id, ...itemRest } = item
        const tagName = itemRest.href ? 'a' : tag

        return (
          <DropdownItem
            {...itemRest}
            className={cn(st.item, {
              [className]: !!className
            })}
            key={_id}
            tag={tagName}
            onClick={handleItemClick(item)}
          >
            {content}
          </DropdownItem>
        )
      })
    ) : (
      <DropdownItem>{noItemsText || 'No items found'}</DropdownItem>
    )

  const onWindowClick = (e) => {
    if (isUncontrolled) {
      const clickedEl = e.target
      const wrapEl = wrapRef.current
      const clickedInWrap = wrapEl.contains(clickedEl)

      setOpened(clickedInWrap)
    }
  }

  useEffect(() => {
    if (isUncontrolled) {
      window.addEventListener('click', onWindowClick)
    }

    return isUncontrolled
      ? () => {
          window.removeEventListener('click', onWindowClick)
        }
      : noop
  }, [isUncontrolled])

  return (
    <div ref={wrapRef} onBlur={onBlur}>
      <DropdownRS
        {...props}
        className={cn(st.wrap, {
          [className]: className.length > 0
        })}
        isOpen={finalOpened}
      >
        <DropdownToggle className={classNameToggler} tag={toggleTag}>
          {children}
        </DropdownToggle>
        <DropdownMenu className={classNameMenu} right={menuRight}>
          {itemsJSX}
        </DropdownMenu>
      </DropdownRS>
    </div>
  )
}
Dropdown.defaultProps = {
  children: null,
  className: '',
  classNameMenu: '',
  classNameToggler: '',
  direction: 'down',
  isOpen: false,
  isUncontrolled: false,
  items: [],
  menuRight: false,
  noItemsText: '',
  toggle: noop,
  toggleTag: 'div'
}
Dropdown.propTypes = {
  children: node,
  className: string,
  classNameMenu: string,
  direction: string,
  isOpen: bool,
  isUncontrolled: bool,
  items: arrayOf(
    shape({
      className: string,
      content: node.isRequired,
      disabled: bool,
      divider: bool,
      header: bool,
      href: string,
      tag: string,
      _id: string.isRequired,
      onClick: func
    })
  ),
  menuRight: bool,
  noItemsText: string,
  toggle: func,
  toggleTag: string,
  classNameToggler: string,
  onBlur: func
}

export default Dropdown
