import { Spinner } from 'components'
import { bool, number, shape } from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import Style from './loading.module.css'

const Loading = ({ length, loadingReducer, show, size }) => {
  const { progress, show: reducerShow } = loadingReducer
  const showLoading = reducerShow || show
  const statusMessage =
    progress === 100 ? 'Processing images...' : 'Images uploading'

  return showLoading ? (
    <div className={Style.mainDiv}>
      <Spinner show length={length} width={200} size={size} />

      {progress > 0 && (
        <div className={Style.progressBarWrapper}>
          <div className={Style.progressBar}>
            <span className={Style.progressStatus}>
              {progress}%{' - '}
              <FormattedMessage id={statusMessage} />
            </span>
            <div
              className={Style.progressBarColor}
              style={{
                width: `${progress}%`
              }}
            />
          </div>
        </div>
      )}
    </div>
  ) : null
}
Loading.defaultProps = {
  length: 5,
  show: false,
  size: 20
}
Loading.propTypes = {
  length: number,
  show: bool,
  size: number,
  loadingReducer: shape({
    progress: number,
    show: bool
  })
}

const mapStateToProps = (state) => ({ loadingReducer: state.loadingReducer })

export default withRouter(connect(mapStateToProps)(Loading))
