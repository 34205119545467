import { combineReducers } from 'redux'

import AuthReducer from './auth-reducer'
import BraintreeReducer from './braintree-reducer'
import CollectionReducer from './collection-reducer'
import CompanyReducer from './company-reducer'
import ForgotPasswordReducer from './forgot-password-reducer'
import LanguageReducer from './language-reducer'
import LoadingReducer from './loading-reducer'
import OrderReducer from './order-reducer'
import ProductReducer from './product-reducer'
import ProductDataReducer from './product-types-reducer'
import TranslationReducer from './translation-reducer'
import UserReducer from './user-reducer'
import VendorReducer from './vendor-reducer'

const allReducers = combineReducers({
  authReducer: AuthReducer,
  braintreeReducer: BraintreeReducer,
  userReducer: UserReducer,
  languageReducer: LanguageReducer,
  loadingReducer: LoadingReducer,
  vendorReducer: VendorReducer,
  collectionReducer: CollectionReducer,
  productReducer: ProductReducer,
  productDataReducer: ProductDataReducer,
  translationReducer: TranslationReducer,
  companyReducer: CompanyReducer,
  orderReducer: OrderReducer,
  forgotPasswordReducer: ForgotPasswordReducer
})

export default allReducers
