export const AUTHENTICATION = 'AUTHENTICATION'
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const RESET_USER_STATE = 'RESET_USER_STATE'
export const INVALID_AUTHORIZATION = 'INVALID_AUTHORIZATION'
export const RESET_ERROR_STATE = 'RESET_ERROR_STATE'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const HIDE_LOADING = 'HIDE_LOADING'
export const SHOW_LOADING = 'SHOW_LOADING'
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'

export const ADDED_COMPANY = 'ADDED_COMPANY'
export const COMPANY_PHOTO_PENDING = 'COMPANY_PHOTO_PENDING'
export const SHOW_COPY_MODAL = 'SHOW_COPY_MODAL'
export const GET_COMPANY = 'GET_COMPANY'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const BRAINTREE_CLIENT_TOKEN_SET = 'BRAINTREE_CLIENT_TOKEN_SET'
export const BRAINTREE_DEVICE_DATA_SET = 'BRAINTREE_DEVICE_DATA_SET'
export const BRAINTREE_IS_NOTICE_PERIOD = 'BRAINTREE_IS_NOTICE_PERIOD'
export const BRAINTREE_PLAN_PENDING_ID = 'BRAINTREE_PLAN_PENDING_ID'
export const BRAINTREE_PLANS_GET = 'BRAINTREE_PLANS_GET'
export const BRAINTREE_SUBSCRIPTIONS_CANCELED_IDS =
  'BRAINTREE_SUBSCRIPTIONS_CANCELED_IDS'
export const BRAINTREE_SUBSCRIPTIONS_GET = 'BRAINTREE_SUBSCRIPTIONS_GET'

export const ADDED_VENDOR = 'ADDED_VENDOR'
export const GET_VENDORS = 'GET_VENDORS'
export const GET_VENDOR = 'GET_VENDOR'
export const DELETE_VENDOR = 'DELETE_VENDOR'
export const UPDATE_VENDOR = 'UPDATE_VENDOR'
export const APPROVE_VENDOR = 'APPROVE_VENDOR'
export const GOT_USER = 'GOT_USER'
export const VENDOR_PREMIUM_ANALYTICS_ENABLE = 'VENDOR_PREMIUM_ANALYTICS_ENABLE'
export const VENDOR_PRODUCTS_GOT = 'VENDOR_PRODUCTS_GOT'

export const ADDED_COLLECTION = 'ADDED_COLLECTION'
export const COLLECTION_TRANSLATES_PENDING = 'COLLECTION_TRANSLATES_PENDING'
export const COLLECTION_TRANSLATES_PAGE = 'COLLECTION_TRANSLATES_PAGE'
export const GOT_COLLECTIONS = 'GOT_COLLECTIONS'
export const APPROVE_COLLECTION = 'APPROVE_COLLECTION'
export const DISAPPROVE_COLLECTION = 'DISAPPROVE_COLLECTION'
export const DELETED_COLLECTION = 'DELETED_COLLECTION'
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'
export const DELETED_IMAGE = 'DELETED_IMAGE'
export const GOT_COLLECTION = 'GOT_COLLECTION'
export const GET_PRODUCT_LIST_BY_COLLECTION_FOR_TRANSLATES =
  'GET_PRODUCT_LIST_BY_COLLECTION_FOR_TRANSLATES'

export const ADDED_PRODUCT = 'ADDED_PRODUCT'
export const SAVE_IN_DRAFT = 'SAVE_IN_DRAFT'
export const SEND_TO_PROOF_READING = 'SEND_TO_PROOF_READING'
export const GET_PRODUCT = 'GET_PRODUCT'
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const ADDED_PRODUCTS_FROM_COLLECTION = 'ADDED_PRODUCTS_FROM_COLLECTION'
export const GOT_PRODUCTS_FROM_COLLECTION = 'GOT_PRODUCTS_FROM_COLLECTION'
export const DELETE_PRODUCTS = 'DELETE_PRODUCTS'
export const PRODUCT_AUDITED = 'PRODUCT_AUDITED'
export const PRODUCT_DISCONTINUED = 'PRODUCT_DISCONTINUED'
export const PRODUCT_REACTIVATED = 'PRODUCT_REACTIVATED'
export const PRODUCT_CARE_INSTRUCTIONS_PENDING =
  'PRODUCT_CARE_INSTRUCTIONS_PENDING'
export const PRODUCT_CARE_INSTRUCTIONS_UPDATE =
  'PRODUCT_CARE_INSTRUCTIONS_UPDATE'
export const PRODUCT_MAIN_IMAGE_PENDING = 'PRODUCT_MAIN_IMAGE_PENDING'
export const PRODUCT_MAIN_IMAGE_UPLOADED = 'PRODUCT_MAIN_IMAGE_UPLOADED'
export const PRODUCT_ONBOARDING_PHOTO_GOT = 'PRODUCT_ONBOARDING_PHOTO_GOT'
export const PRODUCT_OTHER_IMAGE_PENDING = 'PRODUCT_OTHER_IMAGE_PENDING'
export const PRODUCT_ATTACHEMENT_PENDING_ADD = 'PRODUCT_ATTACHEMENT_PENDING_ADD'
export const PRODUCT_ATTACHEMENT_PENDING_REMOVE =
  'PRODUCT_ATTACHEMENT_PENDING_REMOVE'
export const PRODUCT_OTHER_INFO_PENDING = 'PRODUCT_OTHER_INFO_PENDING'
export const PRODUCT_OTHER_INFO_UPDATE = 'PRODUCT_OTHER_INFO_UPDATE'
export const PRODUCT_PHOTOS_SET = 'PRODUCT_PHOTOS_SET'
export const PRODUCT_PROPERTIES_GOT = 'PRODUCT_PROPERTIES_GOT'
export const PRODUCTS_BY_COLLECTION_GOT = 'PRODUCTS_BY_COLLECTION_GOT'
export const PRODUCTS_REJECT_BY_ID_SUCCESS = 'PRODUCTS_REJECT_BY_ID_SUCCESS'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_ATTACHEMENT_UPDATE = 'PRODUCT_ATTACHEMENT_UPDATE'
export const PRODUCT_ATTACHEMENT_REMOVE = 'PRODUCT_ATTACHEMENT_REMOVE'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const DISABLE_ADDED_PRODUCTS_FROM_COLLECTION =
  'DISABLE_ADDED_PRODUCTS_FROM_COLLECTION'

export const SAVE_MAIN_IMAGE = 'SAVE_MAIN_IMAGE'
export const SAVE_OTHER_IMAGES = 'SAVE_OTHER_IMAGES'
export const UPDATE_PRODUCT_VALUES = 'UPDATE_PRODUCT_VALUES'
export const UPDATE_PRODUCTS_ID_BY_COLLECTION =
  'UPDATE_PRODUCTS_ID_BY_COLLECTION'
export const COPY = 'COPY'
export const GET_PRODUCT_DATA = 'GET_PRODUCT_DATA'
export const GET_PRODUCTS_DATA = 'GET_PRODUCTS_DATA'
export const GET_PRODUCT_DATA_IDS = 'GET_PRODUCT_DATA_IDS'
export const ADDED_PRODUCT_TYPE = 'ADDED_PRODUCT_TYPE'
export const DELETE_PRODUCT_DATA = 'DELETE_PRODUCT_DATA'
export const PRODUCT_DATA_ITEM_UPDATE = 'PRODUCT_DATA_ITEM_UPDATE'
export const UPDATE_PRODUCT_DATA = 'UPDATE_PRODUCT_DATA'
export const SELECT_PROD_ADDITIONAL_TYPE_ID = 'SELECT_PROD_ADDITIONAL_TYPE_ID'
export const SHOW_MODAL = 'SHOW_MODAL'
export const SHOW_EDIT_MODAL = 'SHOW_EDIT_MODAL'
export const SWITCH_DATA_TYPE = 'SWITCH_DATA_TYPE'
export const CHANGE_ADD_STATUS = 'CHANGE_ADD_STATUS'

export const TRANSLATIONS_GET_SUCCESS = 'TRANSLATIONS_GET_SUCCESS'
export const UPDATE_TRANSLATIONS = 'UPDATE_TRANSLATIONS'
export const APPROVE_ALL = 'APPROVE_ALL'
export const UPDATE_TRANSLATIONS_BY_ON_ID = 'UPDATE_TRANSLATIONS_BY_ON_ID'
export const SET_TRANSLATION_READY = 'SET_TRANSLATION_READY'

export const SAVE_PROMOTE = 'SAVE_PROMOTE'
export const SAVED_HANDLE = 'SAVED_HANDLE'
export const DELETE_URL = 'DELETE_URL'

export const GET_ORDERS = 'GET_ORDERS'
export const ORDER_SHIPMENT_PENDING = 'ORDER_SHIPMENT_PENDING'
export const ORDER_SHIPMENT_STATE = 'ORDER_SHIPMENT_STATE'
export const ORDERS_FETCHING = 'ORDERS_FETCHING'
export const ORDERS_PAGE_SET = 'ORDERS_PAGE_SET'
export const USER_EMAIL_UPDATE_SUCCESS = 'USER_EMAIL_UPDATE_SUCCESS'
