import cn from 'classnames'
import { Tooltip } from 'components'
import { Button } from 'components/buttons'
import DropdownToggle from 'components/DropdownToggle/'
import { FieldsList, Input } from 'components/formParts'
import { ProductPackaging } from 'components/formParts'
import { Field, Form, Formik } from 'formik'
import gIcons from 'glyphicons'
import { get, noop } from 'lodash'
import {
  array,
  arrayOf,
  bool,
  element,
  func,
  node,
  shape,
  string
} from 'prop-types'
import React, { useEffect } from 'react'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { FormattedMessage, injectIntl } from 'react-intl'
import { scroller } from 'react-scroll'
import {
  Col,
  DropdownItem,
  DropdownMenu,
  Row,
  UncontrolledButtonDropdown
} from 'reactstrap'
import { ERRORS, USER_TYPES } from 'utils/constants'
import { SELECT_OPTIONS_TYPE } from 'utils/propTypes'
import * as Yup from 'yup'

import {
  additionalFields,
  certificationsFields,
  colsWidth,
  dimensionsFields,
  ERRORS_ORDER,
  INITIAL_VALUES,
  leftFields2,
  mainColor,
  materials,
  moreInfoFields,
  postFields,
  rightFields,
  rightFields2,
  translationBlockFields,
  validate,
  weightColsWidth
} from './config'
import st from './ProductDetailForm.module.css'

const ProductDetailForm = ({
  allowBlock,
  allowDelete,
  allowTranslate,
  categoryOptions,
  className,
  colorOptions,
  dataItems,
  decimalSign,
  designerOptions,
  fileInputs,
  highResImagesInputs,
  intl,
  lang,
  materialOptions,
  onboardingImage,
  otherImages,
  showCommentsInput,
  userType,
  onBlock,
  onCategoryChange,
  onCopyTo,
  onDelete,
  onAudit,
  onFlagAsIncomplete,
  onFlagAsDiscontinued,
  flaggedAsDiscontinuedOn,
  onReactivateProduct,
  shippingInOptions,
  vendorId,
  productId,
  ...props
}) => {
  return (
    <Formik
      {...props}
      enableReinitialize
      validate={validate}
      validationSchema={Yup.object().shape({
        parcels: Yup.array().of(
          Yup.object().shape({
            weight: Yup.number()
              .min(0.1, intl.formatMessage({ id: ERRORS.ENTER_A_VALUE }))
              .required(intl.formatMessage({ id: ERRORS.ENTER_A_VALUE })),
            D: Yup.number()
              .min(0.1, intl.formatMessage({ id: ERRORS.ENTER_A_VALUE }))
              .required(intl.formatMessage({ id: ERRORS.ENTER_A_VALUE })),
            W: Yup.number()
              .min(0.1, intl.formatMessage({ id: ERRORS.ENTER_A_VALUE }))
              .required(intl.formatMessage({ id: ERRORS.ENTER_A_VALUE })),
            H: Yup.number()
              .min(0.1, intl.formatMessage({ id: ERRORS.ENTER_A_VALUE }))
              .required(intl.formatMessage({ id: ERRORS.ENTER_A_VALUE }))
          })
        )
      })}
    >
      {({ errors, isSubmitting, values }) => {
        const canBeRemade = get(values, 'canBeRemade', false)
        const blocked = get(values, 'blocked', false)
        const copyingAllow = !blocked
        const aFields = additionalFields({
          blocked,
          dataItems,
          decimalSign,
          formatMessage: intl.formatMessage,
          lang,
          userType
        })
        const labelComments = intl.formatMessage({ id: 'Comments' })
        const labelWeight = `${intl.formatMessage({ id: 'Weight' })} *`

        useEffect(() => {
          if (isSubmitting) {
            return
          }

          const errorField = ERRORS_ORDER.find((eo) => errors[eo])

          if (errorField) {
            scroller.scrollTo(errorField, {
              delay: 0,
              duration: 800,
              smooth: 'easeInOutQuart'
            })
          }
        }, [isSubmitting])

        return (
          <Form
            className={cn(st.form, {
              [className]: className.length > 0
            })}
          >
            <Row className={st.row1}>
              <Col {...colsWidth}>
                {onboardingImage && (
                  <div className={st.onboardingImage}>{onboardingImage}</div>
                )}
              </Col>
              <Col {...colsWidth}>
                <FieldsList
                  fields={rightFields({
                    designerOptions,
                    intl
                  })}
                />
                {(vendorId === 'DANEMI' || vendorId === 'FORNMI') && (
                  <Field
                    component={Input}
                    label="External ID"
                    name="externalId"
                    placeholder="Fornasetti only"
                  />
                )}
              </Col>
            </Row>
            <Row className="rowAlignBottom">
              <Col md={6} xs={12}>
                <FieldsList
                  alignBottom
                  fields={mainColor({
                    colorOptions,
                    intl
                  })}
                />
              </Col>
              <Col md={6} xs={12}>
                <FieldsList
                  colsWidth={{ xs: 12, sm: 6 }}
                  fields={materials({
                    intl,
                    materialOptions
                  })}
                />
              </Col>
            </Row>
            <Row className="rowAlignBottom">
              <Col md={6} xs={12}>
                <FieldsList
                  alignBottom
                  colsWidth={{ xs: 12, sm: 6 }}
                  fields={leftFields2({
                    blocked,
                    decimalSign,
                    intl,
                    userType
                  })}
                />
              </Col>
              <Col md={6} xs={12}>
                <FieldsList
                  fields={rightFields2({
                    blocked,
                    decimalSign,
                    intl,
                    userType
                  })}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className={cn('rowAlignBottom', st.rowMargins)}>
                  <Col {...weightColsWidth}>
                    <Field
                      isScrollTo
                      separateNumberValue
                      className={st.field}
                      component={Input}
                      decimalSign={decimalSign}
                      label={labelWeight}
                      name="weight"
                      placeholder={labelWeight}
                      type="number"
                      labelAfter={
                        <Tooltip
                          id="weight-tooltip"
                          tooltip={intl.formatMessage({
                            id: 'decimalsTooltip'
                          })}
                        >
                          <AiFillQuestionCircle className={st.popup} />
                        </Tooltip>
                      }
                    />
                  </Col>
                  <Col>
                    <FieldsList
                      colsWidth={weightColsWidth}
                      fields={dimensionsFields({
                        decimalSign,
                        intl
                      })}
                      gutterWidth={10}
                      title={`${intl.formatMessage({ id: 'Dimensions' })} *`}
                      labelAfter={
                        <Tooltip
                          id="dimensions-tooltip"
                          tooltip={intl.formatMessage({
                            id: 'decimalsTooltip'
                          })}
                        >
                          <AiFillQuestionCircle className={st.popup} />
                        </Tooltip>
                      }
                    />
                  </Col>
                </Row>
                <Field
                  disabled
                  className={st.field}
                  component={Input}
                  label={intl.formatMessage({
                    id: 'Product Category'
                  })}
                  name="category"
                  options={categoryOptions}
                  placeholder={intl.formatMessage({ id: 'Select' })}
                  onChange={onCategoryChange}
                />
                <Field
                  horizontal
                  reversed
                  className={st.suitableForContract}
                  component={Input}
                  label={intl.formatMessage({
                    id: 'suitableForContract'
                  })}
                  name="suitableForContract"
                  type="checkbox"
                />
                {aFields.length > 0 && <FieldsList fields={aFields} />}
              </Col>
              <Col {...colsWidth}>
                <Row className={st.noMargin}>
                  <Col>
                    <Field
                      className={st.field}
                      component={Input}
                      label={intl.formatMessage({
                        id: 'Retail price USD'
                      })}
                      name="retailUSD"
                      placeholder={intl.formatMessage({
                        id: 'Type price here'
                      })}
                      type="number"
                    />
                  </Col>
                  <Col>
                    <Field
                      className={st.field}
                      component={Input}
                      label={intl.formatMessage({
                        id: 'Retail price GBP'
                      })}
                      name="retailGBP"
                      placeholder={intl.formatMessage({
                        id: 'Type price here'
                      })}
                      type="number"
                    />
                  </Col>
                </Row>

                <FieldsList
                  colsWidth={{ sm: 6, xs: 12 }}
                  fields={postFields({ intl, userType })}
                />
                <FieldsList
                  colsWidth={{ sm: 6, xs: 12 }}
                  fields={certificationsFields()}
                  title={intl.formatMessage({ id: 'Certifications' })}
                />
                {showCommentsInput && (
                  <Field
                    component={Input}
                    label={labelComments}
                    name="comments"
                    placeholder={labelComments}
                  />
                )}
                {fileInputs && (
                  <div className={st.fileInputs}>{fileInputs}</div>
                )}
              </Col>
            </Row>
            <Row className={st.row}>
              <Col {...colsWidth}>
                <ProductPackaging
                  productId={productId}
                  parcels={values.parcels}
                  lightInputs
                />
              </Col>
              <Col {...colsWidth}>
                <h2 className="mod1">
                  <FormattedMessage id="Shipping time" />
                </h2>
                <Field
                  isScrollTo
                  separateNumberValue
                  className={st.packagingTime}
                  component={Input}
                  decimalSign={decimalSign}
                  label={`${intl.formatMessage({
                    id: 'Number of days'
                  })}*`}
                  name="packagingTime"
                  placeholder={intl.formatMessage({
                    id: 'Type amount here'
                  })}
                  type="number"
                />
              </Col>
            </Row>
            <Row className={st.row}>
              <Col {...colsWidth}>
                <h2 className="mod1">
                  <FormattedMessage id="Hi res images" />
                </h2>
                {highResImagesInputs}
              </Col>
              <Col {...colsWidth}>
                <h2 className="mod1">
                  <FormattedMessage id="More info" />
                </h2>
                <FieldsList
                  topMarginLess
                  fields={moreInfoFields({
                    intl,
                    shippingInOptions,
                    nextNodeRemade: (
                      <Field
                        horizontal
                        reversed
                        separateNumberValue
                        decimalSign={decimalSign}
                        disabled={!canBeRemade}
                        inputClassName={st.daysInput}
                        component={Input}
                        label={intl.formatMessage({
                          id: 'calendar days'
                        })}
                        name="remadeDays"
                        type="number"
                      />
                    )
                  })}
                />
                <Field
                  isScrollTo
                  className={st.field}
                  component={Input}
                  label={`${intl.formatMessage({ id: 'Stock quantity' })}*`}
                  name="stockQuantity"
                  placeholder={`${intl.formatMessage({
                    id: 'Stock quantity'
                  })}*`}
                  type="number"
                  min="0"
                />
                <Field
                  isScrollTo
                  className={st.field}
                  component={Input}
                  label={intl.formatMessage({
                    id: 'Year of introduction into market'
                  })}
                  name="yearIntoMarket"
                  placeholder={intl.formatMessage({
                    id: 'Year of introduction into market'
                  })}
                  type="number"
                />
              </Col>
            </Row>
            {otherImages && (
              <Row>
                <Col xs={12}>{otherImages}</Col>
              </Row>
            )}
            <Row>
              <Col className={st.verticalCenter} sm={4} xs={12}>
                {allowBlock && allowTranslate && (
                  <FieldsList
                    colsWidth={{ sm: 6, xs: 12 }}
                    fields={translationBlockFields({ intl, onBlock })}
                  />
                )}
              </Col>
              <Col className={st.verticalCenter} sm={2} xs={12}>
                {allowDelete && (
                  <Button
                    lightBrown
                    thin
                    disabled={!allowDelete}
                    onClick={onDelete}
                  >
                    {intl.formatMessage({ id: 'Delete product' })}{' '}
                    {gIcons.cross}
                  </Button>
                )}
              </Col>
              <Col className={st.buttons} {...colsWidth}>
                {userType === USER_TYPES.ADMIN ||
                userType === USER_TYPES.LOGISTICS ? (
                  <UncontrolledButtonDropdown className={st.saveAdminWrapper}>
                    <Button black type="submit" className={st.saveAdmin}>
                      <FormattedMessage id="Save" />
                    </Button>
                    <DropdownToggle split black></DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>
                        <FormattedMessage id="Actions" />
                      </DropdownItem>
                      <DropdownItem onClick={onAudit}>Audit</DropdownItem>
                      <DropdownItem onClick={onFlagAsIncomplete}>
                        Flag as incomplete
                      </DropdownItem>
                      {flaggedAsDiscontinuedOn ? (
                        <DropdownItem onClick={onReactivateProduct}>
                          <FormattedMessage id="Reactivate product" />
                        </DropdownItem>
                      ) : (
                        <DropdownItem onClick={onFlagAsDiscontinued}>
                          <FormattedMessage id="Flag as discontinued" />
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                ) : (
                  <Button black type="submit">
                    <FormattedMessage id="Save" />
                  </Button>
                )}

                {copyingAllow && (
                  <Button onClick={onCopyTo}>
                    <FormattedMessage id="Copy To" />
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

ProductDetailForm.defaultProps = {
  allowBlock: true,
  allowDelete: true,
  allowTranslate: true,
  categoryOptions: [],
  className: '',
  colorOptions: [],
  dataItems: [],
  decimalSign: '.',
  designerOptions: [],
  fileInputs: null,
  highResImagesInputs: null,
  initialValues: INITIAL_VALUES.DEFAULT_PROPS,
  lang: '',
  materialOptions: [],
  onboardingImage: null,
  otherImages: null,
  showCommentsInput: false,
  userType: '',
  onBlock: noop,
  onCategoryChange: noop,
  onCopyTo: noop,
  onDelete: noop,
  onSubmit: noop,
  onFlagAsIncomplete: noop,
  onFlagAsDiscontinued: noop,
  onAudit: noop,
  productAuditColor: '',
  parcels: [],
  flaggedAsDiscontinuedOn: false,
  vendorId: ''
}
ProductDetailForm.propTypes = {
  allowBlock: bool,
  allowDelete: bool,
  allowTranslate: bool,
  categoryOptions: SELECT_OPTIONS_TYPE,
  className: string,
  colorOptions: SELECT_OPTIONS_TYPE,
  copyingAllow: bool,
  dataItems: arrayOf(
    shape({
      name: string,
      type: string
    })
  ),
  decimalSign: string,
  designerOptions: SELECT_OPTIONS_TYPE,
  shippingInOptions: SELECT_OPTIONS_TYPE,
  fileInputs: element,
  highResImagesInputs: node,
  initialValues: INITIAL_VALUES.PROP_TYPES,
  lang: string,
  materialOptions: SELECT_OPTIONS_TYPE,
  onboardingImage: node,
  otherImages: element,
  showCommentsInput: bool,
  userType: string,
  onBlock: func,
  onCategoryChange: func,
  onCopyTo: func,
  onDelete: func,
  onSubmit: func,
  onAudit: func,
  onFlagAsIncomplete: func,
  onFlagAsDiscontinued: func,
  flaggedAsDiscontinuedOn: bool,
  onReactivateProduct: func,
  intl: shape({
    formatMessage: func
  }),
  productAuditColor: string,
  parcels: array,
  vendorId: string,
  productId: string
}

export default injectIntl(ProductDetailForm)
