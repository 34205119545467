import cn from 'classnames'
import { Close } from 'components/buttons'
import { noop } from 'lodash'
import { bool, element, func, string } from 'prop-types'
import React from 'react'

import st from './CloseFrame.module.css'

const CloseFrame = ({ children, className, disabled, onClose }) => (
  <div
    className={cn(st.icon, {
      [className]: className.length > 0
    })}
  >
    {children}
    {!disabled && <Close className={st.close} onClick={onClose} />}
  </div>
)
CloseFrame.defaultProps = {
  children: null,
  className: '',
  disabled: false,
  onClose: noop
}
CloseFrame.propTypes = {
  children: element,
  className: string,
  disabled: bool,
  onClose: func
}

export default CloseFrame
