import cn from 'classnames'
import { Button } from 'components/buttons'
import { noop } from 'lodash'
import { element, func, node, string } from 'prop-types'
import React from 'react'

import st from './ThinIconBtn.module.css'

const ThinIconBtn = ({ children, className, color, icon, ...props }) => {
  const style = { color }

  return (
    <Button
      {...props}
      thin
      className={cn(st.btn, {
        [className]: className.length > 0
      })}
      style={style}
    >
      {children}
      <span className={st.icon}>{icon}</span>
    </Button>
  )
}

ThinIconBtn.defaultProps = {
  children: null,
  className: '',
  color: '#B69864',
  icon: null,
  onClick: noop
}
ThinIconBtn.propTypes = {
  children: node,
  className: string,
  color: string,
  icon: element,
  onClick: func
}

export default ThinIconBtn
