import cn from 'classnames'
import { string } from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

import st from './NewOnboarding.module.css'

const NewOnboarding = ({ className }) => (
  <div
    className={cn(st.wrap, {
      [className]: className.length > 0
    })}
  >
    <p className={st.title}>
      <FormattedMessage id="new onboarding" />
    </p>
    <Link to="/onboarding">
      <Button className={st.button} color="primary">
        <FormattedMessage id="Start" />
      </Button>
    </Link>
  </div>
)
NewOnboarding.defaultProps = {
  className: ''
}
NewOnboarding.propTypes = {
  className: string
}

export default NewOnboarding
