import { checkPassword } from 'utils/auth'
import { ERRORS } from 'utils/constants'
import { passwordErrors } from 'utils/form'

/**
 * @param {Object} config
 * @param {Function} config.formatMessage
 */
export const validate =
  (config = {}) =>
  (values = {}) => {
    const { formatMessage } = config
    const { confirmPassword, password } = values
    const errors = {}
    const passwordError = checkPassword(password)

    if (!password) {
      errors.password = formatMessage({ id: ERRORS.REQUIRED })
    }

    passwordErrors({
      errorField: 'password',
      errors,
      formatMessage,
      passwordCheckObj: passwordError
    })

    if (confirmPassword !== password) {
      errors.confirmPassword = formatMessage({
        id: ERRORS.PASSWORDS_MUST_MATCH
      })
    }

    return errors
  }
