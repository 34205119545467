import 'react-perfect-scrollbar/dist/css/styles.css'

import cn from 'classnames'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { PROP_TYPES } from './config'
import st from './FloatList.module.css'

const FloatList = ({ className, activeClassName, items, mod, noWrap }) => {
  const listEl = (
    <ul
      className={cn(st.list, className, {
        [st.mod1]: mod === 1,
        [st.noWrap]: noWrap
      })}
    >
      {items.map(({ active, content, id }) => (
        <li
          className={cn({
            [st.active]: active,
            [activeClassName]: active
          })}
          key={id}
        >
          {content}
        </li>
      ))}
    </ul>
  )

  return noWrap ? <PerfectScrollbar>{listEl}</PerfectScrollbar> : listEl
}

FloatList.defaultProps = {
  className: '',
  items: [],
  mod: 0,
  noWrap: false
}
FloatList.propTypes = PROP_TYPES

export default FloatList
