import Loading from 'components/loading/loading'
import { noop } from 'lodash'
import { func, number, oneOfType, shape, string } from 'prop-types'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { readProductsId } from 'redux/service/product-types-service'
import { mapProductTypes, sortProdTypesByNameAsc } from 'utils/products'
import notify, { notifyPositions } from 'utils/toast'

import { mapState } from './config'
import st from './style.module.css'

const ProductDataTypesAutocomplete = ({
  defaultValue,
  id,
  intl,
  lang,
  optionId,
  placeholder,
  selectElement
}) => {
  const formattedPlaceholder =
    intl.formatMessage({ id: placeholder }) || placeholder
  const inputProps = {
    className: st.inputField
  }
  const inputRef = useRef()
  const [options, setOptions] = useState([])

  useEffect(() => {
    readProductsId()
      .then((res) => {
        const { data, status } = res

        if (status === 200) {
          const mappedProductTypes = mapProductTypes({
            lang,
            productTypes: data
          })
            .filter(({ name }) => name)
            .sort(sortProdTypesByNameAsc)

          setOptions(mappedProductTypes)
        }
      })
      .catch(() => {
        notify(
          'error',
          intl.formatMessage({
            id: 'can not fetch data from server'
          }),
          notifyPositions.bottom.center
        )
      })
  }, [])

  const clickBtn = () => {
    inputRef.current.getInstance().focus()
  }
  const handleChange = (e) => {
    if (e[0]) {
      selectElement(e[0], optionId || null)
    }
  }

  return options ? (
    <div className={st.input}>
      <Typeahead
        defaultSelected={[defaultValue || '']}
        id={id}
        inputProps={inputProps}
        labelKey="name"
        options={options}
        placeholder={formattedPlaceholder}
        ref={inputRef}
        onChange={handleChange}
      />
      <span className={st.btn} onClick={clickBtn} />
    </div>
  ) : (
    <Fragment>
      <Loading />
      <h3>
        <FormattedMessage id="Waiting..." />
      </h3>
    </Fragment>
  )
}
ProductDataTypesAutocomplete.defaultProps = {
  id: '',
  optionId: '',
  placeholder: 'Choose a state...',
  selectElement: noop
}
ProductDataTypesAutocomplete.propTypes = {
  id: oneOfType([number, string]),
  optionId: oneOfType([number, string]),
  placeholder: string,
  selectElement: func,
  defaultValue: string,
  lang: string,
  intl: shape({
    formatMessage: func
  })
}

export default connect(mapState)(injectIntl(ProductDataTypesAutocomplete))
