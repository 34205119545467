import { cloneDeep } from 'lodash'

import {
  ADDED_PRODUCT_TYPE,
  AUTHENTICATION_ERROR,
  DELETE_PRODUCT_DATA,
  GET_PRODUCT_DATA,
  GET_PRODUCT_DATA_IDS,
  GET_PRODUCTS_DATA,
  PRODUCT_DATA_ITEM_UPDATE,
  SELECT_PROD_ADDITIONAL_TYPE_ID,
  SHOW_EDIT_MODAL,
  SHOW_MODAL,
  UPDATE_PRODUCT_DATA
} from '../actions/action-types'

const defaultState = {
  added: false,
  deleted: false,
  editId: '',
  editItems: [],
  gotProductData: null,
  gotProductsDataIds: [],
  id: '',
  isOpen: false,
  isOpenEditModal: false,
  product: null,
  productData: null,
  productsData: [],
  productsDataIds: [],
  updatedProductData: null
}

const ProductDataReducer = (state = defaultState, action) => {
  switch (action.type) {
    case AUTHENTICATION_ERROR:
      return {
        ...state,
        ...defaultState
      }

    case GET_PRODUCT_DATA:
      return {
        ...state,
        gotProductData: action.gotProductData,
        productData: action.productData
      }
    case GET_PRODUCTS_DATA:
      return {
        ...state,
        gotProductsData: action.gotProductsData,
        productsData: action.productsData
      }
    case GET_PRODUCT_DATA_IDS:
      return {
        ...state,
        gotProductsDataIds: action.gotProductsDataIds,
        productsDataIds: action.productsDataIds
      }
    case ADDED_PRODUCT_TYPE:
      return {
        ...state,
        added: action.added,
        product: action.product
      }
    case DELETE_PRODUCT_DATA:
      return {
        ...state,
        deleted: action.deleted
      }

    case PRODUCT_DATA_ITEM_UPDATE: {
      const { dataItem } = action.payload
      const productsData = cloneDeep(state.productsData || [])
      const idx = productsData.findIndex((pd) => pd._id === dataItem._id)

      productsData[idx] = dataItem

      return {
        ...state,
        productsData
      }
    }

    case UPDATE_PRODUCT_DATA:
      return {
        ...state,
        updatedProductData: action.updatedProductData
      }
    case SELECT_PROD_ADDITIONAL_TYPE_ID:
      return {
        ...state,
        id: action.id
      }
    case SHOW_MODAL:
      return {
        ...state,
        isOpen: action.isOpen
      }
    case SHOW_EDIT_MODAL:
      return {
        ...state,
        isOpenEditModal: action.isOpenEditModal,
        editItems: action.editItems,
        editId: action.editId
      }
    default:
      return state
  }
}

export default ProductDataReducer
