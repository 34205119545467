import { arrayOf, element, number, oneOfType, shape, string } from 'prop-types'

export const PROP_TYPES_PRODUCTS = arrayOf(
  shape({
    _id: oneOfType([number, string]).isRequired,
    alt: string.isRequired,
    hits: number,
    hitsIcon: element,
    imageUrl: string,
    name: oneOfType([element, string]),
    path: string
  })
)
