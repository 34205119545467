import { Button } from 'components/buttons'
import { InputFile } from 'components/formParts'
import { InnerPage } from 'components/layout'
import Loading from 'components/loading/loading'
import { get, isEqual } from 'lodash'
import { func, shape } from 'prop-types'
import React, { Component } from 'react'
import { FaTimes } from 'react-icons/fa'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Col, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap'
import {
  DeletePic,
  GetVendor,
  SaveHandle,
  SavePromote
} from 'redux/actions/vendor-action'
import { ACCEPTED_FILE_TYPES } from 'utils/constants'

import Style from './styles/promote-ui.module.css'

class PromoteUi extends Component {
  state = {
    instaName: '',
    uploadedImages: []
  }

  componentDidMount() {
    const id = this.getId()

    this.props.GetVendor({ vendorId: id })
  }

  componentDidUpdate(prev) {
    const {
      vendorReducer: { vendor: prevVendor }
    } = prev
    const {
      vendorReducer: { vendor }
    } = this.props

    const images = get(vendor, 'images', [])
    const instaName = get(vendor, 'instaName', '')
    const prevImages = get(prevVendor, 'images', [])
    const prevInstaName = get(prevVendor, 'instaName', '')
    const { instaName: stateInstaName, uploadedImages } = this.state

    if (prevInstaName !== instaName && stateInstaName) {
      this.setState({ instaName: '' })
    }
    if (!isEqual(images, prevImages) && uploadedImages.length > 0) {
      this.setState({ uploadedImages: [] })
    }
  }

  changeInstaInput = (e) => {
    this.setState({ instaName: e.target.value })
  }

  changePhotoInput = (e) => {
    this.setState({ uploadedImages: e.target.files })
  }

  deleteImageFiles = () => {
    this.setState({ uploadedImages: [] })
  }

  getId = () => get(this.props, 'match.params.vendor', '')

  saveHandleOfInstagram = () => {
    const { instaName } = this.state
    this.props.saveHandle(this.getId(), instaName)
  }

  uploadImages = () => {
    const id = this.getId()
    const { uploadedImages } = this.state

    this.props.savePromote(id, {
      images: uploadedImages
    })
  }

  renderImages = (images = []) => {
    const id = this.getId()

    return images.map((image) => (
      <Col className={Style.imageCol} key={image} md={4} lg={3}>
        <div
          className={Style.removeImage}
          onClick={() => {
            this.props.deletePic(id, image)
          }}
        >
          <FaTimes color="var(--main-red-color)" size={27} />
        </div>
        <img className={Style.image} alt="product" src={image} />
      </Col>
    ))
  }

  render() {
    const {
      intl,
      loadingReducer: { show: isLoading },
      vendorReducer: { vendor }
    } = this.props
    const { instaName, uploadedImages } = this.state
    const artisanName = get(vendor, 'name', '')
    const images = get(vendor, 'images', [])
    const vendorInstaName = get(vendor, 'instaName', '')
    const instaNamePlaceholder = `${intl.formatMessage({
      id: 'insta handle'
    })}: ${vendorInstaName}`

    return isLoading ? (
      <Loading />
    ) : (
      <InnerPage>
        <div className={Style.vendorName}>
          <p>{artisanName}</p>
        </div>
        <Row className={Style.mainRow}>
          <Col md={6} lg={6}>
            <div className={Style.descriptionDiv}>
              <div className={Style.descTitle}>
                <FormattedMessage id="Your images for social media" />
              </div>
              <div className={Style.desc}>
                <FormattedMessage id="big promote desc" />
              </div>
            </div>
            <div className={Style.instaInput}>
              <Label for="instaName">
                <FormattedMessage id="insta handle" />
              </Label>
              <InputGroup>
                <Input
                  id="instaName"
                  placeholder={instaNamePlaceholder}
                  value={instaName}
                  onChange={this.changeInstaInput}
                />
                <InputGroupAddon addonType="append">
                  <Button
                    onClick={this.saveHandleOfInstagram}
                    disabled={instaName.length === 0}
                  >
                    <FormattedMessage id="Save" />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </div>
          </Col>
          <Col md={6} lg={6}>
            <Row className="rowWide">
              <Col md={6} lg={6}>
                <InputFile
                  multiple
                  accept={ACCEPTED_FILE_TYPES.IMAGES}
                  allowDeleting={uploadedImages.length > 0}
                  className={Style.fileInput}
                  isUploaded={uploadedImages.length > 0}
                  size={200}
                  onChange={this.changePhotoInput}
                  onClose={this.deleteImageFiles}
                />
              </Col>
              <Col md={6} lg={6}>
                <Button
                  disabled={uploadedImages.length === 0}
                  onClick={this.uploadImages}
                >
                  <FormattedMessage id="upload" />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {images.length > 0 && (
          <Row className={Style.imageGallery}>{this.renderImages(images)}</Row>
        )}
      </InnerPage>
    )
  }
}

PromoteUi.propTypes = {
  GetVendor: func,
  vendorReducer: shape({
    vendor: shape({})
  }),
  saveHandle: func,
  savePromote: func,
  deletePic: func,
  intl: shape({
    formatMessage: func
  }),
  loadingReducer: shape({
    show: func
  })
}
const mapStateToProps = (state) => ({
  loadingReducer: state.loadingReducer,
  vendorReducer: state.vendorReducer
})

const mapDispatchToProps = (dispatch) => ({
  GetVendor: (config) => dispatch(GetVendor(config)),
  savePromote: (id, data) => dispatch(SavePromote(id, data)),
  saveHandle: (id, handle) => dispatch(SaveHandle(id, handle)),
  deletePic: (id, url) => dispatch(DeletePic(id, url))
})

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(PromoteUi)
)
