import cn from 'classnames'
import { Spinner, Status } from 'components'
import { BarChart } from 'components/charts'
import { VendorAnalyticsLayout } from 'components/layout'
import { ProductHeadingList, TitleIconContent } from 'components/lists'
import Tooltip from 'components/Tooltip'
import { get } from 'lodash'
import { func, number, shape, string } from 'prop-types'
import React, { useEffect, useReducer } from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { GetVendor } from 'redux/actions/vendor-action'
import { GUIDE_LINKS } from 'utils/constants'
import { canSeeCubeData, getCubeJsApi } from 'utils/cube'
import { dtFormat } from 'utils/dateTime'

import { mapState, totalData } from './config'
import { getTopProductsMw, getTotalDataMw } from './mw'
import { initialState, reducer } from './reducer'
import st from './VendorAnalytics.module.css'

const VendorAnalytics = ({
  analyticsToken,
  intl,
  location: { pathname },
  match,
  totalProducts,
  authReducer,
  vendorName,
  GetVendor
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    impressions,
    siteViews,
    topSavedProducts,
    topSavedProductsPending,
    topViewedProducts,
    topViewedProductsPending,
    totalProductDataPending,
    totalProductViews,
    totalSales
  } = state
  const vendorId = get(match, 'params.id', '')
  useEffect(() => {
    GetVendor({ vendorId })
  }, [])
  const cubeJsApi = getCubeJsApi(analyticsToken)
  useEffect(() => {
    getTopProductsMw({
      analyticsToken,
      areViewed: true,
      dimensions: ['Product.sku', 'Product.product_name', 'Product.image_link'],
      dispatch,
      measures: ['Product.detail_views'],
      queryOrder: {
        'Product.detail_views': 'desc'
      },
      timeDimensions: [
        {
          dateRange: 'Last 90 days',
          dimension: 'Product.date'
        }
      ]
    })

    getTopProductsMw({
      analyticsToken,
      dimensions: ['Product.sku', 'Product.product_name', 'Product.image_link'],
      dispatch,
      measures: ['Product.interactions'],
      queryOrder: {
        'Product.interactions': 'desc'
      },
      timeDimensions: [
        {
          dateRange: 'Last 90 days',
          dimension: 'Product.date'
        }
      ]
    })

    getTotalDataMw({
      analyticsToken,
      dispatch
    })
  }, [analyticsToken])

  const barChartQuery = {
    dimensions: [],
    measures: ['Product.detail_views'],
    timeDimensions: [
      {
        dateRange: 'Last 90 days',
        dimension: 'Product.date',
        granularity: 'day'
      }
    ]
  }

  const mapBarChartData = (dataObj) => ({
    x: dtFormat(dataObj['Product.date.day'], 'DD.MM'),
    y: parseInt(dataObj['Product.detail_views'])
  })

  const ticItems = totalData({
    impressions,
    intl,
    productViews: totalProductViews,
    siteViews,
    totalProducts,
    totalSales
  })

  const topProducts = [
    {
      headingId: 'Top Viewed Products',
      items: topViewedProducts,
      noItemsLabel: 'No items',
      pending: topViewedProductsPending
    },
    {
      headingId: 'Top Interactions',
      tooltipId: 'interactionsExplanation',
      items: topSavedProducts,
      noItemsLabel: 'No items',
      pending: topSavedProductsPending
    }
  ]

  return (
    <VendorAnalyticsLayout
      currentURL={pathname}
      timeFrame={90}
      vendorId={vendorId}
      authReducer={authReducer}
      vendorName={vendorName}
    >
      <div className={st.instructions}>
        <FormattedMessage id="Learn more about how to work with Artemest" />:
        <ul>
          <li>
            <a
              href={GUIDE_LINKS.ordersManagement}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage id="Artemest Orders Management Guide" />
            </a>
          </li>
          <li>
            <a
              href={GUIDE_LINKS.ordersVideoTutorial}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage id="Artemest Orders Video Tutorial" />
            </a>
          </li>
        </ul>
      </div>
      {canSeeCubeData({
        canSee: Boolean(analyticsToken),
        userType: get(authReducer, 'userType'),
        toShow: (
          <>
            <Spinner show={totalProductDataPending}>
              <TitleIconContent className={st.totalTop} items={ticItems} />
            </Spinner>
            <h2 className={cn('mod1', st.h2)}>
              <FormattedMessage id="views" />
            </h2>
            <section className={st.views}>
              <div className={st.diagramWrap}>
                <BarChart
                  cubeJsApi={cubeJsApi}
                  mapChartData={mapBarChartData}
                  query={barChartQuery}
                  yAllowDecimals={false}
                />
              </div>
            </section>
            <Row className={st.topProducts}>
              {topProducts.map(
                ({ headingId, items, noItemsLabel, pending, tooltipId }) => (
                  <Col key={headingId} sm={6} xs={12}>
                    <div className={st.topProductsHd}>
                      <h2 className="mod1">
                        <FormattedMessage id={headingId} />
                      </h2>
                      {tooltipId && (
                        <Tooltip
                          id={tooltipId}
                          tooltip={<FormattedMessage id={tooltipId} />}
                        >
                          <AiOutlineInfoCircle className={st.popup} />
                        </Tooltip>
                      )}
                      {items.length > 0 && (
                        <a href={`/vendor/${vendorId}/analytics/sales`}>
                          <FormattedMessage id="View More" />
                        </a>
                      )}
                    </div>
                    <Spinner show={pending} hideChildrenWhileLoading>
                      {items.length > 0 ? (
                        <ProductHeadingList
                          className={st.topProductsList}
                          items={items}
                        />
                      ) : (
                        <Status fontSize="1.2em" textAlign="left">
                          <FormattedMessage id={noItemsLabel} />
                        </Status>
                      )}
                    </Spinner>
                  </Col>
                )
              )}
            </Row>
          </>
        )
      })}
    </VendorAnalyticsLayout>
  )
}

VendorAnalytics.propTypes = {
  analyticsToken: string,
  intl: shape({
    formatMessage: func
  }),
  location: shape({
    pathname: string
  }),
  match: shape({}),
  totalProducts: number,
  authReducer: shape({}),
  GetVendor: func,
  vendorName: string
}

const mapActions = {
  GetVendor
}
export default injectIntl(connect(mapState, mapActions)(VendorAnalytics))
