import cn from 'classnames'
import { Close } from 'components/buttons'
import Spinner from 'components/Spinner/Spinner'
import { noop, uniqueId } from 'lodash'
import {
  bool,
  element,
  func,
  number,
  oneOfType,
  shape,
  string
} from 'prop-types'
import React, { createRef, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  INPUT_FILE_DISPLAYED_THUMBNAILS_FILETYPE,
  INPUT_FILE_SIZE
} from 'utils/constants'
import { BAD_IMAGE_DIMENSIONS } from 'utils/errors'
import { checkFileType, getImagesDimensions } from 'utils/files'
import { getURLExtension } from 'utils/utils'

import st from './InputFile.module.css'

const InputFile = ({
  accept,
  allowDeleting,
  alt,
  className,
  cntClassName,
  error,
  fileDimensions,
  fileUrl,
  height,
  isUploaded,
  label,
  labelClassName,
  multiple,
  size,
  subLabel,
  title,
  titleClassName,
  width,
  wrapClassName,
  onChange,
  onClose,
  onError,
  isLoading,
  disableModify,
  dataCy
}) => {
  const [imageSize, setImageSize] = useState(null)
  const wrapRef = useRef(null)
  const _input = createRef()
  const cntStyle = { paddingBottom: height || size }
  const showCancel = allowDeleting || fileUrl.length > 0
  const uId = uniqueId('InputFile-')
  const filesUploaded = fileUrl.length > 0 || isUploaded
  const uploadStr = `Upload${filesUploaded ? 'ed' : ''}`
  const wrapStyle = { width: width || size }
  const clearInputValue = () => {
    if (_input.current) {
      _input.current.value = ''
    }
  }

  const setInputSize = (ev) => {
    const imgDom = ev.target
    const imgSize = imgDom.clientHeight
    const wrapperSize = wrapRef.current.clientHeight
    const size = Math.min(imgSize, wrapperSize)

    setImageSize(size > 0 ? size : null)
  }

  useEffect(() => {
    if (!fileUrl.length) {
      clearInputValue()
      setImageSize(null)
    }
  }, [fileUrl])

  const handleChange = (e) => {
    const { files } = e.target
    const { maxHeight, maxWidth, minHeight, minWidth } = fileDimensions

    if (maxHeight || maxWidth || minHeight || minWidth) {
      const imageFiles = [...files].filter((file) =>
        checkFileType({
          file,
          type: 'image/'
        })
      )

      getImagesDimensions({ imageFiles }).then((res) => {
        const isInvalid = res.find(({ dimensions = {}, error }) => {
          const { height, width } = dimensions

          return (
            error ||
            height > maxHeight ||
            height < minHeight ||
            width > maxWidth ||
            width < minWidth
          )
        })

        if (isInvalid) {
          onError({ error: BAD_IMAGE_DIMENSIONS })
        } else {
          onChange({
            target: { files }
          })
        }
      })
    } else {
      onChange(e)
    }
  }

  const handleClose = () => {
    clearInputValue()
    onClose()
  }
  return (
    <div
      className={cn(wrapClassName, {
        [st.error]: error.length > 0
      })}
    >
      {title && (
        <div
          className={cn(st.title, {
            [titleClassName]: titleClassName.length > 0
          })}
        >
          {title}
        </div>
      )}
      <div
        className={cn(st.wrap, {
          [className]: className.length > 0
        })}
        style={wrapStyle}
        ref={wrapRef}
      >
        <div
          className={cn(st.cnt, {
            [st.hasFile]: fileUrl.length > 0,
            [cntClassName]: cntClassName.length > 0
          })}
          style={imageSize ? { height: `${imageSize}px` } : {}}
        >
          {isLoading ? (
            <Spinner show width={50} length={3} size={14} />
          ) : (
            <>
              {fileUrl.length > 0 &&
                INPUT_FILE_DISPLAYED_THUMBNAILS_FILETYPE.includes(
                  getURLExtension(fileUrl).toLowerCase()
                ) && (
                  <img
                    alt={alt}
                    className={st.file}
                    src={fileUrl}
                    onLoad={setInputSize}
                  />
                )}
              <div className={st.text}>
                <label
                  className={cn(st.label, {
                    [labelClassName]: labelClassName.length > 0
                  })}
                  htmlFor={uId}
                >
                  {label}
                </label>
                {subLabel}
                <span className={st.actionLabel}>
                  <FormattedMessage id={uploadStr} />
                </span>
              </div>
              <input
                accept={accept}
                className={st.input}
                id={uId}
                multiple={multiple}
                ref={_input}
                type="file"
                onChange={handleChange}
                disabled={disableModify}
                data-cy={dataCy}
              />
              {!disableModify && showCancel && (
                <Close className={st.close} onClick={handleClose} />
              )}
            </>
          )}
        </div>
        <span className={st.filler} style={cntStyle} />
      </div>
      {error.length > 0 && <p className={st.errorMsg}>{error}</p>}
    </div>
  )
}
InputFile.defaultProps = {
  accept: '',
  allowDeleting: false,
  alt: '',
  className: '',
  cntClassName: '',
  error: '',
  fileDimensions: {},
  fileUrl: '',
  height: '',
  isUploaded: false,
  label: '',
  labelClassName: '',
  multiple: false,
  size: INPUT_FILE_SIZE,
  subLabel: null,
  title: null,
  titleClassName: '',
  width: 0,
  wrapClassName: '',
  onChange: noop,
  onClose: noop,
  onError: noop,
  isLoading: false,
  disableModify: false
}
InputFile.propTypes = {
  accept: string,
  allowDeleting: bool,
  alt: string,
  className: string,
  cntClassName: string,
  error: string,
  fileDimensions: shape({
    maxHeight: number,
    maxWidth: number,
    minHeight: number,
    minWidth: number
  }),
  fileUrl: string,
  height: oneOfType([number, string]),
  isUploaded: bool,
  label: string,
  labelClassName: string,
  multiple: bool,
  size: number,
  subLabel: oneOfType([element, string]),
  title: oneOfType([element, string]),
  titleClassName: string,
  width: oneOfType([number, string]),
  wrapClassName: string,
  onChange: func,
  onClose: func,
  onError: func,
  isLoading: bool,
  disableModify: bool,
  dataCy: string
}

export default InputFile
