import cookie from 'react-cookies'
import { COOKIES, LANG_EXPIRES } from 'utils/constants'
import { getDecimalSignByLang } from 'utils/number'

import { changeLanguage } from '../service/user-service'
import { CHANGE_LANGUAGE } from './action-types'

export const Language = (lang) => ({
  payload: {
    decimalSign: getDecimalSignByLang(lang),
    lang
  },
  type: CHANGE_LANGUAGE
})

const ChangeLanguages = (lang, id) => (dispatch) => {
  return changeLanguage(lang, id)
    .then((res) => {
      if (res.status === 200) {
        cookie.save(COOKIES.LANG, lang, {
          expires: LANG_EXPIRES,
          path: '/'
        })
        dispatch(Language(lang))
      }
    })
    .catch(() => {
      dispatch(Language(lang))
    })
}

export default ChangeLanguages
