import { InnerPage } from 'components/layout'
import FilesListUi3 from 'components/onboarding-flow-ui-part-3-components/files-list-UI3'
import UploadFilesUi3 from 'components/onboarding-flow-ui-part-3-components/upload-files-UI3'
import { shape } from 'prop-types'
import React from 'react'
import { Col, Row } from 'reactstrap'

const OnboardingFlowUi3 = ({ match }) => (
  <InnerPage>
    <Row className="rowWide">
      <Col md={6} sm={12} lg={6}>
        <UploadFilesUi3 match={match} />
      </Col>
      <Col md={6} sm={12} lg={6}>
        <FilesListUi3 match={match} />
      </Col>
    </Row>
  </InnerPage>
)

OnboardingFlowUi3.propTypes = {
  match: shape({})
}
export default OnboardingFlowUi3
