import { string } from 'prop-types'
import React from 'react'

import st from './PlanStatus.module.css'

const PlanStatus = ({ message }) => <span className={st.wrap}>{message}</span>

PlanStatus.defaultProps = {
  message: ''
}
PlanStatus.propTypes = {
  message: string
}

export default PlanStatus
