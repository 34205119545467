import cn from 'classnames'
import { bool, func, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { FaPlus, FaTrashAlt } from 'react-icons/fa'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'
import { Add, ShowModal } from 'redux/actions/product-types-action'

import Style from './add-product-data-types.module.css'

const FIELDS_INPUTS_DATA = [
  {
    langCode: 'EN',
    name: 'nameEn',
    valueKey: 'valueEn'
  },
  {
    langCode: 'IT',
    name: 'nameIt',
    valueKey: 'valueIt'
  }
]
const initState = {
  dataNameEn: '',
  dataNameIt: '',
  input: {
    valueEn: '',
    valueIt: '',
    type: ''
  }
}

class AddProductDataTypes extends Component {
  state = {
    ...initState,
    inputs: [],
    modal: this.props.open
  }

  componentDidMount() {
    this.setState({ modal: this.props.open })
  }

  componentDidUpdate(prevProps) {
    const {
      productDataReducer: { added: prevAdded }
    } = prevProps
    const {
      productDataReducer: { added }
    } = this.props

    if (added && added !== prevAdded) {
      this.preparingInputsToAddNewItem()
    }
  }

  renderInputs = () => {
    const { inputs } = this.state

    return inputs.map(
      (input, i) =>
        input && (
          <li className={cn('list-group-item', Style.vendorInfo)} key={i}>
            <p className={Style.vendorInfoKey}>
              EN: {input.valueEn}
              <br />
              IT: {input.valueIt}
            </p>
            <p className={Style.vendorInfoValue}>{input.type}</p>
            <Button
              className={'btn btn-primary'}
              onClick={this.removeInput.bind(this, input.id)}
            >
              <FaTrashAlt size={16} color={'var(--main-red-color)'} />
            </Button>
          </li>
        )
    )
  }

  removeInput = (id) => {
    const inputs = this.state.inputs
      .filter((el) => el.id !== id)
      .map((el, i) => {
        el.id = i
        return el
      })
    this.setState({ inputs: inputs })
  }

  clearState = () => {
    this.setState({ ...initState })
  }

  closeModal = () => {
    this.props.ShowModal(false)
    this.clearState()
  }

  changeHandler = (e, objKey) => {
    const currentInput = this.state.input
    currentInput[objKey] = e.target.value
    this.setState({
      input: currentInput
    })
  }

  fillInputs = () => {
    const inputs = this.state.inputs.map((el, i) => {
      el.id = i
      return el
    })
    const input = this.state.input
    if (!input.type) input.type = 'text'
    this.setState({
      inputs: [...inputs, { id: inputs.length, ...input }],
      input: {}
    })
  }

  handleChangeNameEn = (e) => {
    this.setState({ dataNameEn: e.target.value })
  }

  handleChangeNameIt = (e) => {
    this.setState({ dataNameIt: e.target.value })
  }

  isBtnPlusDisabled = () => {
    const {
      input: { valueEn, valueIt }
    } = this.state

    return !valueEn || !valueIt
  }

  isBtnSaveDisabled = () => {
    const { dataNameEn, dataNameIt } = this.state

    return !dataNameEn || !dataNameIt
  }

  preparingInputsToAddNewItem = () =>
    this.setState({
      id: '',
      inputs: []
    })

  prepareDataForAddRequest = () => {
    const { dataNameEn, dataNameIt, inputs } = this.state
    const filteredData = inputs
      .filter(({ valueEn, valueIt }) => valueEn || valueIt)
      .map(({ valueEn, valueIt, type }) => ({
        en: valueEn || '',
        it: valueIt || '',
        type: type || 'text'
      }))
    const data = {
      _id: `${dataNameEn}-${dataNameIt}`,
      dataItems: filteredData,
      name: {
        en: dataNameEn,
        it: dataNameIt
      },
      onSuccess: this.clearState
    }

    this.props.Add(data)
  }

  renderProductDataName = () => {
    const { dataNameEn, dataNameIt } = this.state

    return [
      {
        id: 'id-EN',
        name: 'nameEn',
        placeholder: 'EN:',
        value: dataNameEn,
        onChange: this.handleChangeNameEn
      },
      {
        id: 'id-IT',
        name: 'nameIt',
        placeholder: 'IT:',
        value: dataNameIt,
        onChange: this.handleChangeNameIt
      }
    ]
  }

  render() {
    const { intl, productDataReducer } = this.props
    const productFields = this.renderProductDataName()

    return (
      <Modal
        isOpen={productDataReducer.isOpen}
        toggle={this.closeModal}
        className={this.props.className}
      >
        <ModalHeader toggle={this.closeModal}>
          <FormattedMessage id={'add product additional fields'} />
        </ModalHeader>
        <ModalBody>
          <Row className={Style.myRow}>
            <Col sm={12}>
              <div className={Style.idInputDiv}>
                <label className={Style.label} htmlFor="id-EN">
                  <FormattedMessage id="Product data name" />
                </label>
                {productFields.map((data) => (
                  <input
                    {...data}
                    required
                    className={cn('form-control', Style.idInput)}
                    key={data.id}
                    type="text"
                  />
                ))}
              </div>
            </Col>
            <Col sm={12}>
              <Row className={Style.rowAdd}>
                <Col sm={5}>
                  {FIELDS_INPUTS_DATA.map((config) => {
                    const { langCode, name, valueKey } = config

                    return (
                      <input
                        required
                        className={cn('form-control', Style.inputElement)}
                        key={langCode}
                        name={name}
                        placeholder={
                          intl.formatMessage({
                            id: 'Field Name'
                          }) + ` (${langCode})`
                        }
                        type="text"
                        value={this.state.input[valueKey] || ''}
                        onChange={(e) => {
                          this.changeHandler(e, valueKey)
                        }}
                      />
                    )
                  })}
                </Col>
                <Col sm={5}>
                  <select
                    required
                    name="name"
                    className={cn('form-control', Style.inputElement)}
                    placeholder={intl.formatMessage({
                      id: 'Product Name'
                    })}
                    value={this.state.input.type || ''}
                    onChange={(e) => this.changeHandler(e, 'type')}
                  >
                    <option>text</option>
                    <option>number</option>
                    <option>boolean</option>
                  </select>
                </Col>
                <Col md={2} sm={2}>
                  <Button
                    className={'btn btn-primary'}
                    onClick={this.fillInputs}
                    disabled={this.isBtnPlusDisabled()}
                  >
                    <FaPlus color="var(--main-green-color)" size={16} />
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={12}>{this.renderInputs()}</Col>
          </Row>
          <ModalFooter>
            <Button
              className="btn btn-primary"
              disabled={this.isBtnSaveDisabled()}
              onClick={this.prepareDataForAddRequest}
            >
              <FormattedMessage id="save" />
            </Button>
            <Button
              color={'danger'}
              onClick={() => {
                this.preparingInputsToAddNewItem()
                this.closeModal()
              }}
            >
              <FormattedMessage id={'clear'} />
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    )
  }
}

AddProductDataTypes.propTypes = {
  open: bool,
  productDataReducer: shape({
    added: bool
  }),
  ShowModal: func,
  Add: func,
  intl: shape({
    formatMessage: func
  }),
  className: string
}
const mapActions = {
  Add,
  ShowModal
}
const mapStateToProps = (state) => ({
  productDataReducer: state.productDataReducer
})

export default injectIntl(
  connect(mapStateToProps, mapActions)(AddProductDataTypes)
)
