import cn from 'classnames'
import { arrayOf, element, shape, string } from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import st from './TitleIconList.module.css'

const TitleIconList = ({ className, items }) => (
  <ul
    className={cn(st.list, {
      [className]: className.length > 0
    })}
  >
    {items.map(({ icon, path, text }) => {
      const isExternal = path
        ? path.startsWith('https://') || path.startsWith('http://')
        : false
      return (
        <li key={text}>
          {path ? (
            isExternal ? (
              <a href={path} rel="noopener noreferrer" target="_blank">
                {text}
              </a>
            ) : (
              <Link to={path}>{text}</Link>
            )
          ) : (
            text
          )}
          <span className={st.icon}>{icon}</span>
        </li>
      )
    })}
  </ul>
)

TitleIconList.defaultProps = {
  className: '',
  items: []
}
TitleIconList.propTypes = {
  className: string,
  items: arrayOf(
    shape({
      icon: element,
      path: string,
      text: string
    })
  )
}

export default TitleIconList
