import { endsWith } from 'lodash'

/**
 * @param {Object} config
 * @param {String} config.decimalSign - '.' or ','
 * @param {String} config.numeric
 */
const _separateNumeric = (config = {}) => {
  const { decimalSign = '.', numeric = '' } = config

  if (numeric.length > 3) {
    const hasSign = numeric[0] === '-'
    const numericMin = numeric.length % 3 || 3
    const separator = decimalSign === '.' ? ',' : '.'

    return numeric
      .split('')
      .map((letter, i) => {
        const noSeparator =
          i < numericMin || (i - numericMin) % 3 || (hasSign && i === 1)

        return noSeparator ? letter : `${separator}${letter}`
      })
      .join('')
  }

  return numeric
}

export const getDecimalSignByLang = (lang = '') => {
  switch (lang) {
    case 'it':
      return ','

    default:
      return '.'
  }
}

/**
 * @param {Object} config
 * @param {String} config.decimalSign
 * @param {Boolean} config.separate
 * @param {Number|String} config.value
 */
export const numberToString = (config = {}) => {
  const { decimalSign = '.', separate = false, value = '' } = config

  if (value === '-') {
    return value
  }

  const splitBy = typeof value !== 'number' ? decimalSign : '.'
  let [full, decimal] = `${value}`.split(splitBy)

  if (separate) {
    full = _separateNumeric({
      decimalSign,
      numeric: full
    })
  }

  if (typeof value !== 'number') {
    const parsed = endsWith(value, decimalSign)
      ? `${full}${decimalSign}`
      : parseFloat(`${full}.${decimal}`)

    return parsed ? `${parsed}` : ''
  }

  return decimal ? `${full}${decimalSign}${decimal}` : `${full}`
}
