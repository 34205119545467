import { Pagination } from 'components'
import { get } from 'lodash'
import { array, func, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import {
  Button,
  CustomInput,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
import {
  Copy,
  GetProductsByCollectionId,
  showCopyModal
} from 'redux/actions/product-action'

import Style from './copy-modal.module.css'

class CopyModal extends Component {
  static defaultProps = {
    product: {}
  }

  static propTypes = {
    product: shape({
      _id: string,
      vendorCollection: shape({
        _id: string
      })
    })
  }

  state = {
    selectedIds: [],
    allProductsSelected: false,
    collection: get(this.props, 'product.vendorCollection._id', ''),
    pageSize: 10,
    currentPage: 1
  }

  showModal = () => this.props.showModal(false)

  componentDidMount() {
    const { collection: collectionId } = this.state

    if (collectionId) {
      this.props.getProductsByCollectionId({ collectionId })
    }
  }

  componentWillUnmount() {
    this.setState({
      selectedIds: []
    })
  }

  handlePaginationNavigation = (currentPage = 1) => {
    this.setState({ currentPage })
  }

  toggleProductToCopy = (checkedId) => {
    const { product, productReducer } = this.props
    const { selectedIds } = this.state

    if (selectedIds.includes(checkedId)) {
      this.setState({
        selectedIds: selectedIds.filter((item) => item !== checkedId)
      })

      if (selectedIds.length !== 0) {
        this.setState({ allProductsSelected: false })
      }
    } else {
      this.setState({ selectedIds: [...selectedIds, checkedId] })

      if (
        selectedIds.length ===
        productReducer.productsId.filter((item) => item._id !== product._id)
          .length -
          1
      ) {
        this.setState({ allProductsSelected: true })
      }
    }
  }

  toggleAllProductsToCopy = (checkedIdsArray) => {
    const { allProductsSelected } = this.state

    if (Array.isArray(checkedIdsArray) && allProductsSelected) {
      this.setState({
        selectedIds: [],
        allProductsSelected: !allProductsSelected
      })
    } else {
      this.setState({
        selectedIds: checkedIdsArray,
        allProductsSelected: !allProductsSelected
      })
    }
  }

  copyState = () => {
    const { Copy, product } = this.props
    const { selectedIds } = this.state

    Copy(product._id, selectedIds, () => {
      this.setState({
        selectedIds: [],
        allProductsSelected: false
      })
    })
  }

  renderData = () => {
    const { product, productReducer } = this.props
    const { allProductsSelected, currentPage, pageSize, selectedIds } =
      this.state
    const listOfItems = productReducer.productsId
      .filter((item) => item._id !== product._id)
      .sort((a, b) => (a._id < b._id ? 1 : -1))
    const pagesCount = Math.ceil(listOfItems.length / pageSize)

    return (
      <div className={Style.listContainer}>
        {pagesCount > 1 && (
          <Pagination
            alignCenter
            current={currentPage}
            pages={pagesCount}
            visiblePages={2}
            onChange={this.handlePaginationNavigation}
          />
        )}
        {listOfItems
          .slice((currentPage - 1) * pageSize, currentPage * pageSize)
          .map((el) => (
            <ListGroupItem
              className={Style.listItem}
              key={el._id}
              tag="button"
              href="#"
              action
            >
              <FormGroup check>
                <Label check>
                  <CustomInput
                    disabled={product.blocked}
                    type="checkbox"
                    id={el._id}
                    name="customRadio"
                    onChange={this.toggleProductToCopy.bind(this, el._id)}
                    checked={selectedIds.includes(el._id)}
                    label={el._id}
                  />
                </Label>
              </FormGroup>
            </ListGroupItem>
          ))}
        <CustomInput
          disabled={product.blocked}
          type="checkbox"
          name="customRadio"
          label={`${allProductsSelected ? 'Des' : 'S'}elect all`}
          id="selectAll"
          onChange={this.toggleAllProductsToCopy.bind(
            this,
            productReducer.productsId
              .filter((item) => item._id !== product._id)
              .map((id) => id._id)
          )}
          checked={allProductsSelected}
        />
      </div>
    )
  }

  render() {
    const {
      product: { blocked },
      productReducer
    } = this.props
    const { selectedIds } = this.state
    const copyDisabled = !selectedIds.length || blocked

    return (
      <Modal isOpen={productReducer.isOpen} toggle={this.showModal}>
        <ModalHeader toggle={this.showModal}>
          <FormattedMessage id="Copy" />
        </ModalHeader>
        <ModalBody>
          <ListGroup>
            {productReducer.productsId ? this.renderData() : null}
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={copyDisabled}
            onClick={this.copyState}
            className="btn btn-primary"
          >
            <FormattedMessage id="Copy" />
          </Button>
          <Button onClick={this.showModal} className="btn btn-secondary">
            <FormattedMessage id="Cancel" />
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
CopyModal.propTypes = {
  showModal: func,
  getProductsByCollectionId: func,
  productReducer: shape({
    productsId: array
  }),
  Copy: func
}
const mapActions = {
  getProductsByCollectionId: GetProductsByCollectionId,
  Copy,
  showModal: showCopyModal
}
const mapStateToProps = (state) => ({
  productReducer: state.productReducer
})

export default injectIntl(connect(mapStateToProps, mapActions)(CopyModal))
