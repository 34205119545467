import cn from 'classnames'
import { FloatList } from 'components/lists'
import { PROP_TYPES } from 'components/lists/FloatList/config'
import { take } from 'lodash'
import { number } from 'prop-types'
import React from 'react'

import st from './ItemsVisibleList.module.css'

const ItemsVisibleList = ({ items, visibleItems, ...props }) => {
  const itemsCut =
    visibleItems > 0 && visibleItems < items.length
      ? take(items, visibleItems)
      : items
  const itemsRest = items.length - itemsCut.length

  return (
    <div
      className={cn(st.wrap, {
        [st.noVisibleItems]: visibleItems <= 0
      })}
    >
      <FloatList {...props} noWrap items={itemsCut} />
      {itemsRest > 0 && <span className={st.itemsRest}>+{itemsRest}</span>}
    </div>
  )
}
const { gutter, items } = PROP_TYPES

ItemsVisibleList.defaultProps = {
  gutter: '1em',
  items: [],
  visibleItems: 0
}
ItemsVisibleList.propTypes = {
  gutter,
  items,
  visibleItems: number
}

export default ItemsVisibleList
