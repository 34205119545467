import { get } from 'lodash'
import { array, bool, func, shape, string } from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { ApproveCollection } from 'redux/actions/collection-action'
import { isAdmin, isApprover, isManager } from 'utils/auth'
import { STATUSES } from 'utils/constants'
import { getProductsPending, typeNameByLang } from 'utils/products'

import {
  ChangeProductType,
  DeleteProduct,
  GetImages
} from '../../redux/actions/product-action'
import Loading from '../loading/loading'
import ProductDataTypesAutocomplete from '../product-dataType-select/product-data-types-autocomplete'
import Style from './css-UI3.module.css'

class FilesListUi3 extends PureComponent {
  componentDidMount() {
    this.props.GetProductsByCollection(this.getCollectionId())
  }

  componentDidUpdate(prevProps) {
    const products = this.productsGet(this.props)

    if (products.length > 0) {
      const productsPrev = this.productsGet(prevProps)
      const pPending = getProductsPending(products)
      const pPendingPrev = getProductsPending(productsPrev)

      if (pPendingPrev.length > 0 && pPending.length < 1) {
        const collectionId = this.getCollectionId()

        this.props.ApproveCollectionAc(collectionId)
      }
    }
  }

  changeProdType = (type, id) => {
    const { canAdd, ChangeProductType } = this.props

    if (canAdd) {
      ChangeProductType(id, type.id)
    }
  }

  deleteProduct = (id) => {
    this.props.DeleteProduct({ id })
  }

  getCollectionId = () => get(this.props, 'match.params.collection', '')

  productsGet = (props = {}) => {
    const { productReducer } = props

    return get(productReducer, 'GottenProducts', [])
  }

  renderImages = () => {
    const {
      canAct,
      lang,
      productReducer: { GottenProducts }
    } = this.props

    return GottenProducts.map((product) => {
      const { _id, status, mainPhoto, photos } = product
      const styleWrap = {}
      const typeAutocompleteDefaultValue = {
        name: typeNameByLang({ lang, product })
      }

      if (status === STATUSES.APPROVED) {
        styleWrap.borderColor = '#0f0'
      }
      if (status === STATUSES.REJECTED) {
        styleWrap.borderColor = '#f00'
      }

      return (
        <Col
          className={Style.productCol}
          key={_id}
          lg={6}
          md={6}
          style={styleWrap}
        >
          <Row>
            <Col md={6} lg={6}>
              <img
                className={Style.prodImage}
                src={mainPhoto || (photos && photos[0] ? photos[0] : '')}
                alt="product"
              />
            </Col>
            <Col className={Style.actionsCol} md={6} lg={6}>
              <p>{_id.substring(0, 12)}</p>
              <ProductDataTypesAutocomplete
                defaultValue={typeAutocompleteDefaultValue}
                optionId={_id}
                selectElement={this.changeProdType}
              />
              <button
                className="btn btn-primary flex-end"
                disabled={!canAct}
                onClick={this.deleteProduct.bind(this, _id)}
              >
                <FormattedMessage id="Delete" />
              </button>
            </Col>
          </Row>
        </Col>
      )
    })
  }

  render() {
    const {
      isApprover,
      productReducer: { gottenImages }
    } = this.props

    return gottenImages ? (
      <>
        <Row className={Style.title}>
          <Col className={Style.leftTitle} md={6}>
            <FormattedMessage id="In this collection" />
          </Col>
          {isApprover && (
            <Col className={Style.rightTitle} md={6}>
              <Link to={`/approval-detail/${this.getCollectionId()}`}>
                <FormattedMessage id="Approvals > " />
              </Link>
            </Col>
          )}
        </Row>
        <Row>{this.renderImages()}</Row>
      </>
    ) : (
      <Loading />
    )
  }
}

FilesListUi3.propTypes = {
  GetProductsByCollection: func,
  ApproveCollectionAc: func,
  canAdd: bool,
  ChangeProductType: func,
  DeleteProduct: func,
  canAct: bool,
  lang: string,
  productReducer: shape({
    GottenProducts: array
  }),
  isApprover: bool
}

const mapActions = {
  ApproveCollectionAc: ApproveCollection,
  DeleteProduct,
  GetProductsByCollection: GetImages,
  ChangeProductType
}

const mapStateToProps = ({
  authReducer,
  languageReducer: { lang },
  productReducer
}) => ({
  canAct: isAdmin(authReducer) || isManager(authReducer),
  isApprover: isApprover(authReducer),
  lang,
  productReducer
})

export default connect(mapStateToProps, mapActions)(FilesListUi3)
