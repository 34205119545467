import { get } from 'lodash'

import notify from './toast'

export const BRAINTREE_ERRORS = {
  GATEWAY_REJECTED_DUPLICATE: 'Gateway Rejected: duplicate',
  NONCE_MORE_THAN_ONCE: 'Cannot use a payment_method_nonce more than once.',
  PREPAID_CARD:
    'Please provide a Credit Card. Debit cards and prepaid cards are not accepted.'
}

export const BAD_IMAGE_DIMENSIONS = 'BAD_IMAGE_DIMENSIONS'

export const PRODUCT_DATA_TYPE = {
  DUPLICATE_KEY: 'duplicate key error'
}

/**
 * @param {Object} error
 * @param {String} error.message
 * @param {Object} error.response
 * @param {String} error.response.data
 * @param {Number} error.response.status
 */
export const mapError = (error = {}) => {
  const { message, response } = error

  return {
    data: get(response, 'data'),
    message,
    status: get(response, 'status')
  }
}

/**
 * @param {Object} mappedError
 * @param {Object|String} mappedError.data
 * @param {String} [mappedError.data.error]
 * @param {String} mappedError.message
 */
export const notifyMappedError = (mappedError = {}) => {
  const { data, message } = mappedError
  const dataError = get(data, 'error')

  notify('error', dataError || data || message)
}
