import { bool, func, shape } from 'prop-types'
import React from 'react'
import { FaEdit, FaRegStar } from 'react-icons/fa'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap'

import {
  ApproveCollection,
  DeleteCollection,
  DisapproveCollection,
  GetCollection
} from '../../redux/actions/collection-action'
import avatar from '../../static/images/img_avatar.png'
import Style from './colection-card.module.css'

class ColectionCards extends React.Component {
  state = {
    deleteCardModal: false,
    collection: ''
  }

  showCardDeleteModal = (id) => {
    if (id) {
      this.setState({
        collection: id || '',
        deleteCardModal: true
      })
    }
  }

  closeCardDeleteModal = () => {
    this.setState({ deleteCardModal: false })
  }

  componentDidUpdate() {
    const { collectionReducer } = this.props

    if (collectionReducer.deleted) {
      this.closeCardDeleteModal()
    }
  }

  routeEditPage = (collection) => {
    this.props.history.push({
      pathname: `/onboarding-ui3/${collection._id}`,
      state: { collection }
    })
  }

  renderCards(data) {
    if (!data || !data.collections) return null
    return data.collections.map((element) => (
      <div key={element._id} className={Style.cardDiv}>
        <div className={Style.card}>
          <div className={Style.header}>
            <p className={Style.title}>{element.name}</p>
            <div
              onClick={this.props.DisapproveCollection.bind(this, element._id)}
              className={[
                Style.action,
                element.approved ? 'd-show' : 'd-none'
              ].join(' ')}
            >
              <FaRegStar size={27} color={'var(--main-golden)'} />
            </div>
            <div
              onClick={() => {
                this.routeEditPage(element)
              }}
              className={[
                Style.action,
                !element.approved ? 'd-show' : 'd-none'
              ].join(' ')}
            >
              <FaEdit size={27} color={'var(--main-lightGreen-color)'} />
            </div>
          </div>
          <img
            className={Style.mainImage}
            src={
              element.products && element.products.length
                ? element.products[0].photos[0] || avatar
                : avatar
            }
            alt={'Avatar'}
            style={{ width: '100%' }}
          />
          <div className={Style.container}>
            <button
              onClick={this.props.ApproveCollection.bind(this, element._id)}
              className={[
                Style.submit,
                Style.alignLeft,
                !element.approved ? 'd-show' : 'd-none'
              ].join(' ')}
            >
              <FormattedMessage id={'Approve'} />
            </button>
            <button
              onClick={this.showCardDeleteModal.bind(this, element._id)}
              className={[
                Style.danger,
                Style.alignRight,
                !element.approved ? '' : Style.approved
              ].join(' ')}
            >
              <FormattedMessage id={'Delete'} />
            </button>
          </div>
        </div>
      </div>
    ))
  }

  render() {
    const [props, state] = [this.props, this.state]
    const { collectionReducer } = props
    return collectionReducer.collection ||
      (collectionReducer.collections &&
        collectionReducer.collections.length) ? (
      <div className={[Style.row, Style.main].join(' ')}>
        {this.renderCards(collectionReducer)}
        <Modal
          isOpen={state.deleteCardModal}
          toggle={this.closeCardDeleteModal}
          className={props.className}
        >
          <ModalHeader toggle={this.closeCardDeleteModal}>
            <FormattedMessage id={'Delete Collection'} />
          </ModalHeader>
          <ModalFooter>
            <Button
              color="danger"
              onClick={props.DeleteCollection.bind(this, state.collection)}
            >
              <FormattedMessage id={'Delete'} />
            </Button>{' '}
            <Button color="secondary" onClick={this.closeCardDeleteModal}>
              <FormattedMessage id={'Cancel'} />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    ) : (
      <div className={Style.noCollection}>
        <FormattedMessage id={'noCollection'} />
      </div>
    )
  }
}

ColectionCards.propTypes = {
  collectionReducer: shape({ deleted: bool }),
  history: func,
  DisapproveCollection: func,
  ApproveCollection: func
}

const mapStateToProps = (state) => ({
  collectionReducer: state.collectionReducer
})

const mapDispatchToProps = (dispatch) => ({
  DeleteCollection: (id) => dispatch(DeleteCollection(id)),
  GetCollection: (vendor) => dispatch(GetCollection(vendor)),
  ApproveCollection: (id) => dispatch(ApproveCollection(id)),
  DisapproveCollection: (id) => dispatch(DisapproveCollection(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(ColectionCards)
