import { PageNavVendorActions } from 'components'
import { flatten, get } from 'lodash'
import { func, shape, string } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Col } from 'reactstrap'
import { HideLoading, ShowLoading } from 'redux/actions/loading-action'
import {
  getCollectionByVendor,
  readCollection
} from 'redux/service/collection-service'
import { readProductsData } from 'redux/service/product-types-service'
import { getVendorById } from 'redux/service/vendor-service'
import { getUserType } from 'utils/auth'
import {
  getApprovedProducts,
  sortCollectionsByAddDate
} from 'utils/collections'
import { WOODEN_CRATE } from 'utils/constants'
import { sortProdsByIdDesc } from 'utils/products'

import Loading from '../components/loading/loading'
import PricingTable from '../components/pricing-components/pricing-table'
import notify, { notifyPositions } from '../utils/toast'
import Style from './styles/pricing.module.css'

const Pricing = ({
  HideLoading: HideLoadingAc,
  history,
  lang,
  match: { params, url },
  ShowLoading: ShowLoadingAc,
  userType
}) => {
  const [vendorData, setVendorData] = useState(null)
  const [collection, setCollection] = useState(null)
  const [productsOther, setProductsOther] = useState([])
  const [vendorId, setVendorId] = useState('')
  const collectionId = get(params, 'collection')
  const fiscalRegimen = parseFloat(get(vendorData, 'fiscalRegimen', ''))
  const id = get(params, 'vendor')
  const owner = get(params, 'owner')
  const [productsToShow, setProductsToShow] = useState([])
  const [productsData, setProductsData] = useState([])

  useEffect(() => {
    readProductsData().then(({ data, error }) => {
      if (data) {
        setProductsData(data)
      }

      if (error) {
        const { response } = error

        if (response && response.status === 401) {
          notify('error', 'Auth error', notifyPositions.bottom.center)
        }
        notify(
          'error',
          'Cannot get products data',
          notifyPositions.bottom.center
        )
      }
    })
  }, [])

  const doesCategoryRequireWoodenCrate = (productsData, categoryId) => {
    const categoryData = productsData.find((item) => item._id === categoryId)
    const categoryDataItems = categoryData
      ? categoryData.dataItems.find((item) => item.en === WOODEN_CRATE.en)
      : []
    return !!categoryDataItems
  }

  useEffect(() => {
    if (vendorData && productsOther && productsData) {
      const vendorWoodenCrateNeeded = vendorData.woodenCrateNeeded

      const products = getApprovedProducts(collection)
        .concat(productsOther)
        .map((product) => {
          const { isHeading, label, _id } = product

          return isHeading ? { isHeading, label, _id } : { rowData: product }
        })
      const modifiedProducts = products.map((product) => {
        const woodenCrateLabels = Object.values(WOODEN_CRATE)
        let rowData = { ...product.rowData }
        const categoryReqWoodenCrate = doesCategoryRequireWoodenCrate(
          productsData,
          rowData.type
        )
        // If the vendor does not require wooden crate for all products
        // and category does not require the wooden crate then we set its value to 0
        if (!vendorWoodenCrateNeeded && !categoryReqWoodenCrate) {
          rowData = {
            ...rowData,
            additionalData: rowData.additionalData.map((item) => {
              if (woodenCrateLabels.includes(item.key)) {
                item.value = 0
              }
              return item
            })
          }
        }
        return { rowData }
      })

      setProductsToShow(modifiedProducts)
    }
  }, [productsOther, vendorData, productsData])

  const addHeadingToProducts = (aProducts = []) => {
    if (aProducts.length <= 0) {
      return []
    }

    const collectionId = get(aProducts, '[0].vendorCollection', '')
    const collectionName = get(aProducts, '[0].vendorCollectionName', '')

    return [
      {
        isHeading: true,
        label: (
          <>
            <FormattedMessage id="Products from collection" />:{' '}
            <Link
              className={Style.colNameLink}
              to={`/collection-list/${owner}/${id}/${collectionId}`}
            >
              {collectionName}
            </Link>
          </>
        ),
        _id: collectionId
      },
      ...aProducts
    ]
  }

  useEffect(() => {
    ShowLoadingAc()
    readCollection(collectionId).then(({ data, error }) => {
      if (data) {
        const collectionData = get(data, '[0]') || {}
        const { vendor } = collectionData

        setCollection(collectionData)
        setVendorId(vendor)
      }

      if (error) {
        notify(
          'error',
          `can not fetch data from server with id ${collectionId}`,
          notifyPositions.bottom.center
        )
      }
    })
  }, [collectionId])

  useEffect(() => {
    if (!vendorId) {
      return
    }

    ShowLoadingAc()
    getVendorById(vendorId).then(({ data, error }) => {
      if (data) {
        setVendorData(data)
      }

      if (error) {
        notify(
          'error',
          `can not fetch data from server with id ${vendorId}`,
          notifyPositions.bottom.center
        )
      }
    })

    getCollectionByVendor(vendorId, 1, 1000)
      .then(({ data, status }) => {
        if (status !== 200) {
          return
        }

        const pCollections = data.docs
          .filter(({ approved, _id }) => _id !== collectionId && approved)
          .sort(sortCollectionsByAddDate)
          .map(({ _id }) => readCollection(_id))

        return Promise.all(pCollections)
      })
      .then((res) => {
        const collections = flatten(
          res.filter(({ status }) => status === 200).map(({ data }) => data)
        )
        const products = flatten(
          collections.map((collection) => {
            const prods = getApprovedProducts(collection)
              .sort(sortProdsByIdDesc)
              .map((prod) => ({
                ...prod,
                vendorCollectionName: collection.name
              }))

            return addHeadingToProducts(prods)
          })
        )

        setProductsOther(products)
        HideLoadingAc()
      })
  }, [vendorId])

  const handleClickVAImages = () => {
    history.push({
      pathname: `/image-assets/${owner}/${id}`,
      state: { collection }
    })
  }

  return vendorData && collection ? (
    <div className={Style.main}>
      <div className={Style.header}>
        <Col xs={12}>
          <h4 className="pageTitle">{vendorData.artisanName}</h4>
        </Col>
        <PageNavVendorActions
          collectionId={collectionId}
          currentUrl={url}
          userType={userType}
          vendorId={id}
          onClickImages={handleClickVAImages}
        />
      </div>
      <PricingTable
        data={productsToShow}
        fiscalRegimenPercent={fiscalRegimen}
        lang={lang}
      />
    </div>
  ) : (
    <Loading />
  )
}
Pricing.propTypes = {
  HideLoading: func,
  history: shape({
    push: func
  }),
  match: shape({
    params: string,
    url: string
  }),
  lang: string,
  ShowLoading: func,
  userType: string
}
const mapActions = {
  HideLoading,
  ShowLoading
}
const mapState = ({ authReducer, languageReducer: { lang } }) => ({
  lang,
  userType: getUserType(authReducer)
})

export default injectIntl(connect(mapState, mapActions)(Pricing))
