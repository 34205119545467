export const currencyFormatter = (currencyString = '') => {
  const cleanedNumberString = currencyString.replace(/,/g, '.').replace('-', '')

  const numberValue = Number(cleanedNumberString)

  if (isNaN(numberValue)) {
    return
  }

  const dotIndex = cleanedNumberString.indexOf('.')
  let parsedNumber

  if (dotIndex >= 0) {
    const integerStringNumber = cleanedNumberString.substring(0, dotIndex)
    const decimalStringNumber = cleanedNumberString.substring(
      dotIndex + 1,
      dotIndex + 3
    )

    parsedNumber = `${Number(integerStringNumber)},${decimalStringNumber}`
  } else {
    parsedNumber = `${numberValue}`
  }

  return parsedNumber
}

// Used convert comma separated currency string into number to be sent to the API
export const sanitizeCurrencyString = (currencyString = '') => {
  return parseFloat(currencyString.replace(',', '.')).toFixed(2)
}
