import cn from 'classnames'
import { noop } from 'lodash'
import { bool, func, number, oneOfType, string } from 'prop-types'
import React from 'react'
import { Input } from 'reactstrap'
import { SELECT_OPTIONS_TYPE } from 'utils/propTypes'

import st from './Select.module.css'

const Select = ({ options, placeholder, value, ...props }) => {
  const finalOptions = [
    {
      disabled: true,
      label: placeholder,
      value: placeholder
    },
    ...options
  ]
  const finalValue = value || placeholder

  return (
    <Input
      {...props}
      className={cn(st.wrap, {
        [st.wrapDisabled]: props.disabled
      })}
      type="select"
      value={finalValue}
    >
      {finalOptions.map(({ label, ...opnRest }) => (
        <option key={label} {...opnRest}>
          {label}
        </option>
      ))}
    </Input>
  )
}
Select.defaultProps = {
  options: [],
  placeholder: 'Select',
  value: '',
  onChange: noop
}
Select.propTypes = {
  options: SELECT_OPTIONS_TYPE,
  placeholder: string,
  value: oneOfType([number, string]),
  onChange: func,
  disabled: bool
}

export default Select
