import { Button } from 'components/buttons'
import Tooltip from 'components/Tooltip'
import ObjectsToCsv from 'objects-to-csv'
import { arrayOf, func, number, oneOfType, shape, string } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { FormattedMessage, injectIntl } from 'react-intl'

import SortableTable from '../Table/SortableTable'
import st from './SalesTable.module.css'

const SalesTable = ({ className, items, intl: { formatMessage } }) => {
  const [rowsData, setRowsData] = useState([])
  const [headings, setHeadings] = useState([])

  const exportCSV = async () => {
    var BOM = '\uFEFF' // BOM character to support UTF-8 with Excel
    const csv =
      BOM +
      (await new ObjectsToCsv(
        rowsData.map((row) => {
          const rowrow = Object.keys(row.csvRow).reduce((acc, key) => {
            const rowId = formatMessage({ id: key })
            return {
              ...acc,
              [rowId]: row.csvRow[key]
            }
          }, {})
          return rowrow
        })
      ).toString())

    const blob = new Blob([csv], { type: 'text/csv', charset: 'utf-8' })
    const a = document.createElement('a')
    a.href = URL.createObjectURL(blob)
    a.download = 'export.csv'
    a.click()
    a.remove()
  }

  useEffect(() => {
    const rows = items.map((item) => {
      const { gmv, name, sku, image, units, interactions, views, listViews } =
        item
      const displayName = name ?? sku

      const productCell = (
        <div className={st.product}>
          <img src={image} alt={displayName} />
          <div>
            <div className={st.productLinkWrapper}>
              <a href={`/product-detail/${sku}`}>{displayName}</a>
            </div>
            {sku}
          </div>
        </div>
      )

      return {
        tableRow: {
          product: productCell,
          productImpression: listViews,
          views: views,
          interactions: interactions,
          gmv: parseFloat(gmv).toFixed(2),
          units: units
        },
        csvRow: {
          SKU: sku,
          productName: displayName,
          productImpression: listViews,
          views: views,
          interactions: interactions,
          gmv: parseFloat(gmv).toFixed(2),
          units: units
        }
      }
    })
    setRowsData(rows)
  }, [items])

  useEffect(() => {
    if (rowsData.length) {
      setHeadings(
        Object.keys(rowsData[0].tableRow).map((key) => {
          return {
            id: key,
            name: (
              <>
                <FormattedMessage id={key} />
                {(key === 'interactions' ||
                  key === 'views' ||
                  key === 'productImpression') && (
                  <div className={st.tooltip}>
                    <Tooltip
                      id={`${key}Explanation`}
                      tooltip={<FormattedMessage id={`${key}Explanation`} />}
                    >
                      <AiOutlineInfoCircle className={st.popup} />
                    </Tooltip>
                  </div>
                )}
              </>
            ),
            selector: (row) => row[key],
            sortable: key !== 'product',
            sortFunction:
              key === 'product'
                ? undefined
                : (rowA, rowB) => {
                    let a = rowA[key]
                    let b = rowB[key]

                    if (typeof a === 'number' && typeof b === 'number') {
                      return a - b
                    }

                    return a.localeCompare(b, undefined, { numeric: true })
                  },
            grow: key === 'product' ? '2' : '',
            wrap: true,
            center: key !== 'product'
          }
        })
      )
    }
  }, [rowsData])

  return (
    <div className={className}>
      {rowsData.length > 0 && (
        <div style={{ textAlign: 'right' }}>
          <Button onClick={exportCSV} className={st.exportBtn}>
            <FormattedMessage id="downloadFile" />
          </Button>
        </div>
      )}
      <SortableTable
        className={st.table}
        headings={headings}
        mod={1}
        rows={rowsData.map((row, index) => ({ id: index, ...row.tableRow }))}
        striped={false}
      />
    </div>
  )
}

SalesTable.defaultProps = {
  className: '',
  items: []
}
SalesTable.propTypes = {
  className: string,
  intl: shape({
    formatMessage: func
  }),
  items: arrayOf(
    shape({
      gmv: number,
      image: string,
      interactions: string,
      listViews: string,
      name: string,
      sku: string,
      units: number,
      views: string,
      _id: oneOfType([number, string])
    })
  )
}

export default injectIntl(SalesTable)
