import cn from 'classnames'
import { ItemsVisibleList } from 'components/lists'
import { Table } from 'components/tables'
import { get, keys } from 'lodash'
import { arrayOf, node, shape, string } from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import { headings, mapRows } from './config'
import st from './OrdersTable.module.css'

const OrdersTable = ({ className, rows }) => {
  const mappedRows = mapRows(rows).map((row) => {
    const { cells } = row
    const productsData = get(cells, '[1].content')

    cells[1].content = (
      <ItemsVisibleList
        items={keys(productsData).map((key) => {
          const { imageAlt = '', imagePath = 'https://fakeimg.pl/48' } =
            productsData[key] || {}

          return {
            content: (
              <Link
                className={st.productImg}
                target="_blank"
                to={`/product-detail/${key}`}
              >
                <img alt={imageAlt} src={imagePath} />
              </Link>
            ),
            id: key
          }
        })}
        visibleItems={3}
      />
    )

    return {
      ...row,
      cells
    }
  })

  return (
    <Table
      className={cn(st.table, {
        [className]: className.length > 0
      })}
      headings={headings}
      rows={mappedRows}
    />
  )
}

OrdersTable.defaultProps = {
  className: '',
  rows: []
}
OrdersTable.propTypes = {
  className: string,
  rows: arrayOf(
    shape({
      date: string,
      orderNumber: string,
      orderPath: string,
      product: node,
      shipBy: string,
      status: string,
      value: string
    })
  )
}

export default OrdersTable
