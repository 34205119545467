import { Button, ButtonGroup } from 'components/buttons'
import dayjs from 'dayjs'
import { get, isEmpty, noop } from 'lodash'
import { any, arrayOf, bool, func, shape, string } from 'prop-types'
import React, { useRef, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import {
  ButtonDropdown,
  ButtonToolbar,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Popover,
  PopoverBody,
  PopoverHeader
} from 'reactstrap'
import { LAST_CHANGES_FIELDS_MAPPING } from 'utils/constants'
import { useClickOutside } from 'utils/useClickOutside'

import st from './VendorActions.module.css'

const VendorActions = ({
  collection,
  intl,
  owner,
  url,
  vendorId,
  onClickImages,
  lastUpdatedByAt,
  lastChanges,
  isProduct
}) => {
  const popoverRef = useRef(null)
  const [isOpen, isOpenToggle] = useState(false)
  const [isInfoOpen, setIsInfoOpen] = useState(false)
  const closeInfo = () => setIsInfoOpen(false)
  const product = useSelector((state) => state.productReducer.product)
  const productUrlInSpree = get(product, 'spreeProductUrl', null)
  const discontinuedOn = get(product, 'discontinuedOn', null)
  useClickOutside(popoverRef, closeInfo)
  const paths = {
    createNewCollection: `/vendor/${vendorId}`,
    exportToSpree: `/export-preview/${collection}?page=1&limit=26`,
    imageAssets: `/image-assets/${owner}/${vendorId}`,
    pricing: `/pricing/${owner}/${vendorId}/${collection}`,
    translations: `/translator-dashboard/${owner}/${collection}`
  }
  const dropdownItems = [
    {
      content: intl.formatMessage({ id: 'Create New Collection' }),
      disabled: paths.createNewCollection === url,
      href: paths.createNewCollection,
      _id: 'Create New Collection'
    },
    {
      content: intl.formatMessage({ id: 'Translations' }),
      disabled: paths.translations === url,
      href: paths.translations,
      _id: 'Translations'
    },
    {
      className: st.ddItem,
      content: intl.formatMessage({ id: 'Images' }),
      disabled: paths.imageAssets === url,
      _id: 'Images',
      onClick: onClickImages
    },
    {
      content: intl.formatMessage({ id: 'Pricing' }),
      disabled: paths.pricing === url,
      href: paths.pricing,
      _id: 'Pricing'
    },
    {
      content: intl.formatMessage({ id: 'Export to Spree' }),
      disabled: paths.exportToSpree === url,
      href: paths.exportToSpree,
      _id: 'Export to Spree'
    }
  ]

  const handleToggle = () => {
    isOpenToggle(!isOpen)
  }

  const toggleInfo = () => {
    setIsInfoOpen(!isInfoOpen)
  }
  const handleDropdownClick = (item) => {
    const { onClick } = item

    if (typeof onClick === 'function') {
      onClick(item)
    }
    isOpenToggle(false)
  }
  return (
    <div className={st.wrap}>
      <ButtonToolbar>
        <ButtonGroup size="sm">
          <ButtonDropdown isOpen={isOpen} toggle={handleToggle}>
            <DropdownToggle size="sm" caret>
              <FormattedMessage id="Actions" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>
                <FormattedMessage id="Actions" />
              </DropdownItem>
              {dropdownItems.map((item) => {
                return (
                  <DropdownItem
                    onClick={() => handleDropdownClick(item)}
                    disabled={item.disabled}
                    key={item._id}
                    tag={item.href ? 'a' : 'div'}
                    href={item.href}
                    className={st.ddItem}
                  >
                    {item.content}
                  </DropdownItem>
                )
              })}
            </DropdownMenu>
          </ButtonDropdown>
          {isProduct && (
            <>
              <Button
                href={discontinuedOn ? undefined : productUrlInSpree}
                target="_blank"
              >
                <FormattedMessage
                  id={
                    discontinuedOn
                      ? 'Discontinued'
                      : productUrlInSpree
                      ? 'Live'
                      : 'Working'
                  }
                />
              </Button>

              <Button id="info">
                <FormattedMessage id="Info" />
              </Button>
            </>
          )}
        </ButtonGroup>
      </ButtonToolbar>
      {isProduct && (
        <div ref={popoverRef}>
          <Popover
            placement="bottom"
            isOpen={isInfoOpen}
            target="info"
            toggle={toggleInfo}
            className={st.popover}
            innerClassName={st.popoverInner}
            container={popoverRef.current}
            fade={false}
          >
            <PopoverHeader>Last updated</PopoverHeader>
            <PopoverBody>
              {!isEmpty(lastUpdatedByAt) ? (
                <div className={st.updateDates}>
                  {Object.entries(lastUpdatedByAt).map(([key, value]) => (
                    <div key={key}>
                      Last changed by {key} ({value.email}) on:
                      <br />
                      <b>{dayjs(value.date).format('YYYY-MM-DD [@] HH:mm')}</b>
                    </div>
                  ))}
                </div>
              ) : (
                'No data'
              )}
            </PopoverBody>
            <PopoverHeader>Last changes</PopoverHeader>
            <div className={st.lastChanges}>
              {!isEmpty(lastChanges) ? (
                lastChanges.map(({ email, date, userType, changes }) => (
                  <div key={`${email}-${date}`}>
                    <div>
                      {userType} ({email}) on{' '}
                      <b>{dayjs(date).format('YYYY-MM-DD [@] HH:mm')}</b>
                    </div>
                    <ul>
                      {changes.map(({ field, from, to }) => {
                        const id = LAST_CHANGES_FIELDS_MAPPING[field]
                        const name = id ? intl.formatMessage({ id }) : 'unknown'

                        return (
                          <li key={field}>
                            {name} <FormattedMessage id="changed from" /> {from}{' '}
                            <FormattedMessage id="to" /> {to}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                ))
              ) : (
                <div>No data</div>
              )}
            </div>
          </Popover>
        </div>
      )}
    </div>
  )
}

VendorActions.defaultProps = {
  collection: '',
  owner: '',
  url: '',
  vendorId: '',
  onClickImages: noop,
  lastUpdatedByAt: {},
  lastChanges: []
}
VendorActions.propTypes = {
  collection: string,
  owner: string,
  url: string,
  vendorId: string,
  onClickImages: func,
  intl: shape({
    formatMessage: func
  }),
  lastUpdatedByAt: shape({
    [string]: shape({
      date: string,
      email: string
    })
  }),
  isProduct: bool,
  lastChanges: arrayOf(
    shape({
      userType: string,
      email: string,
      date: string,
      changes: arrayOf(
        shape({
          field: string,
          from: any,
          to: any
        })
      )
    })
  )
}

export default injectIntl(VendorActions)
