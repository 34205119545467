import cn from 'classnames'
import { InnerPage } from 'components/layout'
import { get } from 'lodash'
import { shape, string } from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Col, FormGroup, Input, Row } from 'reactstrap'
import { USER_TYPES } from 'utils/constants'
import { groupByTranslationStatus } from 'utils/products'

import CardElement from '../components/translator-table-card/card-element'
import {
  getCollectionProductTranslates,
  getCompanyBioByVendorId
} from '../redux/service/collection-service'
import { updateTranslation } from '../redux/service/translation-service'
import notify, { notifyPositions } from '../utils/toast'
import Style from './styles/translator.module.css'

class CompanyBio extends Component {
  state = {
    char: 0,
    error: false,
    translatedText: '',
    companyDescription: '',
    translation: null,
    vendorName: '',
    products: null,
    vendor: this.props.match.params.vendor || '',
    id: this.props.match.params.collection || ''
  }

  componentDidMount() {
    const { vendor } = this.state

    getCompanyBioByVendorId({ vendorId: vendor })
      .then(({ data, error }) => {
        if (error) {
          return null
        }

        const { vendor, company } = data || {}

        if (company) {
          this.setState({
            companyDescription: company.description,
            vendorName: vendor.name,
            translation: company.translation,
            translatedText: company.translation.description,
            char: company.translation.description
              ? company.translation.description.length
              : 0
          })
        }

        return vendor ? vendor._id : null
      })
      .then((vendor) => {
        getCollectionProductTranslates({
          collection: this.state.id,
          vendor
        })
          .then(({ docs = [] }) => {
            if (docs && docs[0]) {
              this.setState({ products: docs[0] })
            }
          })
          .catch(() => {
            notify(
              'error',
              'translation can not found',
              notifyPositions.bottom.center
            )
          })
      })
      .catch(() => {
        notify('error', 'server error', notifyPositions.bottom.center)
      })
  }

  updateTranslation = (id, data) =>
    updateTranslation({ id, translation: data }).then(({ error }) => {
      const messages = ['success', 'updated successfully']

      if (error) {
        messages[0] = 'error'
        messages[1] = 'server error: translation did not uptated'
      }

      notify(...messages, notifyPositions.bottom.center)
    })

  actions = (status) => {
    if (this.state.translatedText.length < 20) {
      notify(
        'error',
        'text length should be more then 20 characters',
        notifyPositions.bottom.center
      )
      return
    }
    const data = {
      description: this.state.translatedText,
      status: status
    }
    const { _id } = this.state.translation
    this.updateTranslation(_id, data)
  }

  renderCompanyBio = () => (
    <div className={Style.text}>
      <h4>
        {' '}
        Company <FormattedMessage id={'Bio in original Language'} />
      </h4>
      {this.state.companyDescription || (
        <FormattedMessage id={'Company has not any description'} />
      )}
    </div>
  )

  change = (e) => {
    const length = e.target.value.length

    if (this.state.error && length < 1000) {
      this.setState({ error: false })
    } else if (length > 1000) {
      this.setState({ error: true })
    } else {
      this.setState({
        char: length > 0 ? length : 0,
        translatedText: e.target.value
      })
    }
  }
  render() {
    const {
      authReducer: { userType },
      intl
    } = this.props
    const { char, error, products, translatedText, vendorName } = this.state
    const translationData = groupByTranslationStatus(get(products, 'products'))
    const CAN_APPROVE = [
      USER_TYPES.ADMIN,
      USER_TYPES.MANAGER,
      USER_TYPES.APPROVER
    ]
    return (
      <InnerPage>
        <div className={Style.openTranslations}>{vendorName}</div>
        <Row className="rowWide">
          <Col md={6} sm={6} lg={6}>
            {this.renderCompanyBio()}
          </Col>
          <Col md={6} sm={6} lg={6}>
            {userType === 'translator' && (
              <Row>
                <Col md={11} sm={11} lg={11}>
                  <FormGroup>
                    <Input
                      type="textarea"
                      name="text"
                      placeholder={intl.formatMessage({
                        id: 'Type here your message'
                      })}
                      onChange={this.change}
                      className={Style.textArea}
                      value={translatedText}
                    />
                  </FormGroup>
                </Col>
                <Col md={1} sm={1} lg={1}>
                  <h4 className={error ? Style.errorText : ''}>{char}/1000</h4>
                </Col>
              </Row>
            )}
            {CAN_APPROVE.includes(userType) && (
              <Row>
                <h4>
                  <FormattedMessage
                    className={Style.managerText}
                    id={'Company translated text'}
                  />
                </h4>
                <p className={Style.managerText}>{translatedText}</p>
              </Row>
            )}
          </Col>
          <div className={Style.buttons}>
            {userType === 'translator' && (
              <>
                <button
                  onClick={this.actions.bind(this, 'draft')}
                  className={'btn btn-secondary'}
                >
                  <FormattedMessage id={'Save Draft'} />
                </button>{' '}
                <button
                  onClick={this.actions.bind(this, 'waiting')}
                  className={'btn btn-secondary'}
                >
                  <FormattedMessage id={'Send to proof Reading'} />
                </button>
              </>
            )}
            {CAN_APPROVE.includes(userType) && (
              <>
                {this.state.translation &&
                this.state.translation.status === 'waiting' ? (
                  <>
                    <button
                      onClick={this.actions.bind(this, 'approved')}
                      className={'btn btn-secondary btn-lg'}
                    >
                      <FormattedMessage id={'Accept'} />
                    </button>{' '}
                    <button
                      onClick={this.actions.bind(this, 'todo')}
                      className={'btn btn-secondary'}
                    >
                      <FormattedMessage id={'Reject'} />
                    </button>
                  </>
                ) : (
                  <div className={Style.managerTextWarning}>
                    <FormattedMessage
                      id={'Translator has not been finished yet'}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </Row>
        {products && (
          <Row className="rowWide">
            <Col className={Style.cardCompBio}>
              <div className={cn(Style.openTranslations)}>
                <FormattedMessage id="In this collection" />
              </div>
              <CardElement {...translationData} />
            </Col>
          </Row>
        )}
      </InnerPage>
    )
  }
}
CompanyBio.propTypes = {
  match: shape({
    params: shape({
      vendor: string
    })
  }),
  authReducer: shape({
    userType: string
  }),
  intl: shape({
    formatMessage: string
  })
}
const mapStateToProps = (state) => ({
  authReducer: state.authReducer
})

export default injectIntl(connect(mapStateToProps)(CompanyBio))
