import { ThinIconBtn } from 'components/buttons'
import { arrayOf, bool, node, number, shape, string } from 'prop-types'
import React, { useState } from 'react'
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import st from './RowToggle.module.css'

const RowToggle = ({
  cells,
  cellsToggled,
  hideToggler,
  orderDetailPath,
  toggledClassName,
  toggledInit
}) => {
  const [toggled, toggle] = useState(toggledInit)
  const intl = useIntl()
  const togglerText = `${toggled ? 'Hide' : 'See'} details`
  const Icon = toggled ? AiOutlineUp : AiOutlineDown

  const handleToggleClick = () => {
    if (hideToggler || orderDetailPath) {
      return
    }

    toggle(!toggled)
  }

  const toggler = ({ path = '' }) => {
    if (hideToggler) {
      return null
    }

    const btnJSX = (
      <ThinIconBtn
        className={toggled ? toggledClassName : ''}
        icon={orderDetailPath ? null : <Icon />}
        onClick={handleToggleClick}
      >
        <FormattedMessage id={togglerText} />
      </ThinIconBtn>
    )

    return path ? (
      <Link className={st.togglerLink} to={path}>
        {btnJSX}
      </Link>
    ) : (
      btnJSX
    )
  }

  const renderCells = (cells = []) =>
    cells.map((cell, i) => {
      const { content, _id, translate, ...restCell } = cell

      return (
        <td {...restCell} key={_id}>
          {translate && content ? intl.formatMessage({ id: content }) : content}
          {i === cells.length - 1 &&
            toggler({
              path: orderDetailPath
            })}
        </td>
      )
    })

  return <tr>{renderCells(toggled ? cellsToggled : cells)}</tr>
}

const CELL_PROP_TYPE = {
  className: string,
  colSpan: number,
  content: node,
  _id: string.isRequired
}

RowToggle.defaultProps = {
  cells: [],
  cellsToggled: [],
  hideToggler: false,
  orderDetailPath: '',
  toggledClassName: '',
  toggledInit: false
}
RowToggle.propTypes = {
  cells: arrayOf(shape(CELL_PROP_TYPE)),
  cellsToggled: arrayOf(shape(CELL_PROP_TYPE)),
  hideToggler: bool,
  orderDetailPath: string,
  toggledClassName: string,
  toggledInit: bool
}

export default RowToggle
