import { noop } from 'lodash'
import { HideLoading, ShowLoading } from 'redux/actions/loading-action'
import { Update, userEmailUpdateSuccess } from 'redux/actions/users-action'
import { updateCredentials } from 'redux/service/user-service'
import { notifyMappedError } from 'utils/errors'

/**
 * @param {Object} config
 * @param {String} [config.newEmail]
 * @param {String} config.newPassword
 * @param {Function} config.onFinally
 * @param {Function} config.onSuccess
 * @param {String} config.password
 */
export const updateCredentialsTh =
  (config = {}) =>
  (dispatch) => {
    const { onFinally = noop, onSuccess = noop, ...restConfig } = config

    dispatch(ShowLoading())
    updateCredentials(restConfig).then(({ error, success }) => {
      if (error) {
        dispatch(Update("can't update", false))
        notifyMappedError(error)
      }
      if (success) {
        onSuccess()
        dispatch(Update('Updated', true))
        dispatch(userEmailUpdateSuccess(config.newEmail))
      }

      onFinally()
      dispatch(HideLoading())
    })
  }
