import {
  INIT,
  INIT_PICKUP_FORM,
  ORDERS_CERTIFICATION_FILE_ADD,
  ORDERS_CERTIFICATION_FILE_PENDING_ADD,
  ORDERS_CERTIFICATION_FILE_PENDING_REMOVE,
  ORDERS_CERTIFICATION_FILE_REMOVE,
  ORDERS_DATA_LOADING_ADD,
  ORDERS_DATA_LOADING_REMOVE,
  ORDERS_IMAGE_LOADING_ADD,
  ORDERS_IMAGE_LOADING_REMOVE,
  ORDERS_IMAGE_PENDING_ADD,
  ORDERS_IMAGE_PENDING_REMOVE,
  ORDERS_LAST_SUMITTED_VALUES_ADD,
  ORDERS_LOADING,
  ORDERS_PICKUP_SUBMITTING,
  ORDERS_PROFORMA_FILE_ADD,
  ORDERS_PROFORMA_FILE_PENDING_ADD,
  ORDERS_PROFORMA_FILE_PENDING_REMOVE,
  ORDERS_PROFORMA_FILE_REMOVE,
  PICKUP_FORM_LOADING
} from './types'

export const initAc = (payload) => ({
  payload,
  type: INIT
})

export const orderDataLoadingAdd = (payload) => ({
  payload,
  type: ORDERS_DATA_LOADING_ADD
})

export const orderDataLoadingRemove = (payload) => ({
  payload,
  type: ORDERS_DATA_LOADING_REMOVE
})

export const orderImageLoadingAdd = (payload) => ({
  payload,
  type: ORDERS_IMAGE_LOADING_ADD
})

export const orderImageLoadingRemove = (payload) => ({
  payload,
  type: ORDERS_IMAGE_LOADING_REMOVE
})

export const setLastSubmittedValues = (payload) => ({
  payload,
  type: ORDERS_LAST_SUMITTED_VALUES_ADD
})

export const initPickupForm = (payload) => ({
  payload,
  type: INIT_PICKUP_FORM
})

export const pickupFormLoading = (payload) => ({
  payload,
  type: PICKUP_FORM_LOADING
})

export const orderImagePendingAdd = (payload) => ({
  payload,
  type: ORDERS_IMAGE_PENDING_ADD
})

export const orderImagePendingRemove = (payload) => ({
  payload,
  type: ORDERS_IMAGE_PENDING_REMOVE
})

export const orderProfomaPendingAdd = (payload) => ({
  payload,
  type: ORDERS_PROFORMA_FILE_PENDING_ADD
})

export const orderProfomaPendingRemove = (payload) => ({
  payload,
  type: ORDERS_PROFORMA_FILE_PENDING_REMOVE
})

export const orderProformaAdd = (payload) => ({
  payload,
  type: ORDERS_PROFORMA_FILE_ADD
})

export const orderProformaDelete = (payload) => ({
  payload,
  type: ORDERS_PROFORMA_FILE_REMOVE
})

export const orderCertificationAdd = (payload) => ({
  payload,
  type: ORDERS_CERTIFICATION_FILE_ADD
})

export const orderCertificationRemove = (payload) => ({
  payload,
  type: ORDERS_CERTIFICATION_FILE_REMOVE
})

export const orderPickupSubmitting = (payload) => ({
  payload,
  type: ORDERS_PICKUP_SUBMITTING
})

export const orderCertificationPendingAdd = (payload) => ({
  payload,
  type: ORDERS_CERTIFICATION_FILE_PENDING_ADD
})

export const orderCertificationPendingRemove = (payload) => ({
  payload,
  type: ORDERS_CERTIFICATION_FILE_PENDING_REMOVE
})

export const setOrdersLoading = (payload) => ({
  payload,
  type: ORDERS_LOADING
})
