import notify, { notifyPositions } from 'utils/toast'

import { read, save, saveMedia } from '../service/company-service'
import {
  ADDED_COMPANY,
  COMPANY_PHOTO_PENDING,
  GET_COMPANY
} from './action-types'
import { AuthError } from './auth-action'
import { HideLoading, ShowLoading } from './loading-action'

/**
 * @param {Object} payload
 * @param {String} payload.image
 * @param {Boolean} payload.pending
 */
const companyPhotoPending = ({ image = '', pending = false }) => ({
  payload: { image, pending },
  type: COMPANY_PHOTO_PENDING
})

const getCompany = (company = {}) => ({
  company,
  type: GET_COMPANY
})

const saveCompany = (saved) => ({
  type: ADDED_COMPANY,
  saved
})

const AddCompany = (bio) => (dispatch) => {
  dispatch(ShowLoading())
  save(bio)
    .then(() => {
      notify('success', 'saved successfully', notifyPositions.bottom.center)
      saveCompany(true)
      dispatch(HideLoading())
    })
    .catch((err) => {
      dispatch(HideLoading())
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      saveCompany(true)
      notify('error', 'service error', notifyPositions.bottom.center)
    })
}

const getCompanyById = (id) => async (dispatch) => {
  dispatch(ShowLoading())

  const { company, error } = await read(id)

  if (company) {
    dispatch(getCompany(company))
  }

  if (error) {
    notify(
      'error',
      'Could not get the company data',
      notifyPositions.bottom.center
    )
  }

  dispatch(HideLoading())
}

/**
 * @param {Object} data
 * @param {String} data.description
 * @param {String} data.id - company ID
 * @param {FileList} data.image - array of images
 * @param {Object} data.video
 */
const updateCompanyMedia =
  (data = {}) =>
  async (dispatch) => {
    const { description, id, image, video } = data
    const bodyFormData = new FormData()
    const images = [...image]

    bodyFormData.append('description', description)
    images.forEach((image) => bodyFormData.append('image', image))
    bodyFormData.append('video', video)

    dispatch(ShowLoading())

    try {
      const res = await saveMedia(id, bodyFormData)
      const { status } = res

      if (status === 200) {
        dispatch(getCompanyById(id))
        notify('success', 'Updated successfully', notifyPositions.bottom.center)
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        AuthError(err.response.status)
      }

      notify(
        'error',
        'Could not update the company data',
        notifyPositions.bottom.center
      )
    } finally {
      dispatch(HideLoading())
    }
  }

export {
  AddCompany,
  companyPhotoPending,
  getCompany,
  getCompanyById,
  updateCompanyMedia
}
