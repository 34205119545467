import cn from 'classnames'
import { CloseFrame, Spinner } from 'components'
import { noop } from 'lodash'
import { arrayOf, bool, func, number, shape, string } from 'prop-types'
import React from 'react'
import { Col, Row } from 'reactstrap'

import st from './ImagesList.module.css'

const ImagesList = ({
  className,
  itemClassName,
  colsWidth,
  items,
  pendingIds,
  onClose,
  square
}) => {
  const handleCloseClick = (id) => () => {
    onClose(id)
  }
  return (
    <Row
      className={cn(st.list, {
        [className]: className.length > 0
      })}
    >
      {items.map(({ alt, disabledClose, id, path }) => {
        return (
          <Col
            className={cn(st.item, {
              [itemClassName]: itemClassName.length > 0
            })}
            key={id}
            {...colsWidth}
          >
            <Spinner length={3} show={pendingIds[id]}>
              <CloseFrame
                disabled={disabledClose}
                onClose={handleCloseClick({ id, path })}
              >
                <a href={path} target="_blank" rel="noreferrer">
                  {square ? (
                    <div
                      className={st.imageSquare}
                      style={{ backgroundImage: `url("${path}")` }}
                    >
                      <span>{alt || 'product'}</span>
                    </div>
                  ) : (
                    <img alt={alt || ''} src={path} />
                  )}
                </a>
              </CloseFrame>
            </Spinner>
          </Col>
        )
      })}
    </Row>
  )
}
ImagesList.defaultProps = {
  className: '',
  itemClassName: '',
  colsWidth: { xs: 12 },
  items: [],
  pendingIds: {},
  onClose: noop
}
ImagesList.propTypes = {
  className: string,
  itemClassName: string,
  colsWidth: shape({
    lg: number,
    md: number,
    sm: number,
    xl: number,
    xs: number
  }),
  items: arrayOf(
    shape({
      alt: string,
      disabledClose: bool,
      id: string,
      path: string
    })
  ),
  pendingIds: shape({}),
  onClose: func,
  square: bool
}

export default ImagesList
