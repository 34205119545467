import { get } from 'lodash'
import { COLORS } from 'utils/constants'

export const genderAgeQuery = {
  dimensions: ['AudienceAge.age_bracket'],
  measures: ['AudienceAge.percentage'],
  order: { 'AudienceAge.age_bracket': 'asc' }
}

export const genderAgeResMap = (data) => ({
  x: data['AudienceAge.age_bracket'],
  y: +data['AudienceAge.percentage']
})

export const genderCubeQuery = {
  dimensions: ['AudienceGender.gender'],
  measures: ['AudienceGender.percentage'],
  order: {
    'AudienceGender.percentage': 'desc'
  }
}

export const genderResultsMap = (resultSet) => {
  const data = get(resultSet, 'loadResponses.[0].data') || []

  return data.map((dataObj) => {
    const name = dataObj['AudienceGender.gender']

    return {
      color: name === 'M' ? '#888' : COLORS.BAR,
      name,
      value: parseFloat(dataObj['AudienceGender.percentage']) || 0
    }
  })
}

export const genderResultsMapValidate = (results = []) => {
  const badObjs = results.filter(
    ({ name, value }) => name === undefined || value === undefined
  )

  return badObjs.length === 0
}

export const geoQuery = {
  limit: 5000,
  dimensions: ['TotalSales.customer_type', 'TotalSales.geo_area'],
  measures: ['TotalSales.percentageOfTotalSales'],
  order: {
    'TotalSales.percentageOfTotalSales': 'desc'
  }
}

export const prepareGeoRes = (data, intl = {}) => {
  const areas = [...new Set(data.map((entry) => entry['TotalSales.geo_area']))]

  return areas
    .map((area) => ({
      x: intl.formatMessage({ id: area }),
      y: [
        {
          key: 'Consumer',
          value: data
            .find(
              (entry) =>
                entry['TotalSales.geo_area'] === area &&
                entry['TotalSales.customer_type'] === 'B2C'
            )
            ['TotalSales.percentageOfTotalSales'].toFixed(1)
        },
        {
          key: 'Trade',
          value: data
            .find(
              (entry) =>
                entry['TotalSales.geo_area'] === area &&
                entry['TotalSales.customer_type'] === 'B2B'
            )
            ['TotalSales.percentageOfTotalSales'].toFixed(1)
        }
      ]
    }))
    .sort(
      (areaA, areaB) =>
        areaB.y.reduce((sum, entry) => sum + entry.value, 0) -
        areaA.y.reduce((sum, entry) => sum + entry.value, 0)
    )
}

export const mapState = ({ authReducer, vendorReducer: { vendor } }) => ({
  analyticsToken: get(vendor, 'premium.analyticsToken', ''),
  vendorName: get(vendor, 'artisanName', '') || get(vendor, 'name', ''),
  authReducer
})
