import { Table } from 'components/tables'
import { bool, func, node, shape, string } from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import st from './BraintreePlanTable.module.css'
import { rows } from './config'

const BraintreePlanTable = ({ intl, isEndingOn, renewingCnt, values }) => (
  <Table
    className={st.table}
    rows={rows({
      intl,
      isEndingOn,
      renewingCnt,
      values
    })}
  />
)

BraintreePlanTable.defaultProps = {
  renewingCnt: null,
  values: {}
}
BraintreePlanTable.propTypes = {
  isEndingOn: bool,
  renewingCnt: node,
  intl: shape({
    formatMessage: func
  }),
  values: shape({
    currentPlan: string,
    renewingOn: string,
    status: string,
    validFrom: string
  })
}

export default injectIntl(BraintreePlanTable)
