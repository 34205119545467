import { get, values } from 'lodash'
import { USER_TYPES } from 'utils/constants'

const USER_RIGHTS = {
  APPROVE_TRANSLATIONS: 'approve_translations',
  UPDATE_TRANSLATIONS: 'update_translations'
}

/**
 * @param {Object} config
 * @param {Object} config.authReducer
 * @param {String} config.right
 */
export const checkUserRight = (config = {}) => {
  const { authReducer, right } = config
  const uRights = get(authReducer, 'user.rights') || []

  return uRights.find((uRight) => uRight === right)
}

export const canApproveTranslations = (authReducer = {}) => {
  return checkUserRight({
    authReducer,
    right: USER_RIGHTS.APPROVE_TRANSLATIONS
  })
}

export const canUpdateTranslations = (authReducer = {}) => {
  return checkUserRight({
    authReducer,
    right: USER_RIGHTS.UPDATE_TRANSLATIONS
  })
}

export const checkPassword = (password = '') => {
  const errors = {
    bigLetter: 'password error big letter',
    number: 'password error number',
    smallLetter: 'password error small letter',
    specialChar: 'password error special character',
    minLength: 'password error min length'
  }

  if (/\s/.test(password)) {
    return {
      errors: ['password error whitespace']
    }
  }

  if (/[A-Z]/.test(password)) {
    delete errors.bigLetter
  }

  if (/[a-z]/.test(password)) {
    delete errors.smallLetter
  }

  if (/\d/.test(password)) {
    delete errors.number
  }

  if (/[^A-Za-z\d]/.test(password)) {
    delete errors.specialChar
  }

  if (password.length >= 8) {
    delete errors.minLength
  }

  return {
    errors: values(errors),
    start: 'password error start'
  }
}

/**
 * @param {Object} user
 */
export const defineUserUrl = (user = {}) => {
  const userId = get(user, '_id', '')
  const userType = get(user, 'profile.type', '')

  switch (userType) {
    case USER_TYPES.ADMIN:
    case USER_TYPES.LOGISTICS:
      return `/approval-dashboard/${userId}`
    case USER_TYPES.BUYER:
      return '/approval-dashboard'
    case USER_TYPES.APPROVER:
    case USER_TYPES.MANAGER:
      return `/approver-workflow/${userId}`
    case USER_TYPES.TRANSLATOR:
      return `/translator-dashboard/${userId}`
    case USER_TYPES.VENDOR:
      return `/vendor-dashboard/${userId}`
    default:
      return '/'
  }
}

export const getUserEmail = (authReducer = {}) =>
  get(authReducer, 'user.email', '')

/**
 * @param {Object} authReducer
 */
export const getUserId = (authReducer = {}) => get(authReducer, 'user._id', '')

/**
 * @param {Object} authReducer
 * @param {Object} authReducer.user
 * @param {Object} authReducer.user.profile
 * @param {String} authReducer.user.profile.type
 */
export const getUserType = (authReducer = {}) =>
  get(authReducer, 'user.profile.type', '')

/**
 * @param {Object} authReducer
 * @param {Object} authReducer.user
 */
export const isAdmin = (authReducer = {}) => {
  const userType = getUserType(authReducer)

  return userType === USER_TYPES.ADMIN || userType === USER_TYPES.LOGISTICS
}

/**
 * @param {Object} authReducer
 * @param {Object} authReducer.user
 */
export const isAdApMa = (authReducer = {}) => {
  const userType = getUserType(authReducer)

  return (
    isAdmin(authReducer) ||
    userType === USER_TYPES.APPROVER ||
    userType === USER_TYPES.MANAGER
  )
}

/**
 * @param {Object} authReducer
 * @param {Object} authReducer.user
 */
export const isApprover = (authReducer = {}) => {
  const userType = getUserType(authReducer)

  return userType === USER_TYPES.APPROVER
}

/**
 * @param {Object} authReducer
 * @param {Object} authReducer.user
 */
export const isManager = (authReducer = {}) => {
  const userType = getUserType(authReducer)

  return userType === USER_TYPES.MANAGER
}

/**
 * @param {Object} authReducer
 * @param {Object} authReducer.user
 */
export const isTranslator = (authReducer = {}) => {
  const userType = getUserType(authReducer)

  return userType === USER_TYPES.TRANSLATOR
}

/**
 * @param {Object} authReducer
 * @param {Object} authReducer.user
 */
export const isVendor = (authReducer = {}) => {
  const userType = getUserType(authReducer)

  return userType === USER_TYPES.VENDOR
}
