import { uniqueId } from 'lodash'
import cookie from 'react-cookies'

import { parseJwt } from './utils'

const vendorButtons = ({ vendorId }) => {
  const links = [
    {
      button: 'Orders',
      link: `/order-list/${vendorId}`
    },
    {
      button: 'Merchandising',
      link: `/merchandising/${vendorId}`
    },
    {
      button: 'Social media',
      link: `/promote/${vendorId}`
    },
    {
      button: 'Company page',
      link: `/vendor-bio/${vendorId}`
    },
    {
      button: 'Analytics',
      link: `/vendor/${vendorId}/analytics`
    },
    {
      button: 'Contact info',
      link: `/vendor-contact/${vendorId}`
    }
  ]
  return links
}

/**
 * @param {Object} config
 * @param {String} config.vendorCompanyId
 * @param {String} config.vendorId
 */
export const dataOfButtons = (config = {}) => {
  const token = cookie.load('artemest')
  const { _id } = token ? parseJwt(token) : { _id: '123' }
  const { vendorCompanyId, vendorId = _id } = config
  const vendorButtonsItems = vendorButtons({
    vendorCompanyId,
    vendorId
  }).map((vb) => ({
    ...vb,
    key: `vendor-menu-item_${uniqueId()}`,
    rights: ['vendor']
  }))

  return [
    {
      key: 0,
      title: 'Approve Artisans and products',
      button: 'ListOfOnboarding',
      link: '/#',
      rights: []
    },
    {
      key: 1,
      title: 'Dashboard',
      button: 'Dashboard',
      link: `/approval-dashboard/${_id}/?page=1`,
      rights: ['admin', 'buyer', 'manager', 'logistics']
    },
    {
      key: 2,
      title: 'Create Artisan',
      button: 'Create Artisan',
      link: '/onboarding',
      rights: ['admin', 'buyer', 'manager', 'logistics']
    },
    {
      key: 3,
      title: "Product's visual schema",
      button: 'ProductsSchema',
      link: '/product-data-types',
      rights: ['admin', 'logistics']
    },
    {
      key: 4,
      title: 'See translations',
      button: 'Translations',
      link: `/translator-dashboard/${_id}`,
      rights: ['translator']
    },
    {
      key: 6,
      title: 'Approver Workflow',
      button: 'Approver Workflow',
      link: `/approver-workflow/${_id}`,
      rights: ['approver']
    },
    {
      key: 7,
      title: 'Mass Updater',
      button: 'Mass Updater',
      link: '/mass-updater',
      rights: ['admin', 'logistics']
    },
    ...vendorButtonsItems
  ]
}
