import { notifyMappedError } from 'utils/errors'

import notify, { notifyPositions } from '../../utils/toast'
import {
  addTranslations,
  getAllTranslations,
  getTranslationsByOnId,
  handleTranslationAccept,
  setTranslationReady
} from '../service/translation-service'
import {
  TRANSLATIONS_GET_SUCCESS,
  UPDATE_TRANSLATIONS,
  UPDATE_TRANSLATIONS_BY_ON_ID
} from './action-types'
import { AuthError } from './auth-action'
import { HideLoading, ShowLoading } from './loading-action'

const updateTranslation = (updated) => ({
  type: UPDATE_TRANSLATIONS,
  updated
})

const getByOnId = (gotByOnId, translation) => ({
  type: UPDATE_TRANSLATIONS_BY_ON_ID,
  gotByOnId,
  translation
})

const SetTranslationReady = (id, data) => (dispatch) => {
  dispatch(ShowLoading())

  return setTranslationReady({
    translationData: data,
    translationId: id
  }).then(({ data, error }) => {
    if (data) {
      dispatch(updateTranslation(true))
      notify('success', 'updated successfully', notifyPositions.bottom.center)
    }

    if (error) {
      const message = "hasn't been updated"
      const { response } = error

      if (response && response.status === 401) {
        dispatch(AuthError(response.status))
      }

      notify('error', message, notifyPositions.bottom.center)
    }

    dispatch(HideLoading())
  })
}

const translationsGetSuccess = (translations = []) => ({
  payload: { translations },
  type: TRANSLATIONS_GET_SUCCESS
})

/**
 * @param {Object} params
 * @param {String} [params.source]
 * @param {String} [params.status]
 */
const translationsGetAsync = (params) => (dispatch) => {
  dispatch(ShowLoading())

  getAllTranslations(params).then(({ translations }) => {
    if (translations) {
      dispatch(translationsGetSuccess(translations))
    }

    dispatch(HideLoading())
  })
}

const UpdateTranslation = (id, enTxt) => (dispatch) => {
  dispatch(ShowLoading())

  return addTranslations({
    translationData: enTxt,
    translationId: id
  }).then(({ data, error }) => {
    if (data) {
      dispatch(updateTranslation(true))
      notify('success', 'updated successfully', notifyPositions.bottom.center)
    }

    if (error) {
      const message = "hasn't been updated"

      if (error.response && error.response.status === 401) {
        dispatch(AuthError(error.response.status))
      }

      notify('error', message, notifyPositions.bottom.center)
    }

    dispatch(HideLoading())
  })
}

const GetTranslationsByOnId = (onId) => (dispatch) => {
  getTranslationsByOnId(onId).then(({ data, error }) => {
    if (data) {
      dispatch(getByOnId(true, data))
    }

    if (error) {
      const { status } = error

      if (status === 401) {
        dispatch(AuthError(status))
      }

      notifyMappedError(error)
    }
  })
}

const HandleTranslationAccept = (id, translationState) => (dispatch) => {
  dispatch(ShowLoading())

  return handleTranslationAccept({
    isAccept: translationState === 'approve',
    translationId: id
  }).then(({ data, error }) => {
    if (data) {
      dispatch(updateTranslation(true))
      notify(
        'success',
        `updated successfully to ${translationState}d`,
        notifyPositions.bottom.center
      )
    }

    if (error) {
      const { response } = error
      const message = "hasn't been updated"

      if (response && response.status === 401) {
        dispatch(AuthError(response.status))
      }

      notify('error', message, notifyPositions.bottom.center)
    }

    dispatch(HideLoading())
  })
}

export {
  GetTranslationsByOnId,
  HandleTranslationAccept,
  SetTranslationReady,
  translationsGetAsync,
  UpdateTranslation
}
