import { get } from 'lodash'
import { dtFormat } from 'utils/dateTime'

import { STATUSES } from './constants'

export const BRAINTREE_PLAN = {
  CANCEL: 'BRAINTREE_PLAN_CANCEL',
  SELECT: 'BRAINTREE_PLAN_SELECT'
}

/**
 * @param {Object} config
 * @param {String} config.planId
 * @param {Array} config.plans
 * @param {Object} config.plans[]
 */
export const getPlanTypeById = (config = {}) => {
  const { planId = '', plans = [] } = config
  const chosenPlan = plans.find((plan) => plan.id === planId)
  const chosenPlanName = get(chosenPlan, 'name', '').split(' Plan')[0]
  const price = parseFloat(get(chosenPlan, 'price') || 0)

  return price > 0 ? chosenPlanName : ''
}

export const mapSubscription = (subscription = {}) => {
  const {
    active,
    endDate,
    id,
    lastUpdated,
    planId,
    status,
    subscriptionId,
    firstBillingDate
  } = subscription

  return {
    active,
    id: id || subscriptionId,
    planId,
    firstBillingDate,
    renewingOn: dtFormat(endDate),
    status: status || (active ? STATUSES.ACTIVE : ''),
    validFrom: dtFormat(lastUpdated)
  }
}

export const mapSubscriptions = (subscriptions = []) =>
  subscriptions.map((subscription) => mapSubscription(subscription))

export const onlyActive = (subscriptions = []) =>
  subscriptions.filter(
    ({ active, status } = {}) => status === STATUSES.ACTIVE || active
  )

export const onlyActiveAndPending = (subscriptions = []) =>
  subscriptions.filter(
    ({ active, pending, status } = {}) =>
      status === STATUSES.ACTIVE ||
      active ||
      status === STATUSES.PENDING ||
      pending
  )
