import cn from 'classnames'
import { Status } from 'components'
import { ProductHeadingList } from 'components/lists'
import { PROP_TYPES_PRODUCTS } from 'components/lists/ProductHeadingList/config'
import { keys } from 'lodash'
import { string } from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Col, Row } from 'reactstrap'

import st from './OtherProducts.module.css'

const OtherProducts = ({ className, productsLeft, productsSaved }) => {
  const products = { productsLeft, productsSaved }
  const areProducts = productsLeft.length > 0 || productsSaved.length > 0

  return (
    areProducts && (
      <Row
        className={cn(st.wrap, {
          [className]: className.length > 0
        })}
        tag="section"
      >
        {keys(products).map((productKey) => {
          const productsList = products[productKey]
          const titleId =
            productKey === 'productsLeft'
              ? 'Products left to describe'
              : 'Products saved'

          return (
            <Col key={productKey} sm={6} xs={12}>
              <h2 className="mod1">
                <FormattedMessage id={titleId} />
              </h2>
              {productsList.length > 0 ? (
                <ProductHeadingList
                  className={st.productList}
                  items={productsList}
                />
              ) : (
                <Status fontSize="1.5em" textAlign="left">
                  <FormattedMessage id="No items" />
                </Status>
              )}
            </Col>
          )
        })}
      </Row>
    )
  )
}

OtherProducts.defaultProps = {
  className: '',
  productsLeft: [],
  productsSaved: []
}
OtherProducts.propTypes = {
  className: string,
  productsLeft: PROP_TYPES_PRODUCTS,
  productsSaved: PROP_TYPES_PRODUCTS
}

export default OtherProducts
