import { noop } from 'lodash'
import { func, string } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

const SearchFormLittle = ({ defaultValue, placeholder, onSubmit }) => {
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (defaultValue && inputValue.length === 0) {
      setInputValue(defaultValue)
    }
  }, [defaultValue])

  const onInputChange = (e) => {
    setInputValue(e.target.value)
  }
  const submitForm = () => {
    onSubmit({ value: inputValue })
  }
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      submitForm()
    }
  }

  return (
    <InputGroup>
      <Input
        multiple
        placeholder={placeholder}
        type="text"
        value={inputValue}
        onChange={onInputChange}
        onKeyPress={onKeyPress}
      />
      <InputGroupAddon addonType="append">
        <InputGroupText onClick={submitForm}>
          <FaSearch />
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  )
}
SearchFormLittle.defaultProps = {
  defaultValue: '',
  placeholder: '',
  onSubmit: noop
}
SearchFormLittle.propTypes = {
  defaultValue: string,
  placeholder: string,
  onSubmit: func
}

export default SearchFormLittle
