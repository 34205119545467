import { getData } from 'country-list'
import dayjs from 'dayjs'
import { values } from 'lodash'

import enFlag from './../static/images/flags/en.png'
import itFlag from './../static/images/flags/it.png'

const FILES_URL = 'https://artemest-product-feeds.s3.amazonaws.com/instructions'

export const ACCEPTED_FILE_TYPES = {
  IMAGES: 'image/gif,image/jpeg,image/jpg,image/tiff'
}

export const CHART_LEGEND_HEIGHT = 22

export const GTM_ID = 'GTM-W9ZJZTD' // Google Tag Manager ID

export const COLORS = {
  BAR: '#E6E7E8'
}

export const COOKIES = {
  AUTH: 'artemest',
  LANG: 'lang'
}

export const COUNTRIES_LIST = getData().sort((a, b) =>
  a.name < b.name ? -1 : 1
)

export const CURRENCIES = {
  EUR: 'EUR',
  GBP: 'GBP',
  USD: 'USD'
}

export const CURRENCIES_LIST = values(CURRENCIES)

export const CURRENCY_CONVERT = {
  EUR_USD: 1.2
}

export const CURRENT_YEAR = new Date().getFullYear()

export const DEFAULT_COUNTRY = 'IT'

export const ERRORS = {
  DIFFER_FROM_OLD_PASSWORD: 'Must differ from old password',
  MUST_BE_POSITIVE: 'Must be positive',
  ENTER_A_VALUE: 'Please enter a value',
  PASSWORDS_MUST_MATCH: 'Passwords must match',
  REQUIRED: '* Required',
  DISCOUNT_TOO_LOW: 'Discount is too low',
  PARCELS_REQUIRED: 'At least one parcel has to be added',
  DIFFERENT_MATERIAL: 'Please select a material different than the main one'
}

export const EXTERNAL_URLS = {
  ARTEMEST_PREMIUM: `${FILES_URL}/Artemest_Premium.pdf`,
  PRIVACY_POLICY: `${FILES_URL}/privacy-policy.pdf`
}

export const FAKE_IMAGES = {
  ORDER_PRODUCT: 'https://fakeimg.pl/50',
  PACKAGED_READY_PRODUCT: 'https://fakeimg.pl/120'
}

export const MAIN_IMAGE_DIMENSIONS = {
  minHeight: 1400,
  minWidth: 1400
}

export const IMAGE_DIMENSIONS = {
  minHeight: 1400,
  minWidth: 1400,
  maxHeight: 1400,
  maxWidth: 1400
}

export const INPUT_FILE_SIZE = 150
export const INPUT_FILE_DISPLAYED_THUMBNAILS_FILETYPE = ['jpeg', 'jpg', 'png']

export const LANG_DEFAULT_CODE = 'en'
export const LANG_EXPIRES = dayjs().add(10, 'y').toDate()

export const LIST_LIMIT_DEFAULT = 10

export const ORDER_BY = {
  ASC: 'asc',
  DESC: 'desc'
}

export const ORDER_IMAGE_TYPE = {
  PACKAGED: 'packaged',
  READY: 'ready'
}

export const ORDER_PARAM = {
  ADD_DATE: 'addDate'
}

export const ORDER_PRODUCT = {
  PACKAGED_IMAGE: 'PACKAGED_IMAGE',
  READY_IMAGE: 'READY_IMAGE'
}

export const PAGINATE = {
  VISIBLE_PAGES: 3
}

export const PRODUCT_OTHER_IMAGES = {
  UPLOAD: 'Upload Other Images',
  UPLOADED: 'Uploaded images'
}
export const PRODUCT_PROPERTIES = {
  COLOR: 'color',
  DESIGNER: 'designer',
  MATERIAL: 'material'
}

export const PRODUCT_RESOURCES = {
  CARE_INSTRUCTIONS: 'careInstructions',
  MAIN_PHOTO: 'mainPhoto',
  ONBOARDING_PHOTO: 'onboardingPhoto',
  OTHER_INFO: 'otherInfo'
}

export const STATUSES = {
  ACTIVE: 'Active',
  APPROVED: 'Approved',
  APPROVED_SM: 'approved',
  DONE_SM: 'done',
  DRAFT_SM: 'draft',
  PENDING: 'Pending',
  PENDING_SM: 'pending',
  PREMIUM: 'Premium',
  REJECTED: 'Rejected',
  SHIPPED_SM: 'shipped',
  TODO_SM: 'todo',
  WAITING_SM: 'waiting',
  WORKING_SM: 'working'
}

export const USER_TYPES = {
  ADMIN: 'admin',
  APPROVER: 'approver',
  BUYER: 'buyer',
  MANAGER: 'manager',
  TRANSLATOR: 'translator',
  VENDOR: 'vendor',
  LOGISTICS: 'logistics'
}

export const VENDOR_ORDER_ACTIONS = {
  PENDING: { label: 'Pending', value: 'pending' },
  READY_TO_SHIP: { label: 'Ready To Ship', value: 'ready' },
  SHIPPED: { label: 'Shipped', value: 'shipped' }
}

export const WOODEN_CRATE = {
  en: 'Wooden Crate',
  it: 'Cassa di legno'
}

export const FAILED_STAUTSES = {
  Returned: '-',
  Canceled: '-'
}

export const ORDER_IMAGE_TYPES = {
  READY: 'ready',
  PACKED: 'packed'
}

export const ORDER_IMAGES_LIMIT = 10

export const ORDER_FILE_INPUT_TYPE = {
  PROFORMA: 'proforma',
  CERTIFICATION: 'certification'
}

export const ORDER_PROFORMA_ACTIONS = {
  APPROVE: 'approve',
  REJECT: 'reject'
}

export const ORDER_PROFORMA_STATUSES = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected'
}

export const MATERIALS = {
  WOOD: 'Wood'
}

export const CARB_CERTIFICATE_COUNTRIES = {
  US: 'United States',
  CA: 'Canada'
}

export const ORDER_STATUSES = {
  SHIPPED: {
    value: 'shipped',
    label: 'Shipped'
  },
  PARTIAL_SHIPMENT: {
    value: 'partial shipment',
    label: 'Partial shipment'
  },
  READY: {
    value: 'ready',
    label: 'Working'
  },
  RETURNED: {
    value: 'returned',
    label: 'Returned'
  }
}

export const ORDER_FILTERS_DEFAULT = {
  status: '*'
}

export const LANGUAGES = [
  { name: 'English', code: 'en', flag: enFlag },
  { name: 'Italian', code: 'it', flag: itFlag }
]

export const BRAINTREE_CLASS_NAMES = {
  main: 'braintree-dropin',
  loadingContainer: 'braintree-loader__container',
  loaded: 'braintree-loaded',
  loading: 'braintree-loading',
  hidden: 'braintree-hidden'
}

export const PRODUCT_AUDIT_COLORS = {
  GREEN: '#8bc34a',
  ORANGE: '#ff9800',
  RED: '#e64a19',
  GRAY: '#cccccc'
}

export const PRODUCT_ATTACHEMENTS = {
  CARE_INSTRUCTIONS: 'careInstructions',
  OTHER_INFO: 'otherInfo',
  PRICE_LIST: 'priceList',
  FINISHES: 'finishes',
  ASSEMBLY_INSTRUCTIONS: 'assemblyInstructions',
  TECHNICAL_DATA_SHEET: 'technicalDataSheet'
}

export const LAST_CHANGES_FIELDS_MAPPING = {
  shippingIn: 'Lead time',
  wholesalePrice: 'Wholesale Price',
  retailPrice: 'Retail price',
  woodenCrate: 'Wooden Crate'
}

export const GUIDE_LINKS = {
  ordersManagement:
    'https://artemest-product-feeds.s3.amazonaws.com/instructions/Guida+Gestione+Ordini+Artemest.pdf',
  ordersVideoTutorial:
    'https://artemest-product-feeds.s3.amazonaws.com/instructions/Video+Tutorial+Ordini+Artemest.mp4'
}
