import { get, noop, omit, values } from 'lodash'

import { VENDOR_ORDER_ACTIONS } from './constants'

export const FISCAL_REGIMEN = ['0%', '4%', '20%', '22%']

export const getVendorCompany = (vendor) => get(vendor, 'company', '')

/**
 * @param {Object} vendor
 * @param {String} vendor.artisanCode
 * @param {String} vendor.SKUprefix
 */
export const getVendorSku = (vendor = {}) =>
  get(vendor, 'SKUprefix', '') || get(vendor, 'artisanCode', '')

/**
 * @param {Object} vendor
 * @param {String} vendor.artisanName
 */
export const getVendorName = (vendor = {}) => get(vendor, 'artisanName', '')

export const mapVendorProductsToDescribe = (products = []) =>
  products.map((product) => {
    const { mainPhoto, name = '', onboardingPhoto, _id } = product

    return {
      alt: name,
      imageUrl: mainPhoto || onboardingPhoto || 'https://fakeimg.pl/50',
      name: name || _id,
      path: `/product-detail/${_id}`,
      _id
    }
  })

export const isAnalyticsEnabled = (vendorReducer = {}) => {
  const { vendor } = vendorReducer

  return Boolean(get(vendor, 'premium.analyticsEnabled'))
}

/**
 * @param {Object} config
 * @param {Object} config.intl
 * @param {String} config.value
 */
export const orderActionByValue = (config = {}) => {
  const { intl = {}, value = '' } = config
  const formatMessage = get(intl, 'formatMessage', noop)

  const orderAction = values(VENDOR_ORDER_ACTIONS).find(
    (voa) => voa.value === value
  )

  return orderAction
    ? {
        content: formatMessage({ id: orderAction.label }),
        value
      }
    : {}
}

/**
 * @param {Object} config
 * @param {String} config.disabledValue
 * @param {Boolean} config.inStock
 * @param {Object} config.intl
 * @param {Function} config.intl.formatMessage
 */
export const orderActionsDropdown = (config = {}) => {
  const { disabledValue = '', inStock = false, intl = {} } = config
  const formatMessage = get(intl, 'formatMessage', noop)
  const actionsObj = omit(VENDOR_ORDER_ACTIONS, [
    inStock ? 'PENDING' : 'READY_TO_SHIP'
  ])

  return values(actionsObj).map(({ label, value }) => ({
    content: formatMessage({ id: label }),
    disabled: disabledValue === value,
    value
  }))
}

/**
 * @param {Object} intl
 * @param {Function} intl.formatMessage
 */
export const orderActionsOptions = (intl = {}) => {
  const formatMessage = get(intl, 'formatMessage', noop)

  return values(VENDOR_ORDER_ACTIONS).map(({ label, value }) => ({
    disabled: value === get(VENDOR_ORDER_ACTIONS.COMPLETE, 'value'),
    label: formatMessage({ id: label }),
    value
  }))
}

/**
 * @param {String} id
 */
export const skuFromProductId = (id = '') => {
  const isSplitted = id.split('-')

  return get(isSplitted, '[0]', '')
}

/**
 * @param {Object} config
 * @param {Boolean} config.isPending
 * @param {String} config.userType
 */
export const tableHeadings = (config) => {
  const { isPending, userType } = config
  const headings = [
    { text: 'Name' },
    { text: 'Code' },
    { text: isPending ? 'Area' : 'Collection' }
  ]

  if (isPending) {
    headings.push({ text: 'Exclusive' })
  }
  if (!(isPending && userType === 'manager')) {
    headings.push({ text: 'Action' })
  }

  return headings
}

/**
 * @param {Object} collectionReducer
 * @param {Object} collectionReducer.collection
 * @param {String} collectionReducer.collection.vendor
 */
export const vendorIdFromCollectionReducer = (collectionReducer = {}) =>
  get(collectionReducer, 'collection.vendor', '')

/**
 * @param {Object} productData
 * @param {Object} productData.vendorCollection
 * @param {String} productData.vendorCollection.vendor
 * @param {String} productData._id
 */
export const vendorIdFromProduct = (productData = {}) => {
  const productId = get(productData, '_id', '')

  return (
    get(productData, 'vendorCollection.vendor', '') ||
    skuFromProductId(productId)
  )
}
