import { get } from 'lodash'
import { GetVendor } from 'redux/actions/vendor-action'
import {
  buySubscriptionTh,
  deleteSubscriptionsTh,
  initialSubscriptionTh,
  updatePaymentDetails
} from 'redux/thunks/braintree'
import { dtFormat } from 'utils/dateTime'
import { getPlanTypeById } from 'utils/payment'
import { formatPrice } from 'utils/utils'

/**
 * @param {Object} config
 * @param {String} config.planId
 * @param {Array} config.planItems
 */
export const getSelectedPrice = (config = {}) => {
  const { planId = '', planItems = [] } = config || {}
  const plan = planItems.find((p) => p.id === planId)

  if (plan) {
    const { currency, price } = plan

    return formatPrice({ currency, price })
  }

  return ''
}

export const mapActions = {
  buySubscriptionTh,
  deleteSubscriptionsTh,
  GetVendor,
  initialSubscriptionTh,
  updatePaymentDetails
}

export const mapState = (
  { braintreeReducer, vendorReducer: { vendor } },
  { intl: { formatMessage } }
) => {
  const { clientToken, isNoticePeriod, planPendingId, plans, subscriptions } =
    braintreeReducer

  const subscriptionObj = get(subscriptions, '[0]')
  const {
    id: subscriptionSelectedId,
    planId: subscriptionPlanId,
    ...subscription
  } = subscriptionObj || {}
  const vendorIsNoticePeriod = get(vendor, 'premium.isNoticePeriod')
  const vendorPlanId = get(vendor, 'premium.planId', '')
  return {
    clientToken,
    planItems: plans.map((plan) => {
      const { currencyIsoCode, description, id, name, price } = plan

      return {
        currency: currencyIsoCode,
        description,
        disabled: Boolean(
          planPendingId || subscriptionPlanId || vendorIsNoticePeriod
        ),
        isNoticePeriod:
          id === isNoticePeriod ||
          (id === vendorPlanId && vendorIsNoticePeriod),
        id,
        isSelected: id === subscriptionPlanId,
        name,
        pending: id === planPendingId,
        price: parseFloat(price)
      }
    }),
    subscriptionSelectedId,
    subscriptionStatus: {
      currentPlan: getPlanTypeById({
        planId: vendorIsNoticePeriod ? vendorPlanId : subscriptionPlanId,
        plans
      }),
      currentPlanId: vendorPlanId,
      firstBillingDate: subscriptionObj ? subscription.firstBillingDate : '',
      renewingOn: vendorIsNoticePeriod
        ? dtFormat(get(vendor, 'premium.endDate', ''))
        : subscription.renewingOn,
      status: subscriptionObj
        ? subscription.status
        : formatMessage({ id: 'On Notice Period' }),
      validFrom: subscriptionObj
        ? subscription.validFrom
        : dtFormat(get(vendor, 'premium.lastUpdated', ''))
    },
    vendorIsNoticePeriod
  }
}
