import {
  AUTHENTICATION,
  AUTHENTICATION_ERROR,
  USER_EMAIL_UPDATE_SUCCESS
} from 'redux/actions/action-types'

const defaultState = {
  authorized: false,
  received: false,
  message: '',
  username: '',
  userType: '',
  user: {}
}

const AuthReducer = (state = defaultState, action) => {
  switch (action.type) {
    case AUTHENTICATION:
      return {
        ...state,
        authorized: action.authorized,
        received: true,
        message: action.message,
        username: action.username,
        userType: action.userType,
        user: action.user
      }

    case AUTHENTICATION_ERROR:
      return {
        ...state,
        ...defaultState,
        received: true,
        message: action.message
      }

    case USER_EMAIL_UPDATE_SUCCESS: {
      const { email } = action.payload

      return {
        ...state,
        user: {
          ...state.user,
          email
        }
      }
    }

    default:
      return state
  }
}

export default AuthReducer
