/* eslint-disable no-unused-vars */
import cn from 'classnames'
import { get, keys, omit, range } from 'lodash'
import { func, shape } from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Col, CustomInput, Row } from 'reactstrap'
import {
  batchShippingTimeEdit,
  edit,
  getProductsByCollectionId
} from 'redux/service/product-service'
import { isVendor } from 'utils/auth'
import { STATUSES } from 'utils/constants'
import { notifyMappedError } from 'utils/errors'
import { showNotify } from 'utils/toast'

import MerchElements from '../components/merchandising-compoents/merch-elements'
import { getCollectionByVendor } from '../redux/service/collection-service'
import Style from './styles/merchandising.module.css'

class Merchandising extends Component {
  state = {
    leadTme: 0,
    data: [],
    selectedIdsToBatchEdit: {}
  }

  componentDidMount() {
    const vendorId = this.getVendorId()

    getCollectionByVendor(vendorId).then((res) => {
      const ids = res.data.docs.map((collection) => collection._id)

      ids.forEach((item) => {
        getProductsByCollectionId({
          collectionId: item,
          productStatus: STATUSES.APPROVED
        }).then(({ error, products }) => {
          if (error) {
            notifyMappedError(error)
          }

          if (products) {
            const newData = [...this.state.data, ...products]

            this.setState({
              data: newData.sort((a, b) => (a._id < b._id ? 1 : -1))
            })
          }
        })
      })
    })
  }

  getVendorId = () => get(this.props, 'match.params.vendor', '')

  changeSelected = (id) => {
    const { selectedIdsToBatchEdit } = this.state

    this.setState({
      selectedIdsToBatchEdit: selectedIdsToBatchEdit[id]
        ? omit(selectedIdsToBatchEdit, [id])
        : { ...selectedIdsToBatchEdit, [id]: true }
    })
  }

  changeShippingIn = (id, value) => {
    const { data } = this.state
    data.forEach((el) => {
      if (el._id === id) {
        el.shippingIn = value
        edit(el._id, { shippingIn: el.shippingIn })
      }
    })
    this.setState({ data })
  }

  changeAcceptDiscount = (id) => {
    const { data } = this.state
    data.forEach((el) => {
      if (el._id === id) {
        el.acceptDiscount = !el.acceptDiscount
        edit(el._id, { acceptDiscount: el.acceptDiscount })
      }
    })
    this.setState({ data })
  }

  batchEditAcceptDiscount = (value) => {
    const { data, selectedIdsToBatchEdit } = this.state

    data.forEach((el) => {
      if (selectedIdsToBatchEdit[el._id]) {
        el.acceptDiscount = value
        edit(el._id, { acceptDiscount: value })
      }
    })

    this.setState({ data })
  }

  batchEditShippingIn = async (value) => {
    const { data, selectedIdsToBatchEdit } = this.state
    const itemsToEdit = Object.keys(selectedIdsToBatchEdit)
    const dataItemsToEdit = data
      .filter((item) => itemsToEdit.includes(item._id))
      .map((item) => ({ id: item._id, currentValue: item.shippingIn }))

    const {
      data: { amountModified }
    } = await batchShippingTimeEdit(dataItemsToEdit, value)
    data.forEach((el) => {
      if (selectedIdsToBatchEdit[el._id]) {
        el.shippingIn = value
      }
    })
    showNotify({ message: `Updated ${amountModified} products` })
    this.setState({ data })
  }

  handleSelectAll = (e) => {
    const { checked } = e.target
    const { data } = this.state

    this.setState({
      selectedIdsToBatchEdit: checked
        ? data.reduce((acc, { _id }) => {
            acc[_id] = true

            return acc
          }, {})
        : {}
    })
  }

  shouldShowBatchEdit = () => {
    const { selectedIdsToBatchEdit } = this.state

    return keys(selectedIdsToBatchEdit).length < 2
  }

  render() {
    const {
      intl: { formatMessage },
      authReducer
    } = this.props
    const { data, selectedIdsToBatchEdit } = this.state
    const isUserVendor = isVendor(authReducer)

    const products = !isUserVendor
      ? data
      : data.filter((el) => !el.discontinuedOn)

    const selectAllChecked =
      data.length > 0 && data.length === keys(selectedIdsToBatchEdit).length

    return (
      <div className={Style.main}>
        <Row className={Style.headerRow}>
          <Col md={6}>
            <h3>
              <FormattedMessage id="Merchandising" />
            </h3>
          </Col>
          <Col md={{ size: 3, offset: 3 }}>
            {this.shouldShowBatchEdit() && (
              <p>
                <FormattedMessage id="Select more than one item to batch edit" />
              </p>
            )}
            <CustomInput
              checked={selectAllChecked}
              className={Style.selectAll}
              id="select_all_products"
              label={formatMessage({
                id: 'Select all products'
              })}
              type="checkbox"
              onChange={this.handleSelectAll}
            />
            <select
              className={cn('custom-select', Style.selectEl)}
              label="Set New Lead Time"
              onChange={(e) => this.batchEditShippingIn(e.target.value)}
              disabled={this.shouldShowBatchEdit()}
              value={0}
            >
              <option value={0} disabled>
                {formatMessage({ id: 'Set New Lead Time' })}
              </option>
              {range(1, 19).map((num) => (
                <option key={num} value={num}>
                  {`${formatMessage(
                    {
                      id: 'Weeks'
                    },
                    { amount: num }
                  )}`}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <MerchElements
          elements={products}
          selectedProductIds={selectedIdsToBatchEdit}
          changeAcceptDiscount={this.changeAcceptDiscount}
          changeShippingIn={this.changeShippingIn}
          changeSelected={this.changeSelected}
        />
      </div>
    )
  }
}
Merchandising.propTypes = {
  intl: shape({
    formatMessage: func
  }),
  authReducer: shape({})
}
const mapStateToProps = (state) => ({
  vendorReducer: state.vendorReducer,
  authReducer: state.authReducer
})

export default injectIntl(connect(mapStateToProps)(Merchandising))
