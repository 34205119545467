import { Cube } from 'components'
import { noop } from 'lodash'
import { func, number, shape, string } from 'prop-types'
import React from 'react'
import { Cell, Legend, Pie, PieChart as PieChartCmp, Tooltip } from 'recharts'
import { CHART_LEGEND_HEIGHT } from 'utils/constants'

import st from './PieChart.module.css'

const PieChart = ({
  cubeJsApi,
  height,
  mapResults,
  query,
  validateMappedResults,
  valueItem
}) => {
  const outerRadius = (height - CHART_LEGEND_HEIGHT) / 2

  return (
    <Cube
      isChart
      cubeJsApi={cubeJsApi}
      height={height}
      mapResults={mapResults}
      query={query}
      validateMappedResults={validateMappedResults}
    >
      {({ data } = {}) => (
        <PieChartCmp
          height={height}
          style={{
            paddingTop: CHART_LEGEND_HEIGHT * 1.4
          }}
        >
          <Legend
            wrapperStyle={{
              bottom: 'auto',
              top: 0
            }}
            formatter={(value) => {
              return <span style={{ color: '#666' }}>{value}</span>
            }}
          />
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            outerRadius={outerRadius}
            startAngle={90}
            endAngle={450}
          >
            {data.map(({ color, name, value }) => (
              <Cell fill={color} key={`${name}${value}`} />
            ))}
          </Pie>
          <Tooltip
            contentStyle={{
              background: '#000',
              fontSize: '0.75em'
            }}
            formatter={(value, name, { payload }) => {
              const { fill: backgroundColor } = payload

              return [
                <span key={name} className={st.toolTip}>
                  <span
                    className={st.toolTipColor}
                    style={{
                      backgroundColor
                    }}
                  />
                  {name}: {value}
                  {valueItem}
                </span>
              ]
            }}
            itemStyle={{ color: '#fff' }}
          />
        </PieChartCmp>
      )}
    </Cube>
  )
}

PieChart.defaultProps = {
  cubeJsApi: {},
  height: 0,
  mapResults: noop,
  query: {},
  validateMappedResults: noop,
  valueItem: ''
}
PieChart.propTypes = {
  cubeJsApi: shape({}),
  height: number,
  mapResults: func,
  query: shape({}),
  validateMappedResults: func,
  valueItem: string
}

export default PieChart
