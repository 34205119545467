import axios from 'axios'
import braintree from 'braintree-web'
import { get, pick } from 'lodash'
import cookie from 'react-cookies'
import { BRAINTREE_ERRORS, mapError } from 'utils/errors'

import apiUrl from './apiUrl'

export const prepaidError = BRAINTREE_ERRORS.PREPAID_CARD

/**
 * @param {Object} config
 * @param {String} config.clientToken
 * @param {String} config.environment
 */
export const brainTreeSetup = (config = {}) => {
  const { clientToken } = config
  return new Promise((onCreate) => {
    braintree.client.create(
      {
        authorization: clientToken,
        threeDSecure: true
      },
      (err, clientInstance) => {
        if (err) {
          console.log(err)
          return
        }
        braintree.dataCollector.create(
          {
            client: clientInstance
          },
          (err, dataCollectorInstance) => {
            if (err) {
              return
            }
            const deviceData = dataCollectorInstance.deviceData
            onCreate({ deviceData })
          }
        )
      }
    )
  })
}

export const deleteSubscriptions = async (ids = []) => {
  const apiPath = (id) => `${apiUrl}/api/payment/subscription/${id}`

  try {
    const promises = ids.map((id) => {
      return axios.delete(apiPath(id), {
        headers: { token: cookie.load('artemest') }
      })
    })
    const results = await Promise.all(promises)
    const canceled = results
      .filter((result) => result.status === 200)
      .map((result, i) => ({
        ...get(result, 'data.subscription'),
        id: ids[i]
      }))

    return { canceled }
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} config
 * @param {Object} config.instance - Braintree instance
 * @param {Number|String} config.planId
 * @param {Number|String} config.vendorId
 */
export const buySubscription = async (config = {}) => {
  const { instance, planId, vendorId, amount } = config
  const apiPath = `${apiUrl}/api/payment/subscription`
  return new Promise((onPayment) => {
    instance.requestPaymentMethod(
      { threeDSecure: { amount } },
      async (err, payload) => {
        try {
          if (err) {
            throw new Error('Payment error - card authentication unsuccessful')
          }

          const binData = payload.binData
          console.log('binData', binData)
          if (
            binData &&
            (binData.debit === 'Yes' || binData.prepaid === 'Yes')
          ) {
            instance.clearSelectedPaymentMethod()
            throw new Error(prepaidError)
          }

          if (!payload.liabilityShifted && payload.liabilityShiftPossible) {
            throw new Error('Payment error - liability did not shift')
          }

          const { nonce } = payload
          const { data, status } = await axios.post(
            apiPath,
            {
              nonce,
              planId,
              vendorId
            },
            {
              headers: { token: cookie.load('artemest') }
            }
          )

          instance.clearSelectedPaymentMethod()

          if (status === 200 || status === 201) {
            const { errors, message } = data

            if (errors && message) {
              throw new Error(message)
            } else {
              onPayment({ data })
            }
          }
        } catch (err) {
          onPayment({ error: mapError(err) })
        }
      }
    )
  })
}

export const updateCardDetails = async (config) => {
  const apiPath = `${apiUrl}/api/payment/payment-method`
  const { instance, amount, showLoading } = config
  return new Promise((onUpdate) => {
    instance.requestPaymentMethod(
      { threeDSecure: { amount } },
      async (err, payload) => {
        try {
          if (err) {
            throw new Error(err)
          }
          const binData = payload.binData
          console.log('binData', binData)
          if (
            binData &&
            (binData.debit === 'Yes' || binData.prepaid === 'Yes')
          ) {
            instance.clearSelectedPaymentMethod()
            throw new Error(prepaidError)
          }
          if (!payload.liabilityShifted && payload.liabilityShiftPossible) {
            throw new Error('Payment error - liability did not shift')
          }
          const { nonce, details } = payload
          showLoading()
          const { data, status } = await axios.patch(
            apiPath,
            { cardDetails: details, nonce },
            {
              headers: {
                token: cookie.load('artemest')
              }
            }
          )
          if (status === 200 || status === 201) {
            const { errors, message } = data

            if (errors && message) {
              throw new Error(message)
            } else {
              onUpdate({ data })
              return data
            }
          }
        } catch (err) {
          onUpdate({ error: mapError(err) })
        }
      }
    )
  })
}

export const getSubscriptionsForVendor = async (vendorId = '') => {
  const apiPath = `${apiUrl}/api/payment/${vendorId}/subscription`

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return { subscriptions: data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

export const getSubscriptionPlans = async (token = '') => {
  const apiPath = `${apiUrl}/api/payment/plan`

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: {
        braintreetoken: token,
        token: cookie.load('artemest')
      }
    })

    if (status === 200) {
      return data
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

export const getTokenByVendorId = async (vendorId = '') => {
  const apiPath = `${apiUrl}/api/payment/token/${vendorId}`

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return pick(data, ['clientToken'])
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}
