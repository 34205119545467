import {
  AUTHENTICATION_ERROR,
  BRAINTREE_CLIENT_TOKEN_SET,
  BRAINTREE_DEVICE_DATA_SET,
  BRAINTREE_IS_NOTICE_PERIOD,
  BRAINTREE_PLAN_PENDING_ID,
  BRAINTREE_PLANS_GET,
  BRAINTREE_SUBSCRIPTIONS_CANCELED_IDS,
  BRAINTREE_SUBSCRIPTIONS_GET
} from 'redux/actions/action-types'

const defaultState = {
  clientToken: '',
  deviceData: '',
  error: false,
  isNoticePeriod: '',
  planPendingId: '',
  plans: [],
  subscriptions: [],
  transactionInProgress: false,
  transactionSuccess: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case BRAINTREE_CLIENT_TOKEN_SET:
    case BRAINTREE_DEVICE_DATA_SET:
    case BRAINTREE_IS_NOTICE_PERIOD:
    case BRAINTREE_PLAN_PENDING_ID:
    case BRAINTREE_PLANS_GET:
    case BRAINTREE_SUBSCRIPTIONS_GET:
      return {
        ...state,
        ...action.payload
      }

    case AUTHENTICATION_ERROR:
      return {
        ...state,
        ...defaultState
      }

    case BRAINTREE_SUBSCRIPTIONS_CANCELED_IDS: {
      const { ids } = action.payload

      return {
        ...state,
        subscriptions: state.subscriptions.filter(
          ({ id }) => ids.indexOf(id) < 0
        )
      }
    }

    default:
      return state
  }
}
