import cn from 'classnames'
import { bool, element, func, number, shape, string } from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import st from './ProductSummary.module.css'

const ProductSummary = ({
  bottomString,
  className,
  imageAlt,
  productURL,
  imageSrc,
  mod,
  name,
  id,
  size,
  isOrder,
  details,
  vendorCode,
  intl
}) => {
  const imageJSX = <img alt={imageAlt} src={imageSrc} />
  const imageStyle = {
    height: size,
    width: size
  }

  return (
    <div
      className={cn(st.wrap, {
        [className]: className.length > 0,
        [st.mod1]: mod === 1
      })}
    >
      <span className={st.image} style={imageStyle}>
        <a target="_blank" href={imageSrc} rel="noreferrer">
          {imageJSX}
        </a>
      </span>
      <div className={st.cnt}>
        <Link target="_blank" to={productURL}>
          <p className={st.name}>{name}</p>
        </Link>
        <p className={st.id}>SKU: {id}</p>
        {vendorCode && (
          <p className={st.id}>
            {intl.formatMessage({ id: 'Vendor code' })}: {vendorCode}
          </p>
        )}
        {bottomString.length > 0 && (
          <p className={st.bottomString}>{bottomString}</p>
        )}
        {isOrder && details}
      </div>
    </div>
  )
}

ProductSummary.defaultProps = {
  bottomString: '',
  className: '',
  imageAlt: '',
  productURL: '',
  imageSrc: '',
  mod: 0,
  name: '',
  id: '',
  size: 48,
  isOrder: false,
  details: null,
  vendorCode: ''
}
ProductSummary.propTypes = {
  intl: shape({
    formatMessage: func
  }),
  bottomString: string,
  className: string,
  imageAlt: string,
  productURL: string,
  imageSrc: string,
  mod: number,
  name: string,
  id: string,
  size: number,
  category: string,
  isOrder: bool,
  details: element,
  vendorCode: string
}

export default injectIntl(ProductSummary)
