import { node } from 'prop-types'
import React from 'react'
import { ButtonGroup as BtnGroup } from 'reactstrap'

import st from './ButtonGroup.module.css'

const ButtonGroup = ({ children, ...props }) => {
  return (
    <BtnGroup className={st.buttonGroup} {...props}>
      {children}
    </BtnGroup>
  )
}

ButtonGroup.propTypes = {
  children: node.isRequired
}
export default ButtonGroup
