import { INITIAL_VALUES } from 'components/forms/ProductDetailForm/config'
import { get, pick } from 'lodash'
import React from 'react'
import { GetCollectionById } from 'redux/actions/collection-action'
import {
  DeleteProduct,
  GetById,
  getPropertiesAsync,
  showCopyModal
} from 'redux/actions/product-action'
import { GetVendor } from 'redux/actions/vendor-action'
import {
  auditProduct,
  deleteFiles,
  deleteOtherImage,
  deleteResourceTh,
  editProduct,
  flagAsDiscontinued,
  flagAsIncomplete,
  getProductsByCollectionTh,
  reactivateProduct,
  saveMainImage,
  saveOtherImages,
  uploadFiles
} from 'redux/thunks/product'
import {
  getUserType,
  isAdApMa,
  isAdmin,
  isApprover,
  isVendor
} from 'utils/auth'
import { ERRORS, STATUSES } from 'utils/constants'
import {
  additionalFieldsInitialValues,
  getCollectionId,
  getDataItems,
  isProductApproved as isProductApprovedUtil,
  parcelsToList,
  productPhotos,
  propertiesToOptions,
  shippingInOptions
} from 'utils/products'
import { filterByProps } from 'utils/utils'
import { getVendorName, getVendorSku, vendorIdFromProduct } from 'utils/vendors'

import { changeTypeTh } from './mw'

const otherProdsMapper = (prod) => ({
  ...prod,
  name: (
    <>
      {prod._id}
      {prod.name && <br />}
      {prod.name}
    </>
  )
})

export const mapActions = {
  flagAsIncomplete,
  flagAsDiscontinued,
  reactivateProduct,
  auditProduct,
  changeTypeTh,
  deleteFilesAc: deleteFiles,
  deleteOtherImageAc: deleteOtherImage,
  deleteProductByIdAc: DeleteProduct,
  deleteResourceTh,
  editProductAc: editProduct,
  GetCollectionById,
  GetProductById: GetById,
  getProductsByCollectionTh,
  getPropertiesAsync,
  GetVendorById: GetVendor,
  saveMainImageAc: saveMainImage,
  saveOtherImagesAc: saveOtherImages,
  showCopyModalAc: showCopyModal,
  uploadFilesAc: uploadFiles
}

export const mapState = (
  {
    authReducer,
    collectionReducer: { collection },
    languageReducer: { decimalSign, lang: language },
    productReducer: {
      colors,
      collectionProducts,
      designers,
      mainImagePending,
      materials,
      parcelIndex,
      product,
      productPhotosPending,
      attachementsPending
    },
    vendorReducer: { vendor }
  },
  { intl }
) => {
  const collectionId = getCollectionId(product)
  const photos = productPhotos(product)
  const productDimensions = get(product, 'dimensions') || {}
  const productPricingEur = get(product, 'pricing.EUR') || {}
  const productPricingUSD = get(product, 'pricing.USD', {})
  const productPricingGBP = get(product, 'pricing.GBP', {})
  const userType = getUserType(authReducer)
  const vendorSKU = getVendorSku(vendor)
  const woodenCrateNeeded = get(vendor, 'woodenCrateNeeded', false)

  return {
    additionalDataOld: get(product, 'additionalData', []),
    auditedOn: get(product, 'auditedOn', ''),
    flaggedAsIncompleteOn: get(product, 'flaggedAsIncompleteOn', ''),
    flaggedAsDiscontinuedOn: get(product, 'discontinuedOn', ''),
    careInstructions: get(product, 'careInstructions', ''),
    otherInfo: get(product, 'otherInfo', ''),
    collection,
    collectionId,
    colorOptions: propertiesToOptions({
      intl,
      properties: colors
    }),
    copyModalProps: { product: product || {} },
    dataItems: getDataItems({
      language,
      product,
      woodenCrateNeeded
    }),
    lastUpdatedByAt: get(product, 'lastUpdatedByAt', {}),
    dataItemsOriginal: get(product, 'type.dataItems', []),
    decimalSign,
    designerOptions: propertiesToOptions({ properties: designers }),
    shippingInOptions: shippingInOptions({ intl }),
    initialValues: {
      ...INITIAL_VALUES.DEFAULT_PROPS,
      ...additionalFieldsInitialValues({
        language,
        product,
        woodenCrateNeeded
      }),
      ...get(product, 'certifications', []).reduce((acc, c) => {
        acc[`certification${c}`] = true

        return acc
      }, {}),
      ...pick(product, [
        'comments',
        'dedicatedCourier',
        'designer',
        'isCITES',
        'needsAssembly',
        'shippingIn',
        'vendorCode',
        'externalId',
        'packagingTime',
        'yearIntoMarket',
        'blocked'
      ]),
      canBeCustomized: get(product, 'isCustomizable'),
      canBeRemade: get(product, 'remandeIn') > 0,
      category: get(product, 'type._id', ''),
      D: get(productDimensions, 'D') || 0,
      description: get(product, 'description') || '',
      fiscalRegimen: get(vendor, 'fiscalRegimen', ''),
      H: get(productDimensions, 'H') || 0,
      mainColor: get(product, 'mainColor') || '',
      markForTranslation: get(product, 'readyToTranslate', false),
      material: get(product, 'material') || '',
      secondaryMaterial: get(product, 'secondaryMaterial') || '',
      productName: get(product, 'name') || '',
      remadeDays: get(product, 'remandeIn', 0),
      retailPrice: +productPricingEur.retail || 0,
      suitableForContract: get(product, 'suitableForContract', false),
      W: get(productDimensions, 'W') || 0,
      weight: get(product, 'weight') || 0,
      wholesalePrice: +productPricingEur.wholesale || 0,
      stockQuantity: get(product, 'stockQuantity') || 0,
      retailUSD: get(productPricingUSD, 'retail', 0),
      retailGBP: get(productPricingGBP, 'retail', 0),
      parcels: parcelsToList({
        product,
        showAmounts: isAdmin(authReducer)
      })
    },
    isProductApproved: isProductApprovedUtil(product),
    isProductFetched: !!product,
    isUserAdmin: isAdmin(authReducer),
    isUserVendor: isVendor(authReducer),
    language,
    mainImagePending,
    mainPhoto: photos.main,
    materialOptions: propertiesToOptions({
      intl,
      properties: materials
    }),
    onboardingPhoto: photos.onboarding,
    priceList: get(product, 'priceList', ''),
    finishes: get(product, 'finishes', ''),
    assemblyInstructions: get(product, 'assemblyInstructions', ''),
    technicalDataSheet: get(product, 'technicalDataSheet'),
    category: get(product, 'type._id', ''),
    otherPhotos: photos.other.map((path) => ({
      alt: '',
      id: path,
      path
    })),
    parcelIndex,
    parcels: parcelsToList({
      product,
      showAmounts: isAdmin(authReducer)
    }),
    pathOnDeletion: `/collection-list/${userType}/${vendorSKU}/${collectionId}`,
    photos: photos.other,
    productPhotosPending,
    productsLeft: filterByProps({
      arr: collectionProducts,
      filters: [
        (product) => product.saved === false,
        (product) =>
          isApprover(authReducer)
            ? product
            : product.status === STATUSES.APPROVED,
        (product) => (isVendor(authReducer) ? !product.discontinuedOn : product)
      ]
    }).map(otherProdsMapper),
    productsSaved: filterByProps({
      arr: collectionProducts,
      filters: [
        (product) => product.saved && product.status === STATUSES.APPROVED,
        (product) => (isVendor(authReducer) ? !product.discontinuedOn : product)
      ]
    }).map(otherProdsMapper),
    showCommentsInput: isAdApMa(authReducer),
    userType,
    vendorCollection: get(product, 'vendorCollection') || {},
    vendorId: vendorIdFromProduct(product),
    vendorName: getVendorName(vendor),
    attachementsPending,
    lastChanges: get(product, 'lastChanges', [])
  }
}

export const validate = (values = {}) => {
  const errors = {}

  if (values.weight <= 0) {
    errors.weight = ERRORS.MUST_BE_POSITIVE
  }
  if (values.W <= 0) {
    errors.W = ERRORS.MUST_BE_POSITIVE
  }
  if (values.D <= 0) {
    errors.D = ERRORS.MUST_BE_POSITIVE
  }
  if (values.H <= 0) {
    errors.H = ERRORS.MUST_BE_POSITIVE
  }

  return errors
}
