export const headings = [
  { content: 'Order No.' },
  { content: 'Products', width: '250px' },
  { content: 'Customer expected shipping date' },
  // { content: 'Country' },
  { content: 'Status' },
  {
    colSpan: 2,
    content: 'Net to vendor'
  }
]
