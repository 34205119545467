import cn from 'classnames'
import { any, node, string } from 'prop-types'
import React from 'react'

import st from './Radio.module.css'
const Radio = ({ id, children, ...props }) => {
  return (
    <div className="custom-control custom-radio">
      <input id={id} type="radio" className="custom-control-input" {...props} />
      <label className={cn('custom-control-label', st.label)} htmlFor={id}>
        {children}
      </label>
    </div>
  )
}

Radio.propTypes = {
  id: string,
  children: node,
  props: any
}

export default Radio
