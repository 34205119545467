import { Button } from 'components/buttons'
import { Input } from 'components/formParts'
import { Field, Form, Formik } from 'formik'
import { noop } from 'lodash'
import { bool, func, node, shape, string } from 'prop-types'
import React, { useCallback } from 'react'
import { injectIntl } from 'react-intl'
import { STATUSES } from 'utils/constants'

import st from './TranslateProductForm.module.css'

const TranslateProductForm = ({
  children,
  description,
  disableSaveDraft,
  hideAccept,
  hideProofReading,
  hideReject,
  hideSaveDraft,
  initialValues,
  intl: { formatMessage },
  name,
  translationStatus,
  onAcceptReject,
  onProofReading,
  onSaveDraft
}) => {
  const translationAccepted = translationStatus === STATUSES.APPROVED_SM
  const translationDraft = translationStatus === STATUSES.DRAFT_SM
  const translationProof = translationStatus === STATUSES.WAITING_SM
  const translationRejected = translationStatus === STATUSES.TODO_SM

  const handleAccept = useCallback(
    (values) => () => {
      onAcceptReject({
        isAccept: true,
        translationData: values
      })
    },
    [onAcceptReject]
  )

  const handleProofReading = useCallback(
    (values) => () => {
      onProofReading(values)
    },
    [onProofReading]
  )

  const handleReject = useCallback(
    (values) => () => {
      onAcceptReject({
        isAccept: false,
        translationData: values
      })
    },
    [onAcceptReject]
  )

  const handleSaveDraft = useCallback(
    (values) => () => {
      onSaveDraft(values)
    },
    [onSaveDraft]
  )

  return (
    <Formik enableReinitialize initialValues={initialValues}>
      {({ values }) => {
        const buttonsData = [
          {
            disabled: disableSaveDraft || translationDraft,
            hidden: hideSaveDraft,
            label: `Save${translationDraft ? 'd' : ''} Draft`,
            onClick: handleSaveDraft(values)
          },
          {
            disabled: translationProof,
            hidden: hideProofReading,
            label: `Sen${translationProof ? 't' : 'd'} to proof Reading`,
            onClick: handleProofReading(values)
          },
          {
            disabled: translationAccepted,
            hidden: hideAccept,
            label: `Accept${translationAccepted ? 'ed' : ''}`,
            onClick: handleAccept(values)
          },
          {
            disabled: translationRejected,
            hidden: hideReject,
            label: `Reject${translationRejected ? 'ed' : ''}`,
            onClick: handleReject(values)
          }
        ]

        return (
          <Form>
            <p className={st.label}>{name}</p>
            <Field
              className={st.input}
              component={Input}
              name="name"
              placeholder={formatMessage({
                id: 'Translate product name'
              })}
              valueLengthLimit={65}
            />
            <p className={st.label}>{description}</p>
            <Field
              className={st.input}
              component={Input}
              name="description"
              placeholder={formatMessage({
                id: 'Translate product description'
              })}
              type="textarea"
              valueLengthLimit={500}
            />
            <div>{children}</div>
            <div className={st.buttons}>
              {buttonsData.map(({ disabled, hidden, label, onClick }) =>
                hidden ? null : (
                  <Button disabled={disabled} key={label} onClick={onClick}>
                    {formatMessage({ id: label })}
                  </Button>
                )
              )}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

TranslateProductForm.defaultProps = {
  disableSaveDraft: false,
  hideAccept: false,
  hideProofReading: false,
  hideReject: false,
  hideSaveDraft: false,
  children: null,
  description: '',
  initialValues: {},
  name: '',
  translationStatus: '',
  onAcceptReject: noop,
  onProofReading: noop,
  onSaveDraft: noop
}
TranslateProductForm.propTypes = {
  disableSaveDraft: bool,
  hideAccept: bool,
  hideProofReading: bool,
  hideReject: bool,
  hideSaveDraft: bool,
  children: node,
  description: string,
  initialValues: shape({}),
  name: string,
  translationStatus: string,
  onAcceptReject: func,
  onProofReading: func,
  onSaveDraft: func,
  intl: shape({
    formatMessage: func
  })
}

export default injectIntl(TranslateProductForm)
