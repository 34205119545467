import { bool, node, shape } from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Table } from 'reactstrap'

import { cols } from './config'
import st from './OrderProductsTable.module.css'

const OrderProductsTable = ({ children, hideHeading, intl }) => {
  const tableCols = cols(intl)

  return (
    <Table responsive className={st.table}>
      {!hideHeading && (
        <thead>
          <tr>
            {tableCols.map(({ content, _id, ...restCol }) => (
              <th {...restCol} key={_id}>
                {content}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>{children}</tbody>
    </Table>
  )
}

OrderProductsTable.defaultProps = {
  children: null,
  hideHeading: false
}
OrderProductsTable.propTypes = {
  children: node,
  hideHeading: bool,
  intl: shape({})
}

export default injectIntl(OrderProductsTable)
