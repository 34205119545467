import { toast } from 'react-toastify'

toast.configure()

export const notifyPositions = {
  top: {
    left: 'top-left',
    right: 'top-right',
    center: 'top-center'
  },
  bottom: {
    left: 'bottom-left',
    right: 'bottom-right',
    center: 'bottom-center'
  }
}

const notify = (type, content, position = notifyPositions.bottom.center) => {
  const options = {
    position: position,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  }

  switch (type) {
    case 'success':
      toast.success(content, options)
      break
    case 'error':
      toast.error(content, options)
      break
    case 'warning':
      toast.warn(content, options)
      break
    case 'info':
      toast.info(content, options)
      break
    default:
      toast.info(content, options)
      break
  }
}

export const showNotify = ({ isError = false, message = '' } = {}) => {
  notify(isError ? 'error' : 'success', message, notifyPositions.bottom.center)
}

export default notify
