import { noop, omit } from 'lodash'
import { bool, element, func, node, oneOfType, string } from 'prop-types'
import React from 'react'
import {
  Modal as ModalRS,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'

const Modal = ({ body, footer, title, ...props }) => (
  <ModalRS {...props}>
    <ModalHeader {...omit(props, ['isOpen'])}>{title}</ModalHeader>
    {body && <ModalBody>{body}</ModalBody>}
    <ModalFooter>{footer}</ModalFooter>
  </ModalRS>
)

Modal.defaultProps = {
  body: null,
  footer: null,
  isOpen: false,
  title: '',
  toggle: noop
}
Modal.propTypes = {
  body: element,
  footer: element,
  isOpen: bool,
  title: oneOfType([string, node]),
  toggle: func
}

export default Modal
