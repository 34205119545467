import cn from 'classnames'
import { node, string } from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Table } from 'reactstrap'

import { headings } from './config'
import st from './OrderDetails.module.css'

const OrderDetails = ({ children, className }) => (
  <Table
    responsive
    className={cn(st.table, {
      [className]: className.length > 0
    })}
  >
    <thead>
      <tr>
        {headings.map(({ content, ...headingRest }) => (
          <th {...headingRest} key={content}>
            <FormattedMessage id={content} />
          </th>
        ))}
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </Table>
)

OrderDetails.defaultProps = {
  children: null,
  className: ''
}
OrderDetails.propTypes = {
  children: node,
  className: string
}

export default OrderDetails
