import { get } from 'lodash'

import notify, { notifyPositions } from '../../utils/toast'
import {
  add,
  approve,
  disApprove,
  getCollectionByVendor,
  getCollectionProductTranslates,
  readCollection,
  remove,
  removeFile,
  updateName,
  uploadFile
} from '../service/collection-service'
import {
  ADDED_COLLECTION,
  APPROVE_COLLECTION,
  COLLECTION_TRANSLATES_PAGE,
  DELETED_COLLECTION,
  DELETED_IMAGE,
  DISAPPROVE_COLLECTION,
  GET_PRODUCT_LIST_BY_COLLECTION_FOR_TRANSLATES,
  GOT_COLLECTION,
  GOT_COLLECTIONS,
  UPLOAD_IMAGE
} from './action-types'
import { AuthError } from './auth-action'
import { HideLoading, ShowLoading } from './loading-action'
import { GetVendor } from './vendor-action'

const addCollection = (added, collection) => ({
  type: ADDED_COLLECTION,
  added,
  collection
})

const collectionTranslatesPageAc = (page = 0) => ({
  payload: { page },
  type: COLLECTION_TRANSLATES_PAGE
})

const getCollectionProductTranslatesDispatchObj = (
  gotTranslates,
  translatesList
) => ({
  type: GET_PRODUCT_LIST_BY_COLLECTION_FOR_TRANSLATES,
  gotTranslates,
  translatesList
})

const getCollection = (got, collections) => ({
  type: GOT_COLLECTIONS,
  got,
  collections
})

const getCollectionById = (gotOne, collection) => ({
  type: GOT_COLLECTION,
  gotOne,
  collection
})

const approveCollection = (approve) => ({
  type: APPROVE_COLLECTION,
  approve
})

const disapproveCollection = (disapproved) => ({
  type: DISAPPROVE_COLLECTION,
  disapproved
})

const deleteCollection = ({ deleted, id }) => ({
  payload: { deleted, id },
  type: DELETED_COLLECTION
})

const uploadImage = (uploaded) => ({
  type: UPLOAD_IMAGE,
  uploaded
})

const deleteImage = (collection, deletedImage) => ({
  type: DELETED_IMAGE,
  deletedImage,
  collection
})

const AddCollection = (collection) => (dispatch) =>
  add(collection)
    .then((res) => {
      if (res.status === 200) {
        dispatch(addCollection(true, res.data))
      }
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      dispatch(addCollection(false, null))
    })

const GetCollection = (vendor, page, limit) => (dispatch) =>
  getCollectionByVendor(vendor, page, limit)
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCollection(true, res.data.docs))
      }
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      dispatch(getCollection(false, null))
    })

const GetCollectionById =
  (config = {}) =>
  (dispatch) => {
    const { id, ifGetVendor, withLoading = false } = config || {}

    if (withLoading) {
      dispatch(ShowLoading())
    }

    return readCollection(id).then(({ data, error }) => {
      if (data) {
        const collection = get(data, '[0]')
        const vendorId = get(collection, 'vendor')

        dispatch(getCollectionById(true, collection))

        if (ifGetVendor && vendorId) {
          dispatch(
            GetVendor({
              ifWithLoading: false,
              vendorId
            })
          )
        }
      }

      if (error) {
        const { response } = error

        if (response && response.status === 401) {
          dispatch(AuthError(response.status))
        }

        notify('error', 'service error', notifyPositions.bottom.center)
        dispatch(getCollectionById(false, null))
      }

      if (withLoading) {
        dispatch(HideLoading())
      }
    })
  }

const ApproveCollection = (id) => (dispatch) =>
  approve(id)
    .then((res) => {
      if (res.status === 200) {
        dispatch(approveCollection(true))
      }
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      dispatch(approveCollection(false))
    })

const DisapproveCollection = (id) => (dispatch) =>
  disApprove(id)
    .then((res) => {
      if (res.status === 200) {
        dispatch(disapproveCollection(true))
      }
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      dispatch(disapproveCollection(false))
    })

const DeleteCollection = (id) => (dispatch) =>
  remove(id)
    .then((res) => {
      if (res.status === 204) {
        dispatch(
          deleteCollection({
            deleted: true,
            id
          })
        )
      }
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      dispatch(deleteCollection({ deleted: false }))
    })

const UploadImage = (file, dataTypesId, collectionId) => (dispatch) => {
  const bodyFormData = new FormData()
  bodyFormData.append('image', file)
  uploadFile(bodyFormData, dataTypesId, collectionId)
    .then((res) => {
      if (res.status === 200) {
        dispatch(uploadImage(true))
      }
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      dispatch(uploadImage(false))
    })
}

const RemoveImage = (id) => (dispatch) => {
  removeFile(id)
    .then((res) => {
      if (res.status === 200) {
        dispatch(deleteImage(res.data, true))
      }
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      dispatch(deleteImage(null, false))
    })
}

const UpdateCollectionName = (id, name) => (dispatch) =>
  updateName(id, name)
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCollectionById(true, res.data))
      }
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      dispatch(getCollectionById(false, null))
    })

/**
 * @param {Object} conf
 * @param {String} conf.collection
 * @param {Boolean} [params.hasProducts]
 * @param {Number} conf.page
 * @param {Number} [conf.readyToTranslate]
 * @param {String} conf.vendor
 */
const GetCollectionProductTranslates =
  (conf = {}) =>
  (dispatch) => {
    dispatch(ShowLoading())
    getCollectionProductTranslates(conf).then((res) => {
      const { error } = res

      if (error) {
        const errorStatus = get(error, 'response.status', '')

        dispatch(AuthError(errorStatus))
        dispatch(getCollectionProductTranslatesDispatchObj(false, null))
      } else {
        dispatch(getCollectionProductTranslatesDispatchObj(true, res))
      }

      dispatch(HideLoading())
    })
  }

export {
  AddCollection,
  ApproveCollection,
  collectionTranslatesPageAc,
  DeleteCollection,
  DisapproveCollection,
  GetCollection,
  GetCollectionById,
  GetCollectionProductTranslates,
  RemoveImage,
  UpdateCollectionName,
  UploadImage
}
