import cn from 'classnames'
import { arrayOf, bool, func, node, number, shape, string } from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Table as TableRS } from 'reactstrap'

import st from './Table.module.css'

const Table = ({ className, headings, mod, rows, ...props }) => (
  <TableRS
    {...props}
    className={cn(className, {
      [st.mod1]: mod === 1
    })}
  >
    {headings.length > 0 && (
      <thead className={st.thead}>
        <tr>
          {headings.map(({ text }, i) => (
            <th
              className={cn({
                [st.rightCell]: i === headings.length - 1 && mod !== 1
              })}
              key={text}
            >
              {text.length > 0 && <FormattedMessage id={text} />}
            </th>
          ))}
        </tr>
      </thead>
    )}
    <tbody>
      {rows.map((row) => {
        const { cells = [], _id, ...props } = row

        return (
          <tr {...props} key={_id}>
            {cells.map(({ className, content, ...props }, cellKey) => (
              <td
                {...props}
                className={cn(className, {
                  [st.rightCell]: cellKey === cells.length - 1 && mod !== 1
                })}
                key={cellKey}
              >
                {content}
              </td>
            ))}
          </tr>
        )
      })}
    </tbody>
  </TableRS>
)
Table.defaultProps = {
  className: '',
  headings: [],
  mod: 0,
  rows: [],
  striped: false
}
Table.propTypes = {
  className: string,
  headings: arrayOf(
    shape({
      className: string,
      text: string
    })
  ),
  mod: number,
  rows: arrayOf(
    shape({
      className: string,
      cells: arrayOf(
        shape({
          className: string,
          content: node
        })
      ),
      onClick: func,
      _id: string.isRequired
    })
  ),
  striped: bool
}

export default Table
