import classNames from 'classnames'
import { Button } from 'components/buttons'
import { InputFile } from 'components/formParts'
import Spinner from 'components/Spinner/Spinner'
import { useFormik } from 'formik'
import { bool, string } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  attachExternalDocumentation,
  attachExternalShippingLabel,
  deleteExternalDocumentation,
  deleteExternalShippingLabel,
  finalizeExternalOrder,
  getExternalOrderStatus,
  getOrderDocumentation
} from 'redux/service/order-service'
import { showNotify } from 'utils/toast'

import st from './VendorOrderExternalManagement.module.css'

const VendorOrderExternalManagement = ({ orderNumber, vendor, finalized }) => {
  const [isFinalized, setIsFinalized] = useState(finalized)
  const [status, setStatus] = useState(undefined)
  const [documentationPending, setDocumentationPending] = useState(false)
  const [customShippingLabelPending, setCustomShippingLabelPending] =
    useState(false)
  const [shippingLabel, setShippingLabel] = useState()
  const [etdDocumentation, setEtdDocumentation] = useState()
  const [dirty, setDirty] = useState(false)
  const [canProceed, setCanProceed] = useState(false)
  const intl = useIntl()

  const {
    values,
    errors,
    setValues,
    handleSubmit,
    handleBlur,
    handleChange,
    isSubmitting
  } = useFormik({
    initialValues: {
      otherDocumentation: '',
      attachEtdDocumentation: false,
      attachShippingLabel: false
    },
    onSubmit: async (values, { setSubmitting }) => {
      setDirty(true)
      const { error } = await finalizeExternalOrder({
        orderNumber,
        vendorId: vendor,
        attachEtdDocumentation,
        attachShippingLabel
      })

      if (error) {
        showNotify({ isError: true, message: error.message || error.data })
      } else {
        setIsFinalized(true)
      }

      setSubmitting(false)
    }
  })

  const {
    customShippingLabel,
    otherDocumentation,
    attachShippingLabel,
    attachEtdDocumentation
  } = values

  const onCustomShippingLabelUpload = async (e) => {
    setCustomShippingLabelPending(true)
    const { files } = e.target
    if (files[0]) {
      const response = await attachExternalShippingLabel({
        orderNumber,
        vendorId: vendor,
        shippingLabel: files[0]
      })
      const { error, externalShippingLabel } = response
      if (error) {
        showNotify({ isError: true, message: error.message })
      }
      if (externalShippingLabel) {
        setValues({ ...values, customShippingLabel: externalShippingLabel })
        showNotify({ message: 'Custom shipping label uploaded successfully' })
      }
      if (response) {
        setCustomShippingLabelPending(false)
      }
    }
  }

  const onCustomShippingLabelRemove = async () => {
    const { success, error } = await deleteExternalShippingLabel({
      orderNumber,
      vendorId: vendor
    })
    if (error && !success) {
      showNotify({ isError: true, message: error.message })
    }

    if (success) {
      showNotify({ message: 'Removed custom shipping label successfully' })
      setValues({ ...values, customShippingLabel: '' })
    }
  }

  const onDocumentationUpload = async (e) => {
    setDocumentationPending(true)
    const { files } = e.target
    if (files[0]) {
      const response = await attachExternalDocumentation({
        orderNumber,
        vendorId: vendor,
        documentation: files[0]
      })
      const { error, externalDocumentation } = response
      if (error) {
        showNotify({ isError: true, message: error.message })
      }
      if (externalDocumentation) {
        setValues({ ...values, otherDocumentation: externalDocumentation })
        showNotify({ message: 'Documentation uploaded successfully' })
      }
      if (response) {
        setDocumentationPending(false)
      }
    }
  }

  const onDocumentationRemove = async () => {
    const { success, error } = await deleteExternalDocumentation({
      orderNumber,
      vendorId: vendor
    })
    if (error && !success) {
      showNotify({ isError: true, message: error.message })
    }

    if (success) {
      showNotify({ message: 'Removed documentation successfully' })
      setValues({ ...values, otherDocumentation: '' })
    }
  }

  useEffect(async () => {
    const { data, error } = await getExternalOrderStatus({
      orderNumber,
      vendorId: vendor
    })
    if (error) {
      showNotify({ isError: true, message: error.message })
    }
    if (data) {
      setStatus(data.status)
    }

    const { data: documentationData, error: documentationError } =
      await getOrderDocumentation({
        orderNumber,
        vendorId: vendor
      })

    if (documentationError) {
      showNotify({ isError: true, message: documentationError.message })
    }

    if (documentationData) {
      setEtdDocumentation(documentationData.etdDocumentation)
      setShippingLabel(documentationData.shippingLabel)
      setValues({
        ...values,
        attachEtdDocumentation: !!documentationData.etdDocumentation,
        attachShippingLabel: !!documentationData.shippingLabel,
        otherDocumentation: documentationData.externalDocumentation,
        customShippingLabel: documentationData.externalShippingLabel
      })
    }
  }, [vendor, orderNumber])

  useEffect(() => {
    setCanProceed(
      attachShippingLabel ||
        attachEtdDocumentation ||
        !!otherDocumentation ||
        !!customShippingLabel
    )
  }, [
    attachShippingLabel,
    attachEtdDocumentation,
    otherDocumentation,
    customShippingLabel
  ])

  return (
    <div className={st.container} data-cy="shippingSection">
      <p className={st.fileInputTitle}>
        <FormattedMessage id="External order status" />: {status}
      </p>
      <form onSubmit={handleSubmit}>
        <section>
          {etdDocumentation && (
            <div
              className={classNames(
                'custom-control custom-checkbox',
                st.checkbox
              )}
            >
              <input
                checked={attachEtdDocumentation}
                className="custom-control-input"
                id="attachEtdDocumentation"
                name="attachEtdDocumentation"
                type="checkbox"
                value={attachEtdDocumentation}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isFinalized}
              />
              <label
                className={classNames('custom-control-label', st.label)}
                htmlFor="attachEtdDocumentation"
              >
                <FormattedMessage id="Attach ETD Documentation" />
                <a
                  href={etdDocumentation}
                  rel="noopener noreferrer"
                  target="_"
                  className={st.fileInputsTitleA}
                >
                  Link
                </a>
              </label>
            </div>
          )}
          {shippingLabel && (
            <div
              className={classNames(
                'custom-control custom-checkbox',
                st.checkbox
              )}
            >
              <input
                checked={attachShippingLabel}
                className="custom-control-input"
                id="attachShippingLabel"
                name="attachShippingLabel"
                type="checkbox"
                value={attachShippingLabel}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isFinalized}
              />
              <label
                className={classNames('custom-control-label', st.label)}
                htmlFor="attachShippingLabel"
              >
                <FormattedMessage id="Attach shipping label" />
                <a
                  href={shippingLabel}
                  rel="noopener noreferrer"
                  target="_"
                  className={st.fileInputsTitleA}
                >
                  Link
                </a>
              </label>
            </div>
          )}
          {((!isFinalized && !shippingLabel) || customShippingLabel) && (
            <div className={st.fileInputs}>
              <p className={st.fileInputTitle}>
                <FormattedMessage id="Custom shipping label" />
                {customShippingLabel && (
                  <a
                    href={customShippingLabel}
                    rel="noopener noreferrer"
                    target="_"
                    className={st.fileInputsTitleA}
                  >
                    Link
                  </a>
                )}
              </p>
              {!isFinalized && (
                <InputFile
                  label={intl.formatMessage({
                    id: 'Upload shipping label'
                  })}
                  onChange={onCustomShippingLabelUpload}
                  accept="application/pdf"
                  isLoading={customShippingLabelPending}
                  fileUrl={customShippingLabel}
                  onClose={onCustomShippingLabelRemove}
                  error={dirty ? errors.customShippingLabel : ''}
                  dataCy="ShippingInputFile"
                />
              )}
            </div>
          )}
          {(!isFinalized || otherDocumentation) && (
            <div className={st.fileInputs}>
              <p className={st.fileInputTitle}>
                <FormattedMessage id="Other documentation" />
                {otherDocumentation && (
                  <a
                    href={otherDocumentation}
                    rel="noopener noreferrer"
                    target="_"
                    className={st.fileInputsTitleA}
                  >
                    Link
                  </a>
                )}
              </p>
              {!isFinalized && (
                <InputFile
                  label={intl.formatMessage({
                    id: 'Upload other documentation'
                  })}
                  onChange={onDocumentationUpload}
                  accept="application/pdf"
                  isLoading={documentationPending}
                  fileUrl={otherDocumentation}
                  onClose={onDocumentationRemove}
                  error={dirty ? errors.otherDocumentation : ''}
                  dataCy="ShippingInputFile"
                />
              )}
            </div>
          )}
        </section>
        {!isFinalized && (
          <section className={st.submitRow}>
            <Button
              black
              disabled={!canProceed || isSubmitting}
              onClick={() => setDirty(true)}
              type="submit"
            >
              {isSubmitting ? (
                <Spinner
                  color="--main-bg-light"
                  width={43}
                  length={3}
                  size={14}
                  show
                />
              ) : (
                <FormattedMessage id="Proceed with the external order" />
              )}
            </Button>
          </section>
        )}
      </form>
    </div>
  )
}

VendorOrderExternalManagement.propTypes = {
  orderNumber: string,
  vendor: string,
  finalized: bool
}

export default VendorOrderExternalManagement
