import {
  AUTHENTICATION_ERROR,
  GOT_USER,
  INVALID_AUTHORIZATION,
  RESET_ERROR_STATE,
  RESET_PASSWORD,
  RESET_USER_STATE,
  UPDATE_PASSWORD
} from '../actions/action-types'

const defaultState = {
  received: false,
  message: '',
  updated: false,
  error: false,
  user: null,
  isGotten: false
}

const UserReducer = (state = defaultState, action) => {
  switch (action.type) {
    case AUTHENTICATION_ERROR:
    case RESET_USER_STATE:
      return {
        ...state,
        ...defaultState
      }

    case INVALID_AUTHORIZATION:
      return {
        ...state,
        received: true,
        message: action.message,
        updated: false,
        error: action.error
      }

    case RESET_ERROR_STATE:
      return {
        ...state,
        message: action.message,
        error: action.error
      }
    case UPDATE_PASSWORD:
      return {
        ...state,
        received: false,
        message: action.message,
        updated: action.updated,
        error: false
      }
    case RESET_PASSWORD:
      return {
        ...state,
        received: false,
        message: action.message,
        updated: action.updated,
        error: false
      }
    case GOT_USER:
      return {
        ...state,
        isGotten: action.isGotten,
        user: action.user
      }
    default:
      return state
  }
}

export default UserReducer
