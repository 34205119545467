import admin from './admin'
import analytics from './analytics'
import braintree from './braintree'
import buyerDashboard from './buyer-dashboard'
import chart from './chart'
import collection from './collection'
import colors from './colors'
import form from './form'
import header from './header'
import login from './login'
import materials from './materials'
import onboarding from './onboarding'
import order from './order'
import other from './other'
import pageNF from './page-not-found'
import payment from './payment'
import product from './product'
import promote from './promote'
import propose_new_product from './propose-new-product'
import translations from './translations'
import vendor from './vendor'
import vendorPlans from './vendor-plans'

export default {
  ...analytics,
  ...braintree,
  ...chart,
  ...colors,
  ...header,
  ...login,
  ...materials,
  ...admin,
  ...buyerDashboard,
  ...form,
  ...onboarding,
  ...order,
  ...other,
  ...payment,
  ...vendor,
  ...collection,
  ...product,
  ...promote,
  ...translations,
  ...propose_new_product,
  ...pageNF,
  ...vendorPlans
}
