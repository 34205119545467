import { func, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import logo from 'static/logos/artemestLong.svg'
import { CURRENT_YEAR, EXTERNAL_URLS } from 'utils/constants'

import { LogIn } from '../redux/actions/users-action'
import notify, { notifyPositions } from '../utils/toast'
import Style from './styles/login.module.css'

class Login extends Component {
  state = {
    email: '',
    password: '',
    validate: {
      emailState: ''
    },
    show: false
  }

  validateEmail(e) {
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const { validate } = this.state
    if (emailRex.test(e.target.value)) {
      validate.emailState = 'has-success'
    } else {
      validate.emailState = 'has-danger'
    }
    this.setState({ validate })
  }

  componentDidUpdate() {
    const {
      authReducer,
      history: { push },
      userReducer
    } = this.props

    if (authReducer && authReducer.authorized) {
      push('/admin')
    }

    if (userReducer.error) {
      notify(
        'error',
        'Sorry your password or login did not match',
        notifyPositions.top.center
      )
    }
  }

  logIn = () => {
    if (this.state.email.length && this.state.password.length) {
      const user = {
        email: this.state.email,
        password: this.state.password
      }
      this.props.Authorization(user)
    } else {
      notify(
        'warning',
        'you should type your password and login',
        notifyPositions.top.center
      )
    }
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const { redirectToReferrer } = this.state
    const { intl } = this.props
    if (redirectToReferrer === true) {
      return <Redirect to={from} />
    }

    return (
      <div className={Style.body}>
        <div
          className={['row', Style.mainRow].join(' ')}
          style={{ width: '100%' }}
        >
          <Col
            md={{ size: 4, offset: 4 }}
            sm={{ size: 6, offset: 3 }}
            xs={{ size: 8, offset: 2 }}
            className={Style.mainDiv}
          >
            <img alt={'logo'} className={Style.logo} width={'50%'} src={logo} />
            <h2 className={Style.title}>
              <FormattedMessage id={'login'} />
            </h2>
            <Form onSubmit={(e) => this.submitForm(e)}>
              <Col>
                <FormGroup>
                  <Label for="exampleEmail" className={Style.txtColor}>
                    <FormattedMessage id={'Username'} />
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder={intl.formatMessage({ id: 'email' })}
                    value={this.state.email}
                    valid={this.state.validate.emailState === 'has-success'}
                    invalid={this.state.validate.emailState === 'has-danger'}
                    onChange={(event) => {
                      this.validateEmail(event)
                      this.setState({
                        email: event.target.value,
                        show: false
                      })
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        this.logIn()
                      }
                    }}
                    required
                  />
                  <FormFeedback valid>
                    <FormattedMessage id={'good label'} />
                  </FormFeedback>
                  <FormFeedback>
                    <FormattedMessage id={'error label'} />
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className={Style.txtColor} for="examplePassword">
                    <FormattedMessage id={'password'} />
                  </Label>
                  <Input
                    type="password"
                    name="password"
                    id="examplePassword"
                    placeholder="********"
                    value={this.state.password}
                    onChange={(event) => {
                      this.setState({
                        password: event.target.value,
                        show: false
                      })
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        this.logIn()
                      }
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <div style={{ width: '100%' }}>
                <div className={Style.loginButtonDiv}>
                  <Button
                    className={Style.loginButton}
                    color="primary"
                    onClick={this.logIn}
                  >
                    <FormattedMessage id={'login'} />
                  </Button>
                </div>
                <div className={Style.loginButtonDiv}>
                  <a href="/forgot-password">
                    <FormattedMessage id={'forgotPassword'} />
                  </a>
                </div>
              </div>
            </Form>
          </Col>
        </div>
        <Row
          className={[Style.div, !this.state.show ? 'd-none' : 'd-block'].join(
            ' '
          )}
        >
          <Col
            md={{ size: 4, offset: 4 }}
            sm={{ size: 6, offset: 3 }}
            xs={{ size: 8, offset: 2 }}
            className={[Style.error, Style.flipInX].join(' ')}
          >
            <FormattedMessage id={'incorrect email'} tagName={'span'} />
          </Col>
        </Row>
        <div className={Style.copyRight}>
          <p className={Style.copyRightP}>
            {' '}
            &copy; {CURRENT_YEAR} ARTEMEST.{' '}
            <FormattedMessage id={'CopyRights'} tagName={'span'} />
          </p>
          <p className={Style.privacyPolicy}>
            <a
              href={EXTERNAL_URLS.PRIVACY_POLICY}
              rel="noreferrer"
              target="_blank"
            >
              <FormattedMessage id="Privacy Policy" />
            </a>
          </p>
        </div>
      </div>
    )
  }
}
Login.propTypes = {
  authReducer: shape({}),
  history: shape({
    push: func
  }),
  userReducer: shape({
    error: string
  }),
  Authorization: func,
  intl: shape({
    formatMessage: func
  }),
  location: shape({
    state: string
  })
}
const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  userReducer: state.userReducer
})

const mapDispatchToProps = (dispatch) => ({
  Authorization: (user) => dispatch(LogIn(user))
})

export default withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(Login))
)
