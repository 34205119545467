import { omit } from 'lodash'

import { formInitialValues } from '../components/PickupForm/config'
import {
  INIT,
  INIT_PICKUP_FORM,
  ORDERS_CERTIFICATION_FILE_ADD,
  ORDERS_CERTIFICATION_FILE_PENDING_ADD,
  ORDERS_CERTIFICATION_FILE_PENDING_REMOVE,
  ORDERS_CERTIFICATION_FILE_REMOVE,
  ORDERS_DATA_LOADING_ADD,
  ORDERS_DATA_LOADING_REMOVE,
  ORDERS_IMAGE_LOADING_ADD,
  ORDERS_IMAGE_LOADING_REMOVE,
  ORDERS_IMAGE_PENDING_ADD,
  ORDERS_IMAGE_PENDING_REMOVE,
  ORDERS_LAST_SUMITTED_VALUES_ADD,
  ORDERS_LOADING,
  ORDERS_PICKUP_SUBMITTING,
  ORDERS_PRODUCT_PARCEL_ADD,
  ORDERS_PRODUCT_PARCEL_DELETE,
  ORDERS_PROFORMA_FILE_ADD,
  ORDERS_PROFORMA_FILE_PENDING_ADD,
  ORDERS_PROFORMA_FILE_PENDING_REMOVE,
  ORDERS_PROFORMA_FILE_REMOVE,
  PICKUP_FORM_LOADING
} from './types'

export const initialState = {
  ordersAreLoading: true,
  country: '',
  listPrice: '',
  netPrice: '',
  products: [],
  shipBy: '',
  status: '',
  ordersLoading: [],
  imagesLoading: [],
  lastSubmittedValues: {},
  pickupFormDataFromContact: formInitialValues(),
  pickupFormLoading: true,
  pickupFormSubmitting: false,
  imagesPending: {},
  proformaFiles: {},
  proformaFilesPending: {},
  certificationFiles: {},
  certificationFilesPending: {}
}

export const reducer = (state, action) => {
  switch (action.type) {
    case INIT:
      return {
        ...state,
        ...action.payload
      }
    case ORDERS_LOADING: {
      return { ...state, ordersAreLoading: action.payload }
    }
    case INIT_PICKUP_FORM:
      return { ...state, ...action.payload }
    case ORDERS_PRODUCT_PARCEL_ADD: {
      const { number, productId } = action.payload
      const product = state.products.find(
        (product) => product._id === productId
      )
      const productParcels = product.parcels || []
      const isParcelPresent =
        productParcels.findIndex((parcel) => parcel.number === number) > -1
      if (isParcelPresent) {
        return state
      }
      productParcels.push(action.payload)
      const modifiedProduct = { ...product, parcels: productParcels }
      return {
        ...state,
        products: state.products.map((product) =>
          product._id === productId ? modifiedProduct : product
        )
      }
    }
    case ORDERS_PRODUCT_PARCEL_DELETE: {
      const { parcelNumber, productId } = action.payload
      const product = state.products.find(
        (product) => product._id === productId
      )
      const productParcels = product.parcels || []
      const filteredProductParcels = productParcels.filter(
        (parcel) => parcel.number !== parcelNumber
      )
      const modifiedProduct = { ...product, parcels: filteredProductParcels }

      return {
        ...state,
        products: state.products.map((product) =>
          product._id === productId ? modifiedProduct : product
        )
      }
    }
    case ORDERS_DATA_LOADING_ADD: {
      return {
        ...state,
        ordersLoading: [...state.ordersLoading, action.payload]
      }
    }
    case ORDERS_DATA_LOADING_REMOVE: {
      return {
        ...state,
        ordersLoading: state.ordersLoading.filter(
          (item) => item !== action.payload
        )
      }
    }
    case ORDERS_IMAGE_LOADING_ADD: {
      return {
        ...state,
        imagesLoading: [...state.imagesLoading, action.payload]
      }
    }
    case ORDERS_IMAGE_LOADING_REMOVE: {
      return {
        ...state,
        imagesLoading: state.imagesLoading.filter(
          (item) => item !== action.payload
        )
      }
    }
    case ORDERS_IMAGE_PENDING_ADD: {
      return {
        ...state,
        imagesPending: { ...state.imagesPending, [action.payload]: true }
      }
    }
    case ORDERS_IMAGE_PENDING_REMOVE: {
      return {
        ...state,
        imagesPending: omit(state.imagesLoading, [action.payload])
      }
    }
    case ORDERS_LAST_SUMITTED_VALUES_ADD: {
      return {
        ...state,
        lastSubmittedValues: {
          ...state.lastSubmittedValues,
          [action.payload.productId]: action.payload.data
        }
      }
    }
    case PICKUP_FORM_LOADING: {
      return { ...state, pickupFormLoading: action.payload }
    }
    case ORDERS_PROFORMA_FILE_PENDING_ADD: {
      return {
        ...state,
        proformaFilesPending: {
          ...state.proformaFilesPending,
          [action.payload]: true
        }
      }
    }
    case ORDERS_PROFORMA_FILE_PENDING_REMOVE: {
      return {
        ...state,
        proformaFilesPending: omit(state.proformaFilesPending, [action.payload])
      }
    }
    case ORDERS_CERTIFICATION_FILE_PENDING_ADD: {
      return {
        ...state,
        certificationFilesPending: {
          ...state.certificationFilesPending,
          [action.payload]: true
        }
      }
    }
    case ORDERS_CERTIFICATION_FILE_PENDING_REMOVE: {
      return {
        ...state,
        certificationFilesPending: omit(state.certificationFilesPending, [
          action.payload
        ])
      }
    }

    case ORDERS_PROFORMA_FILE_ADD: {
      return {
        ...state,
        proformaFiles: {
          ...state.proformaFiles,
          [action.payload.productId]: action.payload.url
        }
      }
    }
    case ORDERS_PROFORMA_FILE_REMOVE: {
      return {
        ...state,
        proformaFiles: omit(state.proformaFiles, [action.payload])
      }
    }
    case ORDERS_CERTIFICATION_FILE_ADD: {
      return {
        ...state,
        certificationFiles: {
          ...state.certificationFiles,
          [action.payload.productId]: action.payload.url
        }
      }
    }
    case ORDERS_CERTIFICATION_FILE_REMOVE: {
      return {
        ...state,
        certificationFiles: omit(state.certificationFiles, [action.payload])
      }
    }

    case ORDERS_PICKUP_SUBMITTING: {
      return { ...state, pickupFormSubmitting: action.payload }
    }
    default:
      return state
  }
}
