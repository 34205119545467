import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import './index.css'

import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { GTM_ID } from 'utils/constants'

import App from './App'
import CheckAuth from './redux/actions/auth-action'
import allReducers from './redux/reducers'
import * as serviceWorker from './serviceWorker'

const tagManagerArgs = {
  gtmId: GTM_ID
}

TagManager.initialize(tagManagerArgs)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(allReducers, composeEnhancers(applyMiddleware(thunk)))

store.dispatch(CheckAuth())

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
