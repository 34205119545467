import 'react-bootstrap-typeahead/css/Typeahead.css'

import { Spinner } from 'components'
import { get, noop } from 'lodash'
import { arrayOf, func, number, oneOfType, shape, string } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Button, Input } from 'reactstrap'
import { WOODEN_CRATE } from 'utils/constants'
import { countEurToUsd, countMargin, getPhotoSrc } from 'utils/products'
import { isNumeric } from 'utils/propTypes'
import { parseNumber } from 'utils/utils'

import { readPricing, updatePricing } from '../../redux/service/pricing-service'
import notify, { notifyPositions } from '../../utils/toast'
import Style from './pricing-table.module.css'

const Elements = ({
  element,
  fiscalRegimenPercent,
  pendingCategoryProductId,
  productTypes,
  onCategoryChange
}) => {
  const [pricingElement, setPricing] = useState(null)
  const [type, setType] = useState('')
  const [state, setState] = useState({
    marginPercentange: '',
    finalEUR: '',
    finalUSD: '',
    shippingEUR: '',
    shippingUSD: '',
    expressEUR: false,
    expressUSD: false,
    finalShippingEUR: '',
    finalShippingUSD: '',
    woodenCrateEUR: 0,
    woodenCrateUSD: 0
  })

  const {
    finalEUR,
    finalUSD,
    retailEUR,
    retailUSD,
    shippingEUR,
    shippingUSD,
    expressEUR,
    expressUSD,
    finalShippingEUR,
    finalShippingUSD,
    wholeSaleEUR,
    wholeSaleUSD,
    woodenCrateEUR,
    woodenCrateUSD
  } = state

  const handleChange = (name) => (event) => {
    const { value } = event.target
    const parsed = parseFloat(value)

    setState({
      ...state,
      [name]: isNumeric(parsed) ? parsed : ''
    })
  }

  const changeType = (e) => {
    const productId = get(element, '_id', '')
    const typeObj = get(e, '[0]', {})
    const typeId = typeObj.id

    setType(typeObj)

    if (productId && typeId) {
      onCategoryChange({
        productId: get(element, '_id'),
        typeId: typeObj.id
      })
    }
  }

  const getWoodenCrateObj = (product = {}) => {
    return (
      get(product, 'additionalData', []).find(
        (ad) =>
          (ad.language === 'en' && ad.key === WOODEN_CRATE.en) ||
          (ad.language === 'it' && ad.key === WOODEN_CRATE.it)
      ) || {}
    )
  }

  useEffect(() => {
    if (!element.pricing) {
      return
    }

    readPricing(element.pricing)
      .then((res) => {
        const pricing = res.data
        const finalEURParsed = parseFloat(get(pricing, 'EUR.final'))
        const finalUSDParsed = parseFloat(get(pricing, 'USD.final'))
        const retailEUR = parseFloat(get(pricing, 'EUR.retail')) || 0
        const retailUSD =
          parseFloat(get(pricing, 'USD.retail')) ||
          countEurToUsd(+retailEUR) ||
          0
        const wholeSaleEUR = parseFloat(get(pricing, 'EUR.wholesale')) || 0
        const woodenCrateObj = getWoodenCrateObj(element)
        const woodenCrateValue = woodenCrateObj.value || 0

        setPricing(pricing)
        setState({
          expressEUR: get(pricing, 'EUR.express') || false,
          expressUSD: get(pricing, 'USD.express') || false,
          finalEUR: isNumeric(finalEURParsed) ? finalEURParsed : retailEUR,
          finalUSD: isNumeric(finalUSDParsed) ? finalUSDParsed : retailUSD,
          finalShippingEUR: parseFloat(get(pricing, 'EUR.finalShipping')) || 0,
          finalShippingUSD: parseFloat(get(pricing, 'USD.finalShipping')) || 0,
          productType: get(pricing, 'product.type._id'),
          retailEUR,
          retailUSD,
          shippingEUR: parseFloat(get(pricing, 'EUR.shipping')) || 0,
          shippingUSD: parseFloat(get(pricing, 'USD.shipping')) || 0,
          wholeSaleEUR,
          wholeSaleUSD:
            parseFloat(get(pricing, 'USD.wholesale')) ||
            countEurToUsd(+wholeSaleEUR) ||
            0,
          woodenCrateEUR: woodenCrateValue,
          woodenCrateUSD: woodenCrateValue
        })
      })
      .catch(() => {
        notify(
          'error',
          `can not fetch data from server with id ${element.pricing}`,
          notifyPositions.bottom.center
        )
      })
  }, [element])

  const getCategoryDefaultSelected = () => {
    const typeId = element.type || pricingElement.type
    const productType = productTypes.find((pType) => pType.id === typeId) || {}

    return [get(productType, 'label', '')]
  }

  const imgSrc = getPhotoSrc(element)

  const prepareForRequest = () => {
    if (!pricingElement._id) {
      notify('error', 'element id not found', notifyPositions.bottom.center)

      return
    }

    const eurFinal = isNumeric(finalEUR) ? finalEUR : retailEUR
    const usdFinal = isNumeric(finalUSD) ? finalUSD : retailUSD
    const obj = {
      EUR: {
        final: eurFinal,
        shipping: shippingEUR,
        finalShipping: finalShippingEUR,
        express: expressEUR,
        wholesale: wholeSaleEUR,
        retail: retailEUR,
        marginPercentange: pricingElement.EUR
          ? pricingElement.EUR.marginPercentange
          : '',
        marginTot: pricingElement.EUR ? pricingElement.EUR.marginTot : ''
      },
      USD: {
        final: usdFinal,
        shipping: shippingUSD,
        finalShipping: finalShippingUSD,
        express: expressUSD,
        wholesale: wholeSaleUSD,
        retail: retailUSD,
        marginPercentange: pricingElement.USD
          ? pricingElement.USD.marginPercentange
          : '',
        marginTot: pricingElement.USD ? pricingElement.USD.marginTot : ''
      },
      type: get(type, 'id', '')
    }

    updatePricing(pricingElement._id, obj)
      .then(() => {
        setState({
          ...state,
          finalEUR: eurFinal,
          finalUSD: usdFinal
        })
        notify('success', `updated successfully`, notifyPositions.bottom.center)
      })
      .catch(() => {
        notify(
          'error',
          `can not fetch data from server with id ${pricingElement._id}`,
          notifyPositions.bottom.center
        )
      })
  }

  return pricingElement ? (
    <tr key={element._id}>
      <td className={Style.verticalMiddle}>
        <Spinner length={3} show={pendingCategoryProductId[element._id]}>
          <Typeahead
            className={Style.types}
            defaultSelected={getCategoryDefaultSelected()}
            id={element._id}
            multiple={false}
            options={productTypes}
            placeholder="Choose a state..."
            onChange={changeType}
          />
        </Spinner>
      </td>
      <td className={Style.verticalMiddle}>
        <div className={Style.sku}>
          <Link to={`/product-detail/${element._id}`}>{element._id}</Link>
          <img alt="" className={Style.image} src={imgSrc} />
        </div>
      </td>
      <td>
        <div className={Style.twoValues}>EUR</div>
        <div className={Style.twoValues}>USD</div>
      </td>
      <td>
        <div className={Style.twoValues}>{parseNumber(wholeSaleEUR)}</div>
        <div className={Style.twoValues}>
          {parseNumber(countEurToUsd(wholeSaleEUR))}
        </div>
      </td>
      <td>
        {pricingElement.EUR && pricingElement.EUR.retail ? (
          <div className={Style.twoValues}>{parseNumber(retailEUR)}</div>
        ) : null}
        {pricingElement.EUR && pricingElement.EUR.retail ? (
          <div className={Style.twoValues}>{parseNumber(retailUSD)}</div>
        ) : null}
      </td>
      <td>
        <div className={Style.twoValues}>{parseNumber(shippingEUR)}</div>
        <div className={Style.twoValues}>{parseNumber(shippingUSD)}</div>
      </td>
      <td>
        <div className={Style.twoValues}>{parseNumber(woodenCrateEUR)}</div>
        <div className={Style.twoValues}>{parseNumber(woodenCrateUSD)}</div>
      </td>
      <td>
        {pricingElement.EUR ? (
          <div className={Style.twoValues}>
            {pricingElement.EUR &&
              parseNumber(
                countMargin({
                  final: +finalEUR,
                  finalShipping: +finalShippingEUR,
                  fiscalRegimenPercent,
                  shipping: +shippingEUR,
                  wholeSale: +wholeSaleEUR,
                  woodenCrate: woodenCrateEUR
                })
              )}
          </div>
        ) : null}
        {pricingElement.EUR ? (
          <div className={Style.twoValues}>
            {pricingElement.EUR &&
              parseNumber(
                countMargin({
                  final: +finalUSD,
                  finalShipping: +finalShippingUSD,
                  fiscalRegimenPercent,
                  shipping: +shippingUSD,
                  wholeSale: +wholeSaleUSD,
                  woodenCrate: woodenCrateUSD
                })
              )}
          </div>
        ) : null}
      </td>
      <td>
        {pricingElement.EUR ? (
          <div className={Style.twoValues}>
            {pricingElement.EUR &&
              parseNumber(
                countMargin({
                  final: +finalEUR,
                  finalShipping: +finalShippingEUR,
                  fiscalRegimenPercent,
                  isTotal: true,
                  shipping: +shippingEUR,
                  wholeSale: +wholeSaleEUR,
                  woodenCrate: woodenCrateEUR
                })
              )}
          </div>
        ) : null}
        {pricingElement.EUR ? (
          <div className={Style.twoValues}>
            {pricingElement.EUR &&
              parseNumber(
                countMargin({
                  final: +finalUSD,
                  finalShipping: +finalShippingUSD,
                  fiscalRegimenPercent,
                  isTotal: true,
                  shipping: +shippingUSD,
                  wholeSale: +wholeSaleUSD,
                  woodenCrate: woodenCrateUSD
                })
              )}
          </div>
        ) : null}
      </td>
      <td>
        <div>
          <Input
            type="number"
            className={Style.input}
            value={finalEUR}
            onChange={handleChange('finalEUR')}
          />
        </div>
        <div>
          <Input
            type="number"
            className={Style.input}
            value={finalUSD}
            onChange={handleChange('finalUSD')}
          />
        </div>
      </td>
      <td>
        <div>
          <Input
            type="number"
            className={Style.input}
            value={finalShippingEUR}
            onChange={handleChange('finalShippingEUR')}
          />
        </div>

        <div>
          <Input
            type="number"
            className={Style.input}
            value={finalShippingUSD}
            onChange={handleChange('finalShippingUSD')}
          />
        </div>
      </td>
      <td className={Style.verticalMiddle}>
        <Button onClick={prepareForRequest}>
          <FormattedMessage id="Save" />
        </Button>
      </td>
    </tr>
  ) : null
}

Elements.propTypes = {
  element: {},
  fiscalRegimenPercent: 0,
  pendingCategoryProductId: {},
  productTypes: [],
  onCategoryChange: noop
}
Elements.propTypes = {
  element: shape({}),
  fiscalRegimenPercent: number,
  pendingCategoryProductId: shape({}),
  productTypes: arrayOf(
    shape({
      id: oneOfType([number, string]),
      label: string
    })
  ),
  onCategoryChange: func
}

export default Elements
