import { Button } from 'components/buttons'
import { Input } from 'components/formParts'
import { Field, Form, Formik } from 'formik'
import { noop, string } from 'lodash'
import { func, shape } from 'prop-types'
import React, { useCallback } from 'react'
import { injectIntl } from 'react-intl'

import { validate } from './config'
import st from './PasswordSetForm.module.css'

const PasswordSetForm = ({ className, intl: { formatMessage }, onSubmit }) => {
  const fields = [
    {
      label: formatMessage({ id: 'password' }),
      name: 'password'
    },
    {
      label: formatMessage({ id: 'confirmPassword' }),
      name: 'confirmPassword'
    }
  ]
  const initialValues = fields.reduce((acc, { name }) => {
    acc[name] = ''

    return acc
  }, {})

  const handleValidate = useCallback(validate({ formatMessage }), [
    formatMessage
  ])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={handleValidate}
    >
      {() => (
        <Form className={className}>
          {fields.map(({ label, name }) => (
            <Field
              className={st.input}
              component={Input}
              key={name}
              label={label}
              name={name}
              type="password"
            />
          ))}
          <div className={st.btn}>
            <Button type="submit">
              {formatMessage({ id: 'setPassword' })}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

PasswordSetForm.defaultProps = {
  className: '',
  onSubmit: noop
}
PasswordSetForm.propTypes = {
  className: string,
  onSubmit: func,
  intl: shape({
    formatMessage: func
  })
}

export default injectIntl(PasswordSetForm)
