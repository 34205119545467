import { noop } from 'lodash'
import { checkPassword } from 'utils/auth'
import { ERRORS } from 'utils/constants'
import { passwordErrors } from 'utils/form'

export const formFields = (intl = {}) => {
  const confirmNewPassword = intl.formatMessage({
    id: 'Confirm new password'
  })

  return [
    {
      label: intl.formatMessage({ id: 'Email' }),
      name: 'email',
      placeholder: intl.formatMessage({ id: 'Type new email' }),
      type: 'email'
    },
    {
      label: intl.formatMessage({ id: 'old password' }),
      name: 'oldPassword',
      placeholder: intl.formatMessage({ id: 'Type old password' }),
      type: 'password'
    },
    {
      label: intl.formatMessage({ id: 'new password' }),
      name: 'newPassword',
      placeholder: intl.formatMessage({ id: 'Type new password' }),
      type: 'password'
    },
    {
      label: confirmNewPassword,
      name: 'confirmNewPassword',
      placeholder: confirmNewPassword,
      type: 'password'
    }
  ]
}

export const initialValuesDefault = {
  email: '',
  confirmNewPassword: '',
  newPassword: '',
  oldPassword: ''
}

/**
 * @param {Object} config
 * @param {Function} config.formatMessage
 */
export const validate =
  (config = {}) =>
  (values = {}) => {
    const { formatMessage = noop } = config
    const { confirmNewPassword, email, newPassword, oldPassword } = values
    const errors = {}
    const newPasswordError = checkPassword(newPassword)

    if (!email) {
      errors.email = ERRORS.REQUIRED
    }

    if (!oldPassword) {
      errors.oldPassword = ERRORS.REQUIRED
    }

    if (newPassword && newPassword === oldPassword) {
      errors.newPassword = ERRORS.DIFFER_FROM_OLD_PASSWORD
    }

    if (newPassword !== confirmNewPassword) {
      errors.newPassword = ERRORS.PASSWORDS_MUST_MATCH
      errors.confirmNewPassword = ERRORS.PASSWORDS_MUST_MATCH
    }

    passwordErrors({
      errorField: 'newPassword',
      errors,
      formatMessage,
      passwordCheckObj: newPasswordError
    })

    return errors
  }
