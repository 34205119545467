import { node, string } from 'prop-types'
import React, { useState } from 'react'
import { Tooltip as TooltipRS } from 'reactstrap'

import st from './Tooltip.module.css'

const Tooltip = ({ children, id, tooltip }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={st.wrap}>
      <span id={id}>{children}</span>
      <TooltipRS
        isOpen={isOpen}
        placement="right"
        target={id}
        toggle={handleToggle}
      >
        {tooltip}
      </TooltipRS>
    </div>
  )
}

Tooltip.defaultProps = {
  children: null,
  tooltip: null
}
Tooltip.propTypes = {
  children: node,
  id: string.isRequired,
  tooltip: node
}

export default Tooltip
