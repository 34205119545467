import { get, uniqueId } from 'lodash'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { GetCollection } from 'redux/actions/collection-action'
import { getCollection } from 'utils/collections'

export const topMenuItems = ({ currentURL = '', vendorId = '' } = {}) => {
  const data = [
    {
      anchor: 'Engagement',
      url: `/vendor/${vendorId}/analytics`
    },
    {
      anchor: 'Product Performance',
      url: `/vendor/${vendorId}/analytics/sales`
    },
    {
      anchor: 'Audience',
      url: `/vendor/${vendorId}/analytics/audience`
    }
  ]

  return data.map(({ anchor, url }) => {
    const active = currentURL === url
    const innerCmp = <FormattedMessage id={anchor} />

    return {
      active,
      content: active ? innerCmp : <Link to={url}>{innerCmp}</Link>,
      id: uniqueId(anchor)
    }
  })
}

export const mapActions = {
  GetCollection
}

export const mapState = ({ collectionReducer }) => {
  const collection = getCollection({ collectionReducer })
  return {
    collectionId: get(collection, '_id', '')
  }
}
