import { element } from 'prop-types'
import React from 'react'
import { Col, Row } from 'reactstrap'

import st from './PageTop.module.css'

const PageTop = ({ left, right }) => {
  const slots = [left, right]

  return (
    <Row className="rowWide">
      {slots.map((slot, key) => (
        <Col className={st.onboarding} key={key} md={6} xs={12}>
          {slot}
        </Col>
      ))}
    </Row>
  )
}
PageTop.defaultProps = {
  left: null,
  right: null
}
PageTop.propTypes = {
  left: element,
  right: element
}

export default PageTop
