import DropIn from 'braintree-web-drop-in-react'
import cn from 'classnames'
import { Button } from 'components/buttons'
import { BraintreePlansList } from 'components/lists'
import { Modal } from 'components/modals'
import { BraintreePlanTable } from 'components/tables'
import { get } from 'lodash'
import { array, bool, func, shape, string } from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { BRAINTREE_CLASS_NAMES, EXTERNAL_URLS } from 'utils/constants'
import { BRAINTREE_PLAN } from 'utils/payment'
import { showNotify } from 'utils/toast'

import st from './BraintreeSubscriptionPage.module.css'
import { getSelectedPrice, mapActions, mapState } from './config'

const BraintreeSubscriptionPage = ({
  buySubscriptionTh,
  clientToken,
  deleteSubscriptionsTh,
  GetVendor,
  initialSubscriptionTh,
  intl: { formatMessage },
  match: {
    params: { vendorId }
  },
  planItems,
  subscriptionSelectedId,
  subscriptionStatus,
  vendorIsNoticePeriod,
  updatePaymentDetails
}) => {
  const dropInOptions = {
    authorization: clientToken,
    threeDSecure: true
  }
  const [braintreeInstance, setBraintreeInstance] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [modalType, setModalType] = useState('')
  const [selectedPlan, setSelectedPlan] = useState({})
  const [isChangingPaymentMethod, setIsChangingPaymentMethod] = useState(false)
  const braintreeRef = useRef()
  const history = useHistory()

  useEffect(() => {
    initialSubscriptionTh({ vendorId }, history)
  }, [vendorId])

  const planSelectedPrice = getSelectedPrice({
    planId: selectedPlan.id,
    planItems
  })

  const cancelPlan = () => {
    deleteSubscriptionsTh({
      ids: [subscriptionSelectedId],
      onSuccess: () => {
        GetVendor({ vendorId })
      }
    })
  }

  const getCurrentPlan = () => get(subscriptionStatus, 'currentPlan', '')

  const getPlanById = (id) => planItems.find((pi) => pi.id === id) || {}

  const handleDropInInstance = (instance) => {
    setBraintreeInstance(instance)
  }

  const handlePay = () => {
    setModalType(BRAINTREE_PLAN.SELECT)
    toggleModal()
  }

  const handlePlanCancel = () => {
    setModalType(BRAINTREE_PLAN.CANCEL)
    toggleModal()
  }

  const handlePlanSelect = (plan) => {
    const { checked } = plan
    setSelectedPlan(checked ? plan : {})
  }

  const modalCancel = () => {
    setSelectedPlan({})
    toggleModal()
  }

  const modalConfirm = () => {
    const { CANCEL, SELECT } = BRAINTREE_PLAN

    if (modalType === CANCEL) {
      cancelPlan()
    }
    if (modalType === SELECT) {
      selectPlan()
    }

    toggleModal()
  }

  const selectPlan = () => {
    buySubscriptionTh({
      instance: braintreeInstance,
      planId: selectedPlan.id,
      amount: selectedPlan.price,

      vendorId,
      onSuccess: () => {
        const plan = planItems.find(({ id }) => id === selectedPlan.id)
        const planName = get(plan, 'name', '')

        if (planName) {
          const messageEnd = formatMessage({
            id: 'vendor plan ordered 2'
          })
          const messageStart = formatMessage({
            id: 'vendor plan ordered 1'
          })

          showNotify({
            message: `${messageStart} ${planName} ${messageEnd}`
          })

          setTimeout(() => {
            document.location.reload()
          }, 0)
        }
      }
    })
  }

  const makeModalTitle = () => {
    const { CANCEL, SELECT } = BRAINTREE_PLAN
    const currentPlan = getCurrentPlan()
    const title = 'Do you confirm'

    switch (modalType) {
      case CANCEL:
        return `${title} cancelling the ${currentPlan} plan?`

      case SELECT: {
        const plan = getPlanById(selectedPlan.id)

        return `${title} selecting ${plan.name}?`
      }

      default:
        return `${title} do this?`
    }
  }

  const toggleModal = () => {
    setModalShow(!modalShow)
  }

  const getBraintreeElements = () => {
    const braintreeElement = braintreeRef.current
    const braintreeMainEl = braintreeElement.querySelector(
      `.${BRAINTREE_CLASS_NAMES.main}`
    )

    const braintreeLoadingContainer = braintreeMainEl.querySelector(
      `.${BRAINTREE_CLASS_NAMES.loadingContainer}`
    )
    return {
      main: braintreeMainEl,
      loading: braintreeLoadingContainer
    }
  }

  const showBraintreeLoading = () => {
    const { main, loading } = getBraintreeElements()
    main.classList.remove(BRAINTREE_CLASS_NAMES.loaded)
    main.classList.add(BRAINTREE_CLASS_NAMES.loading)
    loading.classList.remove(BRAINTREE_CLASS_NAMES.hidden)
  }
  const hideBraintreeLoading = () => {
    const { main, loading } = getBraintreeElements()
    main.classList.remove(BRAINTREE_CLASS_NAMES.loading)
    main.classList.add(BRAINTREE_CLASS_NAMES.loaded)
    loading.classList.add(BRAINTREE_CLASS_NAMES.hidden)
  }

  const updateCard = async () => {
    const plan = getPlanById(subscriptionStatus.currentPlanId)
    setIsChangingPaymentMethod(true)
    updatePaymentDetails({
      instance: braintreeInstance,
      amount: plan.price,
      showLoading: showBraintreeLoading,
      onSuccess: () => {
        hideBraintreeLoading()
        setIsChangingPaymentMethod(false)
        showNotify({ message: 'Payment method updated successfully' })
      }
    })
  }
  const currentPlan = getCurrentPlan()
  return (
    <div className={cn('innerPage', st.page)}>
      <h1>Artemest Vendor Program</h1>
      <section className={st.description}>
        <p>
          <FormattedMessage id="Vendor Plans Description 1" />
        </p>
        <p>
          <strong>Premium Plus</strong>
          <br />
          <FormattedMessage id="Premium Plus Description" />
        </p>
        <p>
          <strong>Premium</strong>
          <br />
          <FormattedMessage id="Premium Description" />
        </p>
        <p>
          <strong>Base</strong>
          <br />
          <FormattedMessage id="Base Description" />
        </p>
        <p>
          <FormattedMessage id="Vendor Plans Call to Action" />{' '}
          <a
            href={EXTERNAL_URLS.ARTEMEST_PREMIUM}
            rel="noreferrer"
            target="_blank"
          >
            <FormattedMessage id="presentation" />
          </a>
          .
        </p>
      </section>
      {subscriptionStatus.currentPlan && (
        <>
          <h2>Status</h2>
          <BraintreePlanTable
            isEndingOn={vendorIsNoticePeriod}
            renewingCnt={
              subscriptionSelectedId && (
                <Button
                  danger
                  className={st.unsubscribe}
                  onClick={handlePlanCancel}
                >
                  <FormattedMessage id="Cancel" />
                </Button>
              )
            }
            values={subscriptionStatus}
          />
        </>
      )}

      <BraintreePlansList
        chosenPlanId={selectedPlan.id}
        className={st.plansList}
        items={
          subscriptionStatus.currentPlan
            ? planItems.filter((item) => item.isSelected)
            : planItems
        }
        onItemClick={handlePlanSelect}
      />
      {clientToken && (
        <>
          <h2>Payment Method</h2>
          <div className={st.dropIn} ref={braintreeRef}>
            <DropIn options={dropInOptions} onInstance={handleDropInInstance} />
          </div>
        </>
      )}
      {selectedPlan.id && (
        <div className={st.cta}>
          <Button onClick={handlePay}>Pay {planSelectedPrice}</Button>
        </div>
      )}
      <Modal
        footer={
          <>
            <Button onClick={modalConfirm}>
              <FormattedMessage id="Confirm" />
            </Button>
            <Button danger onClick={modalCancel}>
              <FormattedMessage id="Cancel" />
            </Button>
          </>
        }
        isOpen={modalShow}
        title={makeModalTitle()}
        toggle={modalCancel}
      />
      {currentPlan && (
        <div className={st.cta}>
          <Button disabled={isChangingPaymentMethod} onClick={updateCard}>
            <FormattedMessage id="Save" />
          </Button>
        </div>
      )}
    </div>
  )
}

BraintreeSubscriptionPage.propTypes = {
  buySubscriptionTh: func,
  clientToken: string,
  deleteSubscriptionsTh: func,
  GetVendor: func,
  initialSubscriptionTh: func,
  intl: shape({
    formatMessage: func
  }),
  match: shape({
    params: shape({
      vendorId: string
    })
  }),
  planItems: array,
  subscriptionSelectedId: array,
  subscriptionStatus: string,
  vendorIsNoticePeriod: bool,
  updatePaymentDetails: func
}
export default injectIntl(
  connect(mapState, mapActions)(BraintreeSubscriptionPage)
)
