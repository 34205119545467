import cn from 'classnames'
import { arrayOf, bool, shape, string } from 'prop-types'
import React from 'react'
import { Col, Row } from 'reactstrap'

import st from './LabelValueList.module.css'

const LabelValueList = ({ className, items }) => (
  <Row
    className={cn(st.list, {
      [className]: className.length > 0
    })}
    tag="ul"
  >
    {items.map(({ label, mod1, value }) => (
      <Col
        className={cn({
          [st.mod1]: mod1
        })}
        key={label}
        sm={6}
        tag="li"
        xs={12}
      >
        <p className={st.label}>{label}</p>
        <p>{value}</p>
      </Col>
    ))}
  </Row>
)

LabelValueList.defaultProps = {
  className: '',
  items: []
}
LabelValueList.propTypes = {
  className: string,
  items: arrayOf(
    shape({
      label: string,
      mod1: bool,
      value: string
    })
  )
}

export default LabelValueList
