import cn from 'classnames'
import { arrayOf, element, number, oneOfType, shape, string } from 'prop-types'
import React from 'react'
import { Col, Row } from 'reactstrap'
import { COLS_WIDTH } from 'utils/propTypes'

import st from './TitleIconContent.module.css'

const TitleIconContent = ({ className, cols, items, mod }) => (
  <Row
    className={cn('rowWide', st.list, {
      [st.mod1]: mod === 1,
      [className]: className.length > 0
    })}
    tag="ul"
  >
    {items.map(({ _id, content, contentClassName, icon, title }) => (
      <Col {...cols} key={_id} tag="li">
        <p className={st.title}>{title}</p>
        <div className={st.cnt}>
          <span className={st.icon}>{icon}</span>
          <p
            className={cn(st.content, {
              [contentClassName]: !!contentClassName
            })}
          >
            {content}
          </p>
        </div>
      </Col>
    ))}
  </Row>
)

TitleIconContent.defaultProps = {
  className: '',
  cols: {},
  items: [],
  mod: 0
}
TitleIconContent.propTypes = {
  className: string,
  cols: COLS_WIDTH,
  items: arrayOf(
    shape({
      _id: oneOfType([number, string]),
      contentClassName: string,
      content: oneOfType([number, string]),
      icon: element,
      title: string
    })
  ),
  mod: number
}

export default TitleIconContent
