import { string } from 'prop-types'

export const DEFAULT_PROPS = {
  imageAlt: '',
  imageSrc: 'https://fakeimg.pl/120',
  name: '',
  price: '',
  status: '',
  statusColor: '#000'
}

export const PROP_TYPES = {
  imageAlt: string,
  imageSrc: string,
  name: string,
  price: string,
  status: string,
  statusColor: string
}
