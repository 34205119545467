import { get } from 'lodash'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Table } from 'reactstrap'

import Loading from '../components/loading/loading'
import { getCollectionByVendor } from '../redux/service/collection-service'

class CollectionsForManager extends Component {
  state = {
    collections: []
  }

  componentDidMount() {
    const vendor = this.getVendorId()

    getCollectionByVendor(vendor).then((res) => {
      this.setState({
        collections: res.data.docs
      })
    })
  }

  getOwnerId = () => get(this.props, 'match.params.owner')
  getVendorId = () => get(this.props, 'match.params.vendor')

  render() {
    const { collections } = this.state
    const owner = this.getOwnerId()
    const vendor = this.getVendorId()

    return !collections ? (
      <Loading />
    ) : (
      <Table>
        <thead>
          <tr>
            <th>
              <FormattedMessage id={'Name'} />
            </th>
            <th>
              <FormattedMessage id={'is approved'} />
            </th>
            <th>
              <FormattedMessage id={'vendor'} />
            </th>
          </tr>
        </thead>
        <tbody>
          {collections.map((collection) => {
            return !collection.name ? null : (
              <tr key={collection._id}>
                <td>
                  <Link
                    to={`/collection-list/${owner}/${vendor}/${collection._id}`}
                  >
                    {collection.name}
                  </Link>
                </td>
                <td>{collection.approved ? 'Yes' : 'No'}</td>
                <td>{vendor}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }
}

export default CollectionsForManager
