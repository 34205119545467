import cn from 'classnames'
import { CloseFrame } from 'components'
import { InputFile } from 'components/formParts'
import { OtherImagesList } from 'components/lists'
import { get } from 'lodash'
import { func, shape } from 'prop-types'
import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { edit, saveImages, saveMainImage } from 'redux/service/product-service'
import {
  ACCEPTED_FILE_TYPES,
  IMAGE_DIMENSIONS,
  MAIN_IMAGE_DIMENSIONS
} from 'utils/constants'
import {
  getFilePaths,
  getFormDataWithFiles,
  handleImageUploadError,
  handleMainImageUploadError
} from 'utils/files'

import { HideLoading, ShowLoading } from '../../redux/actions/loading-action'
import notify, { notifyPositions } from '../../utils/toast'
import Loading from '../loading/loading'
import Style from './image-assets-component.module.css'

class ImageAssetsComponent extends Component {
  state = {
    mainPhotoFile: null,
    mainPhotoPrePath: '',
    photosFiles: null,
    product: this.props.product || null,
    uploadedPhotos: false,
    uploadedMainPhoto: false
  }

  changeMainPhoto = (e) => {
    const { files } = e.target

    if (files[0]) {
      this.props.ShowLoading()

      getFilePaths({ files }).then((res) => {
        const mainPhotoPrePath = get(res, '[0]', '')
        const mainPhotoFile = getFormDataWithFiles({ files })

        if (mainPhotoFile && mainPhotoPrePath) {
          this.setState({
            mainPhotoFile,
            mainPhotoPrePath,
            uploadedMainPhoto: true
          })
        }

        this.props.HideLoading()
      })
    }
  }

  changePhotosInput = (e) => {
    const { files } = e.target

    if (files.length) {
      this.props.ShowLoading()

      getFilePaths({ files }).then(() => {
        const photosFiles = getFormDataWithFiles({
          files,
          propName: 'image'
        })

        this.setState({
          photosFiles,
          uploadedPhotos: true
        })

        this.props.HideLoading()
      })
    }
  }

  getPhotoUrl = () => {
    const { product } = this.state

    return (
      get(product, 'mainPhoto', '') ||
      get(product, 'onboardingPhoto', '') ||
      get(product, 'photos.[0]', '')
    )
  }

  getProductId = () => get(this.props, 'product._id', '')

  handleDeleteMainPhoto = () => {
    console.log('Needs API to delete the main product photo.')
  }

  mainPhotoPreClean = () => {
    this.setState({
      mainPhotoFile: null,
      mainPhotoPrePath: '',
      uploadedMainPhoto: false
    })
  }

  photosPreClean = () => {
    this.setState({
      photosFiles: null,
      uploadedPhotos: false
    })
  }

  updateProduct = () => {
    const { mainPhotoFile, photosFiles } = this.state
    const productId = this.getProductId()

    this.props.ShowLoading()

    Promise.all([
      mainPhotoFile
        ? saveMainImage({
            formData: mainPhotoFile,
            productId
          })
        : Promise.resolve(),
      photosFiles
        ? saveImages({
            formData: photosFiles,
            productId
          })
        : Promise.resolve()
    ])
      .then(([mainPhotoObj, otherPhotosObj]) => {
        const mainPhoto = get(mainPhotoObj, 'fileUrl', '')
        const photos = get(otherPhotosObj, 'fileUrls', [])
        const productData = {}

        if (mainPhoto.length) {
          productData.mainPhoto = mainPhoto
        }

        if (photos.length) {
          productData.photos = photos
        }

        return edit(productId, productData)
      })
      .then(({ error, product }) => {
        const toNotify = ['success', 'saved successfully']

        if (error) {
          toNotify[0] = 'error'
          toNotify[1] = 'service error'
        }

        const newState = {
          mainPhotoFile: null,
          mainPhotoPrePath: '',
          photosFiles: null,
          uploadedMainPhoto: false,
          uploadedPhotos: false
        }

        if (product) {
          newState.product = product
        }

        this.setState(newState)

        notify(...toNotify, notifyPositions.bottom.center)

        this.props.HideLoading()
      })
  }

  render() {
    const { intl } = this.props
    const {
      mainPhotoPrePath,
      photosFiles,
      product,
      uploadedMainPhoto,
      uploadedPhotos
    } = this.state
    const allowDeletingOtherFiles = Boolean(photosFiles)
    const imgSrc = this.getPhotoUrl()
    const otherImages = product.photos.map((src) => ({ alt: '', src }))

    return !product ? (
      <Loading />
    ) : (
      <Fragment>
        <Row className="rowWide">
          <Col md={4} lg={4} className={Style.onImageCol}>
            <CloseFrame
              className={Style.imageClose}
              disabled={!imgSrc}
              onClose={this.handleDeleteMainPhoto}
            >
              <img
                alt="onboarding"
                className={Style.onboardingImage}
                src={imgSrc}
              />
            </CloseFrame>
            <OtherImagesList
              className={Style.otherImages}
              images={otherImages}
            />
          </Col>
          <Col md={8} lg={8}>
            <h3>{product._id}</h3>
            <Row className="rowWide">
              <Col className={Style.inputFile} sm={5}>
                <InputFile
                  accept={ACCEPTED_FILE_TYPES.IMAGES}
                  fileDimensions={MAIN_IMAGE_DIMENSIONS}
                  fileUrl={mainPhotoPrePath}
                  label={intl.formatMessage({
                    id: uploadedMainPhoto ? 'image selected' : 'Main Photo'
                  })}
                  labelClassName={
                    Style[uploadedMainPhoto ? 'labelSuccess' : 'label']
                  }
                  size={200}
                  onChange={this.changeMainPhoto}
                  onClose={this.mainPhotoPreClean}
                  onError={handleMainImageUploadError}
                />
              </Col>
              <Col className={Style.inputFile} sm={5}>
                <InputFile
                  multiple
                  accept={ACCEPTED_FILE_TYPES.IMAGES}
                  allowDeleting={allowDeletingOtherFiles}
                  fileDimensions={IMAGE_DIMENSIONS}
                  label={intl.formatMessage({
                    id: uploadedPhotos ? 'images selected' : 'Alt views'
                  })}
                  labelClassName={
                    Style[uploadedPhotos ? 'labelSuccess' : 'label']
                  }
                  size={200}
                  onChange={this.changePhotosInput}
                  onClose={this.photosPreClean}
                  onError={handleImageUploadError}
                />
              </Col>
              <Col className={Style.button} sm={2}>
                <button
                  className={cn('btn btn-secondary', Style.uploadBtn)}
                  disabled={
                    !this.state.uploadedPhotos && !this.state.uploadedMainPhoto
                  }
                  onClick={this.updateProduct}
                >
                  <FormattedMessage id="upload" />
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
      </Fragment>
    )
  }
}

ImageAssetsComponent.propTypes = {
  product: shape({}),
  intl: shape({
    formatMessage: func
  }),
  ShowLoading: func,
  HideLoading: func
}
const mapActions = {
  HideLoading,
  ShowLoading
}

export default connect(null, mapActions)(injectIntl(ImageAssetsComponent))
