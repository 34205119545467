import { companyPhotoPending, getCompany } from 'redux/actions/company-action'
import { deleteMedia } from 'redux/service/company-service'
import { showNotify } from 'utils/toast'

/**
 * @param {Object} config
 * @param {String} config.companyId
 * @param {String} config.imageId
 * @param {String} config.imageUrl
 */
export const deleteMediaTx =
  (config = {}) =>
  (dispatch) => {
    const { companyId, imageId, imageUrl } = config

    dispatch(
      companyPhotoPending({
        image: imageId,
        pending: true
      })
    )
    deleteMedia({ companyId, imageUrl }).then(({ company, error }) => {
      const toNotify = {
        isError: false
      }

      if (error) {
        toNotify.isError = true
        toNotify.message = error.message
      }

      if (company) {
        dispatch(getCompany(company))
        toNotify.message = 'Media deleted successfully'
      }

      showNotify(toNotify)
      dispatch(companyPhotoPending({ image: imageId }))
    })
  }
