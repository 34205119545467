import cn from 'classnames'
import { arrayOf, bool, element, func, number, shape, string } from 'prop-types'
import React from 'react'
import DataTable from 'react-data-table-component'

import st from './Table.module.css'

const SortableTable = ({ className, headings, mod, rows, ...props }) => {
  const customStyles = {
    headCells: {
      style: {
        fontSize: '16px'
      }
    },
    cells: {
      style: {
        fontSize: '16px'
      }
    }
  }
  return (
    <>
      {rows.length > 0 && (
        <DataTable
          {...props}
          className={cn(className, {
            [st.mod1]: mod === 1
          })}
          columns={headings}
          data={rows}
          pagination={rows.length > 15}
          paginationPerPage={15}
          customStyles={customStyles}
          onChangePage={() => {
            window.scrollTo(0, 0)
          }}
          defaultSortFieldId="gmv"
          defaultSortAsc={false}
        />
      )}
    </>
  )
}

SortableTable.defaultProps = {
  className: '',
  headings: [],
  mod: 0,
  rows: [],
  striped: false
}

SortableTable.propTypes = {
  className: string,
  headings: arrayOf(
    shape({
      name: element,
      selector: func,
      sortable: bool,
      center: bool,
      grow: string,
      sortFunction: func,
      wrap: bool
    })
  ),
  mod: number,
  rows: arrayOf(
    shape({
      image: element,
      sku: string,
      productName: element,
      views: string,
      interactions: string,
      gmv: string,
      units: number
    })
  ),
  striped: bool
}

export default SortableTable
