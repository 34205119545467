/* eslint-disable no-unused-vars */
import cn from 'classnames'
import { Button } from 'components/buttons'
import { Modal } from 'components/modals'
import { get } from 'lodash'
import { object } from 'prop-types'
import { node, string } from 'prop-types'
import React, { forwardRef, useEffect, useState } from 'react'
import { IoDiamondOutline, IoStarOutline } from 'react-icons/io5'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Col, Row } from 'reactstrap'
import { ChangeVendorStatus, GetVendor } from 'redux/actions/vendor-action'
import { readContact } from 'redux/service/contact-service'
import { USER_TYPES } from 'utils/constants'

import st from './InnerPage.module.css'
const InnerPage = forwardRef(
  (
    { children, childrenClassName, className, heading, headingSlot, vendor },
    ref
  ) => {
    const params = useParams()
    const userType = useSelector((state) => state.authReducer.userType)
    const statusVisibleFor = [USER_TYPES.ADMIN, USER_TYPES.LOGISTICS]
    const vetted = get(vendor, 'vetted', false)
    const premium = get(vendor, 'premium.active', false)
    const artisanStatus = get(vendor, 'artisanStatus', '')
    let artisanPlan = get(vendor, 'premium.planId', '')
    const dispatch = useDispatch()
    const [artisanStatusModalIsOpen, setArtisanStatusModalIsOpen] =
      useState(false)
    const [publishErrors, setPublishErrors] = useState([])

    if (artisanPlan && artisanPlan.includes('stg')) {
      artisanPlan = artisanPlan.substring(0, artisanPlan.indexOf('_stg'))
    }

    const handleArtisanStatusChange = () => {
      const newStatus =
        artisanStatus.toLowerCase() === 'active' ? 'inactive' : 'active'
      ChangeVendorStatus({
        id: vendor._id,
        status: newStatus,
        onSuccess: () => {
          GetVendor({ vendorId: vendor._id })(dispatch)
          setArtisanStatusModalIsOpen(false)
        }
      })(dispatch)
    }

    useEffect(() => {
      const contactId = vendor?.contact
      const requiredVendorFields = [
        'buyer',
        'discountPercentage',
        'downPaymentWhenReceivedOrder',
        'contractPenalties',
        'fullPaymentBeforeShipping',
        'packagePicsBeforeShipping',
        'productPicsBeforeShipping',
        'returnAllowed',
        'contracts'
      ]

      if (contactId && artisanStatus.toLowerCase() !== 'active') {
        const unsetVendorFields = requiredVendorFields.filter((prop) => {
          if (vendor[prop] === undefined || vendor[prop] === '') return prop
        })
        if (vendor['contracts'] && !vendor['contracts'].length) {
          unsetVendorFields.push('contracts')
        }
        readContact(contactId).then(({ data, status }) => {
          const contact = status === 200 ? data : null
          if (contact) {
            if (!contact.pickupAddressAndTime) {
              unsetVendorFields.push('Pickup address')
            }

            if (!contact.contactForOrderManagement?.email) {
              unsetVendorFields.push('contact for order management')
            }
          }
        })
        setPublishErrors(unsetVendorFields)
      } else {
        setPublishErrors([])
      }
    }, [vendor, artisanStatus])

    return (
      <div
        className={cn('innerPage', {
          [className]: className.length > 0
        })}
        ref={ref}
      >
        <Row>
          {heading.length > 0 && (
            <Col className="pageTitle">
              <div className={st.pageTitle}>
                {statusVisibleFor.includes(userType) ? (
                  <>
                    <div className={st.artisanHeading}>
                      <h1>{heading}</h1>
                      {premium && <IoStarOutline />}
                      {vetted && <IoDiamondOutline />}
                      {premium && artisanPlan && (
                        <span className={st.artisanPlan}>
                          <FormattedMessage
                            id={`artisanPlan-${
                              artisanPlan.split('_')[
                                artisanPlan.split('_').length - 1
                              ]
                            }`}
                            values={{
                              type: artisanPlan.split('_')[0],
                              plus: artisanPlan.toLowerCase().includes('plus')
                                ? 'plus'
                                : ''
                            }}
                          />
                        </span>
                      )}
                    </div>
                    {artisanStatus && (
                      <>
                        <div className={st.artisanAction}>
                          <span
                            className={st[`artisanStatus_${artisanStatus}`]}
                          >
                            <FormattedMessage
                              id={
                                artisanStatus[0].toUpperCase() +
                                artisanStatus.slice(1).toLowerCase()
                              }
                            />
                          </span>
                          <Button
                            onClick={() => setArtisanStatusModalIsOpen(true)}
                          >
                            <FormattedMessage
                              id={
                                artisanStatus.toLowerCase() === 'draft'
                                  ? 'Publish'
                                  : artisanStatus.toLowerCase() === 'active'
                                  ? 'Disable'
                                  : artisanStatus.toLowerCase() ===
                                      'inactive' && 'Enable'
                              }
                            />
                          </Button>
                        </div>
                        <Modal
                          isOpen={artisanStatusModalIsOpen}
                          toggle={() => setArtisanStatusModalIsOpen(false)}
                          body={
                            !publishErrors.length ? (
                              <FormattedMessage
                                id={
                                  artisanStatus.toLowerCase() === 'active'
                                    ? 'artisanStatus_disclaimer_disable'
                                    : 'artisanStatus_disclaimer_enable'
                                }
                              />
                            ) : (
                              <>
                                <FormattedMessage id="Artisan can't be published" />
                                <ul className={st.publishErrors}>
                                  {publishErrors.map((error) => (
                                    <li key={error} id={error}>
                                      <FormattedMessage id={error} />
                                    </li>
                                  ))}
                                </ul>
                              </>
                            )
                          }
                          footer={
                            <Button
                              className={st.button}
                              onClick={handleArtisanStatusChange}
                              disabled={!!publishErrors.length}
                            >
                              <FormattedMessage id="Confirm" />
                            </Button>
                          }
                        />
                      </>
                    )}
                  </>
                ) : (
                  <h1>{heading}</h1>
                )}
              </div>
            </Col>
          )}
          {headingSlot ? (
            <Col className={st.headingSlot}>{headingSlot}</Col>
          ) : null}
        </Row>

        <Col className={childrenClassName}>{children}</Col>
      </div>
    )
  }
)

InnerPage.displayName = 'InnerPage'
InnerPage.defaultProps = {
  children: null,
  childrenClassName: '',
  className: '',
  heading: '',
  vendor: null
}
InnerPage.propTypes = {
  children: node,
  childrenClassName: string,
  className: string,
  heading: string,
  headingSlot: node,
  vendor: object
}

export default InnerPage
