import axios from 'axios/index'
import cookie from 'react-cookies'
import { mapError } from 'utils/errors'

import apiUrl from './apiUrl'

/**
 * @param {Object} config
 * @param {String} config.companyId
 * @param {String} config.imageUrl
 */
const deleteMedia = async (config = {}) => {
  const { companyId, imageUrl } = config
  const apiPath = `${apiUrl}/api/company/media/${encodeURIComponent(companyId)}`

  try {
    const { data, status } = await axios.delete(apiPath, {
      data: { imageUrl },
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return { company: data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

const save = (bio) =>
  axios.post(`${apiUrl}/api/company`, bio, {
    headers: { token: cookie.load('artemest') }
  })

const update = (bio) =>
  axios.patch(`${apiUrl}/api/company`, bio, {
    headers: { token: cookie.load('artemest') }
  })

const read = async (id) => {
  const path = `${apiUrl}/api/company/${encodeURIComponent(id)}`

  try {
    const { data, status } = await axios.get(path, {
      headers: { token: cookie.load('artemest') }
    })

    return {
      company: status === 200 ? data : {}
    }
  } catch (error) {
    return { error }
  }
}

const saveMedia = (id, data) =>
  axios.post(`${apiUrl}/api/company/media/${encodeURIComponent(id)}`, data, {
    headers: { token: cookie.load('artemest') }
  })

export { deleteMedia, read, save, saveMedia, update }
