import { get as getLodash, noop } from 'lodash'
import { PRODUCT_PROPERTIES } from 'utils/constants'
import { notifyMappedError } from 'utils/errors'
import notify, { notifyPositions, showNotify } from 'utils/toast'

import {
  add,
  addProductsFromCollection,
  approveAllByCollection,
  changeProductType,
  copy,
  edit,
  get,
  getImagesByCollection,
  getProductById as getProductByIdAc,
  readProductsByCollectionId,
  readProperty,
  rejectByIds,
  remove,
  saveInDraft,
  sendToProofReading
} from '../service/product-service'
import {
  ADDED_PRODUCT,
  ADDED_PRODUCTS_FROM_COLLECTION,
  APPROVE_ALL,
  COPY,
  DELETE_PRODUCTS,
  DISABLE_ADDED_PRODUCTS_FROM_COLLECTION,
  GET_PRODUCT,
  GET_PRODUCTS,
  GOT_PRODUCTS_FROM_COLLECTION,
  PRODUCT_ATTACHEMENT_PENDING_ADD,
  PRODUCT_ATTACHEMENT_PENDING_REMOVE,
  PRODUCT_ATTACHEMENT_REMOVE,
  PRODUCT_ATTACHEMENT_UPDATE,
  PRODUCT_AUDITED,
  PRODUCT_CARE_INSTRUCTIONS_PENDING,
  PRODUCT_CARE_INSTRUCTIONS_UPDATE,
  PRODUCT_DISCONTINUED,
  PRODUCT_MAIN_IMAGE_PENDING,
  PRODUCT_MAIN_IMAGE_UPLOADED,
  PRODUCT_ONBOARDING_PHOTO_GOT,
  PRODUCT_OTHER_IMAGE_PENDING,
  PRODUCT_OTHER_INFO_PENDING,
  PRODUCT_OTHER_INFO_UPDATE,
  PRODUCT_PHOTOS_SET,
  PRODUCT_PROPERTIES_GOT,
  PRODUCT_REACTIVATED,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCTS_BY_COLLECTION_GOT,
  PRODUCTS_REJECT_BY_ID_SUCCESS,
  SAVE_IN_DRAFT,
  SEND_TO_PROOF_READING,
  SHOW_COPY_MODAL,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_VALUES,
  UPDATE_PRODUCTS_ID_BY_COLLECTION
} from './action-types'
import { AuthError } from './auth-action'
import {
  HideLoading,
  ShowLoading,
  UpdateLoadingProgress
} from './loading-action'

const addProduct = (added, product) => ({
  type: ADDED_PRODUCT,
  added,
  product
})

const productPropertiesGot = ({
  colors = [],
  designers = [],
  materials = []
}) => ({
  payload: { colors, designers, materials },
  type: PRODUCT_PROPERTIES_GOT
})

const update = (updated, product) => ({
  type: UPDATE_PRODUCT_VALUES,
  updated,
  product
})

const copyProductData = (copy) => ({
  type: COPY,
  copy
})

const approvedAll = (approvedAll) => ({
  type: APPROVE_ALL,
  approvedAll
})

const getProductsId = (productsId) => ({
  type: UPDATE_PRODUCTS_ID_BY_COLLECTION,
  productsId
})

const getProducts = (got, products) => ({
  type: GET_PRODUCTS,
  got,
  products
})

const getProductById = (product) => ({
  type: GET_PRODUCT,
  product
})

const deleteProduct = ({ deleted = false, id = '' }) => ({
  payload: { deleted, id },
  type: DELETE_PRODUCTS
})

const changeType = (updated) => ({
  type: UPDATE_PRODUCT,
  updated
})

const addedProducts = (addedFromCollection) => ({
  type: ADDED_PRODUCTS_FROM_COLLECTION,
  addedFromCollection
})

const getImages = (gottenImages, GottenProducts) => ({
  type: GOT_PRODUCTS_FROM_COLLECTION,
  gottenImages,
  GottenProducts
})

const disable = () => ({
  type: DISABLE_ADDED_PRODUCTS_FROM_COLLECTION,
  addedFromCollection: false
})

const productAudited = (product) => ({
  payload: { product },
  type: PRODUCT_AUDITED
})

const productDiscontinued = (product) => ({
  payload: { product },
  type: PRODUCT_DISCONTINUED
})

const productReactivated = (product) => ({
  payload: { product },
  type: PRODUCT_REACTIVATED
})

const productCareInstructionsPending = (pending = false) => ({
  payload: { careInstructionsPending: pending },
  type: PRODUCT_CARE_INSTRUCTIONS_PENDING
})

const productCareInstructionsUpdate = (careInstructions = '') => ({
  payload: { careInstructions },
  type: PRODUCT_CARE_INSTRUCTIONS_UPDATE
})

const productMainImagePending = (mainImagePending = false) => ({
  payload: { mainImagePending },
  type: PRODUCT_MAIN_IMAGE_PENDING
})

const productAttachementUpdate = (attachementType, fileUrl) => ({
  payload: { attachementType, fileUrl },
  type: PRODUCT_ATTACHEMENT_UPDATE
})

const productAttachementRemove = (attachementType) => ({
  payload: attachementType,
  type: PRODUCT_ATTACHEMENT_REMOVE
})
/**
 * @param {Object} payload
 * @param {String} payload.mainPhoto
 * @param {String} payload.productId
 */
const productMainImageUploaded = (payload = {}) => ({
  payload,
  type: PRODUCT_MAIN_IMAGE_UPLOADED
})

const productOnboardingPhotoGot = (onboardingPhoto = '') => ({
  payload: { onboardingPhoto },
  type: PRODUCT_ONBOARDING_PHOTO_GOT
})

const productOtherImagePending = ({ imagePath = '', pending = false }) => ({
  payload: { imagePath, pending },
  type: PRODUCT_OTHER_IMAGE_PENDING
})

const productAttachementPendingAdd = (type) => ({
  payload: type,
  type: PRODUCT_ATTACHEMENT_PENDING_ADD
})

const productAttachementPendingRemove = (type) => ({
  payload: type,
  type: PRODUCT_ATTACHEMENT_PENDING_REMOVE
})

const productOtherInfoPending = (pending = false) => ({
  payload: { otherInfoPending: pending },
  type: PRODUCT_OTHER_INFO_PENDING
})

const productOtherInfoUpdate = (otherInfo = '') => ({
  payload: { otherInfo },
  type: PRODUCT_OTHER_INFO_UPDATE
})

const productPhotosSet = (photos = []) => ({
  payload: { photos },
  type: PRODUCT_PHOTOS_SET
})

const productUpdateSuccess = (product = null) => ({
  payload: { product },
  type: PRODUCT_UPDATE_SUCCESS
})

const showCopyModal = (isOpen = false) => ({
  payload: { isOpen },
  type: SHOW_COPY_MODAL
})

const Add = (product) => (dispatch) =>
  add(product)
    .then((res) => {
      if (res.status === 200) {
        dispatch(addProduct(true, res.data))
      }
    })
    .catch((err) => {
      if (err.response && err.response.status && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
    })

/**
 * @param {Object} config
 * @param {Object} config.productData
 * @param {String} config.productId
 * @param {Boolean} config.showPending
 */
const Update =
  (config = {}) =>
  (dispatch) => {
    const { productData = {}, productId = '', showPending = false } = config

    if (showPending) {
      dispatch(ShowLoading())
    }

    edit(productId, productData).then(({ error, product }) => {
      if (error) {
        notifyMappedError(error)
      }

      if (product) {
        dispatch(update(true, product))
        notify('success', 'updated successfully', notifyPositions.bottom.center)
      }

      if (showPending) {
        dispatch(HideLoading())
      }
    })
  }

const GetById = (id) => (dispatch) => {
  dispatch(ShowLoading())

  getProductByIdAc({ productId: id }).then(({ data, error }) => {
    if (data) {
      dispatch(getProductById(data))
    }

    if (error) {
      const status = getLodash(error, 'response.status')

      if (status === 401) {
        dispatch(AuthError(status))
      }

      notify(
        'error',
        `can't get product with id ${id}`,
        notifyPositions.bottom.center
      )
    }

    dispatch(HideLoading())
  })
}

const Get = () => (dispatch) =>
  get()
    .then((res) => {
      dispatch(getProducts(true, res.data))
    })
    .catch((err) => {
      if (err.response && err.response.status && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      notify('error', "can't got products", notifyPositions.bottom.center)
    })

/**
 * @param {Object} config
 * @param {Number|String} config.id
 * @param {Function} config.onSuccess
 */
const DeleteProduct =
  ({ id = '', onSuccess = noop }) =>
  (dispatch) => {
    dispatch(ShowLoading())

    remove(id)
      .then(() => {
        dispatch(deleteProduct({ deleted: true, id }))
        showNotify({ message: 'Product deleted' })
        onSuccess()
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(AuthError(err.response.status))
        }
        notify('error', "can't delete product", notifyPositions.bottom.center)
      })
      .finally(() => {
        dispatch(HideLoading())
      })
  }

const ChangeProductType = (id, type) => (dispatch) =>
  changeProductType(id, type)
    .then(() => {
      dispatch(changeType(true))
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      notify('error', "can't change product", notifyPositions.bottom.center)
    })

const rejectByIdsSuccess = (payload) => ({
  payload,
  type: PRODUCTS_REJECT_BY_ID_SUCCESS
})
/**
 * @param {Object} config
 * @param {[Number|String]} config.ids
 * @param {Function} config.onError
 * @param {Function} config.onSuccess
 */
const rejectByIdsAsync = (config) => async (dispatch) => {
  const { ids = [], onError = noop, onSuccess = noop } = config

  dispatch(ShowLoading())

  const { data, error, success } = await rejectByIds(ids)

  if (success) {
    dispatch(rejectByIdsSuccess(data))
    onSuccess()
  }

  if (error) {
    onError()
  }

  dispatch(HideLoading())
}

const SaveProductInDraft = (id, data) => (dispatch) =>
  saveInDraft(id, data)
    .then(() => {
      dispatch({
        type: SAVE_IN_DRAFT,
        savedInDraft: true
      })
    })
    .catch((err) => {
      if (err.response && err.response.status && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      notify('error', "can't change product", notifyPositions.bottom.center)
    })

const SendToProofReading = (id, data) => (dispatch) =>
  sendToProofReading(id, data)
    .then(() => {
      dispatch({
        type: SEND_TO_PROOF_READING,
        sentToProofReading: true
      })
    })
    .catch((err) => {
      if (err.response && err.response.status && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      notify('error', "can't change product", notifyPositions.bottom.center)
    })

const GetImages = (collection) => (dispatch) =>
  getImagesByCollection(collection)
    .then((res) => {
      const { data, status } = res

      if (status === 200) {
        dispatch(getImages(true, data))
      }
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      notify('error', "can't got products", notifyPositions.bottom.center)
    })

/**
 * @param {Object} config
 * @param {String} config.collectionId
 * @param {Function} config.onSuccess
 */
const ReadProdByCollection =
  (config = {}) =>
  (dispatch) => {
    const { collectionId = '', onSuccess = noop } = config

    readProductsByCollectionId({ collectionId }).then(({ data, error }) => {
      if (data) {
        dispatch(getImages(true, data))
        onSuccess(data)
      }

      if (error) {
        notifyMappedError(error)
      }
    })
  }

const AddProductsFromCollection =
  (files, dataType, collection) => (dispatch) => {
    const bodyFormData = new FormData()
    const callback = (progress) => dispatch(UpdateLoadingProgress(progress))

    bodyFormData.append('collectionId', collection)

    Array.from(files).forEach((file) => bodyFormData.append(`image`, file))

    dispatch(ShowLoading())

    addProductsFromCollection(bodyFormData, dataType, callback)
      .then((res) => {
        const { data, status } = res

        if (status !== 200) {
          return
        }

        data
          .filter(({ blocked }) => !blocked)
          .map(({ _id }) =>
            edit(_id, {
              readyToTranslate: true
            })
          )

        dispatch(addedProducts(true))
        dispatch(GetImages(collection))
        dispatch(HideLoading())
        notify(
          'success',
          'successfully added all products',
          notifyPositions.bottom.center
        )
      })
      .catch((err) => {
        dispatch(HideLoading())
        if (err.response && err.response.status === 401) {
          dispatch(AuthError(err.response.status))
        }
      })
  }

const DisableAdd = () => (dispatch) => dispatch(disable())

const GetProductsByCollectionId =
  (config = {}) =>
  (dispatch) => {
    const { collectionId = '', onSuccess = noop } = config

    readProductsByCollectionId({ collectionId }).then(({ data, error }) => {
      if (data) {
        dispatch(getProductsId(data))
        onSuccess(data)
      }

      if (error) {
        notifyMappedError(error)
      }
    })
  }

const Copy = (fromId, toId, onSuccess) => (dispatch) =>
  copy(fromId, toId)
    .then((res) => {
      const products = res.map((r) => r.data)

      dispatch(copyProductData(products))
      notify('success', 'copied successfully', notifyPositions.bottom.center)

      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      notify('error', "can't got products", notifyPositions.bottom.center)
    })

const ApproveAll = (collection) => (dispatch) => {
  dispatch(ShowLoading())
  approveAllByCollection(collection)
    .then((res) => {
      dispatch(approvedAll(res.data))
      notify('success', 'approved successfully', notifyPositions.bottom.center)
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      notify(
        'error',
        "can't approved all of them",
        notifyPositions.bottom.center
      )
    })
    .finally(() => {
      dispatch(HideLoading())
    })
}

/**
 * @param {Array} properties
 * @param {String} properties[] - one of PRODUCT_PROPERTIES: 'color', 'designer', 'material'
 */
const getPropertiesAsync =
  (properties = []) =>
  async (dispatch) => {
    dispatch(ShowLoading())

    const results = await Promise.all(
      properties.map((property) => readProperty(property))
    )
    const resultPayload = {}

    results.forEach(({ data, error }, i) => {
      if (data) {
        switch (properties[i]) {
          case PRODUCT_PROPERTIES.DESIGNER:
            resultPayload.designers = data
            break

          case PRODUCT_PROPERTIES.MATERIAL:
            resultPayload.materials = data
            break

          default:
            resultPayload.colors = data
        }
      }

      if (error) {
        notifyMappedError(error)
      }
    })

    dispatch(productPropertiesGot(resultPayload))
    dispatch(HideLoading())
  }

const productsByCollectionGot = (collectionProducts = []) => ({
  payload: { collectionProducts },
  type: PRODUCTS_BY_COLLECTION_GOT
})

export {
  Add,
  AddProductsFromCollection,
  ApproveAll,
  ChangeProductType,
  Copy,
  DeleteProduct,
  DisableAdd,
  Get,
  GetById,
  GetImages,
  GetProductsByCollectionId,
  getPropertiesAsync,
  productAttachementPendingAdd,
  productAttachementPendingRemove,
  productAttachementRemove,
  productAttachementUpdate,
  productAudited,
  productCareInstructionsPending,
  productCareInstructionsUpdate,
  productDiscontinued,
  productMainImagePending,
  productMainImageUploaded,
  productOnboardingPhotoGot,
  productOtherImagePending,
  productOtherInfoPending,
  productOtherInfoUpdate,
  productPhotosSet,
  productReactivated,
  productsByCollectionGot,
  productUpdateSuccess,
  ReadProdByCollection,
  rejectByIdsAsync,
  SaveProductInDraft,
  SendToProofReading,
  showCopyModal,
  Update
}
