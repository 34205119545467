import cn from 'classnames'
import { noop } from 'lodash'
import { func, number, oneOfType, string } from 'prop-types'
import React from 'react'
import { BsFillXSquareFill } from 'react-icons/bs'

import st from './Close.module.css'

const Close = ({ className, size, onClick }) => {
  const iconStyle = {
    fontSize: typeof size === 'string' ? size : `${size}px`
  }

  return (
    <span
      className={cn(st.close, {
        [className]: className.length > 0
      })}
      onClick={onClick}
    >
      <BsFillXSquareFill className={st.icon} style={iconStyle} />
    </span>
  )
}

Close.defaultProps = {
  className: '',
  size: '2em',
  onClick: noop
}
Close.propTypes = {
  className: string,
  size: oneOfType([number, string]),
  onClick: func
}

export default Close
