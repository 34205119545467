import cn from 'classnames'
import { OtherImagesList } from 'components/lists'
import { drop, get } from 'lodash'
import { func, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Badge, Col, Row, Table } from 'reactstrap'
import { STATUSES } from 'utils/constants'
import { pricingShippingFinal } from 'utils/products'

import Style from './export-preview-component.module.css'

class ExportPreviewComponent extends Component {
  static defaultProps = {
    element: {},
    lang: ''
  }
  static propTypes = {
    element: shape({}),
    lang: string
  }

  getParcels = (parcels) =>
    (parcels ?? []).map((parcel) => {
      const { D, H, number, weight, W } = parcel

      return (
        <tr key={number}>
          <th>{number}</th>
          <td>{weight}</td>
          <td>{W}</td>
          <td>{H}</td>
          <td>{D}</td>
        </tr>
      )
    })

  render() {
    const {
      element: {
        _id,
        aimHeight,
        color,
        costs,
        description,
        designer,
        mainPhoto,
        material,
        name,
        parcels,
        photos,
        pricing,
        seatHeight,
        sizing,
        translation,
        type,
        weight
      },
      intl: { formatMessage },
      lang
    } = this.props
    const otherPhotos = drop(photos, 1).map((src) => ({ alt: '', src }))
    const prices = pricingShippingFinal(pricing)
    const productPhoto = get(photos, '[0]')
    const translationApproved =
      get(translation, 'status') === STATUSES.APPROVED_SM
    const prodDesc = translationApproved
      ? get(translation, 'description') || description
      : ''
    const prodName = translationApproved ? get(translation, 'name') || name : ''
    const typeName = get(type, `name.${lang}`, '')

    return (
      <Row className={Style.main}>
        <Col md={6} lg={2} sm={12}>
          <div className={Style.imgDiv}>
            <img
              alt="product"
              className={Style.mainImage}
              src={mainPhoto || productPhoto}
            />
          </div>
          <OtherImagesList className={Style.otherImages} images={otherPhotos} />
        </Col>
        <Col md={6} lg={10} sm={12}>
          <Row>
            <Col md={12} lg={12} sm={6}>
              <h3 className={Style.code}>{_id}</h3>
              <p
                className={cn({
                  [Style.prodName]: !prodName
                })}
              >
                {prodName ||
                  formatMessage({
                    id: 'No approved translations'
                  })}
              </p>
              {prodDesc && <p>{prodDesc}</p>}
              {costs && (
                <div className={Style.currencies}>
                  {costs.EUR && (
                    <h5>
                      EUR
                      <Badge color="secondary" pill>
                        {costs.EUR}
                      </Badge>
                    </h5>
                  )}
                  {costs.GBP && (
                    <h5>
                      GBP
                      <Badge color="danger" pill>
                        {costs.GBP}
                      </Badge>
                    </h5>
                  )}
                  {costs.USD && (
                    <h5>
                      USD
                      <Badge color="primary" pill>
                        {costs.USD}
                      </Badge>
                    </h5>
                  )}
                </div>
              )}
            </Col>
            {parcels?.length > 0 && (
              <Col md={12} lg={6} sm={6}>
                <Table dark className={Style.parcels}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Weight</th>
                      <th>W</th>
                      <th>H</th>
                      <th>D</th>
                    </tr>
                  </thead>
                  <tbody>{this.getParcels(parcels)}</tbody>
                </Table>
              </Col>
            )}
            <Col md={12} lg={12} sm={6}>
              <div className={Style.designerDiv}>
                {prices.length > 0 && (
                  <ul className={Style.currencies}>
                    {prices.map(({ currency, price, shipping }) => (
                      <li key={currency}>
                        {currency} {price} + {shipping}
                      </li>
                    ))}
                  </ul>
                )}
                {designer && (
                  <>
                    <FormattedMessage id="Designer" /> <p> &gt; {designer}</p>
                    <br />
                  </>
                )}
                <FormattedMessage id="Furniture" /> <p> &gt; {typeName}</p>
              </div>
              <div className={Style.materialDiv}>
                <p>{material}</p>
                <p>{color}</p>
              </div>
              <div className={Style.sizing}>
                <p>{sizing}</p>
                <p>{weight}</p>
              </div>
              <div className={Style.heights}>
                {seatHeight && (
                  <>
                    <FormattedMessage id="Seat Height" />:
                    <h4>
                      <Badge color="secondary">{seatHeight}</Badge>
                    </h4>
                    <br />
                  </>
                )}
                {aimHeight && (
                  <>
                    <FormattedMessage id="Aim Height" />:
                    <h4>
                      <Badge color="secondary">{aimHeight}</Badge>
                    </h4>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

ExportPreviewComponent.propTypes = {
  intl: shape({
    formatMessage: func
  })
}
export default injectIntl(ExportPreviewComponent)
