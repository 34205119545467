import { func, shape, string } from 'prop-types'
import React, { Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Tooltip } from 'reactstrap'
import { LANGUAGES } from 'utils/constants'

import ChangeLanguages from '../../redux/actions/language-action'
import Style from '../header/header.module.css'

class ChangeLanguage extends React.Component {
  state = {}

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true
  }

  toggleTooltip = (targetName) => {
    if (this._isMounted) {
      if (!this.state[targetName]) {
        this.setState({
          ...this.state,
          [targetName]: {
            tooltipOpen: true
          }
        })
      } else {
        this.setState({
          ...this.state,
          [targetName]: {
            tooltipOpen: !this.state[targetName].tooltipOpen
          }
        })
      }
    }
  }

  isToolTipOpen = (targetName) =>
    this.state[targetName] ? this.state[targetName].tooltipOpen : false

  render() {
    const { lang, authReducer, className } = this.props
    return (
      <div className={className}>
        {LANGUAGES.map((language) => {
          return (
            lang !== language.code && (
              <Fragment key={language.code}>
                <button
                  className={Style.flagButton}
                  onClick={() => {
                    this.props.ChangeLanguages(
                      language.code,
                      authReducer.username
                    )
                  }}
                  id={language.code}
                >
                  <img
                    height="20px"
                    width="27px"
                    src={language.flag}
                    alt={`${language.code} flag`}
                  />
                </button>
                <Tooltip
                  placement="top"
                  isOpen={this.isToolTipOpen(language.code)}
                  target={language.code}
                  toggle={() => this.toggleTooltip(language.code)}
                >
                  {<FormattedMessage id={`switch_${language.code}`} />}
                </Tooltip>
              </Fragment>
            )
          )
        })}
      </div>
    )
  }
}

ChangeLanguage.propTypes = {
  lang: string,
  authReducer: shape({
    username: string
  }),
  className: string,
  ChangeLanguages: func
}
const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  lang: state.languageReducer.lang
})

const mapDispatchToProps = (dispatch) => ({
  ChangeLanguages: (lang, id) => dispatch(ChangeLanguages(lang, id))
})

export default withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChangeLanguage))
)
