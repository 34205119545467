import { PROP_TYPES as PROP_TYPES_PRODUCT_CARD } from 'components/ProductCard/config'
import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types'

export const DEFAULT_PROPS = {
  items: [],
  noWrap: false
}

export const PROP_TYPES = {
  items: arrayOf(
    shape({
      ...PROP_TYPES_PRODUCT_CARD,
      _id: oneOfType([number, string])
    })
  ),
  noWrap: bool
}
