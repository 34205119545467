import * as at from './action-types'

export const initialState = {
  impressions: 0,
  siteViews: 0,
  topSavedProducts: [],
  topSavedProductsPending: false,
  topViewedProducts: [],
  topViewedProductsPending: false,
  totalProductDataPending: false,
  totalProductViews: 0,
  totalSales: '0.00'
}

export const reducer = (state, action) => {
  const { payload, type } = action

  switch (type) {
    case at.IMPRESSIONS_SET:
    case at.SITE_VIEWS_SET:
    case at.TOP_SAVED_PRODUCTS_GOT:
    case at.TOP_SAVED_PRODUCTS_PENDING:
    case at.TOP_VIEWED_PRODUCTS_GOT:
    case at.TOP_VIEWED_PRODUCTS_PENDING:
    case at.TOTAL_PRODUCT_DATA_PENDING:
    case at.TOTAL_PRODUCT_DATA_SET:
    case at.TOTAL_SALES_SET:
      return { ...state, ...payload }

    default:
      throw new Error()
  }
}
