import { PageNav } from 'components'
import { Button } from 'components/buttons'
import { InputFile } from 'components/formParts'
import { InnerPage } from 'components/layout'
import { ImagesList } from 'components/lists'
import VideoGallery from 'components/vendor-bio/video-gallery'
import { get, isEqual, omit } from 'lodash'
import { func, object, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Col, FormGroup, Input, Row } from 'reactstrap'
import { GetCollection } from 'redux/actions/collection-action'
import {
  getCompanyById,
  updateCompanyMedia
} from 'redux/actions/company-action'
import { HideLoading, ShowLoading } from 'redux/actions/loading-action'
import { GetVendor } from 'redux/actions/vendor-action'
import { deleteMediaTx } from 'redux/thunks/company'
import { getUserType } from 'utils/auth'
import { getCollection } from 'utils/collections'
import { ACCEPTED_FILE_TYPES } from 'utils/constants'
import { vendorNavItems } from 'utils/utils'
import { getVendorCompany } from 'utils/vendors'

import Style from './styles/vendor-bio.module.css'

class VendorBio extends Component {
  constructor(props) {
    super(props)

    const translatedText = get(props, 'companyReducer.company.description', '')

    this.state = {
      companyName: '',
      imagePreviewUrl: '',
      photos: [],
      translatedText,
      video: null
    }
  }

  componentDidMount() {
    this.fetchDataFromServer()
  }

  componentDidUpdate(prevProps) {
    const {
      companyId: companyIdPrev,
      companyReducer: { company: companyPrev }
    } = prevProps
    const {
      companyId,
      companyReducer: { company },
      getCompanyById
    } = this.props
    const { translatedText } = this.state
    const description = get(company, 'description', '')
    const descriptionPrev = get(companyPrev, 'description', '')
    const photoUrlPrev = get(companyPrev, 'photoUrl', [])
    const photoUrl = get(company, 'photoUrl', [])

    if (companyIdPrev !== companyId && companyId) {
      getCompanyById(companyId)
    }

    if (!isEqual(photoUrlPrev, photoUrl)) {
      this.setState({ imagePreviewUrl: '' })
    }

    if (descriptionPrev !== description && description !== translatedText) {
      this.setState({ translatedText: description })
    }
  }

  fetchDataFromServer = async () => {
    const { GetCollectionAc, getCompanyById, GetVendorAc } = this.props
    const id = this.getId()

    GetCollectionAc(id)
    GetVendorAc({
      vendorId: id,
      onSuccess: (vendor) => {
        const companyId = getVendorCompany(vendor)

        if (companyId) {
          getCompanyById(companyId)
        }
      }
    })
  }

  getCollectionId = () => {
    const { collectionReducer } = this.props
    const collection = getCollection({ collectionReducer })

    return get(collection, '_id', '')
  }

  getId = () => get(this.props, 'match.params.vendorId', '')

  getUserType = () => {
    const { authReducer } = this.props

    return getUserType(authReducer)
  }

  change = (e) => {
    this.setState({ translatedText: e.target.value })
  }

  changeCompanyName = (e) => {
    this.setState({ companyName: e.target.value })
  }

  changePhotoInput = (e) => {
    this.setState({ photos: e.target.files })

    let reader = new FileReader()

    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result
      })
    }

    reader.readAsDataURL(e.target.files[0])
  }

  changeVideoInput = (e) => {
    this.setState({
      video: e.target.files[0]
    })
  }

  handleDeletePhoto = (photo = {}) => {
    const { id, path } = photo
    const { companyId, deleteMediaTx } = this.props

    deleteMediaTx({
      companyId,
      imageId: id,
      imageUrl: path
    })
  }

  prepareToUpdate = () => {
    const { companyId } = this.props
    const { video, photos: image, translatedText: description } = this.state
    const tmpObject = {
      video,
      image,
      description,
      _id: companyId
    }

    this.updateCompany(tmpObject)
  }

  removePhotoInput = () => {
    this.setState({
      imagePreviewUrl: '',
      photos: []
    })
  }

  removeVideoInput = () => {
    this.setState({ video: null })
  }

  savedPhotos = () => {
    const {
      companyReducer: { company }
    } = this.props

    return get(company, 'photoUrl', []).map((pu) => ({
      alt: pu,
      id: pu,
      path: pu
    }))
  }

  updateCompany = (bio) => {
    this.props.updateCompanyMedia({
      ...omit(bio, ['_id']),
      id: bio._id
    })
  }

  render() {
    const {
      companyReducer: { company, companyPhotoPending },
      intl,
      location: { pathname },
      vendorName,
      vendor
    } = this.props
    const { imagePreviewUrl, translatedText, video } = this.state
    const navItems = vendorNavItems({
      collectionId: this.getCollectionId(),
      id: this.getId(),
      intl,
      path: pathname,
      userType: this.getUserType()
    })
    const serverPhotos = this.savedPhotos()
    const serverVideo = get(company, 'videoUrl', null)
    const videoName = get(video, 'name', '')

    return (
      <InnerPage heading={vendorName} vendor={vendor}>
        <PageNav className={Style.pageNav} items={navItems} />
        <Row className="rowWide">
          <Col sm={12} md={7}>
            <div className={Style.history}>
              <FormattedMessage id="Company History" />
            </div>
            <FormGroup>
              <Input
                type="textarea"
                name="text"
                id="history"
                placeholder={intl.formatMessage({
                  id: 'type here your message'
                })}
                onChange={this.change}
                className={Style.textArea}
                value={translatedText}
              />
            </FormGroup>
          </Col>
          <Col sm={12} md={5}>
            <Row className="rowWide">
              <Col lg={12} md={12} sm={6}>
                <p className={Style.photoAndVideo}>
                  <FormattedMessage id="Profile Images" />
                </p>
                <div className={Style.photo}>
                  <InputFile
                    multiple
                    accept={ACCEPTED_FILE_TYPES.IMAGES}
                    className={Style.uploadBlock}
                    fileUrl={imagePreviewUrl}
                    label={intl.formatMessage({
                      id: 'Upload one or more image'
                    })}
                    size={178}
                    subLabel="(1400x1400)"
                    onChange={this.changePhotoInput}
                    onClose={this.removePhotoInput}
                  />
                </div>
              </Col>
              <Col md={12} sm={6}>
                <p className={Style.photoAndVideo}>
                  <FormattedMessage id="Company video" />
                </p>
                <div className={Style.photo}>
                  <InputFile
                    accept="video/*"
                    className={Style.uploadBlock}
                    fileUrl={videoName}
                    label={intl.formatMessage({
                      id: video ? 'Video selected' : 'Upload video'
                    })}
                    size={178}
                    subLabel="(mpg, avi)"
                    onChange={this.changeVideoInput}
                    onClose={this.removeVideoInput}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="text-left">
          <Button className={Style.saveButton} onClick={this.prepareToUpdate}>
            <FormattedMessage id="Save" />
          </Button>
        </div>
        {serverPhotos.length > 0 && (
          <ImagesList
            className={Style.mediaGalleries}
            colsWidth={{ lg: 3, md: 3, sm: 6 }}
            items={serverPhotos}
            pendingIds={companyPhotoPending}
            onClose={this.handleDeletePhoto}
          />
        )}
        {serverVideo && (
          <Row className={Style.mediaGalleries}>
            <VideoGallery video={serverVideo} />
          </Row>
        )}
      </InnerPage>
    )
  }
}

VendorBio.propTypes = {
  companyId: string,
  companyReducer: shape({
    company: shape({})
  }),
  getCompanyById: func,
  GetCollectionAc: func,
  GetVendorAc: func,
  collectionReducer: shape({}),
  authReducer: shape({}),
  deleteMediaTx: func,
  intl: shape({
    formatMessage: func
  }),
  updateCompanyMedia: func,
  location: shape({
    pathname: string
  }),
  vendorName: string,
  vendor: object
}
const mapActions = {
  deleteMediaTx,
  GetCollectionAc: GetCollection,
  getCompanyById,
  GetVendorAc: GetVendor,
  hideLoading: HideLoading,
  showLoading: ShowLoading,
  updateCompanyMedia
}
const mapStateToProps = (
  { authReducer, collectionReducer, companyReducer, vendorReducer: { vendor } },
  { intl: { formatMessage } }
) => ({
  authReducer,
  collectionReducer,
  companyId: getVendorCompany(vendor),
  companyReducer,
  vendor,
  vendorName:
    get(vendor, 'artisanName', '') || formatMessage({ id: 'Vendor Bio' })
})

export default injectIntl(connect(mapStateToProps, mapActions)(VendorBio))
