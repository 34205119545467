import cn from 'classnames'
import { InnerPage } from 'components/layout'
import { func } from 'prop-types'
import React, { Component } from 'react'
import { FaPlus } from 'react-icons/fa'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Button, Row, Tooltip } from 'reactstrap'

import AddProductDataTypes from '../components/add-product-data-types/add-product-data-types'
import ListProductDataTypes from '../components/list-product-data-types/list-product-data-types'
import { ShowModal } from '../redux/actions/product-types-action'
import Style from './styles/product-data-types.module.css'

class ProductDataTypes extends Component {
  state = {
    showAdd: false,
    plus: false
  }

  toggle = () =>
    this.setState({
      plus: !this.state.plus
    })

  showAddModal = () => this.props.ShowModal(true)

  render() {
    return (
      <InnerPage heading="Data Types">
        <Row className={Style.header}>
          <div className={cn('custom-control custom-switch', Style.switch)}>
            <Button
              className="btn btn-primary"
              id="plus"
              onClick={this.showAddModal}
            >
              <FaPlus color="var(--main-green-color)" size={16} />
              <Tooltip
                placement="bottom"
                isOpen={this.state.plus}
                target="plus"
                toggle={this.toggle}
              >
                <FormattedMessage id="add data types" />
              </Tooltip>
            </Button>
          </div>
        </Row>
        <AddProductDataTypes />
        <ListProductDataTypes className={Style.dataTypesList} />
      </InnerPage>
    )
  }
}

ProductDataTypes.propTypes = {
  ShowModal: func
}
const mapStateToProps = (state) => ({
  productDataReducer: state.productDataReducer
})

const mapDispatchToProps = (dispatch) => ({
  ShowModal: (status) => dispatch(ShowModal(status))
})

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ProductDataTypes)
)
