import React from 'react'
import { AiTwotoneEye } from 'react-icons/ai'
import { FaFlag, FaWallet } from 'react-icons/fa'
import { GiBedLamp } from 'react-icons/gi'
import { GetOrdersTh, ordersPageSet } from 'redux/actions/order-action'
import { vendorDashboardInitTh } from 'redux/thunks/vendor'
import { mapOrders } from 'utils/orders'
import { mapVendorProductsToDescribe } from 'utils/vendors'

export const mapActions = {
  GetOrdersTh,
  ordersPageSet,
  vendorDashboardInitTh
}

export const mapState = ({
  orderReducer: { currentPage, limit, orders, ordersFetching, pages, received },
  vendorReducer: { products }
}) => ({
  areOrdersReceived: received,
  currentPage,
  limit,
  ordersFetching,
  ordersRows: mapOrders(orders),
  pages,
  productsToDescribe: mapVendorProductsToDescribe(products),
  totalItems: [
    {
      content: '€65.340',
      icon: <FaWallet />,
      title: 'Total sales'
    },
    {
      content: '872',
      icon: <GiBedLamp />,
      title: 'Total products'
    },
    {
      content: '221.702',
      icon: <AiTwotoneEye />,
      title: 'Product views'
    },
    {
      content: '4.7',
      icon: <FaFlag />,
      title: 'Seller score'
    }
  ].map((item, i) => ({
    ...item,
    _id: i
  }))
})
