const {
  REACT_APP_API_HOST,
  REACT_APP_CUBEJS_API_HOST,
  REACT_APP_CUBEJS_API_PATH
} = process.env
const apiUrl = REACT_APP_API_HOST ? REACT_APP_API_HOST : ''

export const cubeApiUrl = REACT_APP_CUBEJS_API_HOST + REACT_APP_CUBEJS_API_PATH

export default apiUrl
