import cn from 'classnames'
import { bool, element, node, string } from 'prop-types'
import React from 'react'
import { Button as Btn } from 'reactstrap'

import st from './Button.module.css'

const Button = ({
  black,
  children,
  className,
  danger,
  icon,
  lightBrown,
  thin,
  color,
  disabled,
  ...props
}) => (
  <Btn
    {...props}
    disabled={disabled}
    color={color}
    className={cn(st.button, {
      [st.defaultColor]: !color,
      [className]: className.length > 0,
      [st.black]: black,
      [st.danger]: danger,
      [st.disabled]: disabled,
      [st.lightBrown]: lightBrown,
      [st.thin]: thin
    })}
  >
    {children}
    {icon}
  </Btn>
)

Button.defaultProps = {
  black: false,
  className: '',
  danger: false,
  disabled: false,
  icon: null,
  lightBrown: false,
  thin: false,
  type: 'button'
}

Button.propTypes = {
  black: bool,
  children: node.isRequired,
  className: string,
  danger: bool,
  disabled: bool,
  icon: element,
  lightBrown: bool,
  thin: bool,
  type: string,
  color: string
}

export default Button
