import { array } from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Col, Row } from 'reactstrap'

import Style from '../../pages/styles/vendor-collection-list.module.css'
import CardElement from './card-element'

const VendorCollectionListCard = (props) => {
  const renderCards = () =>
    props.cardsData.map((data) => (
      <Col key={data._id} md={3} lg={3} sm={6}>
        <CardElement cardData={data} />
      </Col>
    ))
  return props.cardsData ? (
    <Row>{renderCards()}</Row>
  ) : (
    <h2 className={Style.message}>
      <FormattedMessage id={'Products not found for current collection'} />
    </h2>
  )
}

VendorCollectionListCard.propTypes = {
  cardsData: array
}
export default VendorCollectionListCard
