import { includes } from 'lodash'
import { shape, string } from 'prop-types'
import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

import Footer from '../components/footer/footer'
import { dataOfButtons } from '../utils/header-button-data'
import Style from './styles/admin.module.css'

class Admin extends Component {
  renderButtons() {
    const userType = this.props.authReducer.userType || ''
    return dataOfButtons().map(({ key, rights, title, link, button }) => (
      <Row
        key={`${key}_${title}`}
        className={[
          'row justify-content-between',
          Style.ButtonsList,
          !includes(rights, userType) ? 'd-none' : ''
        ].join(' ')}
      >
        <Col md={4}>
          <h4>{title}</h4>
        </Col>
        <Col md={{ size: 4, offset: 4 }}>
          <Link className={['btn btn-primary', Style.link].join(' ')} to={link}>
            <FormattedMessage id={button} tagName={'span'} />
          </Link>
        </Col>
      </Row>
    ))
  }

  render() {
    return (
      <Fragment>
        <Row className={Style.mainDiv}>
          <div className="col-md-8 offset-2">
            <Row>
              <h1>
                <FormattedMessage id={'Admin Dashboard'} />
              </h1>
            </Row>
            {this.renderButtons()}
          </div>
        </Row>
        <Footer absolute />
      </Fragment>
    )
  }
}

Admin.propTypes = {
  authReducer: shape({
    userType: string
  })
}
const mapStateToProps = (state) => ({ authReducer: state.authReducer })

export default withRouter(injectIntl(connect(mapStateToProps)(Admin)))
