import cookie from 'react-cookies'
import { defineUserUrl } from 'utils/auth'

import { isExpired, parseJwt } from '../../utils/utils'
import { AUTHENTICATION, AUTHENTICATION_ERROR } from './action-types'

export const Auth = (authorized, message, username, user) => {
  return {
    type: AUTHENTICATION,
    authorized,
    username,
    userType: user ? user.profile.type : '',
    message,
    user
  }
}

export const AuthError = (errMessage) => {
  if (errMessage === 401 || errMessage === 'token expired')
    cookie.remove('artemest')
  return {
    type: AUTHENTICATION_ERROR,
    authorized: false,
    err: true,
    message: errMessage
  }
}

const CheckAuth = () => (dispatch) => {
  const token = cookie.load('artemest')

  if (token) {
    const user = parseJwt(token)

    if (isExpired(user.exp)) {
      return dispatch(AuthError('token expired'))
    }

    user.url = defineUserUrl(user)
    dispatch(Auth(true, 'success', user.roles ? user.roles[0] : {}, user))
  } else {
    dispatch(AuthError("can't find token"))
  }
}

export default CheckAuth
