import {
  ADDED_COLLECTION,
  APPROVE_COLLECTION,
  AUTHENTICATION_ERROR,
  COLLECTION_TRANSLATES_PAGE,
  COLLECTION_TRANSLATES_PENDING,
  DELETED_COLLECTION,
  DELETED_IMAGE,
  DISAPPROVE_COLLECTION,
  GET_PRODUCT_LIST_BY_COLLECTION_FOR_TRANSLATES,
  GOT_COLLECTION,
  GOT_COLLECTIONS,
  UPLOAD_IMAGE
} from '../actions/action-types'

const defaultState = {
  added: false,
  collection: null,
  collections: [],
  deleted: false,
  fetched: false,
  gotOne: false,
  pending: false,
  translatesList: {}
}

const CollectionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case COLLECTION_TRANSLATES_PENDING:
      return {
        ...state,
        ...action.payload
      }

    case ADDED_COLLECTION:
      return {
        ...state,
        added: action.added,
        collection: action.collection
      }

    case AUTHENTICATION_ERROR:
      return {
        ...state,
        ...defaultState
      }

    case GOT_COLLECTIONS:
      return {
        ...state,
        got: action.got,
        collections: action.collections
      }
    case DELETED_COLLECTION: {
      const { deleted, id } = action.payload

      return {
        ...state,
        collections: state.collections.filter(({ _id }) => _id !== id),
        deleted
      }
    }
    case APPROVE_COLLECTION:
      return {
        ...state,
        approved: action.approved
      }
    case UPLOAD_IMAGE:
      return {
        ...state,
        uploaded: action.uploaded
      }

    case COLLECTION_TRANSLATES_PAGE: {
      const { page } = action.payload
      const { translatesList } = state
      return {
        ...state,
        translatesList: {
          ...translatesList,
          page
        }
      }
    }

    case DISAPPROVE_COLLECTION:
      return {
        ...state,
        disapproved: action.disapproved
      }
    case DELETED_IMAGE:
      return {
        ...state,
        deletedImage: action.deletedImage,
        collection: action.collection
      }
    case GOT_COLLECTION:
      return {
        ...state,
        gotOne: action.gotOne,
        collection: action.collection,
        fetched: true
      }
    case GET_PRODUCT_LIST_BY_COLLECTION_FOR_TRANSLATES:
      return {
        ...state,
        gotTranslates: action.gotTranslates,
        translatesList: action.translatesList
      }
    default:
      return state
  }
}

export default CollectionReducer
