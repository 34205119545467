import cn from 'classnames'
import { Pagination, Spinner, Status } from 'components'
import { Button } from 'components/buttons'
import { Table } from 'components/tables'
import fileDownload from 'js-file-download'
import { get } from 'lodash'
import { bool, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { IoDiamondOutline, IoStarOutline } from 'react-icons/io5'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { downloadCSV, read } from 'redux/service/collection-service'
import { headingsApproved, SORT_BY } from 'utils/collections'
import { ORDER_BY } from 'utils/constants'

import Style from './approver-dashboard-component.module.css'

class Approved extends Component {
  static defaultProps = {
    filteredVendorName: '',
    history: {},
    viewSheets: false
  }
  static propTypes = {
    filteredVendorName: string,
    history: shape({}),
    viewSheets: bool
  }

  state = {
    docs: [],
    page: 1,
    pages: 1,
    pending: true
  }

  componentDidMount() {
    this.fetchCollections()
  }

  componentDidUpdate(prevProps, prevState) {
    const { filteredVendorName: filteredVendorNamePrev } = prevProps
    const { page: pagePrev } = prevState
    const { filteredVendorName } = this.props
    const { page } = this.state

    if (filteredVendorNamePrev !== filteredVendorName || pagePrev !== page) {
      this.fetchCollections({
        resetPage: filteredVendorNamePrev !== filteredVendorName
      })
    }
  }

  csvHandler = (id) => () => {
    downloadCSV(id).then((res) => {
      fileDownload(res.data, `${id}s_products.csv`)
    })
  }

  approwedRows = () => {
    const {
      authReducer
      // viewSheets -  TODO: Uncomment when the mass update CSV upload API is ready
    } = this.props
    const { docs } = this.state

    return docs
      ? docs.map(({ _id, name, productsCount, vendor }) => {
          const { artisanName, SKUprefix, _id: vendorId, vetted } = vendor
          const isPremium = get(vendor, 'premium.active', false)
          const actionButtons = [
            {
              className: Style.actionButton1,
              labelId: 'View Sheets',
              onClick: this.routeToVendorCollectionListPage.bind(
                this,
                _id,
                SKUprefix,
                authReducer.userType
              )
            }
          ]
          const vendorPath = `/vendor/${vendorId}`
          const rows = {
            cells: [
              { content: productsCount },
              {
                content: (
                  <Link to={vendorPath} onClick={this.handleVendorClick}>
                    <div className={Style.artisanName}>
                      {artisanName || name || SKUprefix}
                      <span>{isPremium && <IoStarOutline />}</span>
                      <span>{vetted && <IoDiamondOutline />}</span>
                    </div>
                  </Link>
                )
              },
              { content: name },
              {
                className: Style.actionCell,
                content: (
                  <div className={Style.actionButtonWrap}>
                    {actionButtons.map(
                      ({ className, labelId, ...buttonRest }) => (
                        <Button
                          {...buttonRest}
                          className={cn(Style.actionButton, className)}
                          key={labelId}
                        >
                          <FormattedMessage id={labelId} />
                        </Button>
                      )
                    )}
                  </div>
                )
              }
            ],
            _id
          }

          return rows
        })
      : []
  }

  fetchCollections = (config = {}) => {
    const { resetPage } = config
    const { filteredVendorName } = this.props
    const { page } = this.state
    const readArg = {
      allReviewed: true,
      approved: true,
      limit: 10,
      order: ORDER_BY.DESC,
      page: resetPage ? 1 : page,
      sort: SORT_BY.APPROVAL_DATE
    }

    if (filteredVendorName) {
      readArg.artisan = filteredVendorName
    }

    this.setState({ pending: true })

    read(readArg).then((data) => {
      this.setState({
        ...data,
        pending: false
      })
    })
  }

  handlePaginate = (page) => {
    this.setState({ page })
  }

  routeToVendorCollectionListPage = (id, vendor, owner) => {
    const { history } = this.props
    const path = `/collection-list/${owner}/${vendor}/${id}`

    return history.push(path)
  }

  render = () => {
    const { viewSheets } = this.props
    const { page, pages, pending } = this.state
    const rows = this.approwedRows()
    const rowsEmpty = rows.length === 0

    return (
      <>
        <div className={Style.tableTitle}>
          <FormattedMessage id="Approved collections" />
        </div>
        <Spinner show={pending} showDumb={rowsEmpty}>
          {pending || rows.length > 0 ? (
            <>
              <Table
                responsive
                striped
                headings={headingsApproved({
                  showActions: true,
                  viewSheets
                })}
                rows={rows}
              />
              <Pagination
                alignCenter
                className={Style.paginator}
                current={page}
                disabled={pending}
                pages={pages}
                visiblePages={3}
                onChange={this.handlePaginate}
              />
            </>
          ) : (
            <Status
              className={Style.notFound}
              fontSize="1.5em"
              textAlign="left"
            >
              <FormattedMessage id="No collections found for the current page" />
            </Status>
          )}
        </Spinner>
      </>
    )
  }
}

Approved.propTypes = {
  authReducer: shape({
    userType: string
  })
}
const mapStateToProps = (state) => ({
  authReducer: state.authReducer
})

export default connect(mapStateToProps)(Approved)
