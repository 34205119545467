import { Cube } from 'components'
import { get, uniqueId } from 'lodash'
import { bool, func, noop, number, shape, string } from 'prop-types'
import React, { useCallback } from 'react'
import { AiTwotoneEye } from 'react-icons/ai'
import { BiCalendarAlt } from 'react-icons/bi'
import {
  Bar,
  BarChart as ChartCmp,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { COLORS } from 'utils/constants'

import st from './BarChart.module.css'

const labelFormat = (label) => (
  <span className={st.labelFormatter}>
    <BiCalendarAlt className={st.labelFormatterIcon} />
    {label}
  </span>
)
const validateMappedResults = (results = []) => results.length > 0

const BarChart = ({
  barColor,
  cubeJsApi,
  height,
  mapChartData,
  query,
  valueItem,
  yAllowDecimals,
  yLabel
}) => {
  const mapResults = useCallback(
    (resultSet) =>
      get(resultSet, 'loadResponses.[0].data', []).map(mapChartData),
    [mapChartData]
  )
  const tooltipFormat = useCallback(
    (value) => [
      <span key={uniqueId()} className={st.barValue}>
        <AiTwotoneEye className={st.barIcon} />
        {value}
        {valueItem}
      </span>
    ],
    [valueItem]
  )

  return (
    <Cube
      isChart
      cubeJsApi={cubeJsApi}
      height={height}
      mapResults={mapResults}
      query={query}
      validateMappedResults={validateMappedResults}
    >
      {({ data }) => (
        <ChartCmp data={data}>
          <Tooltip
            contentStyle={{
              background: '#000',
              fontSize: '0.875em'
            }}
            formatter={tooltipFormat}
            itemStyle={{ color: '#fff' }}
            labelFormatter={labelFormat}
            labelStyle={{ color: '#fff' }}
          />
          <CartesianGrid vertical={false} />
          <Bar dataKey="y" fill={barColor} />
          <XAxis dataKey="x" />
          <YAxis
            allowDecimals={yAllowDecimals}
            label={{
              angle: -90,
              position: 'insideLeft',
              value: yLabel
            }}
          />
        </ChartCmp>
      )}
    </Cube>
  )
}

BarChart.defaultProps = {
  barColor: COLORS.BAR,
  cubeJsApi: {},
  height: 200,
  mapChartData: noop,
  query: {},
  valueItem: '',
  yAllowDecimals: true,
  yLabel: ''
}
BarChart.propTypes = {
  barColor: string,
  cubeJsApi: shape({}),
  height: number,
  mapChartData: func,
  query: shape({}),
  valueItem: string,
  yAllowDecimals: bool,
  yLabel: string
}

export default BarChart
