import { cloneDeep, get, pick } from 'lodash'
import { LIST_LIMIT_DEFAULT } from 'utils/constants'

import {
  AUTHENTICATION_ERROR,
  GET_ORDERS,
  ORDER_SHIPMENT_PENDING,
  ORDER_SHIPMENT_STATE,
  ORDERS_FETCHING,
  ORDERS_PAGE_SET
} from '../actions/action-types'

const defaultState = {
  currentPage: 1,
  limit: LIST_LIMIT_DEFAULT,
  orders: [],
  ordersFetching: false,
  pages: 1,
  pendingShipment: {},
  received: false
}

const OrderReducer = (state = defaultState, action) => {
  switch (action.type) {
    case AUTHENTICATION_ERROR:
      return {
        ...state,
        ...defaultState
      }

    case ORDERS_FETCHING:
    case ORDERS_PAGE_SET:
      return {
        ...state,
        ...action.payload
      }

    case GET_ORDERS:
      return {
        ...state,
        ...pick(action, ['currentPage', 'limit', 'pages']),
        orders: get(action, 'orders') || [],
        vendorId: get(action, 'vendorId', ''),
        received: true
      }

    case ORDER_SHIPMENT_PENDING: {
      const { pending, pendingShipment } = action.payload
      const newPendingShipment = {
        ...state.pendingShipment
      }

      if (pending) {
        newPendingShipment[pendingShipment] = Boolean(pending)
      } else {
        delete newPendingShipment[pendingShipment]
      }

      return {
        ...state,
        pendingShipment: newPendingShipment
      }
    }

    case ORDER_SHIPMENT_STATE: {
      const { orderId, shipment, state: shipmentState } = action.payload
      const newOrders = cloneDeep(state.orders)
      const orderIdx = state.orders.findIndex((o) => o.id === orderId)
      const shipmentIdx = get(
        newOrders,
        `[${orderIdx}].shipments`,
        []
      ).findIndex((o) => o.number === shipment)

      if (orderIdx < 0 || shipmentIdx < 0) {
        return state
      }

      newOrders[orderIdx].shipments[shipmentIdx].state = shipmentState

      return {
        ...state,
        orders: newOrders
      }
    }

    default:
      return state
  }
}

export default OrderReducer
