/**
 * @param {Object} config
 * @param {String} config.decimalSign - '.' or ','
 * @param {String} config.value - numeric string
 */
export const decimalStringToNumber = (config = {}) => {
  const { decimalSign = '.', value = '' } = config

  if (!value || value === '+') {
    return ''
  }

  if (value === '-') {
    return value
  }

  const valueWithoutSeparators = value.split(',').join('')

  if (value[value.length - 1] === '.' && decimalSign === '.') {
    return valueWithoutSeparators
  }

  if (decimalSign === ',') {
    const splittedByDot = value.split('.')
    const valueNoSeparated = splittedByDot.join('')
    const [whole, decimal] = valueNoSeparated.split(',')

    if (decimal) {
      return parseFloat(`${whole}.${decimal}`)
    }

    if (valueNoSeparated === whole) {
      return parseFloat(valueNoSeparated)
    }

    return valueNoSeparated
  }

  return parseFloat(valueWithoutSeparators)
}
