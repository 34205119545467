import { Cube } from 'components'
import { VendorAnalyticsLayout } from 'components/layout'
import { SalesTable } from 'components/tables'
import { get } from 'lodash'
import { func, shape, string } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { GetVendor } from 'redux/actions/vendor-action'
import { canSeeCubeData, getCubeJsApi } from 'utils/cube'

import { getCubeQuery, getData, mapState } from './config'
import st from './VendorAnalyticsSales.module.css'

const VendorAnalyticsSales = ({
  analyticsToken,
  location: { pathname },
  match,
  authReducer,
  GetVendor,
  vendorName
}) => {
  const [timeFrame, setTimeFrame] = useState(90)
  const vendorId = get(match, 'params.id', '')
  const cubeProps = {
    cubeJsApi: getCubeJsApi(analyticsToken),
    mapResults: (resultSet) => getData(resultSet),
    query: getCubeQuery(timeFrame),
    validateMappedResults: (resultSet) => resultSet.length > 0
  }

  useEffect(() => {
    GetVendor({ vendorId })
  }, [])

  return (
    <VendorAnalyticsLayout
      currentURL={pathname}
      timeFrame={timeFrame}
      setTimeFrame={setTimeFrame}
      vendorId={vendorId}
      authReducer={authReducer}
      vendorName={vendorName}
    >
      {canSeeCubeData({
        canSee: Boolean(analyticsToken),
        userType: get(authReducer, 'userType'),
        toShow: (
          <Cube {...cubeProps}>
            {({ data }) => <SalesTable className={st.table} items={data} />}
          </Cube>
        )
      })}
    </VendorAnalyticsLayout>
  )
}

VendorAnalyticsSales.propTypes = {
  analyticsToken: string,
  vendorName: string,
  location: shape({
    pathname: string
  }),
  match: shape({}),
  authReducer: shape({}),
  GetVendor: func
}

const mapActions = {
  GetVendor
}

export default connect(mapState, mapActions)(VendorAnalyticsSales)
