import cubejs from '@cubejs-client/core'
import { Status } from 'components'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { USER_TYPES } from './constants'

export const canSeeCubeData = ({
  canSee = false,
  userType,
  toShow = null
} = {}) =>
  canSee ? (
    toShow
  ) : (
    <Status fontSize="1.5em">
      <FormattedMessage id="analyticsNeedVendorSubscription" />
      {userType === USER_TYPES.VENDOR && (
        <>
          <br />
          <FormattedMessage
            id="pleaseContactInfo"
            values={{
              email: (
                <a href="mailto:premium@artemest.com">premium@artemest.com</a>
              )
            }}
          />
        </>
      )}
    </Status>
  )

export const getCubeJsApi = (token = '') => {
  const { REACT_APP_CUBEJS_API_HOST, REACT_APP_CUBEJS_API_PATH } = process.env

  if (token && token !== '') {
    return cubejs(token, {
      apiUrl: REACT_APP_CUBEJS_API_HOST + REACT_APP_CUBEJS_API_PATH
    })
  }
}
