import { PageNavVendorActions } from 'components'
import { InnerPage } from 'components/layout'
import { get } from 'lodash'
import { func, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { GetVendor } from 'redux/actions/vendor-action'
import { getUserType } from 'utils/auth'
import { getApprovedProducts } from 'utils/collections'
import { getVendorName } from 'utils/utils'

import ImageAssetsComponent from '../components/image-assets/image-assets-component'
import Loading from '../components/loading/loading'
import { readCollection } from '../redux/service/collection-service'
import Style from './styles/image-assets.module.css'

const mapActions = {
  GetVendor
}
const mapState = (state) => ({
  authReducer: state.authReducer,
  vendorReducer: state.vendorReducer
})

class ImageAssets extends Component {
  state = { collection: '', products: null }

  componentDidMount() {
    const {
      GetVendor,
      location: { state },
      match: { params }
    } = this.props
    const collection = get(state, 'collection')
    const vendorId = this.getVendorId()

    GetVendor({ vendorId })

    if (!state || !state.collection) {
      this.routeToBuyerDashboard(params.owner)

      return false
    }

    this.setState({ collection })
    readCollection(collection._id).then(({ data, error }) => {
      if (error) {
        return
      }

      const resCollection = get(data, '[0]')

      if (resCollection) {
        this.setState({
          products: getApprovedProducts(resCollection)
        })
      }
    })
  }

  handleClickVAImages = () => {
    const { history } = this.props
    const { collection } = this.state
    const owner = this.getOwner()
    const vendorId = this.getVendorId()

    history.push({
      pathname: `/image-assets/${owner}/${vendorId}`,
      state: { collection }
    })
  }

  renderProd = (products) => {
    return products.map((product) => (
      <ImageAssetsComponent key={product._id} product={product} />
    ))
  }

  routeToBuyerDashboard = (owner) => {
    this.props.history.push({
      pathname: `/approval-dashboard/${owner}`,
      state: {}
    })
  }

  getCollectionId = () => get(this.state, 'collection._id')
  getOwner = () => get(this.props, 'match.params.owner', '')
  getVendorId = () => get(this.props, 'match.params.vendor', '')

  render() {
    const {
      authReducer,
      match: { url },
      vendorReducer
    } = this.props
    const { products, collection } = this.state
    const collectionId = this.getCollectionId()
    const userType = getUserType(authReducer)
    const vendorId = this.getVendorId()
    const vendorName = getVendorName(vendorReducer)

    return products ? (
      <InnerPage heading={vendorName}>
        <PageNavVendorActions
          collectionId={collectionId}
          currentUrl={url}
          userType={userType}
          vendorId={vendorId}
          onClickImages={this.handleClickVAImages}
        />
        <Row className="rowWide">
          <Col xs={12}>
            <h3 className={Style.hd}>
              <FormattedMessage id="Collection name:" />
              <b>{collection.name}</b>
            </h3>
            <h5>
              <FormattedMessage id="Upload final images for use in website" />
            </h5>
            <div className={Style.prods}>{this.renderProd(products)}</div>
          </Col>
        </Row>
      </InnerPage>
    ) : (
      <Loading />
    )
  }
}

ImageAssets.propTypes = {
  GetVendor: func,
  location: shape({
    state: shape({
      collection: string
    })
  }),
  match: shape({
    params: shape({
      owner: string,
      url: string
    })
  }),
  history: shape({
    push: func
  }),
  authReducer: shape({}),
  vendorReducer: shape({})
}
export default connect(mapState, mapActions)(injectIntl(ImageAssets))
