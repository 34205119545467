import cn from 'classnames'
import { Button } from 'components/buttons'
import { FieldsList } from 'components/formParts'
import Spinner from 'components/Spinner/Spinner'
import { Form, Formik } from 'formik'
import { bool, func, node, object, shape, string } from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Col, Row } from 'reactstrap'

import Style from './../../../styles/vendor-contact.module.css'
import {
  additionalFields,
  addressFields,
  createFormFields,
  timeFields,
  validateFields
} from './config'
import st from './PickupForm.module.css'
import PickupLocationsSelect from './PickupLocationsSelect'

function PickupForm({
  className,
  intl,
  isLoading,
  pickupFormSubmitting,
  onSubmit,
  pickupFormDataFromContact,
  ...props
}) {
  const t = intl.formatMessage

  return (
    <Formik
      validate={(values) => validateFields(values, intl)}
      enableReinitialize
      onSubmit={onSubmit}
      {...props}
    >
      {({ values, setValues }) => (
        <Form className={cn(className)}>
          {isLoading ? (
            <Spinner show />
          ) : (
            <Row>
              <Col xs={12} sm={6}>
                <div className={Style.titleRow}>
                  <FormattedMessage id="Pickup address" />
                </div>
                <div className={Style.artemestPickupRow}>
                  <FormattedMessage id="artemestPickupRow" />
                  <PickupLocationsSelect
                    values={values}
                    setValues={setValues}
                    pickupFormDataFromContact={pickupFormDataFromContact}
                  />
                </div>
                <FieldsList fields={createFormFields(t, addressFields)} />
              </Col>
              <Col xs={12} sm={6}>
                <div className={Style.titleRow}>
                  <FormattedMessage id="Pickup time" />
                </div>
                <FieldsList fields={createFormFields(t, timeFields)} />
                <div className={Style.titleRow} style={{ marginTop: '20px' }}>
                  <FormattedMessage id="Additional informations" />
                </div>
                <FieldsList fields={createFormFields(t, additionalFields)} />
                <div className={st.submitButtonWrapper}>
                  <Button black type="submit">
                    {pickupFormSubmitting ? (
                      <Spinner
                        color="--main-bg-light"
                        width={43}
                        length={3}
                        size={14}
                        show
                      />
                    ) : (
                      <FormattedMessage id="Save" />
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </Form>
      )}
    </Formik>
  )
}

PickupForm.propTypes = {
  className: string,
  initialValues: shape({
    label: node,
    name: string,
    placeholder: string,
    value: string
  }),
  intl: shape({ formattedMessage: func }),
  isLoading: bool,
  pickupFormSubmitting: bool,
  onSubmit: func,
  pickupFormDataFromContact: object
}
export default PickupForm
