export const cols = (intl = {}) => {
  const titles = [
    'Product',
    'Ready/Packages',
    'Shipping Date',
    'Status',
    '',
    'Value'
  ]

  return titles.map((id, i) => {
    const res = {
      content: id ? intl.formatMessage({ id }) : '',
      _id: id || 'sizes'
    }

    if (i === titles.length - 1) {
      res.colSpan = 2
    }

    return res
  })
}
