import {
  AUTHENTICATION_ERROR,
  TRANSLATIONS_GET_SUCCESS,
  UPDATE_TRANSLATIONS,
  UPDATE_TRANSLATIONS_BY_ON_ID
} from '../actions/action-types'

const defaultState = {
  added: false,
  gotByOnId: null,
  translation: null,
  translations: [],
  updated: false
}

const TranslationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case AUTHENTICATION_ERROR:
      return {
        ...state,
        ...defaultState
      }

    case TRANSLATIONS_GET_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_TRANSLATIONS:
      return {
        ...state,
        updated: action.updated
      }
    case UPDATE_TRANSLATIONS_BY_ON_ID:
      return {
        ...state,
        gotByOnId: action.gotByOnId,
        translation: action.translation
      }
    default:
      return state
  }
}

export default TranslationReducer
