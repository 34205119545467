import {
  arrayOf,
  bool,
  node,
  number,
  oneOfType,
  shape,
  string
} from 'prop-types'

export const PROP_TYPES = {
  className: string,
  gutter: oneOfType([number, string]),
  items: arrayOf(
    shape({
      active: bool,
      content: node,
      id: oneOfType([number, string])
    })
  ),
  mod: number,
  noWrap: bool
}
