import { noop } from 'lodash'
import { HideLoading, ShowLoading } from 'redux/actions/loading-action'
import {
  productAttachementPendingAdd,
  productAttachementPendingRemove,
  productAttachementRemove,
  productAttachementUpdate,
  productAudited,
  productDiscontinued,
  productMainImagePending,
  productMainImageUploaded,
  productOnboardingPhotoGot,
  productOtherImagePending,
  productPhotosSet,
  productReactivated,
  productsByCollectionGot,
  productUpdateSuccess
} from 'redux/actions/product-action'
import {
  audit,
  deleteProductImage,
  deleteResource,
  edit,
  flagDiscontinued,
  flagIncomplete,
  getProductsByCollectionId,
  reactivate,
  removeAttachmentService,
  saveAttachementService,
  saveImages,
  saveMainImage as saveMainImageService,
  saveOnboardingPhoto
} from 'redux/service/product-service'
import { PRODUCT_RESOURCES } from 'utils/constants'
import { notifyMappedError } from 'utils/errors'
import { getFormDataWithFiles } from 'utils/files'
import { showNotify } from 'utils/toast'

/**
 * @param {Object} config
 * @param {String} config.productId
 */
export const auditProduct =
  (config = {}) =>
  (dispatch) => {
    const { productId } = config
    dispatch(ShowLoading())
    audit(productId).then(({ product }) => {
      if (product) {
        dispatch(productAudited(product))
      }
      dispatch(HideLoading())
    })
  }

/**
 * @param {Object} config
 * @param {String} config.productId
 */
export const flagAsIncomplete =
  (config = {}) =>
  (dispatch) => {
    const { productId } = config
    dispatch(ShowLoading())
    flagIncomplete(productId).then(({ product }) => {
      if (product) {
        dispatch(productAudited(product))
      }
      dispatch(HideLoading())
    })
  }

/**
 * @param {Object} config
 * @param {String} config.productId
 */
export const flagAsDiscontinued =
  (config = {}) =>
  (dispatch) => {
    const { productId } = config
    dispatch(ShowLoading())
    flagDiscontinued(productId).then(({ product }) => {
      if (product) {
        dispatch(productDiscontinued(product))
      }
      dispatch(HideLoading())
    })
  }

/**
 * @param {Object} config
 * @param {String} config.productId
 */
export const reactivateProduct =
  (config = {}) =>
  (dispatch) => {
    const { productId } = config
    dispatch(ShowLoading())
    reactivate(productId).then(({ product }) => {
      if (product) {
        dispatch(productReactivated(product))
      }
      dispatch(HideLoading())
    })
  }

/**
 * @param {Object} data
 * @param {String} data.imagePath
 * @param {String} data.productId
 */
export const deleteOtherImage =
  ({ imagePath = '', productId = '' }) =>
  (dispatch) => {
    dispatch(
      productOtherImagePending({
        imagePath,
        pending: true
      })
    )

    deleteProductImage({
      imageUrl: imagePath,
      productId
    }).then(({ error, photos }) => {
      if (error) {
        showNotify({
          isError: true,
          message: error.message
        })
      }

      if (photos) {
        dispatch(productPhotosSet(photos))
      }

      dispatch(productOtherImagePending({ imagePath }))
    })
  }

/**
 * @param {Object} config
 * @param {String} config.productId
 * @param {String} config.resourceType - PRODUCT_RESOURCES
 */
export const deleteResourceTh =
  (config = {}) =>
  (dispatch) => {
    const { productId } = config

    dispatch(productMainImagePending(true))
    deleteResource(config).then(({ error, success }) => {
      if (error) {
        notifyMappedError(error)
      }

      if (success) {
        const { resourceType } = config
        const { ONBOARDING_PHOTO } = PRODUCT_RESOURCES

        switch (resourceType) {
          case ONBOARDING_PHOTO:
            dispatch(productOnboardingPhotoGot())
            break

          default:
            dispatch(productMainImageUploaded({ productId }))
        }
      }

      dispatch(productMainImagePending())
    })
  }

/**
 * @param {Object} config
 * @param {Function} config.formatMessage
 * @param {String} config.id
 * @param {Object} config.product
 */
export const editProduct =
  (config = {}) =>
  (dispatch) => {
    const { formatMessage = noop, id, product } = config

    dispatch(ShowLoading())

    edit(id, product).then(({ product }) => {
      if (product) {
        dispatch(productUpdateSuccess(product))
        showNotify({
          message: formatMessage({ id: 'product update success' })
        })
      }

      dispatch(HideLoading())
    })
  }

/**
 * @param {Object} config
 * @param {String} config.collectionId
 * @param {Function} config.mapProducts
 */
export const getProductsByCollectionTh =
  ({ collectionId = '', mapProducts = (p) => p } = {}) =>
  (dispatch) => {
    dispatch(ShowLoading())

    getProductsByCollectionId({ collectionId }).then(({ error, products }) => {
      if (error) {
        notifyMappedError(error)
      }

      if (products) {
        const mapped = mapProducts(products)

        dispatch(productsByCollectionGot(mapped))
      }

      dispatch(HideLoading())
    })
  }

/**
 * @param {Object} data
 * @param {File} data.image
 * @param {String} data.productId
 */
export const saveMainImage =
  ({ image, productId }) =>
  (dispatch) => {
    if (!image) {
      return
    }

    dispatch(productMainImagePending(true))

    saveMainImageService({
      formData: getFormDataWithFiles({ files: [image] }),
      productId
    }).then(({ error = {}, fileUrl }) => {
      const notifyArgs = {
        isError: true,
        message: error.message
      }

      if (fileUrl) {
        dispatch(
          productMainImageUploaded({
            mainPhoto: fileUrl,
            productId
          })
        )
        notifyArgs.isError = false
        notifyArgs.message = 'Main photo uploaded successfully'
      }

      showNotify(notifyArgs)
      dispatch(productMainImagePending())
    })
  }

/**
 * @param {Object} config
 * @param {File} config.file
 * @param {String} config.productId
 */
export const saveOnboardingPhotoTh =
  (config = {}) =>
  (dispatch) => {
    dispatch(productMainImagePending(true))

    saveOnboardingPhoto(config).then(({ error, onboardingPhoto }) => {
      if (error) {
        notifyMappedError(error)
      }

      if (onboardingPhoto) {
        dispatch(productOnboardingPhotoGot(onboardingPhoto))
      }

      dispatch(productMainImagePending())
    })
  }

/**
 * @param {Object} data
 * @param {Files} data.imageFiles
 * @param {String} data.productId
 */
export const saveOtherImages =
  (data = {}) =>
  (dispatch) => {
    const { imageFiles = [], productId = '' } = data

    if (imageFiles.length === 0) {
      return
    }

    const bodyFormData = new FormData()

    dispatch(ShowLoading())
    Array.prototype.forEach.call(imageFiles, (image) =>
      bodyFormData.append('image', image)
    )

    saveImages({
      formData: bodyFormData,
      productId
    }).then(({ error = {}, fileUrls }) => {
      const notifyArgs = {
        isError: true,
        message: error.message
      }

      if (fileUrls) {
        dispatch(productPhotosSet(fileUrls))
        notifyArgs.isError = false
        notifyArgs.message = 'Images uploaded successfully'
      }

      dispatch(HideLoading())
    })
  }

/**
 * @param {Object} config
 * @param {FileList} config.files
 * @param {String} config.productId
 * @param {String} config.type
 */
export const uploadFiles =
  (config = {}) =>
  (dispatch) => {
    const { files = [], type = '', productId = '' } = config
    if (files.length === 0) {
      return
    }

    dispatch(productAttachementPendingAdd(type))
    const formData = getFormDataWithFiles({ files })

    saveAttachementService({ formData, productId, type }).then(
      ({ fileUrl, error }) => {
        if (error) {
          notifyMappedError(error)
        } else {
          showNotify({ message: `File uploaded successfully!` })
          dispatch(productAttachementUpdate(type, fileUrl))
          dispatch(productAttachementPendingRemove(type))
        }
      }
    )
  }

/**
 * @param {Object} config
 * @param {String} config.type
 * @param {String} config.productId
 */
export const deleteFiles =
  (config = {}) =>
  (dispatch) => {
    const { type, productId } = config

    dispatch(productAttachementPendingAdd(type))

    removeAttachmentService({ productId, type }).then(() => {
      showNotify({ message: `File removed successfully!` })
      dispatch(productAttachementRemove(type))
      dispatch(productAttachementPendingRemove(type))
    })
  }
