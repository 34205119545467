import {
  ADDED_COMPANY,
  AUTHENTICATION_ERROR,
  COMPANY_PHOTO_PENDING,
  GET_COMPANY,
  UPDATE_COMPANY
} from '../actions/action-types'

const initialState = {
  company: {},
  companyPhotoPending: {},
  saved: false,
  updated: false
}

const CompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADDED_COMPANY:
      return {
        ...state,
        saved: true
      }

    case AUTHENTICATION_ERROR:
      return {
        ...state,
        ...initialState
      }

    case COMPANY_PHOTO_PENDING: {
      const { image, pending } = action.payload
      const newCompanyPhotoPending = {
        ...state.companyPhotoPending
      }

      if (pending) {
        newCompanyPhotoPending[image] = pending
      } else {
        delete newCompanyPhotoPending[image]
      }

      return {
        ...state,
        companyPhotoPending: newCompanyPhotoPending
      }
    }
    case UPDATE_COMPANY:
      return {
        ...state,
        updated: true
      }
    case GET_COMPANY:
      return {
        ...state,
        company: action.company
      }
    default:
      return {
        ...state
      }
  }
}

export default CompanyReducer
