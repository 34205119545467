import { get, take, uniqueId } from 'lodash'

export const getCubeQuery = (days) => ({
  limit: 5000,
  dimensions: [
    'SalesProducts.sku',
    'SalesProducts.product_name',
    'SalesProducts.image_link'
  ],
  measures: [
    'SalesProducts.interactions',
    'SalesProducts.GMV',
    'SalesProducts.units',
    'SalesProducts.detailViews',
    'SalesProducts.listViews'
  ],
  order: {
    'SalesProducts.GMV': 'desc',
    'SalesProducts.interactions': 'desc'
  },
  timeDimensions: [
    {
      dimension: 'SalesProducts.date',
      dateRange: `last ${days} days`
    }
  ]
})

export const getData = (resultSet = {}) => {
  const data = get(resultSet, 'loadResponses.[0].data')

  return take(data, 300).map((dataItem) => {
    const name = dataItem['SalesProducts.product_name']

    return {
      gmv: dataItem['SalesProducts.GMV'],
      name,
      sku: dataItem['SalesProducts.sku'],
      image: dataItem['SalesProducts.image_link'],
      units: +dataItem['SalesProducts.units'],
      interactions: dataItem['SalesProducts.interactions'],
      views: dataItem['SalesProducts.detailViews'],
      listViews: dataItem['SalesProducts.listViews'],
      _id: uniqueId(name)
    }
  })
}

export const mapState = ({ authReducer, vendorReducer: { vendor } }) => ({
  analyticsToken: get(vendor, 'premium.analyticsToken', ''),
  vendorName: get(vendor, 'artisanName', '') || get(vendor, 'name', ''),
  authReducer
})
