import { HideLoading, ShowLoading } from 'redux/actions/loading-action'
import { changeProductType } from 'redux/service/product-service'

/**
 * @param {Object} config
 * @param {Function} config.dispatchRedux
 * @param {String} config.productId
 * @param {String} config.typeId
 */
export const changeTypeTh =
  (config = {}) =>
  (dispatch) => {
    const { productId = '', typeId = '' } = config

    if (!typeId) {
      return
    }

    dispatch(ShowLoading())
    changeProductType(productId, typeId).then(() => {
      dispatch(HideLoading())
    })
  }
