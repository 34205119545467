import { NewOnboarding, PageTop } from 'components'
import Approved from 'components/approver-dashboard-components/approved'
import ToApprove from 'components/approver-dashboard-components/to-approve'
import { SearchFormLittle } from 'components/forms'
import { InnerPage } from 'components/layout'
import { func, shape } from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { getUserType, isApprover } from 'utils/auth'
import { USER_TYPES } from 'utils/constants'

class ApproverWorkflow extends Component {
  state = {
    vendorNameToFilter: ''
  }

  isApprover = () => {
    const { authReducer } = this.props

    return isApprover(authReducer)
  }

  submitFilterVendor = ({ value }) => {
    this.setState({
      vendorNameToFilter: value
    })
  }

  userType = () => {
    const { authReducer } = this.props

    return getUserType(authReducer)
  }

  render() {
    const { history, intl } = this.props
    const { vendorNameToFilter } = this.state
    const userType = this.userType()
    const viewSheets =
      userType === USER_TYPES.ADMIN || userType === USER_TYPES.MANAGER

    return (
      <InnerPage>
        <PageTop
          left={this.isApprover() ? null : <NewOnboarding />}
          right={
            <SearchFormLittle
              defaultValue={vendorNameToFilter}
              placeholder={intl.formatMessage({
                id: 'Search for artisans'
              })}
              onSubmit={this.submitFilterVendor}
            />
          }
        />
        <Row className="rowWide">
          <Col xs={12} lg={6}>
            <ToApprove
              filteredVendorName={vendorNameToFilter}
              history={history}
            />
          </Col>
          <Col xs={12} lg={6}>
            <Approved
              filteredVendorName={vendorNameToFilter}
              history={history}
              viewSheets={viewSheets}
            />
          </Col>
        </Row>
      </InnerPage>
    )
  }
}

ApproverWorkflow.propTypes = {
  authReducer: shape({}),
  intl: shape({
    formatMessage: func
  }),
  history: shape({})
}
// TODO create route
const mapStateToProps = (state) => ({
  authReducer: state.authReducer
})

export default injectIntl(connect(mapStateToProps)(ApproverWorkflow))
