import { cloneDeep, get } from 'lodash'

import {
  ADDED_VENDOR,
  APPROVE_VENDOR,
  AUTHENTICATION_ERROR,
  CHANGE_ADD_STATUS,
  DELETE_URL,
  DELETE_VENDOR,
  GET_VENDOR,
  GET_VENDORS,
  SAVE_PROMOTE,
  SAVED_HANDLE,
  UPDATE_VENDOR,
  VENDOR_PREMIUM_ANALYTICS_ENABLE,
  VENDOR_PRODUCTS_GOT
} from '../actions/action-types'

const defaultState = {
  added: false,
  approved: false,
  delete: false,
  deletedUrl: '',
  products: [],
  received: false,
  savedPromote: null,
  updated: false,
  vendor: null,
  vendors: []
}

const UserReducer = (state = defaultState, action) => {
  switch (action.type) {
    case AUTHENTICATION_ERROR:
      return {
        ...state,
        ...defaultState
      }

    case VENDOR_PREMIUM_ANALYTICS_ENABLE: {
      const { analyticsEnabled } = action.payload
      const premium = get(state, 'vendor.premium')

      if (!premium) {
        return state
      }

      return {
        ...state,
        vendor: {
          ...state.vendor,
          premium: {
            ...premium,
            analyticsEnabled
          }
        }
      }
    }

    case VENDOR_PRODUCTS_GOT:
      return {
        ...state,
        ...action.payload
      }

    case ADDED_VENDOR:
      return {
        ...state,
        added: action.added,
        vendor: action.vendor,
        delete: action.delete
      }
    case GET_VENDOR:
      return {
        ...state,
        received: true,
        vendor: action.vendor,
        delete: action.delete
      }
    case UPDATE_VENDOR:
      return {
        ...state,
        added: action.added,
        updated: action.updated,
        delete: action.delete
      }
    case GET_VENDORS:
      return {
        ...state,
        received: true,
        vendors: action.vendors,
        delete: action.delete
      }
    case DELETE_VENDOR:
      return {
        ...state,
        delete: action.delete
      }
    case APPROVE_VENDOR: {
      const { approved } = action
      const vendorProfileData = get(state.vendor, 'profile.data')
      let newVendor = null

      if (vendorProfileData) {
        newVendor = cloneDeep(state.vendor)
        newVendor.profile.data.approved = approved
      }

      return {
        ...state,
        approved,
        vendor: newVendor || state.vendor
      }
    }
    case CHANGE_ADD_STATUS:
      return {
        ...state,
        added: action.added
      }
    case SAVE_PROMOTE:
      return {
        ...state,
        savedPromote: action.savedPromote,
        vendor: {
          ...state.vendor,
          images: action.promotedImages || defaultState.promotedImages
        }
      }
    case SAVED_HANDLE:
      return {
        ...state,
        vendor: action.vendor
      }
    case DELETE_URL:
      return {
        ...state,
        deletedUrl: action.deletedUrl,
        vendor: {
          ...state.vendor,
          images: action.images
        }
      }
    default:
      return state
  }
}

export default UserReducer
