import axios from 'axios/index'
import cookie from 'react-cookies'
import { mapError } from 'utils/errors'

import apiUrl from './apiUrl'

const add = (data) =>
  axios.post(`${apiUrl}/api/vendor/`, data, {
    headers: { token: cookie.load('artemest') }
  })

const update = (id, data) =>
  axios.put(`${apiUrl}/api/vendor/${id}`, data, {
    headers: { token: cookie.load('artemest') }
  })

const changeStatus = (id, status) =>
  axios.put(
    `${apiUrl}/api/vendor/${id}/status`,
    { status },
    {
      headers: { token: cookie.load('artemest') }
    }
  )

const get = () =>
  axios.get(`${apiUrl}/api/vendor/`, {
    headers: { token: cookie.load('artemest') }
  })

const getVendorById = async (vendorId = '') => {
  const apiPath = `${apiUrl}/api/vendor/${vendorId}`

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return { data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

const remove = (id) =>
  axios.delete(`${apiUrl}/api/vendor/${id}`, {
    headers: { token: cookie.load('artemest') }
  })

const approve = (id, isApprove) =>
  axios.put(
    `${apiUrl}/api/vendor/approve/${id}`,
    { approve: isApprove },
    { headers: { token: cookie.load('artemest') } }
  )

const approveById = (id, isApprove) =>
  axios.put(
    `${apiUrl}/api/vendor/approve-vendor/${id}`,
    { approve: isApprove },
    { headers: { token: cookie.load('artemest') } }
  )

const getFilteredVendors = (page, limit, name) =>
  axios.get(`${apiUrl}/api/vendor/`, {
    params: { page, limit, name },
    headers: { token: cookie.load('artemest') }
  })

const savePromoteService = (id, data) =>
  axios.put(`${apiUrl}/api/vendor/promote/${id}`, data, {
    headers: { token: cookie.load('artemest') }
  })

const getPromote = (id) =>
  axios.get(`${apiUrl}/api/vendor/promote/${id}`, {
    headers: { token: cookie.load('artemest') }
  })

const removeImage = (id, url) =>
  axios.put(
    `${apiUrl}/api/vendor/remove-image/${id}`,
    { url },
    { headers: { token: cookie.load('artemest') } }
  )

const saveInstaHandle = (id, nickname) =>
  axios.put(
    `${apiUrl}/api/vendor/insta-name/${id}`,
    { nickname },
    {
      headers: { token: cookie.load('artemest') }
    }
  )

const setPremium = (id, endDate, status, planId) =>
  axios.put(
    `${apiUrl}/api/user/${id}/premium`,
    { endDate, status, planId },
    {
      headers: { token: cookie.load('artemest') }
    }
  )

const saveAttachement = async (id, type, files) => {
  const apiPath = `${apiUrl}/api/vendor/attachment/${type}`
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('files', file)
  })
  if (id) {
    formData.append('id', id)
  }

  try {
    const { data, status } = await axios.put(apiPath, formData, {
      headers: { token: cookie.load('artemest') }
    })
    if (status !== 200) {
      throw new Error(`Unable to attach file: ${data}`)
    }
    return data
  } catch (error) {
    return { error: mapError(error) }
  }
}

const deleteAttachment = async (id, type, file) => {
  const apiPath = `${apiUrl}/api/vendor/${id}/attachment/${type}`

  try {
    const { data, status } = await axios.delete(apiPath, {
      data: { file },
      headers: { token: cookie.load('artemest') }
    })
    if (status !== 200) {
      throw new Error(`Unable to attach file: ${data}`)
    }
    return data
  } catch (error) {
    return { error: mapError(error) }
  }
}

export {
  add,
  approve,
  approveById,
  changeStatus,
  deleteAttachment,
  get,
  getFilteredVendors,
  getPromote,
  getVendorById,
  remove,
  removeImage,
  saveAttachement,
  saveInstaHandle,
  savePromoteService,
  setPremium,
  update
}
