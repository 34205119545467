import cookie from 'react-cookies'
import { defineUserUrl } from 'utils/auth'
import { COOKIES, LANG_DEFAULT_CODE, LANG_EXPIRES } from 'utils/constants'

import notify, { notifyPositions } from '../../utils/toast'
import { parseJwt } from '../../utils/utils'
import {
  forgotPassword,
  login,
  logout,
  read,
  resetPassword
} from '../service/user-service'
import {
  FORGOT_PASSWORD,
  GOT_USER,
  INVALID_AUTHORIZATION,
  RESET_ERROR_STATE,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  USER_EMAIL_UPDATE_SUCCESS
} from './action-types'
import { Auth, AuthError } from './auth-action'
import { HideLoading, ShowLoading } from './loading-action'

const InvalidAuthorization = (_message, error) => ({
  type: INVALID_AUTHORIZATION,
  message: _message,
  error
})

const resetErrorState = (error) => ({
  type: RESET_ERROR_STATE,
  error
})

const Update = (_message, updated) => ({
  type: UPDATE_PASSWORD,
  message: _message,
  updated
})

const setPassword = (_message, updated) => ({
  type: RESET_PASSWORD,
  message: _message,
  updated
})

const forgotPasswordCompleted = (completed) => ({
  type: FORGOT_PASSWORD,
  completed
})

const getUser = (isGotten, user) => ({
  type: GOT_USER,
  isGotten,
  user
})

const LogIn = (user) => (dispatch) => {
  login(user)
    .then((res) => {
      if (res.status === 200) {
        const user = parseJwt(res.data.token)

        if (!user.active) {
          dispatch(AuthError(401))
          notify('error', 'User is inactive', notifyPositions.top.center)
          return
        }

        const dateExp = new Date().valueOf() + user.exp
        const language = user.language || LANG_DEFAULT_CODE

        cookie.save(COOKIES.AUTH, res.data.token, {
          expires: new Date(dateExp),
          path: '/'
        })
        cookie.save(COOKIES.LANG, language, {
          expires: LANG_EXPIRES,
          path: '/'
        })

        user.url = defineUserUrl(user)

        dispatch(Auth(true, 'success', user._id, user))
      }
    })
    .catch((err) => {
      dispatch(InvalidAuthorization('Could not get data from service', true))
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
    })
    .then(() => {
      dispatch(resetErrorState(false))
    })
}

const LogOut = () => (dispatch) => {
  dispatch(ShowLoading())
  logout()
    .then(() => {
      cookie.remove(COOKIES.AUTH, { path: '/' })
      dispatch(AuthError('logOut'))
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }

      notify('error', 'server error', notifyPositions.bottom.center)
    })
    .finally(() => {
      cookie.remove(COOKIES.AUTH)
      dispatch(HideLoading())
    })
}

const GetUser = (id) => (dispatch) => {
  read(id)
    .then((res) => {
      dispatch(getUser(true, res.data))
    })
    .catch((err) => {
      dispatch(getUser(false, null))
      if (err.response && err.response.status && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
    })
}

const ResetPassword = (token, data) => (dispatch) => {
  dispatch(ShowLoading())
  resetPassword(token, data)
    .then((res) => {
      dispatch(HideLoading())
      if (res && res.status === 200) {
        dispatch(setPassword('Password set', true))
      }
    })
    .catch((err) => {
      dispatch(HideLoading())
      notify('error', 'password not set', notifyPositions.bottom.center)
      if (err.response && err.response.status && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      dispatch(setPassword("can't reset password", false))
    })
}

const ForgotPassword = (email) => (dispatch) => {
  dispatch(ShowLoading())
  forgotPassword(email)
    .then((res) => {
      dispatch(HideLoading())
      if (res && res.status === 200) {
        dispatch(forgotPasswordCompleted(true))
      }
    })
    .catch(() => {
      dispatch(HideLoading())
      dispatch(forgotPasswordCompleted(false))
      notify('error', 'user not found', notifyPositions.bottom.center)
    })
}

const userEmailUpdateSuccess = (email = '') => ({
  payload: { email },
  type: USER_EMAIL_UPDATE_SUCCESS
})

export {
  ForgotPassword,
  GetUser,
  LogIn,
  LogOut,
  ResetPassword,
  Update,
  userEmailUpdateSuccess
}
